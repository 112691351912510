import './list-section.scss';

type ListSectionProps = {
  title: string;
  list: string[];
};

export const ListSection: React.FC<ListSectionProps> = ({ title, list }) =>
  list.length > 0 ? (
    <section className="list-section">
      <h3 className="list-section__title">{title}</h3>

      <ul className="list-section__list">
        {list.map((item) => (
          <li className="list-section__item" key={item}>
            {item}
          </li>
        ))}
      </ul>
    </section>
  ) : null;
