import clsx from 'clsx';
import { Card } from 'components/card';
import './no-resource.scss';

type NoResourceProps = {
  className?: string;
};

export const NoResource: React.FC<NoResourceProps> = ({
  children,
  className,
}) => {
  return (
    <div className={clsx('info-card no-resource', className)}>
      <Card>{children}</Card>
    </div>
  );
};
