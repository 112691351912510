import { useCallback } from 'react';
import clsx from 'clsx';

type UseRadioClassNamesParams = {
  checked: boolean;
  disabled: boolean;
  correct: boolean;
};

type CallbackParams = {
  baseClassName: string;
  turnsRedOnError?: boolean;
};

export const useRadioClassNames = ({
  checked,
  disabled,
  correct,
}: UseRadioClassNamesParams) => {
  return useCallback(
    ({ baseClassName, turnsRedOnError }: CallbackParams) => {
      return clsx(
        baseClassName,
        checked && `${baseClassName}--checked`,
        disabled && `${baseClassName}--disabled`,
        turnsRedOnError &&
          checked &&
          disabled &&
          correct &&
          `${baseClassName}--green`,
        turnsRedOnError &&
          checked &&
          disabled &&
          !correct &&
          `${baseClassName}--red`
      );
    },
    [checked, disabled, correct]
  );
};
