import { Children, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/card';
import { Input, Select } from 'components/form';
import { SubscriptionName } from 'components/subscription-name';
import { ViewWrapper } from 'components/view-wrapper';
import { format } from 'helpers/time';
import { useSubscriptions } from 'hooks/use-subscriptions';
import './subscription.scss';

const Subscription: React.FC = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('-1');
  const {
    filteredSubscriptions,
    uniqueOptionsNames,
    restCompanySubscriptionsQuery,
    restSubscriptionOptionsQuery,
  } = useSubscriptions(searchPhrase, selectedSubscriptionId);
  const { t } = useTranslation();

  return (
    <ViewWrapper
      loading={
        restCompanySubscriptionsQuery.isLoading ||
        restSubscriptionOptionsQuery.isLoading
      }
    >
      <div className="subscription">
        <header className="subscription__header">
          <Input
            className="subscription__search"
            type="search"
            value={searchPhrase}
            placeholder={t('find-course')}
            onChange={(value) => {
              setSearchPhrase(value);
            }}
          />
          <Select
            name="subscription-select"
            options={[
              {
                value: `-1`,
                label: `${t('all-packages')}`,
              },
              ...uniqueOptionsNames,
            ]}
            onChange={(value) => setSelectedSubscriptionId(value)}
          />
        </header>

        <Card className="subscription__table-wrapper">
          <table className="subscription__table">
            <thead className="subscription__thead">
              <tr>
                <th className="subscription__th">{t('subscription')}</th>
                <th className="subscription__th">{t('course')}</th>
                <th className="subscription__th">{t('date-range')}</th>
                <th className="subscription__th">{t('slots')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredSubscriptions.map((subscriptionElement) => {
                const coursesNames: string[] = [];
                const coursesDates: string[] = [];
                const coursesSlots: number[] = [];

                subscriptionElement.company_subscription_courses.forEach(
                  (companySubscription) => {
                    const startDate = format(companySubscription.start_date);
                    const endDate = format(companySubscription.end_date);
                    coursesNames.push(companySubscription.course.name);
                    coursesDates.push(`${startDate} - ${endDate}`);
                    coursesSlots.push(companySubscription.slots);
                  }
                );
                return Children.toArray(
                  <tr className="subscription__tr" key={subscriptionElement.id}>
                    <td className="subscription__td">
                      <SubscriptionName
                        className="subscription__name"
                        name={subscriptionElement.subscription.name}
                      />
                    </td>
                    <td className="subscription__td">
                      {coursesNames.map((courseName) =>
                        Children.toArray(
                          <p className="subscription__content subscription__content--left">
                            {courseName}
                          </p>
                        )
                      )}
                    </td>
                    <td className="subscription__td">
                      {coursesDates.map((dates) =>
                        Children.toArray(
                          <p className="subscription__content subscription__content--date">
                            {dates}
                          </p>
                        )
                      )}
                    </td>
                    <td className="subscription__td">
                      {coursesSlots.map((slots) =>
                        Children.toArray(
                          <p className="subscription__content">{slots}</p>
                        )
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </div>
    </ViewWrapper>
  );
};

export default Subscription;
