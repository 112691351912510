import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar-line.svg';
import './coaching-sessions-button.scss';

export const CoachingSessionsButton = () => {
  const { t } = useTranslation();

  return (
    <Tippy content={t('coaching-sessions')}>
      <div className="coaching-sessions-button">
        <Link to="/coaching-sessions">
          <IconCalendar className="coaching-sessions-button__icon" />
        </Link>
      </div>
    </Tippy>
  );
};
