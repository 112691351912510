import clsx from 'clsx';
import { Loader } from 'components/loader';
import { getUserInitials } from 'helpers/utils';
import { Avatar } from './avatar';
import { PersonName } from './person-name';
import { Separator } from './separator';
import './people-list.scss';

type Person = {
  id: number;
  avatar: string | null;
  first_name: string | null;
  last_name: string | null;
};

type ButtonProps = {
  personId: number;
};

type PeopleListProps = {
  isLoading: boolean;
  title: string;
  subtitle: string;
  people: Person[] | null;
  button?: React.ComponentType<ButtonProps>;
  handleClick?: (id: number) => void;
};

export const PeopleList: React.FC<PeopleListProps> = ({
  isLoading,
  title,
  subtitle,
  people,
  handleClick,
  button: Button,
}) => (
  <section className="people-list">
    <header className="people-list__header">
      <h3 className="people-list__title">{title}</h3>
      <h4 className="people-list__subtitle">{subtitle}</h4>
    </header>

    <Separator />

    <ul className="people-list__list">
      {isLoading ? (
        <Loader />
      ) : (
        (people || []).map(({ id, avatar, first_name, last_name }) => (
          <li key={id} className="people-list__item">
            {handleClick ? (
              <button
                onClick={() => handleClick(id)}
                className="people-list__button"
              >
                <Avatar
                  className="people-list__avatar"
                  avatar={avatar}
                  userInitials={getUserInitials({ first_name, last_name })}
                  alt={`${first_name} ${last_name} avatar`}
                />
              </button>
            ) : (
              <Avatar
                className="people-list__avatar"
                avatar={avatar}
                userInitials={getUserInitials({ first_name, last_name })}
                alt={`${first_name} ${last_name} avatar`}
              />
            )}
            <div className="people-list__wrapper">
              {handleClick ? (
                <button
                  onClick={() => handleClick(id)}
                  className={clsx(
                    'people-list__button',
                    'people-list__button--underlined'
                  )}
                >
                  <PersonName>
                    {first_name} {last_name}
                  </PersonName>
                </button>
              ) : (
                <PersonName>
                  {first_name} {last_name}
                </PersonName>
              )}
              {!!Button && <Button personId={id} />}
            </div>
          </li>
        ))
      )}
    </ul>
  </section>
);
