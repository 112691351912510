import clsx from 'clsx';
import { convertPackageClass } from 'helpers/utils';
import './subscription-name.scss';

type SubscriptionNameProps = {
  name: string;
  className?: string;
};

export const SubscriptionName: React.FC<SubscriptionNameProps> = ({
  name,
  className,
}) => {
  return (
    <p
      className={clsx(
        'subscription-name',
        `subscription-name--${convertPackageClass(name)}`,
        className
      )}
    >
      {name}
    </p>
  );
};
