import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts';
import { isFuture } from 'date-fns';
import { CourseTile } from 'components/course-tile';
import { Loader } from 'components/loader';
import { NoResource } from 'components/no-resource';
import { ViewTransition } from 'components/view-transition';
import { useCourses } from 'hooks/use-courses';
import './courses-gridV2.scss';

export const CoursesGridV2: React.FC = () => {
  const { t } = useTranslation();
  const { isSales } = useContext(AuthContext);

  const { isLoading, courses, availableCourses } = useCourses({ isSales });

  const isCourseDisabled = (startDate: string) => isFuture(new Date(startDate));

  if (isLoading) {
    return <Loader />;
  }

  if (!courses?.length && !isSales) {
    return <NoResource>{t('no-courses')}</NoResource>;
  }
  return (
    <ViewTransition resolved={!isLoading}>
      <div className="courses-grid">
        <div className="courses-grid__grid--header">{t('your-courses')}</div>
        <div className="courses-grid__grid">
          {!isSales &&
            courses &&
            courses.map((course) => (
              <div className="courses-grid__content" key={course.id}>
                <CourseTile
                  disabled={isCourseDisabled(course.start_date)}
                  key={course.id}
                  subscriptionCourse={course}
                  courseId={course.course.id}
                />
              </div>
            ))}
          {isSales &&
            availableCourses &&
            availableCourses.map((course) => (
              <div className="courses-grid__content" key={course.id}>
                <CourseTile key={course.id} availableCourses={course} />
              </div>
            ))}
        </div>
      </div>
    </ViewTransition>
  );
};
