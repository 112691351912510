import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Loader } from 'components/loader';
import { ModuleContentHeader } from 'components/module-content-header';
import { Video } from 'components/video';
import { useCourseLesson } from 'hooks/use-course-lesson';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { ModuleDetails } from 'types/api';
import { AnswerStatus } from 'types/enums';
import { LessonTask } from './lesson-task';
import './lesson-details.scss';

type LessonDetailsProps = {
  modules: ModuleDetails[];
  moveToNext: () => Promise<void>;
  courseSubscriptionId: number;
};

export const LessonDetails: React.FC<LessonDetailsProps> = ({
  modules,
  moveToNext,
  courseSubscriptionId,
}) => {
  const { get } = useQueryParameters();
  const { lesson, restLessonQuery, finishLesson } =
    useCourseLesson(courseSubscriptionId);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(
    Boolean(get('task-open'))
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const isTaskSentAgain = useRef<boolean>(false);
  const { t } = useTranslation();
  const { isParticipant } = useContext(AuthContext);

  const currentModule = modules.find(
    (module) => module.id === lesson?.course_module_id
  );

  const moduleOrder =
    modules.findIndex((module) => module.id === lesson?.course_module_id) + 1;

  const handleFinishLesson = async () => {
    setIsProcessing(true);

    finishLesson();

    if (lesson?.task) {
      setIsTaskModalOpen(true);
    } else {
      moveToNext();
    }
  };

  const handleModalClose = () => {
    if (isProcessing) {
      moveToNext();
    } else {
      const isAnswerRejected =
        lesson?.task?.participant_answer?.status === AnswerStatus.FAILED;

      if (
        (isAnswerRejected || !lesson?.task?.participant_answer) &&
        isTaskSentAgain.current
      ) {
        isTaskSentAgain.current = false;
      }
      setIsTaskModalOpen(false);
    }
  };

  if (restLessonQuery.isLoading) {
    return <Loader />;
  }
  return (
    <Card className="lesson-details">
      {lesson && (
        <>
          <ModuleContentHeader
            moduleOrder={moduleOrder}
            moduleName={currentModule?.name || ''}
            coach={currentModule?.coach}
          >
            {lesson.task && (
              <LessonTask
                isTaskModalOpen={isTaskModalOpen}
                onModalOpen={() => setIsTaskModalOpen(true)}
                onModalClose={handleModalClose}
                onSend={() => {
                  isTaskSentAgain.current = true;
                }}
                task={lesson.task}
                isLessonCompleted={!!lesson.user_lesson?.completed}
              />
            )}
          </ModuleContentHeader>
          {lesson && (
            <Video
              source={lesson.video_url as string}
              initialTime={lesson.user_lesson?.video_watched_time as number}
              lessonSlug={lesson.slug}
            />
          )}
          {lesson.task?.instruction && (
            <div
              dangerouslySetInnerHTML={{
                __html: String(lesson.task.instruction),
              }}
              className="lesson-details__desc-content"
            />
          )}
          {!lesson.user_lesson?.completed && isParticipant && (
            <div className="lesson-details__navigation">
              <Button
                loading={isProcessing}
                className="lesson-details__back-button"
                onClick={handleFinishLesson}
              >
                {t('end-lesson')}
              </Button>
            </div>
          )}
        </>
      )}
    </Card>
  );
};
