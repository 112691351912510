import { Loader } from 'components/loader';
import { useSelectsData } from 'hooks/use-selects-data';
import { useTasks } from 'hooks/use-tasks';
import { useTasksFilters } from 'hooks/use-tasks-filters';
import { TasksHeader } from './components/tasks-header';
import { Tasks } from './views';

export const ParticipantTask: React.FC = () => {
  const {
    courseIds,
    moduleIds,
    currentPage,
    participantIds,
    companyId,
    status,
    sortBy,
    sortDirection,
  } = useTasksFilters();
  const { courseModulesQuery, participantsQuery, companyDetailsQuery } =
    useSelectsData();

  const isSelectsDataLoading = [
    courseModulesQuery,
    participantsQuery,
    companyDetailsQuery,
  ].some((query) => query.isLoading);

  const apiParameters = {
    course_id: courseIds || null,
    course_module_id: moduleIds || null,
    page: currentPage,
    participant_id: participantIds || null,
    company_id: companyId || null,
    status: status || null,
    sort_by: sortBy || null,
    sort_direction: sortDirection || null,
  };

  const {
    restTasksQuery,
    tasks,
    tasksMeta,
    removeTask,
    editTaskSubmit,
    addTaskSubmit,
  } = useTasks(apiParameters);

  const isTasksLoading = restTasksQuery.isLoading;

  if (isTasksLoading && isSelectsDataLoading) {
    return <Loader />;
  }

  return (
    <div className="participant-task">
      <TasksHeader
        addTaskSubmit={addTaskSubmit}
        isSelectsDataLoading={isSelectsDataLoading}
      />
      <Tasks
        currentPage={currentPage}
        isLoading={isTasksLoading}
        tasks={tasks}
        tasksMeta={tasksMeta}
        removeTask={removeTask}
        editTaskSubmit={editTaskSubmit}
      />
    </div>
  );
};

export default ParticipantTask;
