import clsx from 'clsx';
import { LazyAvatar } from 'components/lazy-avatar';
import { CompanyDetails } from 'types/api';
import { ReactComponent as CompanyAvatar } from 'assets/icons/avatar.svg';
import './company.scss';

type CompanyProps = {
  company?: CompanyDetails;
  avatarSize?: 'small' | 'medium' | 'large' | 'extra-large';
  displayColumn?: boolean;
  onlyLogo?: boolean;
  className?: string;
};

export const Company: React.FC<CompanyProps> = ({
  company,
  avatarSize,
  displayColumn = false,
  onlyLogo = false,
  className,
}) => {
  return (
    <div
      className={clsx('company', displayColumn && 'company--column', className)}
      title={company?.name}
    >
      <div
        className={clsx(
          'company__avatar-wrapper',
          avatarSize && `company__avatar-wrapper--${avatarSize}`
        )}
      >
        {company?.logo ? (
          <LazyAvatar
            imageClassName="company__image"
            src={company.logo}
            alt={company.logo}
          />
        ) : (
          <CompanyAvatar className="company__image" />
        )}
      </div>
      {!onlyLogo && (
        <div
          className={clsx(
            'company__profile-name',
            displayColumn && 'company__profile-name--column'
          )}
          title={company?.name}
        >
          {company?.name}
        </div>
      )}
    </div>
  );
};
