import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { AuthContext } from 'contexts';
import {
  getParticipantQuiz,
  startQuiz as startQuizRequest,
  submitQuiz as submitQuizRequest,
} from 'services/courses';
import { QuizAttemptType } from 'types/enums';

interface SubmitQuizData {
  id: string;
  answers: { answer_ids: number[] };
}

export const useParticipantQuiz = () => {
  const { t } = useTranslation();
  const [isQuizInProgress, setIsQuizInProgress] = useState(false);
  const { isParticipant } = useContext(AuthContext);
  const history = useHistory();

  const { quizType, quizId, courseId } = useParams<{
    quizType: QuizAttemptType.PRE_QUIZ | QuizAttemptType.POST_QUIZ;
    quizId: string;
    courseId: string;
  }>();

  const baseRedirectPath = `/courses/${courseId}/`;

  const { data: quizInfo, ...restQuizInfoQuery } = useQuery(
    [QueryKeys.PARTICIPANT_QUIZ_INFO, quizId, quizType],
    () =>
      getParticipantQuiz(quizId, {
        attempt_type: quizType,
      }),
    {
      onError: () => {
        toast.error(t('failed-to-get-quiz-info'));
        history.push(`${baseRedirectPath}lesson`);
      },
      enabled: !!quizId && isParticipant,
    }
  );

  const queryClient = useQueryClient();

  const {
    mutate: startQuiz,
    data: startQuizResponse,
    ...restStartQuizMutation
  } = useMutation((id: string) => startQuizRequest(id), {
    onError: () => {
      toast.error(t('failed-to-start-quiz'));
      setIsQuizInProgress(false);
    },
  });

  const quizInfoId = quizInfo?.quiz.id;

  const { mutateAsync: submitQuiz, ...restSubmitQuizMutation } = useMutation(
    ({ id, answers }: SubmitQuizData) => submitQuizRequest(id, answers),
    {
      onSuccess: () => {
        setIsQuizInProgress(false);
        queryClient.invalidateQueries([
          QueryKeys.PARTICIPANT_QUIZ_INFO,
          quizId,
          quizType,
        ]);
      },
      onError: () => {
        toast.error(t('failed-to-submit-quiz'));
      },
    }
  );

  const initiate = useCallback(async () => {
    setIsQuizInProgress(true);
    if (quizInfoId) {
      startQuiz(String(quizInfoId));
    }
  }, [quizInfoId, startQuiz]);

  const submit = useCallback(
    (answers: { answer_ids: number[] }) => {
      if (!quizInfo || !quizInfoId) {
        return;
      }
      submitQuiz({ id: quizInfoId.toString(), answers });
    },
    [quizInfoId, quizInfo, submitQuiz]
  );

  return {
    quizInfo,
    isQuizInProgress,
    questions: startQuizResponse?.questions,
    initiate,
    submit,
    restQuizInfoQuery,
    restStartQuizMutation,
    restSubmitQuizMutation,
  };
};
