import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { t } from 'i18next';
import { getCompaniesDetails } from 'services/dashboard';

export const useDashboard = () => {
  const { data: companies, ...rest } = useQuery(
    QueryKeys.COACH_DASHBOARD,
    () => getCompaniesDetails(),
    {
      onError: () => toast.error(t('companies-error')),
    }
  );

  return { companies, ...rest };
};
