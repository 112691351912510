import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { ChosenTimeslot } from 'components/coaching-session-modal/select-date-step';
import { Loader } from 'components/loader';
import { MonthlyCalendar } from 'components/monthly-calendar';
import { TabSwitch } from 'components/tab-switch';
import { WeeklyCalendar } from 'components/weekly-calendar';
import { convertDateToApiCompatible } from 'helpers/coaching-session-calendar';
import { today } from 'helpers/time';
import { StateSetter } from 'helpers/types';
import { getFullName } from 'helpers/utils';
import { CalendarView, useCalendarViews } from 'hooks/use-calendar-view';
import { useCoachTimeslots } from 'hooks/use-coach-timeslots';
import { CourseModules, MeetingUser, SortedTimeslot } from 'types/api';
import './new-date-select.scss';

export type SelectedSlot = {
  date: Date;
  id: number;
};

type NewDateSelectProps = {
  moduleId: string;
  provider: MeetingUser | null;
  participant: MeetingUser;
  updatedSlot: ChosenTimeslot;
  selectedCourse?: CourseModules;
  setUpdatedSlot: StateSetter<ChosenTimeslot>;
  chosenTimeslotInit?: ChosenTimeslot;
  goForward: () => void;
  goBack: () => void;
};

export const NewDateSelect: React.FC<NewDateSelectProps> = ({
  moduleId,
  participant,
  selectedCourse,
  chosenTimeslotInit = { startTime: today().toISOString(), endTime: '-1' },
  goForward,
  setUpdatedSlot,
  goBack,
}) => {
  const { t } = useTranslation();
  const [selectedSlot, setSelectedSlot] = useState({
    date: today(),
    id: -1,
  });
  const [chosenTimeslot, setChosenTimeslot] = useState(chosenTimeslotInit);
  const [errorMessage, setErrorMessage] = useState('');

  const { month, setMonth, viewType, tabs, changeTab, ISODates } =
    useCalendarViews(new Date(chosenTimeslot.startTime));

  const { coachTimeslots, isLoading: isLoadingTimeslots } = useCoachTimeslots({
    moduleId,
    ISODates,
  });

  const getSortedTimeslots = (
    timeslotsAvailability = coachTimeslots?.availability
  ) => {
    if (!timeslotsAvailability) return [];
    const sortedTimeslots: SortedTimeslot[] = [];
    timeslotsAvailability.forEach(({ start_time, end_time }) => {
      const key = convertDateToApiCompatible(new Date(start_time));
      const index = sortedTimeslots.findIndex(
        ({ date }) => convertDateToApiCompatible(new Date(date)) === key
      );
      if (index === -1) {
        sortedTimeslots.push({
          date: key,
          timeSlots: [{ start_time, end_time }],
        });
      } else {
        sortedTimeslots[index].timeSlots.push({ start_time, end_time });
      }
    });
    return sortedTimeslots;
  };

  const sortedTimeSlots = getSortedTimeslots();

  const handleTabChange = (value: string) => {
    changeTab(value);
    setChosenTimeslot(chosenTimeslotInit);
  };

  const submitHandler = () => {
    if (
      chosenTimeslot.startTime === chosenTimeslotInit.startTime ||
      chosenTimeslot.endTime === chosenTimeslotInit.endTime
    ) {
      setErrorMessage(t('date-required'));
      return;
    }
    if (coachTimeslots && sortedTimeSlots) {
      setUpdatedSlot(chosenTimeslot);
      goForward();
    }
  };

  const lastAvaliableCoachSessionDate = selectedCourse?.end_date
    ? new Date(selectedCourse.end_date)
    : undefined;

  const meetingWith = getFullName(participant);

  if (isLoadingTimeslots) {
    return <Loader />;
  }

  return (
    <div className="new-date-select">
      <p className="new-date-select__participant">
        <Trans i18nKey="meeting-with" values={{ person: meetingWith }}>
          <span className="new-date-select__span">Meeting with</span>
          {meetingWith}
        </Trans>
      </p>

      <h3 className="new-date-select__sub-title">{t('choose-date')}</h3>

      <TabSwitch
        className="new-date-select__switch"
        value={viewType}
        onChange={handleTabChange}
        tabs={tabs}
      />

      {viewType === CalendarView.WEEKLY && (
        <WeeklyCalendar
          selectedSlot={selectedSlot}
          maxDate={lastAvaliableCoachSessionDate}
          setSelectedSlot={setSelectedSlot}
          setErrorMessage={setErrorMessage}
          setChosenTimeslot={setChosenTimeslot}
          coachTimeSlots={sortedTimeSlots}
          chosenTimeSlot={chosenTimeslot}
        />
      )}
      {viewType === CalendarView.MONTHLY && (
        <MonthlyCalendar
          maxDate={lastAvaliableCoachSessionDate}
          minDate={today()}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          selectedMonth={month}
          setSelectedMonth={setMonth}
          setErrorMessage={setErrorMessage}
          coachTimeSlots={sortedTimeSlots}
          setChosenTimeslot={setChosenTimeslot}
          chosenTimeSlot={chosenTimeslot}
        />
      )}

      <div className="new-date-select__wrapper">
        <div className="new-date-select__buttons">
          <Button onClick={goBack} variant="secondary">
            {t('back')}
          </Button>

          <Button onClick={submitHandler}>{t('next')}</Button>
        </div>

        {errorMessage && (
          <p className="new-date-select__error">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};
