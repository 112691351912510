import clsx from 'clsx';
import { format, isSameDay } from 'date-fns';
import {
  ChosenTimeslot,
  SelectedSlot,
} from 'components/coaching-session-modal/select-date-step';
import {
  getActiveTimeslots,
  getAvailableTimeSlots,
  isTimeSlotAvailable,
} from 'helpers/coaching-session-calendar';
import { StateSetter } from 'helpers/types';
import { useDatePicker } from 'hooks/use-date-picker';
import { Availability, TimeslotsAvailability } from 'types/api';
import './single-day.scss';

type SingleDayProps = {
  date: Date;
  availability?: Availability[];
  chosenTimeSlot?: ChosenTimeslot;
  selectedSlot: SelectedSlot;
  setSelectedSlot: StateSetter<SelectedSlot>;
  setErrorMessage?: (message: string) => void;
  setChosenTimeslot?: StateSetter<ChosenTimeslot>;
  coachTimeSlots?: { date: string; timeSlots: TimeslotsAvailability[] };
};

export const SingleDay: React.FC<SingleDayProps> = ({
  date,
  availability,
  setSelectedSlot,
  setErrorMessage,
  selectedSlot,
  coachTimeSlots,
  chosenTimeSlot,
  setChosenTimeslot,
}) => {
  const { MONTHS, WEEKDAYS } = useDatePicker();

  const handleDayClick = (id: number, startTime?: string, endTime?: string) => {
    if (setErrorMessage) {
      setErrorMessage('');
    }
    if (coachTimeSlots && setChosenTimeslot && startTime && endTime) {
      setChosenTimeslot({
        startTime,
        endTime,
      });
    } else {
      setSelectedSlot({
        id,
        date,
      });
    }
  };

  const isTimeSlotActive = (id: number) =>
    selectedSlot.id === id && isSameDay(selectedSlot.date, date);

  const isCoachTimeSlotActive = (startTime: string) => {
    return chosenTimeSlot && chosenTimeSlot.startTime === startTime;
  };

  return (
    <div className="single-day">
      <div className="single-day__date">
        <p className="single-day__date--day">{date.getDate()}</p>
        <p className="single-day__date--month">{MONTHS[date.getMonth()]}</p>
        <p className="single-day__date--day-long">{WEEKDAYS[date.getDay()]}</p>
      </div>
      <div className="single-day__hours">
        {!coachTimeSlots &&
          availability &&
          getAvailableTimeSlots(date, availability)
            .filter(isTimeSlotAvailable)
            .map((slot) => (
              <button
                type="button"
                className={clsx(
                  'single-day__slot',
                  isTimeSlotActive(slot.id) && 'single-day__slot--active'
                )}
                key={slot.id}
                onClick={() => handleDayClick(slot.id)}
              >
                {slot.time}
              </button>
            ))}
        {coachTimeSlots &&
          getActiveTimeslots(coachTimeSlots.timeSlots).map((slot) => (
            <button
              type="button"
              className={clsx(
                'single-day__slot',
                'single-day__slot--wide',

                isCoachTimeSlotActive(slot.start_time) &&
                  'single-day__slot--active'
              )}
              key={slot.start_time}
              onClick={() => handleDayClick(0, slot.start_time, slot.end_time)}
            >
              {format(new Date(slot.start_time), 'k:mm')} -{' '}
              {format(new Date(slot.end_time), 'k:mm')}
            </button>
          ))}
      </div>
    </div>
  );
};
