import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { AuthContext } from 'contexts';
import { getCourseDetails } from 'services/dashboard';
import { Card } from 'components/card';
import { CourseAccordion } from 'components/course-accordion';
import { Loader } from 'components/loader';
import { NoResource } from 'components/no-resource';
import { StatsContent } from 'components/stats-content';
import { encipherId } from 'helpers/utils';
import { useCourses } from 'hooks/use-courses';
import { SubscriptionCourse } from 'types/api';
import DashboardTile1 from 'assets/img/dashboardTile1.png';
import DashboardTile2 from 'assets/img/dashboardTile2.png';
import DashboardTile3 from 'assets/img/dashboardTile3.png';
import DashboardTile4 from 'assets/img/dashboardTile4.png';
import { CoursesAccordion } from './courses-accordion';
import { ProgressTile } from './progress-tile';
import './dashboard.scss';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { courses, isLoading: isCoursesLoading } = useCourses({
    isFinished: false,
  });

  const [chosenCourse, setChosenCourse] = useState<SubscriptionCourse | null>(
    courses ? courses[0] : null
  );

  useEffect(() => {
    if (!chosenCourse && courses?.length) {
      setChosenCourse(courses[0]);
    }
  }, [chosenCourse, courses]);

  const {
    data: dashboardDetails,
    isLoading: isDashboardDetailsLoading,
    isFetching,
  } = useQuery(
    [QueryKeys.DASHBOARD_COURSE_DETAILS, chosenCourse?.id],
    () => getCourseDetails(chosenCourse?.id),
    {
      enabled: !!chosenCourse?.id,
      onError: () => toast.error('course-error'),
    }
  );

  const courseModuleId =
    dashboardDetails?.lesson_to_do?.course_module.display_order;

  const taskModuleName =
    (chosenCourse &&
      dashboardDetails?.task_to_do &&
      chosenCourse.course.course_modules &&
      chosenCourse.course.course_modules.find((module) => {
        return dashboardDetails.task_to_do?.resource_type === 'ModuleLesson'
          ? module.first_lesson_id === dashboardDetails.task_to_do.resource.id
          : module.id === dashboardDetails.task_to_do?.resource.id;
      })?.name) ||
    '';

  const taskModuleId =
    chosenCourse &&
    dashboardDetails?.task_to_do &&
    chosenCourse.course.course_modules &&
    chosenCourse.course.course_modules.findIndex((module) => {
      return dashboardDetails.task_to_do?.resource_type === 'ModuleLesson'
        ? module.first_lesson_id === dashboardDetails.task_to_do.resource.id
        : module.id === dashboardDetails.task_to_do?.resource.id;
    }) + 1;

  const redirectToLesson =
    dashboardDetails?.task_to_do?.resource_type === 'ModuleLesson';

  const shouldShowProgress =
    !!dashboardDetails?.lesson_to_do ||
    !!dashboardDetails?.task_to_do ||
    !!dashboardDetails?.quiz_to_do;

  if (isDashboardDetailsLoading || isCoursesLoading || isFetching) {
    return <Loader />;
  }

  if (!dashboardDetails) {
    return <NoResource>{t('no-courses-assigned')}</NoResource>;
  }

  return (
    <div className="user-dashboard">
      <div className="user-dashboard__header">
        <h1 className="user-dashboard__header--greeting">
          {t('hello-name', {
            name: user?.first_name,
          })}
        </h1>
        <CoursesAccordion
          setCourse={setChosenCourse}
          courses={courses}
          chosenCourse={chosenCourse}
          className="user-dashboard__header--accordion"
        />
      </div>
      {shouldShowProgress && (
        <div className="user-dashboard__progress">
          <h1 className="user-dashboard__progress-header">
            {t('continue-course')}
          </h1>
          <div className="user-dashboard__progress-tiles">
            {dashboardDetails?.lesson_to_do && (
              <Card className="user-dashboard__progress-tile-wrapper">
                <ProgressTile
                  title={t('lesson') || ''}
                  courseName={dashboardDetails.lesson_to_do.course_module.name}
                  description={dashboardDetails.lesson_to_do.name}
                  progress={
                    dashboardDetails.lesson_to_do.course_module.module_progress
                  }
                  buttonText={t('continue')}
                  buttonLink={`/courses/${encipherId(
                    chosenCourse?.id ?? ''
                  )}/lesson`}
                  moduleId={courseModuleId}
                />
              </Card>
            )}
            {dashboardDetails?.task_to_do && taskModuleId !== 0 && (
              <Card className="user-dashboard__progress-tile-wrapper">
                <ProgressTile
                  title={t('task')}
                  courseName={taskModuleName}
                  description={dashboardDetails.task_to_do.resource.name}
                  buttonText={t('task-preview')}
                  buttonLink={
                    redirectToLesson
                      ? `/courses/${encipherId(chosenCourse?.id ?? 0)}/lesson/${
                          dashboardDetails.task_to_do.resource.slug
                        }`
                      : '/tasks'
                  }
                  moduleId={taskModuleId}
                  openTask={dashboardDetails.task_to_do.id}
                  courseId={
                    redirectToLesson
                      ? null
                      : +encipherId(chosenCourse?.course.id ?? 0)
                  }
                />
              </Card>
            )}
            {dashboardDetails?.quiz_to_do && (
              <Card className="user-dashboard__progress-tile-wrapper">
                <ProgressTile
                  title="Quiz"
                  courseName={dashboardDetails.quiz_to_do.course_module.name}
                  description={dashboardDetails.quiz_to_do.name}
                  buttonText={t('make-quiz')}
                  buttonLink={
                    dashboardDetails.quiz_to_do.completed_attempts_count
                      ? `/courses/${chosenCourse?.id}/post_quiz/${dashboardDetails.quiz_to_do.id}`
                      : `/courses/${encipherId(
                          chosenCourse?.id ?? 0
                        )}/pre_quiz/${dashboardDetails.quiz_to_do.id}`
                  }
                />
              </Card>
            )}
          </div>
        </div>
      )}
      <div className="user-dashboard__courses-tiles">
        <h1 className="user-dashboard__courses-tiles--header">
          {t('your')}{' '}
          <span className="user-dashboard__courses-tiles--courses">
            {t('courses')}
          </span>
        </h1>
        <div className="user-dashboard__courses-tiles--stats">
          <Card className="user-dashboard__courses-tiles--card">
            <StatsContent
              target={t('modules', {
                count: dashboardDetails?.tiles_stats.uncompleted_modules_count,
              })}
              image={DashboardTile1}
              description={t('to-go')}
            />
          </Card>
          <Card className="user-dashboard__courses-tiles--card">
            <StatsContent
              target={t('lessons', {
                count: dashboardDetails?.tiles_stats.uncompleted_lessons_count,
              })}
              image={DashboardTile2}
              description={t('to-watch')}
            />
          </Card>
          <Card className="user-dashboard__courses-tiles--card">
            <StatsContent
              target={t('tasks', {
                count: dashboardDetails?.tiles_stats.uncompleted_tasks_count,
              })}
              image={DashboardTile3}
              description={t('to-do')}
            />
          </Card>
          <Card className="user-dashboard__courses-tiles--card">
            <StatsContent
              target={t('quizzes', {
                count: dashboardDetails?.tiles_stats.uncompleted_quizzes_count,
              })}
              image={DashboardTile4}
              description={t('to-solve')}
            />
          </Card>
        </div>
      </div>
      <div className="user-dashboard__course-details">
        <div className="user-dashboard__course-details--header">
          {t('course-content')}
        </div>
        <div className="user-dashboard__accordion">
          <CourseAccordion
            name={chosenCourse?.course.name}
            courseDetails={dashboardDetails?.course_modules}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
