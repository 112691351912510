import { AnswerStatus } from 'types/enums';
import { ReactComponent as IconTaskApproved } from 'assets/icons/task-approved.svg';
import { ReactComponent as IconTaskRejected } from 'assets/icons/task-rejected.svg';
import { ReactComponent as IconTaskInReview } from 'assets/icons/task-review.svg';
import { ReactComponent as IconTaskToDo } from 'assets/icons/task-to-do.svg';

type LessonTaskIconProps = {
  status?: AnswerStatus;
};

export const LessonTaskIcon: React.FC<LessonTaskIconProps> = ({ status }) => {
  switch (status) {
    case AnswerStatus.ACCEPTED:
      return (
        <IconTaskApproved className="lesson-details__exercise-button lesson-details__exercise-button--approved" />
      );

    case AnswerStatus.FAILED:
      return (
        <IconTaskRejected className="lesson-details__exercise-button lesson-details__exercise-button--rejected" />
      );

    case AnswerStatus.TO_VERIFY:
      return (
        <IconTaskInReview className="lesson-details__exercise-button lesson-details__exercise-button--in-review" />
      );

    default:
      return (
        <IconTaskToDo className="lesson-details__exercise-button lesson-details__exercise-button--to-do" />
      );
  }
};
