import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import {
  finishLesson as finishLessonRequest,
  getLessonDetails,
} from 'services/lesson';

export const useCourseLesson = (companySubscriptionCourseId: number) => {
  const { lessonId: lessonSlug } = useParams<{
    lessonId: string;
  }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: lesson, ...restLessonQuery } = useQuery(
    [QueryKeys.COURSE_LESSON, lessonSlug],
    () => getLessonDetails(lessonSlug, companySubscriptionCourseId),
    {
      onError: () => toast.error(t('course-lesson-error')),
      enabled: !!lessonSlug,
    }
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries([QueryKeys.COURSE_LESSON, lessonSlug]);
  }, [lessonSlug, queryClient]);

  const { mutate: finishLesson, ...restFinishLessonQuery } = useMutation(
    () => finishLessonRequest(lessonSlug, companySubscriptionCourseId),
    {
      onSuccess: () => {
        toast.success(t('lesson-ended'));
        invalidate();
      },
      onError: () => {
        toast.error(t('lesson-end-error'));
      },
    }
  );

  return {
    lesson,
    lessonSlug,
    finishLesson,
    invalidate,
    restLessonQuery,
    restFinishLessonQuery,
  };
};
