export const edit = {
  firstNameInputClass: 'cypress-profile-first-name-input',
  firstNameErrorClass: 'cypress-profile-first-name-error',
  lastNameInputClass: 'cypress-profile-last-name-input',
  lastNameErrorClass: 'cypress-profile-last-name-error',
  positionInputClass: 'cypress-profile-position-input',
  positionErrorClass: 'cypress-profile-position-error',
  teamInputClass: 'cypress-profile-team-input',
  teamErrorClass: 'cypress-profile-team-error',
  submitClass: 'cypress-profile-submit',
};
