import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCoachAndHrQuiz } from 'services/courses';

export const useSupervisorQuiz = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { quizId, courseId } = useParams<{
    quizId: string;
    courseId: string;
  }>();

  const { data: quiz, ...rest } = useQuery(
    [QueryKeys.COACH_HR_QUIZ, quizId],
    () => getCoachAndHrQuiz(quizId),
    {
      onError: () => {
        toast.error(t('failed-to-get-quiz-info'));
        history.push(`/courses/${courseId}/lesson`);
      },
    }
  );

  return { quiz, ...rest };
};
