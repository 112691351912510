import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { addParticipants as addParticipantsRequest } from 'services/courses';
import { Button } from 'components/button';
import { Select } from 'components/form';
import { Modal } from 'components/modal';
import { StateSetter } from 'helpers/types';
import { EMAIL } from 'helpers/validate';
import { useSelectOptions } from 'hooks/use-select-options';
import { InvitationsSummary } from 'types/api';
import { ReactComponent as CloseLine } from 'assets/icons/close-line.svg';
import { Summary } from './summary';
import './add-participants.scss';

type AddParticipantsProps = {
  modal: boolean;
  setModal: StateSetter<boolean>;
  updateParticipantsProgress: () => void;
};

const TRIGGER_KEYS = ['Enter', 'Tab', ',', ' '];

enum AddParticipantsSteps {
  FORM = 'form',
  SUMMARY = 'summary',
}

const commaSpaceSemicolonRegexp = /[,;\s]+/;

export const AddParticipants: React.FC<AddParticipantsProps> = ({
  modal,
  setModal,
  updateParticipantsProgress,
}) => {
  const { t } = useTranslation();
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const { selectOptions } = useSelectOptions();
  const [step, setStep] = useState(AddParticipantsSteps.FORM);
  const [summaryData, setSummaryData] = useState<InvitationsSummary>({
    added_to_course: [],
    already_in_course: [],
    sent_ivitation: [],
    course_name: '',
  });

  useEffect(() => {
    if (selectOptions.length) {
      setSelectedCourse(selectOptions[0].value);
    }
  }, [selectOptions]);

  const isInList = (email: string) => {
    return emailList.includes(email);
  };

  const isEmail = (email: string) => {
    return EMAIL.test(email);
  };

  const isValid = (email: string) => {
    let error = '';

    if (isInList(email)) {
      error = t('email-on-list');
    }

    if (!isEmail(email)) {
      error = t('email-invalid');
    }

    if (error) {
      setErrorText(error);
      return false;
    }

    return true;
  };

  const passCorrectEmails = (emails: string) => {
    const pastedEmails = emails.trim().split(commaSpaceSemicolonRegexp);
    const emailsToCheck = new Set<string>();
    pastedEmails.forEach((email) => {
      emailsToCheck.add(email);
    });
    const correctEmails: string[] = [];
    const failedEmails: string[] = [];
    Array.from(emailsToCheck).forEach((email) => {
      if (isValid(email)) {
        correctEmails.push(email);
      } else {
        failedEmails.push(email);
      }
    });
    setEmailValue(failedEmails.join(' '));
    setEmailList([...emailList, ...correctEmails]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setErrorText('');
    if (event.key === 'Backspace' && emailList.length && !emailValue) {
      setEmailList((prevEmailList) => prevEmailList.slice(0, -1));
    }
    if (TRIGGER_KEYS.includes(event.key)) {
      event.preventDefault();
      passCorrectEmails(emailValue);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedString = event.clipboardData.getData('text');
    event.preventDefault();
    passCorrectEmails(pastedString);
  };

  const handleDelete = (item: string) => {
    const filter = emailList.filter((ele) => ele !== item);
    setEmailList(filter);
  };

  const { mutate: addParticipants, isLoading } = useMutation(
    async () => {
      const result = await addParticipantsRequest(
        emailList.join(', '),
        selectedCourse
      );
      return {
        ...result,
        course_name: selectOptions.find(({ value }) => value === selectedCourse)
          ?.label,
      };
    },
    {
      onSuccess: (data) => {
        setEmailList([]);
        setSummaryData(data);
        updateParticipantsProgress();
        setStep(AddParticipantsSteps.SUMMARY);
        toast.success(t('invitation-success'));
      },
      onError: (error: AxiosError) => {
        const serverMessage = error.response && error.response.data.errors;
        toast.error(serverMessage);
      },
    }
  );

  const closeModal = () => setModal(false);

  return (
    <Modal
      className={clsx(
        'participants-invite',
        isLoading && 'participants-invite--loading'
      )}
      bodyClassName="participants-invite__modal-body"
      isOpen={modal}
      onRequestClose={closeModal}
      onAfterClose={() => setStep(AddParticipantsSteps.FORM)}
      maxWidth={step === AddParticipantsSteps.SUMMARY ? '1040px' : '540px'}
      noPadding
    >
      {step === AddParticipantsSteps.FORM ? (
        <form className="participants-invite__form">
          {t('add-participants')}
          <Select
            disabled={!selectOptions.length}
            options={
              selectOptions.length
                ? selectOptions
                : [{ value: '-1', label: t('courses-not-available') }]
            }
            onChange={(value) => {
              setSelectedCourse(value);
            }}
            label={t('choose-course')}
          />
          <div className="participants-invite__emails">
            <Trans i18nKey="enter-emails">
              Fullfil below input with participants mails
            </Trans>
            <label
              htmlFor="emailInput"
              className={clsx(
                'participants-invite__emails-label',
                errorText && 'participants-invite__emails-label--error'
              )}
            >
              {emailList.map((email) => (
                <div key={email} className="participants-invite__email">
                  {email}
                  <Button
                    variant="icon"
                    icon={<CloseLine className="participants-invite__icon" />}
                    onClick={() => handleDelete(email)}
                  />
                </div>
              ))}

              <input
                autoComplete="off"
                id="emailInput"
                className="participants-invite__input"
                type="text"
                value={emailValue}
                onChange={(event) => setEmailValue(event.target.value)}
                onPaste={handlePaste}
                onKeyDown={handleKeyDown}
              />
            </label>
            {errorText && (
              <div className="participants-invite__error-message">
                {errorText}
              </div>
            )}
          </div>
          <div className="participants-invite__button-container">
            <Button
              disabled={!emailList.length || !selectOptions.length || isLoading}
              loading={isLoading}
              onClick={() => addParticipants()}
            >
              {t('send-invitations')}
            </Button>
          </div>
        </form>
      ) : (
        <Summary data={summaryData} onClose={closeModal} />
      )}
    </Modal>
  );
};
