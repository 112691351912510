import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Card } from 'components/card';
import './progress-card.scss';

type ProgressCardProps = {
  text: string;
  count: number;
  maxCount: number;
  percentageValue: number;
  circleColor?: 'blue' | 'turquoise';
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const ProgressCard: React.FC<ProgressCardProps> = ({
  text,
  count,
  maxCount,
  percentageValue,
  circleColor = 'turquoise',
  icon: Icon,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="progress-card">
      <section className="progress-card__top">
        <Icon className="progress-card__icon" />

        <div className="progress-card__numbers">
          {t('count-of', {
            count,
            maxCount,
          })}
        </div>

        <div className="progress-card__text">{text}</div>
      </section>

      <div className="progress-card__line-break" />

      <section className="progress-card__bottom">
        <CircularProgressbarWithChildren
          className={clsx(
            'progress-card__progressbar',
            `progress-card__progressbar--${circleColor}`
          )}
          value={percentageValue}
        >
          <strong className="progress-card__inner-text">
            {percentageValue} %
          </strong>
        </CircularProgressbarWithChildren>
      </section>
    </Card>
  );
};
