import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { CourseStats } from 'components/course-stats';
import { StyledScrolls } from 'components/styled-scrolls';
import { formatDurationModule } from 'helpers/time';
import { AvailableCourses, CourseItem, CourseModulePreview } from 'types/api';
import { ReactComponent as IconClose } from 'assets/icons/close-line.svg';
import './course-info.scss';

type CourseInfoProps = {
  courseInfo: CourseItem | AvailableCourses;
  hideInfo: () => void;
  isVisible?: boolean;
  isSales: boolean;
  userCourseModules?: CourseModulePreview[];
};

export const CourseInfo: React.FC<CourseInfoProps> = ({
  isVisible,
  hideInfo,
  courseInfo: {
    name,
    video_lessons_duration,
    tasks_count,
    quizzes_count,
    course_modules: courseModules,
  },
  isSales,
  userCourseModules,
}) => {
  const { t } = useTranslation();
  return (
    <Card className={clsx('course-info', isVisible && 'course-info--visible')}>
      <header className="course-info__header">
        <p className="course-info__name">{name}</p>
        <CourseStats
          lessonTime={video_lessons_duration}
          tasksCount={tasks_count}
          quizzesCount={quizzes_count}
          className="course-info__stats"
        />
        <Button
          className="course-info__close-button"
          variant="icon"
          icon={<IconClose width={36} height={36} />}
          onClick={hideInfo}
        />
        <span className="course-info__course-name">
          {t('course-name', { name })}
        </span>
      </header>
      <section>
        <StyledScrolls className="course-info__scrollable">
          <ul className="course-info__modules">
            {isSales
              ? (courseModules || []).map((module, index) => (
                  <li className="course-info__module" key={module.id}>
                    <Trans
                      i18nKey="module-number"
                      values={{ number: index + 1 }}
                    >
                      <span className="course-info__module-number">module</span>
                    </Trans>
                    {module.name}
                    {module.video_lessons_duration > 0 && (
                      <span className="course-info__module-duration">
                        {formatDurationModule(module.video_lessons_duration)}
                      </span>
                    )}
                  </li>
                ))
              : (userCourseModules || []).map(
                  ({ id, name: moduleName }, index) => (
                    <li className="course-info__module" key={id}>
                      <Trans
                        i18nKey="module-number"
                        values={{ number: index + 1 }}
                      >
                        <span className="course-info__module-number">
                          module
                        </span>
                      </Trans>
                      {moduleName}
                    </li>
                  )
                )}
          </ul>
        </StyledScrolls>
      </section>
    </Card>
  );
};
