import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Collapse } from 'components/collapse';
import { SubscriptionCourse } from 'types/api';
import { CoursesAccordionItem } from './courses-accordion-item';
import './courses-accordion.scss';

type CoursesAccordionType = {
  setCourse: Dispatch<SetStateAction<SubscriptionCourse | null>>;
  className?: string;
  courses?: SubscriptionCourse[];
  chosenCourse: SubscriptionCourse | null;
};

export const CoursesAccordion: React.FC<CoursesAccordionType> = ({
  setCourse,
  className,
  courses,
  chosenCourse,
}) => {
  const { t } = useTranslation();

  function handleOnClick(course: SubscriptionCourse) {
    setCourse(course);
  }

  return (
    <div className={clsx('courses-accordion', className)}>
      <div className="courses-accordion__header">{t('your-courses')}</div>

      <div className="courses-accordion__wrapper">
        <Collapse
          wrapperClassName="courses-accordion__collapse-wrapper"
          isAutoScroll={false}
          contentWrapperClassName="courses-accordion__content-wrapper"
          className="courses-accordion__control"
          arrowClassName="courses-accordion__arrow"
          hiddenContent={courses?.map((course) => (
            <button
              className={clsx(
                'courses-accordion__button',
                course.id === chosenCourse?.id &&
                  'courses-accordion__button--active'
              )}
              key={course.id}
              onClick={() => handleOnClick(course)}
            >
              <CoursesAccordionItem name={course.course.name} />
            </button>
          ))}
          closeOnSelection
        >
          <div className="courses-accordion__title">
            {chosenCourse ? chosenCourse.course.name : t('choose-course')}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
