import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCourseParticipants } from 'services/courses';

export const useCourseParticipants = (subscriptionCourseId?: string) => {
  const { t } = useTranslation();
  const { data, ...rest } = useQuery(
    [QueryKeys.COURSE_PARTICIPANTS, subscriptionCourseId],
    () => getCourseParticipants(subscriptionCourseId!),
    {
      enabled: !!subscriptionCourseId,
      onError: () => {
        toast.error(t('course-participants-error'));
      },
    }
  );

  return { participants: data || null, ...rest };
};
