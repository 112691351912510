import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { format } from 'date-fns';
import {
  ChosenTimeslot,
  SelectedSlot,
} from 'components/coaching-session-modal/select-date-step';
import { RadioButton } from 'components/monthly-calendar/radio-button';
import { getActiveTimeslots } from 'helpers/coaching-session-calendar';
import { StateSetter } from 'helpers/types';
import { TimeslotsAvailability } from 'types/api';
import './time-picker.scss';

type Option = {
  id: number;
  time: string;
};

type TimePickerProps = {
  options?: Option[];
  availableTimeslots?: TimeslotsAvailability[];
  chosenTimeSlot?: ChosenTimeslot;
  setChosenTimeslot?: StateSetter<ChosenTimeslot>;
  selectedSlot?: SelectedSlot;
  setSelectedSlot?: StateSetter<SelectedSlot>;
};

export const TimePicker: React.FC<TimePickerProps> = ({
  options,
  selectedSlot,
  setSelectedSlot,
  availableTimeslots,
  chosenTimeSlot,
  setChosenTimeslot,
}) => {
  const { t } = useTranslation();

  const areOptionsAvailable = (options || []).length > 0 || availableTimeslots;
  const legendText = areOptionsAvailable
    ? t('pick-time')
    : t('no-available-time-slots');

  const handleRadioChange = ({
    target: { value, id },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelectedSlot) {
      setSelectedSlot((previousSlot) => ({
        ...previousSlot,
        id: +value,
      }));
    } else if (setChosenTimeslot) {
      setChosenTimeslot({
        startTime: value,
        endTime: id,
      });
    }
  };
  const availableTimeslotsWithoutPastDates = getActiveTimeslots(
    availableTimeslots || []
  );

  return (
    <fieldset className="time-picker">
      <legend className="time-picker__legend">{legendText}</legend>

      {areOptionsAvailable && (
        <ul
          className={clsx(
            'time-picker__radio-group',
            availableTimeslots && 'time-picker__radio-group--wide'
          )}
        >
          {!availableTimeslots
            ? options?.map(({ id, time }) => (
                <li key={id} className="time-picker__item">
                  <RadioButton
                    name="time"
                    label={time}
                    value={id}
                    checked={selectedSlot?.id === id}
                    onChange={handleRadioChange}
                  />
                </li>
              ))
            : availableTimeslotsWithoutPastDates?.map(
                ({ start_time, end_time }) => (
                  <li
                    key={start_time}
                    className={clsx(
                      'time-picker__item',
                      'time-picker__item--wide'
                    )}
                  >
                    <RadioButton
                      name="time"
                      label={`${format(new Date(start_time), 'k:mm')} -
                    ${format(new Date(end_time), 'k:mm')}`}
                      value={start_time}
                      id={end_time}
                      checked={chosenTimeSlot?.startTime === start_time}
                      onChange={handleRadioChange}
                    />
                  </li>
                )
              )}
        </ul>
      )}
    </fieldset>
  );
};
