import Tippy from '@tippyjs/react';

interface TooltipProps {
  title?: string;
  disabled?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  disabled,
}) => {
  return (
    <Tippy disabled={disabled} content={<div>{title}</div>} interactive>
      <div>{children}</div>
    </Tippy>
  );
};
