import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { ParticipantAssignment } from 'components/participant-assignment';
import { RichTextWrapper } from 'components/rich-text-wrapper';
import { StyledScrolls } from 'components/styled-scrolls';
import { TaskInfo } from 'components/task-info';
import { StateSetter } from 'helpers/types';
import { getPathDestination } from 'helpers/utils';
import { UpdateTasksData } from 'hooks/use-tasks';
import { CompanyDetails, Profile, Task } from 'types/api';
import { ResourceTypes } from 'types/enums';
import 'react-quill/dist/quill.snow.css';
import './task-assignment-modal.scss';

type TaskAssignmentProps = {
  modal: boolean;
  setModal: StateSetter<boolean>;
  selectedTask: Task | null;
  companies?: CompanyDetails[];
  handleRemoveParticipant: (id: number) => void;
  setSelectedTask: StateSetter<Task | null>;
  updateTasks: UseMutateFunction<void, unknown, UpdateTasksData, unknown>;
};

export const TaskAssignmentModal: React.FC<TaskAssignmentProps> = ({
  modal,
  setModal,
  selectedTask,
  companies,
  updateTasks,
  handleRemoveParticipant,
  setSelectedTask,
}) => {
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const isAuthor = selectedTask?.owner?.id === user?.id;

  const isCourseModule =
    selectedTask?.resource_type === ResourceTypes.COURSE_MODULE;

  const isModuleLesson =
    selectedTask?.resource_type === ResourceTypes.MODULE_LESSON;

  const assignParticipant = (participantToAssign: Profile) => {
    const participantIsAssigned =
      selectedTask &&
      selectedTask.participants.some(
        (participant) => participant.id === participantToAssign.id
      );

    setSelectedTask((prevState) => {
      if (prevState && !participantIsAssigned) {
        return {
          ...prevState,
          participants: [participantToAssign, ...prevState.participants],
        };
      }
      toast.info(t('participant-assigned'));
      return prevState;
    });
  };

  const handleUpdateTask = () => {
    if (selectedTask) {
      const participantsIds = selectedTask?.participants.map(
        (participant) => participant.id
      );
      updateTasks({ task: selectedTask, ids: participantsIds });
      setModal(false);
    }
  };

  const handleModalClose = () => {
    setModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      noPadding
      maxWidth="1011px"
      onRequestClose={handleModalClose}
      className="task-assignment-modal"
      bodyClassName="task-assignment-modal__body"
    >
      <div className="task-assignment">
        {selectedTask && (
          <section
            className={clsx('task-assignment__left', {
              'task-assignment__left--wide': !(isAuthor && isCourseModule),
            })}
          >
            <header>
              <div className="task-assignment__type">{t('task')}</div>
              <h3 className="task-assignment__title">
                {`${selectedTask.resource.name} - ${getPathDestination(
                  selectedTask.name
                )}`}
              </h3>

              <h2 className="task-assignment__course-name">
                {selectedTask.course.name}
              </h2>
            </header>

            <TaskInfo
              isModule={isCourseModule}
              resourceOrder={selectedTask.resource_number}
              owner={selectedTask.owner}
              resourceName={selectedTask.resource.name}
            />

            <StyledScrolls className="task-assignment__scrollable">
              <RichTextWrapper className="task-assignment__description">
                {selectedTask.description || ''}
              </RichTextWrapper>

              <div className="task-assignment__description-title">
                {t('instruction')}
              </div>

              <RichTextWrapper className="task-assignment__description-point">
                {selectedTask.instruction}
              </RichTextWrapper>
            </StyledScrolls>
          </section>
        )}

        {isAuthor && !isModuleLesson && (
          <section className="task-assignment__right">
            <ParticipantAssignment
              companies={companies}
              assignedParticipants={selectedTask?.participants}
              handleAssignParticipant={assignParticipant}
              handleUnassignParticipant={handleRemoveParticipant}
              courseId={selectedTask?.course.id}
            />
            <div className="task-assignment__buttons">
              <Button onClick={handleUpdateTask}>{t('confirm')}</Button>
              <Button onClick={() => setModal(false)} variant="secondary">
                {t('discard')}
              </Button>
            </div>
          </section>
        )}
      </div>
    </Modal>
  );
};
