import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatButton } from 'components/chat/chat-button';
import { Loader } from 'components/loader';
import { ViewTransition } from 'components/view-transition';
import { Client } from 'types/api';

export type CompanyDataProps = {
  company: Client | null;
  isLoading: boolean;
};

export const CompanyData: React.FC<CompanyDataProps> = ({
  company,
  isLoading,
}): ReturnType<React.FC<any>> => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ViewTransition resolved={!isLoading}>
      {company && (
        <div className="company-data">
          <div className="company-data__detail">
            <p className="company-data__field">{t('company-name')}</p>
            {company.name}
          </div>

          <div className="company-data__detail">
            <p className="company-data__field">{t('representative')}</p>
            {company.hr ? company.hr.email : t('company-lacks-hr')}
          </div>

          {!!company.hr && (
            <div className="company-data__detail">
              <p className="company-data__field">
                {t('representative-contact')}
              </p>
              <div className="company-data__contact">
                {company.hr.email}
                <ChatButton
                  userIdToChatWith={company.hr.id}
                  position={company.hr.role}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </ViewTransition>
  );
};
