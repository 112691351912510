import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/form';
import {
  ALL_ITEMS_PARAM_VALUE,
  useQueryParameters,
} from 'hooks/use-query-parameters';
import { Client } from 'types/api';
import { QueryParamNameTypes } from 'types/enums';

export interface CompaniesSelectProps {
  companies: Client[];
}

export const CompaniesSelect: FC<CompaniesSelectProps> = ({ companies }) => {
  const { t } = useTranslation();
  const queryParameters = useQueryParameters();
  const selectValue = queryParameters.get(QueryParamNameTypes.NAME) || '';

  const handleSelect = (value: string) => {
    queryParameters.replace([
      {
        key: QueryParamNameTypes.NAME,
        value: value === ALL_ITEMS_PARAM_VALUE ? '' : value,
      },
    ]);
  };

  return (
    <Select
      value={selectValue}
      options={[
        { value: ALL_ITEMS_PARAM_VALUE, label: t('all-clients') },
        ...companies.map((company) => ({
          value: company.id.toString(),
          label: company.name,
        })),
      ]}
      onChange={handleSelect}
    />
  );
};
