import axios from 'helpers/axios';
import {
  AvailableCourseModule,
  AvailableCourses,
  CoachAndHrQuizResponse,
  CoachTasksData,
  CourseAside,
  CourseCertificate,
  CourseCoach,
  CourseHeader,
  CourseModulePreview,
  Intro,
  InvitationsSummary,
  Notepad,
  NotepadData,
  Profile,
  QuizInfo,
  StartQuizResponse,
  SubscriptionCourse,
  SubscriptionCourseParams,
} from 'types/api';
import { QuizAttemptType } from 'types/enums';

export async function getCompletedCourses() {
  const {
    data: { data },
  } = await axios.get('v1/courses?completed=true');
  return data;
}

export async function getAvailableCourses() {
  const { data } = await axios.get<AvailableCourses[]>('v1/courses');
  return data;
}

export async function getCoachCourses() {
  const { data } = await axios.get<CoachTasksData[]>(
    '/v1/courses/coach_courses'
  );
  return data;
}

export async function getCourseModules(courseId: number) {
  const { data } = await axios.get<CourseModulePreview[]>(
    `/v1/courses/${courseId}/modules`
  );
  return data;
}

export async function getAvailableUserCourses(meetingTypeId: number) {
  const { data } = await axios.get<AvailableCourseModule[]>(
    `/v1/meeting_types/${meetingTypeId}/company_subscription_courses/selector_details`
  );
  return data;
}
export async function getParticipants(id: number) {
  const { data } = await axios.get(`v1/participants/course/${id}`);
  return data;
}

export async function createNote(dataToSend: NotepadData, courseId: number) {
  const { data } = await axios.post<Notepad>(
    `v1/notes/?course_id=${courseId}`,
    dataToSend
  );
  return data;
}
export async function updateNote(dataToSend: NotepadData, noteId: number) {
  const { data } = await axios.put<Notepad>(`v1/notes/${noteId}`, dataToSend);
  return data;
}

export async function fetchSubscriptionCourses(
  params?: SubscriptionCourseParams
) {
  const { data } = await axios.get<SubscriptionCourse[]>(
    'v1/company_subscription_courses',
    {
      params,
    }
  );
  return data;
}

export async function addParticipants(
  emailList: string,
  selectedCourse: string | null
) {
  const { data } = await axios.post<InvitationsSummary>(
    `v1/company_subscription_courses/${selectedCourse}/invite_participants`,
    { user_emails: emailList }
  );
  return data;
}

export async function getCourseAside(courseId: string) {
  const params = { only_main_quiz: true };

  const { data } = await axios.get<CourseAside>(
    `v1/company_subscription_courses/${courseId}/course_details`,
    { params }
  );

  return data;
}

export async function getCourseHeader(courseId: string) {
  const { data } = await axios.get<CourseHeader>(
    `/v1/company_subscription_courses/${courseId}/header_info`
  );
  return data;
}

export async function getCourseIntro(courseId: string) {
  const { data } = await axios.get<Intro>(
    `/v1/company_subscription_courses/${courseId}/intro`
  );
  return data;
}

export async function getCourseCoaches(courseId: string) {
  const { data } = await axios.get<CourseCoach[]>(
    `/v1/company_subscription_courses/${courseId}/coaches`
  );
  return data;
}

export async function getCourseParticipants(courseId: string) {
  const { data } = await axios.get<Profile[]>(
    `/v1/company_subscription_courses/${courseId}/participants`
  );
  return data;
}

export async function getParticipantQuiz(
  id: string,
  params: { attempt_type: string }
) {
  const { data } = await axios.get<QuizInfo>(`/v1/quizzes/${id}`, {
    params,
  });
  return data;
}

export async function getCoachAndHrQuiz(id: string) {
  const params = { attempt_type: QuizAttemptType.POST_QUIZ };

  const { data } = await axios.get<CoachAndHrQuizResponse>(
    `/v1/quizzes/${id}/details`,
    {
      params,
    }
  );
  return data;
}

export async function startQuiz(id: string) {
  const { data } = await axios.post<StartQuizResponse>(
    `/v1/quizzes/${id}/start_quiz`
  );
  return data;
}

export async function submitQuiz(
  id: string,
  answers: { answer_ids: number[] }
) {
  const { data } = await axios.post<QuizInfo>(
    `/v1/quizzes/${id}/submit`,
    answers
  );
  return data;
}

export async function getFinishedCourses() {
  const { data } = await axios.get<CourseCertificate[]>('/v1/courses/finished');
  return data;
}
