import { useContext } from 'react';
import { ChatContext } from 'contexts';
import { Button } from 'components/button';
import './chat-with-person-button.scss';

type ChatWithCoachButtonProps = {
  personId: number;
};

export const ChatWithPersonButton: React.FC<ChatWithCoachButtonProps> = ({
  personId,
  children,
}) => {
  const { openConversation } = useContext(ChatContext);

  return (
    <Button
      className="chat-with-person-button"
      variant="secondary"
      onClick={() => openConversation(personId)}
    >
      {children}
    </Button>
  );
};
