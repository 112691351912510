import { useCallback, useState } from 'react';

type HeaderRenderer = null | (() => React.ReactNode);
export type SetHeaderRenderer = (renderer: HeaderRenderer) => void;

export type TabRouterHook = {
  headerRenderer: HeaderRenderer;
  setHeaderRenderer: SetHeaderRenderer;
};

export const useTabRouter = (): TabRouterHook => {
  const [headerRenderer, setHeaderRenderer] = useState<HeaderRenderer>(null);

  return {
    headerRenderer,
    setHeaderRenderer: useCallback((renderer: HeaderRenderer) => {
      setHeaderRenderer(() => renderer);
    }, []),
  };
};
