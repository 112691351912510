import { useState } from 'react';

export const useResourceById = <T extends { id: number }>(
  resourceArray: T[]
) => {
  const [resourceId, setResourceId] = useState(-1);
  const resource = resourceArray.find(
    (resourceItem) => resourceItem.id === resourceId
  );
  return { resource, setResourceId };
};
