import axios from 'helpers/axios';
import { ModuleProgress, Participant, ParticipantProgress } from 'types/api';

export async function getParticipants() {
  const { data } = await axios.get<Participant[]>('v1/participants');
  return data;
}

export async function getParticipantsProgress(companyId?: string) {
  const { data } = await axios.get<ParticipantProgress[]>(
    'v1/participants/progress',
    { params: { company_id: companyId } }
  );
  return data;
}

export async function getParticipantModulesProgress(
  participantId: number,
  courseId: number
) {
  const { data } = await axios.get<ModuleProgress[]>(
    `/v1/participants/${participantId}/courses/${courseId}/user_course_modules`
  );
  return data;
}
