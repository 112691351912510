import { Link } from 'react-router-dom';
import { Logo } from 'components/logo';
import { ReactComponent as NotFoundImage } from 'assets/img/404.svg';
import './not-found.scss';

const NotFound = () => {
  return (
    <div className="not-found">
      <header className="not-found__header">
        <Link to="/">
          <Logo className="not-found__logo" />
        </Link>
      </header>
      <main className="not-found__main">
        <NotFoundImage className="not-found__image" />
        <h1 className="not-found__message">Page not found</h1>
      </main>
    </div>
  );
};

export default NotFound;
