import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { DropdownSelect } from 'components/form/dropdown-select';
import {
  SelectDate,
  SelectDateValue,
} from 'components/group-meeting-form/select-date';
import { Loader } from 'components/loader';
import { Modal } from 'components/modal';
import { convertDateToApiCompatible } from 'helpers/coaching-session-calendar';
import { format } from 'helpers/time';
import { getFullName, isObjectWithFalsyValues } from 'helpers/utils';
import { useCoachSessions } from 'hooks/use-coach-sessions';
import { useCourses } from 'hooks/use-courses';
import { useGroupMeetingsData } from 'hooks/use-group-meetings-data';
import {
  BookedSession,
  GroupSessionEditing,
  SubscriptionCourse,
} from 'types/api';
import './edit-group-meeting-modal.scss';

type EditGroupMeetingModalProps = {
  isOpen: boolean;
  sessionToEdit: BookedSession;
  close: () => void;
};

type EditFormData = {
  calendarData: SelectDateValue;
  participants: { value: string; label: string }[];
};

export const EditGroupMeetingModal: React.FC<EditGroupMeetingModalProps> = ({
  isOpen,
  sessionToEdit,
  close,
}) => {
  const { t } = useTranslation();
  const { courses } = useCourses({});
  const [subscriptionCourse, setSubscriptionCourse] =
    useState<SubscriptionCourse>();
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm();
  const { updateCoachGroupSession } = useCoachSessions();

  const bookedParticipants = sessionToEdit.participants.map((participant) => ({
    value: String(participant.id),
    label: `${participant.first_name} ${participant.last_name}`,
  }));

  const submitHandler = async ({
    participants,
    calendarData,
  }: EditFormData) => {
    const newParticipants = participants.map(
      (participant) => +participant.value
    );

    const dataToSend: GroupSessionEditing = {
      user_ids: newParticipants,
      time_slot_id: +calendarData.slotId,
      selected_date: convertDateToApiCompatible(calendarData.date),
      course_id: subscriptionCourse?.course.id,
    };

    if (+calendarData.slotId === -1) {
      delete dataToSend.time_slot_id;
      delete dataToSend.selected_date;
    }

    await updateCoachGroupSession({
      meetingId: String(sessionToEdit.id),
      dataToSend,
    });

    reset();
    close();
  };

  useEffect(() => {
    const course = courses?.find(
      (item) => item.course.id === sessionToEdit.course.id
    );
    setSubscriptionCourse(course);
  }, [courses, sessionToEdit.course.id]);

  const { participantOptions } = useGroupMeetingsData(
    String(subscriptionCourse?.id)
  );

  if (!module || !sessionToEdit?.provider?.id) {
    return <Loader />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="edit-group-meeting-modal"
      maxWidth="787"
    >
      <div className="edit-group-meeting-modal__info">
        <div>
          <h2 className="edit-group-meeting-modal__title">
            {t('edit-group-meeting')}
          </h2>
          <p>{`${t('course')}: ${sessionToEdit.course.name}`}</p>
          <p>{`${t('meeting-date')}: ${format(
            sessionToEdit.start_time,
            'dateWithTime'
          )}`}</p>
          <p>{`${t('coach')}: ${getFullName(sessionToEdit.provider)}`}</p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="edit-group-meeting-form"
      >
        <Controller
          rules={{
            validate: {
              participants: (participants) =>
                participants.length || t('participants-list-is-empty'),
            },
          }}
          control={control}
          defaultValue={bookedParticipants}
          name="participants"
          render={(props) => (
            <DropdownSelect
              {...props}
              options={participantOptions}
              headingText={t('choose-participant')}
              isMulti
              errors={errors}
            />
          )}
        />
        <Controller
          rules={{
            validate: {
              dateSelected: (object) => !isObjectWithFalsyValues(object),
            },
          }}
          control={control}
          name="calendarData"
          render={({ onChange, value }) => (
            <SelectDate
              onChange={onChange}
              value={value}
              moduleId={String(sessionToEdit.course_module.id)}
              coachId={sessionToEdit?.provider?.id}
            />
          )}
        />
        <div className="edit-group-meeting-form__buttons-container">
          <Button onClick={close} variant="secondary">
            {t('back-to-list')}
          </Button>
          <Button loading={isSubmitting} type="submit">
            {t('edit')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
