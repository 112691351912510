import clsx from 'clsx';
import { ReactComponent as RegularLogo } from 'assets/img/logo.svg';
import './logo.scss';

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }: LogoProps) => {
  return <RegularLogo className={clsx(className, 'logo')} />;
};
