import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import {
  SecondStepFormProps,
  SessionModalStep,
} from 'components/coaching-session-modal';
import { Loader } from 'components/loader';
import { MonthlyCalendar } from 'components/monthly-calendar';
import { TabSwitch } from 'components/tab-switch';
import { WeeklyCalendar } from 'components/weekly-calendar';
import { convertDateToApiCompatible } from 'helpers/coaching-session-calendar';
import { today } from 'helpers/time';
import { StateSetter } from 'helpers/types';
import { useCalendarViews } from 'hooks/use-calendar-view';
import { useCoachTimeslots } from 'hooks/use-coach-timeslots';
import { AvailableCourseModule, SortedTimeslot } from 'types/api';
import './select-date-step.scss';

type SelectDateStepProps = {
  moduleId: string;
  setStep: StateSetter<SessionModalStep>;
  selectedCourse: AvailableCourseModule | null;
  setSecondStepValue: StateSetter<SecondStepFormProps>;
};

export type SelectedSlot = {
  date: Date;
  id: number;
};
export type ChosenTimeslot = {
  startTime: string;
  endTime: string;
};

enum CalendarView {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

const initialSlot: SelectedSlot = {
  date: today(),
  id: -1,
};

const initialChosenTimeslot: ChosenTimeslot = {
  startTime: today().toISOString(),
  endTime: '-1',
};

export const SelectDateStep: React.FC<SelectDateStepProps> = ({
  moduleId,
  setStep,
  selectedCourse,
  setSecondStepValue,
}) => {
  const { t } = useTranslation();
  const [selectedSlot, setSelectedSlot] = useState(initialSlot);
  const [chosenTimeslot, setChosenTimeslot] = useState(initialChosenTimeslot);
  const [errorMessage, setErrorMessage] = useState('');

  const { month, setMonth, viewType, tabs, changeTab, ISODates } =
    useCalendarViews(
      chosenTimeslot.startTime !== initialChosenTimeslot.startTime
        ? new Date(chosenTimeslot.startTime)
        : new Date()
    );

  const { coachTimeslots, isLoading: isLoadingTimeslots } = useCoachTimeslots({
    moduleId,
    ISODates,
  });

  const getSortedTimeslots = (
    timeslotsAvailability = coachTimeslots?.availability
  ) => {
    if (!timeslotsAvailability) return [];
    const sortedTimeslots: SortedTimeslot[] = [];
    timeslotsAvailability.forEach(({ start_time, end_time }) => {
      const key = convertDateToApiCompatible(new Date(start_time));
      const index = sortedTimeslots.findIndex(
        ({ date }) => convertDateToApiCompatible(new Date(date)) === key
      );
      if (index === -1) {
        sortedTimeslots.push({
          date: key,
          timeSlots: [{ start_time, end_time }],
        });
      } else {
        sortedTimeslots[index].timeSlots.push({ start_time, end_time });
      }
    });
    return sortedTimeslots;
  };

  const sortedTimeSlots = getSortedTimeslots();

  const handleTabChange = (value: string) => {
    changeTab(value);
    setChosenTimeslot(initialChosenTimeslot);
  };

  const submitHandler = () => {
    if (
      chosenTimeslot.startTime === initialChosenTimeslot.startTime ||
      chosenTimeslot.endTime === initialChosenTimeslot.endTime
    ) {
      setErrorMessage(t('date-required'));
      return;
    }
    if (coachTimeslots && sortedTimeSlots) {
      setSecondStepValue({
        coach: {
          id: coachTimeslots.id,
          firstName: coachTimeslots.first_name,
          lastName: coachTimeslots.last_name,
        },
        start_time: chosenTimeslot.startTime,
        end_time: chosenTimeslot.endTime,
      });
      setStep(SessionModalStep.THIRD);
    }
  };

  if (isLoadingTimeslots) {
    return <Loader />;
  }

  const lastAvaliableCoachSessionDate = selectedCourse?.end_date
    ? new Date(selectedCourse.end_date)
    : undefined;

  return (
    <div className="select-date-step">
      <p className="select-date-step__sub-title">
        {coachTimeslots?.first_name} {coachTimeslots?.last_name}
      </p>
      <div className="select-date-step__switch">
        <p className="select-date-step__sub-title">{t('choose-date')}</p>
        <TabSwitch
          buttonClassName="select-date-step__switch-button"
          value={viewType}
          onChange={handleTabChange}
          tabs={tabs}
        />
      </div>
      {viewType === CalendarView.WEEKLY && (
        <WeeklyCalendar
          selectedSlot={selectedSlot}
          maxDate={lastAvaliableCoachSessionDate}
          setSelectedSlot={setSelectedSlot}
          setErrorMessage={setErrorMessage}
          setChosenTimeslot={setChosenTimeslot}
          coachTimeSlots={sortedTimeSlots}
          chosenTimeSlot={chosenTimeslot}
        />
      )}
      {viewType === CalendarView.MONTHLY && (
        <MonthlyCalendar
          maxDate={lastAvaliableCoachSessionDate}
          minDate={today()}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          selectedMonth={month}
          setSelectedMonth={setMonth}
          setErrorMessage={setErrorMessage}
          coachTimeSlots={sortedTimeSlots}
          setChosenTimeslot={setChosenTimeslot}
          chosenTimeSlot={chosenTimeslot}
        />
      )}

      <div className="select-date-step__bottom-panel">
        {errorMessage && (
          <div className="select-date-step__error-message">{errorMessage}</div>
        )}
        <Button
          onClick={() => {
            setSelectedSlot(initialSlot);
            setStep(SessionModalStep.FIRST);
          }}
          className="select-date-step__back"
          variant="secondary"
        >
          {t('back')}
        </Button>
        <Button onClick={submitHandler} type="submit">
          {t('next')}
        </Button>
      </div>
    </div>
  );
};
