import { ModuleDetails } from 'types/api';

export const getCurrentModuleInfo = (
  modules: ModuleDetails[],
  quizId: number
) => {
  const isQuizInModule = (module: ModuleDetails) => module.quiz?.id === quizId;

  const currentModuleIndex = modules.findIndex(isQuizInModule);
  const currentModule = modules[currentModuleIndex];
  const isLast = currentModuleIndex === modules.length - 1;
  const moduleOrder = currentModule ? currentModuleIndex + 1 : '';

  return {
    name: currentModule?.name || '',
    order: moduleOrder.toString(),
    coach: currentModule?.coach,
    isLast,
  };
};
