import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ChatContext } from 'contexts';
import { DropdownButton } from 'components/dropdown-button';
import { DropdownMenuItem } from 'components/dropdown-menu-item';
import { Avatar } from 'components/people-list/avatar';
import { Tooltip } from 'components/tooltip';
import { isExpired, isExpiringSoon } from 'helpers/days';
import { getFullName, getUserInitials } from 'helpers/utils';
import { useNotification } from 'hooks/use-notification';
import { ParticipantProgress, ParticipantProgressCourse } from 'types/api';
import { ReactComponent as KebabIcon } from 'assets/icons/kebab.svg';
import { CourseCollapse } from './course-collapse';
import './participant-table-row.scss';

type ParticipantTableRowProps = {
  participant: ParticipantProgress;
};

export const ParticipantTableRow: React.FC<ParticipantTableRowProps> = ({
  participant,
}) => {
  const { t } = useTranslation();
  const { openConversation } = useContext(ChatContext);
  const { sendNotification, isSuccess } = useNotification(participant.id);

  const onChat = () => {
    openConversation(participant.id);
  };

  const canNotifyOfExpiringCourse = (courses: ParticipantProgressCourse[]) =>
    participant.could_be_notified &&
    courses.some(
      ({ end_date }) => isExpiringSoon(end_date) && !isExpired(end_date)
    );

  const participantName =
    participant.first_name && participant.last_name && getFullName(participant);

  return (
    <tr className="participant-table-row" key={participant.id}>
      <td className="participant-table-row__participant">
        <div
          className={clsx(
            'participant-table-row__participant-details',
            'participant-table-row__participant-details--left-margin'
          )}
        >
          <div className="participant-table-row__person">
            {participant?.email && (
              <Tooltip title={participant.email} disabled={!participant.email}>
                <Avatar
                  avatar={participant.avatar}
                  alt="avatar"
                  small
                  userInitials={getUserInitials(participant)}
                />
              </Tooltip>
            )}

            <div className="participant-table-row__person--name">
              {participantName || participant.email}
            </div>
          </div>
          <div className="participant-table-row__dot-menu">
            <DropdownButton
              buttonContent={<KebabIcon />}
              menuClassName="participant-table-row__menu"
            >
              <DropdownMenuItem
                onClick={onChat}
                wrapperClassName="participant-table-row__menu-item"
              >
                {t('chat-with-participant')}
              </DropdownMenuItem>
              {!isSuccess && canNotifyOfExpiringCourse(participant.courses) && (
                <DropdownMenuItem
                  onClick={() => sendNotification()}
                  wrapperClassName="participant-table-row__menu-item"
                >
                  {t('send-reminder')}
                </DropdownMenuItem>
              )}
            </DropdownButton>
          </div>
        </div>
      </td>

      <td>
        <div className="participant-table-row__participant-details">
          {participant.position}
        </div>
      </td>
      <td>
        <div className="participant-table-row__participant-details">
          {participant.team}
        </div>
      </td>
      <td className="participant-table-row__courses" colSpan={2}>
        <div className="participant-table-row__courses-wrapper">
          {participant.courses.length ? (
            participant.courses.map((course) => (
              <CourseCollapse
                key={course.id}
                course={course}
                participantId={participant.id}
              />
            ))
          ) : (
            <div className="participant-table-row__no-courses">
              {t('no-courses-participant')}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
