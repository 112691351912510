import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { loginSelectors } from 'constants/selectors/authentication/login';
import { AuthContext } from 'contexts';
import { login as loginRequest } from 'services/user';
import { Button } from 'components/button';
import { Input } from 'components/form';
import { Logo } from 'components/logo';
import { setAuthorization } from 'helpers/axios';
import { pushGTMEvent } from 'helpers/google-tag-manager';
import { setLocalStorageItem } from 'helpers/local-storage';
import { EMAIL } from 'helpers/validate';
import { useQueryParameters } from 'hooks/use-query-parameters';
import './authentication.scss';

export type LoginData = {
  email: string;
  password: string;
};

export const Authentication: React.FC = () => {
  const [message, setMessage] = useState('');
  const [shouldButtonBeLocked, setShouldButtonBeLocked] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const { get, replace } = useQueryParameters();
  const { setAuthToken } = useContext(AuthContext);
  const history = useHistory();

  const loginUserWithToken = useCallback(
    (accessToken: string) => {
      setLocalStorageItem(
        'skillsarena-auth',
        accessToken,
        (process.env.REACT_APP_TOKEN_LIFETIME_IN_DAYS ?? 1) as number
      );
      setAuthToken(accessToken);
      setAuthorization(accessToken);
    },
    [setAuthToken]
  );

  const { mutate: login, isLoading } = useMutation(
    (loginData: LoginData) => loginRequest(loginData),
    {
      onSuccess: (data) => {
        loginUserWithToken(data);
        setMessage('');
        history.push('/');
      },
      onError: (error: AxiosError) => {
        setShouldButtonBeLocked(true);
        setTimeout(() => {
          setShouldButtonBeLocked(false);
        }, 3000);
        setMessage(`${t('login-failed')}: ${error.response?.data.message}`);
      },
    }
  );

  useEffect(() => {
    const accessToken = get('access-token');
    if (accessToken) {
      replace([{ key: 'access-token', value: '' }]);
      loginUserWithToken(accessToken);
    }
  }, [get, loginUserWithToken, replace]);

  const submitLogin = (loginData: LoginData) => {
    pushGTMEvent({ event: 'login' });
    login(loginData);
  };

  return (
    <div className="authentication">
      <Logo className="authentication__logo" />
      <span className="authentication__title">{t('sign-in')}</span>
      <form
        data-testid="login-form"
        onSubmit={handleSubmit(submitLogin)}
        className="authentication__form"
      >
        {message !== '' && message && (
          <div
            className={clsx(
              'authentication__error-message',
              loginSelectors.errorClass
            )}
          >
            {message}
          </div>
        )}
        <Input
          autoComplete="username"
          label={t('login-e-mail') as string}
          name="email"
          placeholder={t('e-mail-placeholder')}
          errors={errors}
          className="authentication__form-input"
          register={register({
            required: true,
            pattern: { value: EMAIL, message: `${t('invalid-email')}` },
          })}
          inputClassName={loginSelectors.emailInputClass}
          errorClassName={loginSelectors.emailErrorClass}
        />
        <Input
          autoComplete="current-password"
          label={t('password') as string}
          name="password"
          type="password"
          className="authentication__form-input"
          errors={errors}
          placeholder={t('type-password')}
          register={register({
            required: true,
          })}
          inputClassName={loginSelectors.passwordInputClass}
          togglerClassName={loginSelectors.passwordTogglerClass}
          errorClassName={loginSelectors.passwordErrorClass}
        />
        <Button
          className={clsx('authentication__submit', loginSelectors.submitClass)}
          type="submit"
          variant="primary"
          loading={isLoading || shouldButtonBeLocked}
        >
          {t('log-in')}
        </Button>
      </form>
      <Link
        to="/forgot-password"
        className={clsx(
          'authentication__forget-password',
          loginSelectors.forgetPasswordPinkClass
        )}
      >
        <Trans i18nKey="reset-password-click">
          Forget password? <strong>Click here</strong>
        </Trans>
      </Link>
    </div>
  );
};

export default Authentication;
