import { ReactNode } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconLoader } from 'assets/icons/puff.svg';
import './button.scss';

type ButtonProps = {
  className?: string;
  children?: ReactNode;
  variant?: 'primary' | 'secondary' | 'outlined' | 'icon-text' | 'icon';
  icon?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  title?: string;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  className,
  type = 'button',
  icon,
  loading,
  disabled,
  ...rest
}) => {
  return (
    <>
      {
        {
          primary: (
            <button
              className={clsx('button button--primary', className, {
                'button--loading': loading,
              })}
              type={type}
              disabled={disabled}
              {...rest}
            >
              {children}
              {loading && <IconLoader className="button__loader" />}
            </button>
          ),
          secondary: (
            <button
              className={clsx('button button--secondary', className)}
              disabled={disabled}
              type={type}
              {...rest}
            >
              {children}
            </button>
          ),
          'icon-text': (
            <button
              className={clsx('button button--icon-text', className)}
              type={type}
              {...rest}
            >
              <span className="button__icon-wrapper" aria-hidden="true">
                {icon}
              </span>
              <span className="button__text">{children}</span>
            </button>
          ),
          outlined: (
            <button
              className={clsx('button button--outlined', className)}
              disabled={disabled}
              type={type}
              {...rest}
            >
              {children}
            </button>
          ),
          icon: (
            <button
              className={clsx('button button--icon', className)}
              disabled={disabled}
              type={type}
              {...rest}
            >
              {icon}
            </button>
          ),
        }[variant]
      }
    </>
  );
};
