import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components, MultiValue } from 'react-select';
import './multiselect.scss';

type SelectType = {
  value: string;
  label: string;
};

type SelectProps = {
  options: SelectType[];
  className?: string;
  id?: string;
  onMenuClose: (values: string[]) => void;
  placeholder?: string;
  isDisabled?: boolean;
  values: string[];
  defaultValue?: string[];
};

export const MultiSelect: React.FC<SelectProps> = ({
  values,
  defaultValue,
  ...props
}) => {
  const { className, onMenuClose, options, placeholder } = props;
  const { t } = useTranslation();

  const computedSelectOptions = useMemo(
    () => options.filter(({ value }) => values.includes(value)),
    [options, values]
  );
  const [selectedOptions, setSelectedOptions] = useState<
    MultiValue<SelectType>
  >(computedSelectOptions);

  const onNativeChange = (newOptions: MultiValue<SelectType>) => {
    if (newOptions instanceof Array) {
      setSelectedOptions(newOptions);
    }
  };

  useEffect(() => {
    setSelectedOptions(computedSelectOptions);
  }, [computedSelectOptions]);

  return (
    <div className={className}>
      <Select
        {...props}
        isSearchable
        value={selectedOptions}
        isMulti
        closeMenuOnSelect={false}
        onMenuClose={() => {
          const selectValues = selectedOptions.map((option) => option.value);
          onMenuClose(selectValues);
        }}
        hideSelectedOptions={false}
        classNamePrefix="react-multiselect"
        noOptionsMessage={() => t('no-options')}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null,
          Option: ({ data, isSelected, ...rest }) => {
            return (
              <components.Option data={data} isSelected={isSelected} {...rest}>
                <label className="option">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => null}
                  />
                  {data.label}
                </label>
              </components.Option>
            );
          },
        }}
        onChange={(newOptions) => onNativeChange(newOptions)}
        placeholder={placeholder}
      />
    </div>
  );
};
