import { StyledScrolls } from 'components/styled-scrolls';
import { ModuleDetails } from 'types/api';
import { ModuleLessons } from './list-item';
import './modules-list.scss';

type ModulesProps = {
  modules: ModuleDetails[];
  goToLesson: (id: number) => void;
  activeContentId: number | string;
};

export const ModulesList: React.FC<ModulesProps> = ({
  modules,
  goToLesson,
  activeContentId,
}) => {
  return (
    <StyledScrolls className="modules-list">
      <ul className="modules-list__container">
        {modules.map((module, index) => {
          return (
            <ModuleLessons
              order={index + 1}
              key={module.id}
              module={module}
              modulesCount={modules.length}
              goToLesson={goToLesson}
              activeContentId={activeContentId}
            />
          );
        })}
      </ul>
    </StyledScrolls>
  );
};
