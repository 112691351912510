import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { SubscriptionName } from 'components/subscription-name';
import { format } from 'helpers/time';
import { CourseHeader as CourseHeaderType } from 'types/api';
import { ReactComponent as IconArrow } from 'assets/icons/arrow-left-s-line.svg';
import './course-header.scss';

type CourseHeaderProps = {
  headerDetails: CourseHeaderType | null;
};

export const CourseHeader: React.FC<CourseHeaderProps> = ({
  headerDetails,
}) => {
  const { t } = useTranslation();
  const { isHR, isCoach } = useContext(AuthContext);
  const { state } = useLocation<{
    linkedFrom: string;
  }>();

  if (!headerDetails) {
    return null;
  }

  return (
    <header className="course-header">
      <h1 className="course-header__heading">
        <Link
          className="course-header__link"
          to={state?.linkedFrom || '/courses'}
        >
          <IconArrow className="course-header__arrow" />
        </Link>

        <span className="course-header__name">
          {headerDetails.course.name || t('back-to-course-list')}
        </span>
      </h1>
      <div className="course-header__details">
        {isHR && <SubscriptionName name={headerDetails.subscription.name} />}
        {!isCoach && (
          <div className="course-header__calendar">
            <span className="course-header__date">
              {`${format(headerDetails.start_date)} -
              ${format(headerDetails.end_date)}`}
            </span>
          </div>
        )}
      </div>
    </header>
  );
};
