import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AttachmentItem } from 'components/attachment-item';
import { Button } from 'components/button';
import { Textarea } from 'components/form';
import { Modal } from 'components/modal';
import { Person } from 'components/person/person';
import { StyledScrolls } from 'components/styled-scrolls';
import { TaskInfo } from 'components/task-info';
import { format } from 'helpers/time';
import { StateSetter } from 'helpers/types';
import { getPathDestination } from 'helpers/utils';
import { useAnswer } from 'hooks/use-answer';
import { Answer, AnswerData, Task } from 'types/api';
import { AnswerStatus, ResourceTypes } from 'types/enums';
import './task-answer-modal.scss';

type TaskAnswerProps = {
  answerModal: boolean;
  setAnswerModal: StateSetter<boolean>;
  selectedTask: Task | null;
  selectedAnswer: Answer | null;
};

const NOTE_NAME = 'note';

export const TaskAnswerModal: React.FC<TaskAnswerProps> = ({
  answerModal,
  setAnswerModal,
  selectedTask,
  selectedAnswer,
}) => {
  const [evaluationStatus, setEvaluationStatus] = useState<
    AnswerStatus | undefined
  >();

  const { register, handleSubmit, reset } = useForm();
  const { t } = useTranslation();
  const handleClose = () => {
    setEvaluationStatus(undefined);
    setAnswerModal(false);
    reset();
  };
  const user = selectedAnswer?.user;
  const answerStatus = selectedAnswer?.status;
  const isAnswerAcceptable = answerStatus === AnswerStatus.ACCEPTED;
  const isAnswerRejectable = answerStatus === AnswerStatus.FAILED;
  const isAnswerToVerify = answerStatus === AnswerStatus.TO_VERIFY;

  const { sendAnswer } = useAnswer();
  const sendEvaluation = (data: { [NOTE_NAME]: string }) => {
    const dataToSend: any = {
      task_answer: {
        [NOTE_NAME]: data[NOTE_NAME],
      },
    };
    if (evaluationStatus && selectedAnswer) {
      dataToSend.task_answer.status = evaluationStatus;
      sendAnswer({ id: selectedAnswer.id, data: dataToSend as AnswerData });
    } else if (answerStatus && answerStatus !== AnswerStatus.TO_VERIFY) {
      dataToSend.task_answer.status = answerStatus;
      sendAnswer({ id: selectedAnswer.id, data: dataToSend as AnswerData });
    }
    setAnswerModal(false);
    setEvaluationStatus(undefined);
  };

  const answerType =
    (answerStatus === AnswerStatus.ACCEPTED && t('exercise-accepted')) ||
    (answerStatus === AnswerStatus.FAILED && t('task-to-improve')) ||
    t('task-to-evaluate');

  return (
    <Modal
      isOpen={answerModal}
      onRequestClose={handleClose}
      noPadding
      maxWidth="1011px"
      className="answer-modal"
      bodyClassName="answer-modal__body"
    >
      <div className="answer-modal__container">
        {selectedTask && (
          <section className="answer-modal__left">
            <header>
              <h2 className="answer-modal__type">{answerType}</h2>
              <h3 className="answer-modal__course-name">
                {selectedTask.course.name}
              </h3>
            </header>
            <StyledScrolls className="answer-modal__description-wrapper">
              <TaskInfo
                isModule={
                  selectedTask.resource_type === ResourceTypes.COURSE_MODULE
                }
                resourceName={selectedTask.resource.name}
                resourceOrder={selectedTask.resource_number}
              />

              <h4 className="answer-modal__task-name">
                {`${selectedTask.resource.name} - ${getPathDestination(
                  selectedTask.name
                )}`}
              </h4>

              <div
                className="answer-modal__description"
                dangerouslySetInnerHTML={{
                  __html: selectedTask.description || '',
                }}
              />

              <h4 className="answer-modal__description-title">
                {`${t('instruction')}:`}
              </h4>
              <div
                className="answer-modal__description-point"
                dangerouslySetInnerHTML={{
                  __html: selectedTask.instruction,
                }}
              />
            </StyledScrolls>
          </section>
        )}
        {selectedAnswer && (
          <section
            className={clsx(
              'answer-modal__right',
              !isAnswerToVerify && 'answer-modal__right--done'
            )}
          >
            {user && (
              <header className="answer-modal__participant-info">
                <p>
                  {t('task-to-review', {
                    date: format(selectedAnswer.answer_date, 'dateWithTime'),
                  })}
                </p>
                <Person
                  type="participant"
                  details={false}
                  profile={user}
                  layout="large"
                  size={48}
                />
              </header>
            )}
            <h2 className="answer-modal__heading">{t('user-answer')}</h2>
            {selectedAnswer.collateral ? (
              <div>
                <AttachmentItem
                  name={selectedAnswer.collateral.name}
                  url={selectedAnswer.collateral.url}
                />
                {selectedAnswer.collateral.description && (
                  <>
                    <p className="answer-modal__heading answer-modal__heading--top-space">
                      {t('short-desc')}
                    </p>
                    <p>{selectedAnswer.collateral.description}</p>
                  </>
                )}
              </div>
            ) : (
              <StyledScrolls className="answer-modal__answer-scrollbar">
                {selectedAnswer.answer}
              </StyledScrolls>
            )}

            <form onSubmit={handleSubmit(sendEvaluation)}>
              <div className="answer-modal__buttons answer-modal__buttons--with-space">
                <Button
                  className={clsx(
                    'answer-modal__accept-button',
                    evaluationStatus === AnswerStatus.ACCEPTED &&
                      'answer-modal__accept-button--accepted'
                  )}
                  onClick={() => setEvaluationStatus(AnswerStatus.ACCEPTED)}
                  variant={
                    evaluationStatus === AnswerStatus.ACCEPTED
                      ? 'primary'
                      : 'secondary'
                  }
                  disabled={isAnswerAcceptable}
                >
                  {t('accept')}
                </Button>
                <Button
                  className={clsx(
                    'answer-modal__reject-button',
                    evaluationStatus === AnswerStatus.FAILED &&
                      'answer-modal__reject-button--failed'
                  )}
                  onClick={() => setEvaluationStatus(AnswerStatus.FAILED)}
                  variant={
                    evaluationStatus === AnswerStatus.FAILED
                      ? 'primary'
                      : 'secondary'
                  }
                  disabled={isAnswerRejectable}
                >
                  {t('reject')}
                </Button>
              </div>

              <Textarea
                className={clsx(!isAnswerToVerify && 'answer-modal__note')}
                rows={4}
                name={NOTE_NAME}
                register={register}
                label={
                  isAnswerToVerify ? t('answer-feedback') : t('your-answer')
                }
                value={isAnswerToVerify ? '' : selectedAnswer.note}
              />

              <div className="answer-modal__buttons">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!evaluationStatus}
                >
                  {isAnswerToVerify ? t('send-evalutaion') : t('edit')}
                </Button>
                <Button onClick={handleClose} variant="secondary">
                  {t('cancel')}
                </Button>
              </div>
            </form>
          </section>
        )}
      </div>
    </Modal>
  );
};
