import Select, { SingleValue } from 'react-select';
import { t } from 'i18next';
import './select.scss';

type SelectType = {
  value: string;
  label: string;
};

type SelectProps = {
  options: SelectType[];
  className?: string;
  id?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  isDisabled?: boolean;
  value?: string;
  defaultValue?: string;
  isSearchable?: boolean;
};

export const SelectComponent: React.FC<SelectProps> = ({
  value,
  defaultValue,
  isSearchable = false,
  ...props
}) => {
  const { className, onChange, options, placeholder } = props;
  const handleChange = (newValue: SingleValue<SelectType>) => {
    if (newValue && onChange) {
      onChange(newValue.value);
    }
  };

  const computeSelectOption =
    options.find((option: SelectType) => option.value === value) ??
    options.find((option: SelectType) => option.value === defaultValue) ??
    options[0];

  return (
    <Select
      className={className}
      {...props}
      isSearchable={isSearchable}
      value={computeSelectOption}
      defaultValue={computeSelectOption}
      classNamePrefix="react-select"
      components={{ IndicatorSeparator: () => null }}
      onChange={handleChange}
      placeholder={
        (placeholder && !options) || !defaultValue ? t('select') : placeholder
      }
    />
  );
};
