import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  isAfter,
  isSameDay,
  set,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { pl } from 'date-fns/locale';
import { today } from 'helpers/time';
import {
  Availability,
  BookedSession,
  BookedSessionStatus,
  SortedTimeslot,
  TimeslotsAvailability,
} from 'types/api';
import { DateFormats } from 'types/enums';
import { CoachSessions } from 'views/calendar';

export type WeekDay = {
  name: string;
  date: Date;
};

type TimeSlot = {
  id: number;
  time: string;
  busy: boolean;
};

export const convertDateToApiCompatible = (date: Date) =>
  format(date, DateFormats.YEAR_MONTH_DAY);

export const getDateFromObj = (date: Date) =>
  format(date, DateFormats.DAY_MONTH_YEAR);

export const getLocaleTimeFromDate = (date: Date) =>
  format(date, DateFormats.LOCALE_TIME, { locale: pl });

export const getHours = (date: Date) =>
  `${String(date.getHours()).padStart(2, '0')}:00`;

export const convertToLocaleTimezone = (date: string) => {
  const targetTime = new Date(date);
  const tzDifference = targetTime.getTimezoneOffset();
  return new Date(targetTime.getTime() - tzDifference * 60 * 1000).toString();
};

export const getTodayAndWeekLater = () => {
  const todayDate = today();
  const weekLater = addWeeks(todayDate, 1);
  return [
    convertDateToApiCompatible(todayDate),
    convertDateToApiCompatible(weekLater),
  ];
};

export const getWeekDays = (): WeekDay[] => {
  const now = new Date();
  const start = startOfWeek(now, { locale: pl });
  const end = endOfWeek(now, { locale: pl });
  const weekDays = eachDayOfInterval({ start, end }).map((day) => ({
    name: format(day, DateFormats.DAY_LONG, { locale: pl }),
    date: day,
  }));

  return weekDays;
};

export const getMonthlySessionPeriod = (date: Date) => [
  convertDateToApiCompatible(startOfMonth(date)),
  convertDateToApiCompatible(
    startOfMonth(
      date.getMonth() === 11
        ? new Date(date.getFullYear() + 1, 0)
        : new Date(date.getFullYear(), date.getMonth() + 1)
    )
  ),
];

export const selectedDayTimeSlots = (
  day: Date,
  availabilities: Availability[]
) => {
  const convertedDate = convertDateToApiCompatible(day);
  return availabilities.find(
    (availability) => availability.date === convertedDate
  );
};

export const isTimeSlotAvailable = (timeSlot: TimeSlot) => !timeSlot.busy;

export const getAvailableTimeSlots = (
  day: Date,
  availabilities: Availability[]
) => {
  const availableSlots =
    selectedDayTimeSlots(day, availabilities)?.time_slots.filter(
      isTimeSlotAvailable
    ) || [];

  if (day.getDate() === today().getDate()) {
    return availableSlots.filter(
      (slot) => parseInt(slot.time, 10) > today().getHours()
    );
  }
  return availableSlots;
};

export const calculateMeetingsCount = (
  date: Date,
  availabilities: Availability[]
) => getAvailableTimeSlots(date, availabilities)?.length || 0;

export const getDayLong = (date: Date) =>
  format(date, DateFormats.DAY_LONG, { locale: pl });

export const groupSessionsByDate = (sessions: BookedSession[]) => {
  return sessions.reduce((date: Record<string, BookedSession[]>, session) => {
    const { start_time: starTime } = session;
    const formattedDay = convertDateToApiCompatible(new Date(starTime));
    date[formattedDay] = date[formattedDay] ?? [];
    date[formattedDay].push(session);
    return date;
  }, {});
};

export const getActiveTimeslots = (timeSlots: TimeslotsAvailability[]) => {
  return timeSlots.filter(({ start_time: startTime }) => {
    const timeslotDate = new Date(startTime);
    if (isSameDay(today(), timeslotDate)) {
      return isAfter(timeslotDate, today());
    }
    return true;
  });
};

export const getSelectedMeetingsDay = (
  day: Date,
  coachMeetingDays: SortedTimeslot[]
) => {
  const convertedDate = convertDateToApiCompatible(day);
  return coachMeetingDays.find(({ date }) => date === convertedDate);
};

export const groupSessionsByDay = (sessions: BookedSession[]) => {
  return sessions.reduce((date: Record<string, BookedSession[]>, session) => {
    const { start_time: starTime } = session;
    const formattedDay = new Date(starTime).getDay();
    date[formattedDay] = date[formattedDay] ?? [];
    date[formattedDay].push(session);
    return date;
  }, {});
};

export const getCoachMeetingsCount = (
  date: Date,
  coachSessions?: CoachSessions
) =>
  coachSessions?.[convertDateToApiCompatible(date)]?.filter(
    (session) => session.status !== BookedSessionStatus.REJECTED
  ).length ?? 0;

export const setTimeInDate = (
  date: Date,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0
) => {
  const updatedDate = set(date, { hours, minutes, seconds, milliseconds });
  return updatedDate.toISOString();
};

export const createPickerValue = (value: string, date: string) => {
  const [hours, minutes] = value.split(':');
  const updateDate = set(new Date(date), { hours: +hours, minutes: +minutes });
  return updateDate.toISOString();
};

export const isCoachAvailable = (availability?: Availability[]): boolean => {
  return (
    availability?.some(
      (day) =>
        (day.time_slots.length > 0 &&
          day.time_slots.find((time) => !time.busy)) ??
        false
    ) ?? false
  );
};
