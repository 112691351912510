import { percentage } from 'helpers/utils';
import './linear-indicator.scss';

type LinearIndicatorProps = {
  value: number;
  outOf: number;
};

export const LinearIndicator: React.FC<LinearIndicatorProps> = ({
  value,
  outOf,
}) => (
  <div className="linear-indicator">
    <div
      style={{ width: `${percentage(value, outOf)}%` }}
      className="linear-indicator__line"
    />
  </div>
);
