import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Loader } from 'components/loader';
import { TaskPreview } from 'components/task-preview';
import { Video } from 'components/video';
import { useCourseAsideV2 } from 'hooks/use-course-asideV2';
import { useCourseLesson } from 'hooks/use-course-lesson';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { ModuleDetails } from 'types/api';
import { ModuleContent } from 'views/coursesV2/components/module-content';
import { LessonTask } from './lesson-task';
import './lesson-detailsV2.scss';

type LessonDetailsProps = {
  modules: ModuleDetails[];
  courseId: number;
  courseSubscriptionId: number;
};

export const LessonDetailsV2: React.FC<LessonDetailsProps> = ({
  modules,
  courseId,
  courseSubscriptionId,
}) => {
  const { get } = useQueryParameters();
  const { moveToNext: moveToNextLesson } = useCourseAsideV2();

  const {
    lesson,
    finishLesson,
    restLessonQuery,
    restFinishLessonQuery,
    invalidate: invalidateLesson,
  } = useCourseLesson(courseSubscriptionId);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(
    Boolean(get('task-open'))
  );
  const [isTaskPreviewOpen, setIsTaskPreviewOpen] = useState(false);
  const { t } = useTranslation();
  const { isParticipant } = useContext(AuthContext);
  const { optimisticallyValidate } = useCourseAsideV2();

  const currentModule = modules.find(
    (module) => module.id === lesson?.course_module_id
  );

  const moduleOrder =
    modules.findIndex((module) => module.id === lesson?.course_module_id) + 1;

  const handleFinishLesson = () => {
    finishLesson(undefined, {
      onSuccess: () => {
        if (lesson?.task) {
          setIsTaskPreviewOpen(true);
        } else {
          moveToNextLesson();
        }
      },
    });
  };

  const handleModalClose = () => {
    setIsTaskPreviewOpen(false);
    setIsTaskModalOpen(false);
    optimisticallyValidate();
  };

  const handleOpenTask = () => {
    setIsTaskModalOpen(true);
    setIsTaskPreviewOpen(false);
  };

  if (restLessonQuery.isLoading) {
    return <Loader />;
  }

  return (
    <div className="lesson-detailsV2">
      <p className="lesson-detailsV2__header">
        {t('lesson-details', {
          moduleOrder: modules.length > 1 ? moduleOrder : '',
          moduleName: currentModule?.name || '',
        })}
      </p>

      {lesson?.video_url && (
        <Video
          source={String(lesson.video_url)}
          initialTime={Number(lesson?.user_lesson?.video_watched_time)}
          lessonSlug={lesson?.slug}
        />
      )}
      {currentModule && (
        <ModuleContent
          coach={currentModule?.coach}
          attachments={
            (lesson &&
              lesson.module_lesson_attachments.concat(
                lesson.course_module_attachments
              )) ||
            []
          }
          courseId={courseId}
        >
          {!lesson?.user_lesson?.completed && isParticipant && (
            <div className="lesson-detailsV2__navigation">
              <Button
                loading={restFinishLessonQuery.isLoading}
                onClick={handleFinishLesson}
              >
                {t('end-lesson')}
              </Button>
            </div>
          )}
        </ModuleContent>
      )}
      {lesson?.task && (
        <>
          <TaskPreview
            isTaskModalOpen={isTaskPreviewOpen}
            onModalClose={handleModalClose}
            onTaskOpen={handleOpenTask}
            task={lesson.task}
            moduleName={currentModule?.name ?? ''}
            moduleOrder={moduleOrder}
          />
          <LessonTask
            isTaskModalOpen={isTaskModalOpen}
            onModalOpen={() => setIsTaskModalOpen(true)}
            onModalClose={handleModalClose}
            invalidateLesson={invalidateLesson}
            currentModule={currentModule}
            task={lesson.task}
            isLessonCompleted={!!lesson?.user_lesson?.completed}
          />
        </>
      )}
    </div>
  );
};
