import { useTranslation } from 'react-i18next';
import './radio.scss';

type Option<T> = {
  value: T;
  label?: string;
};

type PropsRadio<T> = {
  label?: string | boolean;
  name?: string;
  errorLabel?: string;
  register?: any;
  errors?: Record<string, string>;
  disabled?: boolean;
  value?: string;
  options?: Option<T>[];
  onChange?: (value: T, event?: React.FormEvent<HTMLDivElement>) => void;
};

export const Radio: React.FC<PropsRadio<string>> = ({
  label,
  name,
  register,
  errors = {},
  errorLabel,
  disabled = false,
  value,
  options = [],
  onChange = () => {},
}: PropsRadio<string>) => {
  const change = (checked: React.FormEvent<HTMLDivElement>) => {
    onChange((checked.target as HTMLInputElement).value, checked);
  };
  const { t } = useTranslation();
  const errorLabelWithFallback = errorLabel || `${t('field-required')}`;

  return (
    <fieldset className="form-radio">
      {!!label && <legend className="form-radio__legend">{label}</legend>}
      <div onChange={change} className="form-radio__options">
        {options.map((option) => (
          <label
            key={`${name}-${option.label}-${option.value}`}
            className="form-radio__label"
          >
            <input
              className="form-radio__input"
              defaultChecked={value === option.value}
              value={option.value}
              name={name}
              type="radio"
              disabled={disabled}
              ref={register}
            />
            <div className="form-radio__radio-helper" />
            <span className="form-radio__label-text">{option?.label}</span>
          </label>
        ))}
      </div>
      {name && errors?.[name] && errorLabelWithFallback}
    </fieldset>
  );
};
