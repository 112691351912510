import { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { DropdownMenu } from 'components/dropdown-menu';
import './dropdown-button.scss';

type DropdownButtonProps = {
  buttonContent: ReactNode;
  menuClassName?: string;
  listClassName?: string;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  buttonContent,
  children,
  menuClassName,
  listClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div className="dropdown-button">
      <button
        className={clsx(
          'dropdown-button__button',
          isOpen && 'dropdown-button__button--active'
        )}
        onClick={handleClick}
      >
        {buttonContent}
      </button>
      {isOpen && (
        <DropdownMenu
          className={clsx('dropdown-button__dropdown', menuClassName)}
          listClassName={listClassName}
          onClose={handleClose}
        >
          {children}
        </DropdownMenu>
      )}
    </div>
  );
};
