import { createRef } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import './cookie-banner.scss';

const COOKIE_EXPIRATION_DAYS = 365;

export const CookieBanner: React.FC = () => {
  const { t } = useTranslation();
  const cookieConsentRef = createRef<CookieConsent & { accept: () => void }>();
  return (
    <CookieConsent
      ref={cookieConsentRef}
      contentClasses="cookie-banner__content"
      containerClasses="cookie-banner__wrapper"
      expires={COOKIE_EXPIRATION_DAYS}
      cookieName="skills-arena-accepted"
      disableStyles
      ButtonComponent={() => (
        <Button
          type="submit"
          className="cookie-banner__button"
          onClick={() => cookieConsentRef.current?.accept()}
        >
          {t('accept')}
        </Button>
      )}
    >
      {t('cookie-text')}
    </CookieConsent>
  );
};
