import { useState } from 'react';
import { Tab } from './tab';
import { TabList } from './tab-list';
import { TabPanel } from './tab-panel';

type Item = {
  id: number;
  label: string;
  component: React.ComponentType<any>;
};

type TabbedInterfaceProps = {
  items: Item[];
  ariaLabel?: string;
  className?: string;
};

export const TabbedInterface: React.FC<TabbedInterfaceProps> = ({
  items,
  ariaLabel,
  className,
}) => {
  const [activeTabId, setActiveTabId] = useState(items[0].id);

  const ActiveContent = items.find(({ id }) => id === activeTabId)?.component;

  return (
    <div className={className} aria-label={ariaLabel}>
      <TabList>
        {items.map(({ id, label }) => (
          <Tab
            active={id === activeTabId}
            key={id}
            onClick={() => setActiveTabId(id)}
          >
            {label}
          </Tab>
        ))}
      </TabList>

      <TabPanel>{ActiveContent ? <ActiveContent /> : null}</TabPanel>
    </div>
  );
};
