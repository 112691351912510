import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useConnectionStatus = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const setOffline = () => {
      toast.error(t('lost-connection'));
    };
    const setOnline = () => {
      toast.success(t('retrieved-connection'));
    };

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, [t]);
};
