import { Link } from 'react-router-dom';
import { isFuture, isToday } from 'date-fns';
import { t } from 'i18next';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { format } from 'helpers/time';
import { getFullName } from 'helpers/utils';
import { BookedSession } from 'types/api';
import './upcoming-session-card.scss';

type UpcomingSessionProps = {
  sessions: BookedSession[];
};

export const UpcomingSessionCard: React.FC<UpcomingSessionProps> = ({
  sessions,
}) => {
  const upcomingSession = sessions.filter(
    (session) =>
      isFuture(new Date(session.start_time)) &&
      isToday(new Date(session.start_time))
  );
  const isGroupSession =
    upcomingSession[0] && upcomingSession[0].participants.length > 1;

  if (upcomingSession[0]) {
    return (
      <Card className="upcoming-session-card">
        <div className="upcoming-session">
          <div className="upcoming-session__header">
            <span className="upcoming-session__header--title">
              {t('upcoming-session')}
            </span>
          </div>

          <div>
            {isGroupSession
              ? t('upcoming-group-session')
              : getFullName(upcomingSession[0].participants[0])}
          </div>
          <div>{format(upcomingSession[0].start_time, 'dateWithTime')}</div>

          <Link
            className="upcoming-session__link"
            to={{
              pathname: '/calendar',
              state: {
                upcomingSession: upcomingSession[0].start_time,
              },
            }}
          >
            <Button className="primary">{t('go-to-calendar')}</Button>
          </Link>
        </div>
      </Card>
    );
  }
  return null;
};
