import { useContext } from 'react';
import { AuthContext } from 'contexts';
import { Modal } from 'components/modal';
import { ProfileType } from 'types/user';
import { HrDetails } from './hr-details/hr-details';

type HrDetailsModalProps = {
  profile: ProfileType;
  onClose: () => void;
};

export const HrDetailsModal: React.FC<HrDetailsModalProps> = ({
  onClose,
  profile,
}) => {
  const { user } = useContext(AuthContext);
  return (
    <Modal isOpen={!!profile} onRequestClose={onClose}>
      {!!profile && (
        <HrDetails hrDetails={profile} chat={user?.id !== profile?.id} />
      )}
    </Modal>
  );
};
