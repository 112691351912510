import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Input } from 'components/form';
import { FormSelect } from 'components/form-select';
import { ViewWrapper } from 'components/view-wrapper';
import { getFullName } from 'helpers/chat';
import { createCourseSelectOptions, createSelectOptions } from 'helpers/utils';
import { useCompanies } from 'hooks/use-companies';
import { useCompanyDetails } from 'hooks/use-company-details';
import { useReports } from 'hooks/use-reports';
import { useResourceById } from 'hooks/use-resource-by-id';
import { Roles } from 'types/enums';
import { ReportsList } from './components/reports-list/reports-list';
import './report.scss';

const ReportScreen: React.FC = () => {
  const { t } = useTranslation();

  const { userRole } = useContext(AuthContext);
  const isSuperCoach = userRole === Roles.SUPER_COACH;

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      csv_filename: '',
      demand_company_id: '',
      file_extension: 'csv',
      demand_company_subscription_courses_ids: '-1',
      demand_modules_ids: '-1',
      demand_users_ids: '-1',
    },
    mode: 'onChange',
  });
  const registerValue = register({ required: true, min: 1 });

  const { reports, restReportsQuery, submitReport } = useReports();
  const { companies, isLoading: areCompaniesLoading } =
    useCompanies(isSuperCoach);
  const { companyDetails, isLoading: areCompanyDetailsLoading } =
    useCompanyDetails(+getValues().demand_company_id || null);

  const areReportsLoading = restReportsQuery.isLoading;

  const { resource, setResourceId } = useResourceById(companyDetails);

  const isAdditionalSelectsDisabled =
    !+getValues().demand_company_id && isSuperCoach;

  const handleReset = () => {
    reset();
    setResourceId(-1);
  };

  const onSubmit = async (formData: Record<string, string>) => {
    submitReport(formData);
    handleReset();
  };

  const users = resource?.users.map((user) => ({
    id: user.id,
    name: getFullName(user.first_name, user.last_name),
  }));

  return (
    <ViewWrapper loading={areCompanyDetailsLoading || areCompaniesLoading}>
      <div className="report content-inner">
        <Card>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={clsx('report__form', isSubmitting && 'disabled')}
          >
            <div className="report__form-header">{t('generate-report')}</div>
            <div className="report__inputs">
              <Input
                label={t('report-name')}
                type="text"
                name="csv_filename"
                placeholder={t('report-name')}
                errors={errors}
                register={registerValue}
              />
              {isSuperCoach && (
                <FormSelect
                  label={t('company')}
                  name="demand_company_id"
                  control={control}
                  required
                  placeholder={t('select-company')}
                  errors={errors}
                  options={[...createSelectOptions(companies)]}
                  onInputChange={() => {
                    control.setValue(
                      'demand_company_subscription_courses_ids',
                      '-1'
                    );
                    control.setValue('demand_modules_ids', '-1');
                    control.setValue('demand_users_ids', '-1');
                    control.updateFormState();
                  }}
                />
              )}
              <FormSelect
                label={t('file-extension')}
                name="file_extension"
                placeholder={t('choose-file-extension')}
                control={control}
                isDisabled={isAdditionalSelectsDisabled}
                options={[
                  {
                    value: 'csv',
                    label: 'csv',
                  },
                  {
                    value: 'xls',
                    label: 'xls',
                  },
                ]}
                onInputChange={() => {
                  control.setValue(
                    'demand_company_subscription_courses_ids',
                    '-1'
                  );
                  control.setValue('demand_modules_ids', '-1');
                  control.setValue('demand_users_ids', '-1');
                  control.updateFormState();
                }}
              />
              <FormSelect
                label={t('course')}
                name="demand_company_subscription_courses_ids"
                placeholder={t('choose-course')}
                control={control}
                isDisabled={isAdditionalSelectsDisabled}
                options={[
                  {
                    value: '-1',
                    label: t('all'),
                  },
                  ...createCourseSelectOptions(companyDetails),
                ]}
                onInputChange={(value) => {
                  setResourceId(+value);
                  control.setValue('demand_modules_ids', '-1');
                  control.setValue('demand_users_ids', '-1');
                  control.updateFormState();
                }}
              />
              <FormSelect
                label={t('modules')}
                name="demand_modules_ids"
                isDisabled={isAdditionalSelectsDisabled}
                placeholder={t('choose-modules')}
                control={control}
                options={[
                  ...createSelectOptions(resource?.course.course_modules, {
                    id: '-1',
                    name: t('all'),
                  }),
                ]}
                onInputChange={() => {
                  control.setValue('demand_users_ids', '-1');
                  control.updateFormState();
                }}
              />
              <FormSelect
                label={t('participant')}
                name="demand_users_ids"
                isDisabled={isAdditionalSelectsDisabled}
                placeholder={t('choose-participants')}
                control={control}
                options={[
                  ...createSelectOptions(users, {
                    id: '-1',
                    name: t('all'),
                  }),
                ]}
              />
            </div>
            <div className="report__buttons">
              <Button type="submit" disabled={isSubmitting}>
                {t('generate')}
              </Button>
              <Button
                type="reset"
                variant="secondary"
                onClick={handleReset}
                disabled={isSubmitting}
                className="btn-outline"
              >
                {t('clear')}
              </Button>
            </div>
          </form>
        </Card>

        <ReportsList reports={reports} isLoading={areReportsLoading} />
      </div>
    </ViewWrapper>
  );
};

export default ReportScreen;
