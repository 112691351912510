import {
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Channel } from 'actioncable';
import { QueryKeys } from 'constants/query-keys';
import { ActionCableContext, AuthContext } from 'contexts';
import {
  getConversations,
  getOpenedConversations,
  getUnseenMessagesCount,
  updateConversation as updateConversationRequest,
} from 'services/conversations';
import { StateSetter } from 'helpers/types';
import { ChangeSeenStatus, RecipientSeenMessage } from 'types/actioncable';
import { Conversation, Message } from 'types/api';

export interface ChatType {
  isChatVisible: boolean;
  isConversationOpen: boolean;
  conversations: Conversation[];
  currentConversation: Conversation | null;
  isLoading: boolean;
  messages: Message[];
  conversationChannel: Channel | null;
  chatNotification: boolean;
  unseenMessagesCount: number;
  refreshUnseenMessagesCount: () => void;
  seenStatuses: RecipientSeenMessage[];
  setIsChatVisible: StateSetter<boolean>;
  openConversation: (id: number) => void;
  setCurrentConversation: StateSetter<Conversation | null>;
  setSeenStatuses: StateSetter<RecipientSeenMessage[]>;
  updateSeenStatus: (conversationId: number) => void;
  closeWindow: () => void;
  closeConversation: () => void;
}

type ChatProps = {
  children: ReactChild;
};

export const ChatContext = createContext<ChatType>({
  chatNotification: false,
  isChatVisible: false,
  isConversationOpen: false,
  conversations: [],
  currentConversation: null,
  isLoading: false,
  messages: [],
  conversationChannel: null,
  seenStatuses: [],
  unseenMessagesCount: 0,
  refreshUnseenMessagesCount: () => {},
  setIsChatVisible: () => {},
  openConversation: () => {},
  setCurrentConversation: () => {},
  setSeenStatuses: () => {},
  updateSeenStatus: () => {},
  closeWindow: () => {},
  closeConversation: () => {},
});

export const ChatProvider: React.FC<ChatProps> = ({ children }) => {
  const [isChatVisible, setIsChatVisible] = useState(false);

  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);
  const isConversationOpen = !!currentConversation;
  const [isLoading, setIsLoading] = useState(false);
  const { cable } = useContext(ActionCableContext);
  const { user } = useContext(AuthContext);
  const [conversationChannel, setConversationChannel] =
    useState<Channel | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [seenStatuses, setSeenStatuses] = useState<RecipientSeenMessage[]>([]);

  const closeConversation = useCallback(() => {
    setCurrentConversation(null);
    setMessages([]);
  }, []);

  const closeWindow = useCallback(() => {
    closeConversation();
    setIsChatVisible(false);
  }, [closeConversation]);

  const queryClient = useQueryClient();

  const { data: conservationsData } = useQuery(
    QueryKeys.CONVERSATIONS,
    () => getConversations(),
    {
      onError: () => {
        toast.error('conversations-error');
      },
      enabled: !!user && isChatVisible,
    }
  );

  const { data: unseenMessagesCount } = useQuery(
    QueryKeys.UNSEEN_MESSAGES_COUNT,
    () => getUnseenMessagesCount(),
    {
      onError: () => {
        toast.error('conversations-error');
      },
      enabled: !!user,
    }
  );

  const refreshUnseenMessagesCount = () =>
    queryClient.invalidateQueries(QueryKeys.UNSEEN_MESSAGES_COUNT);

  const updateChannelLastMessageUnseen = (conversation: Conversation) => {
    if (!conversationChannel) return;
    const dataToSend: ChangeSeenStatus = {
      type: 'seen_status',
      channel_sid: conversation.channel_sid,
    };
    conversationChannel.send(dataToSend); // update conversation last_message_unseen
  };

  const activateChatWithUser = (conversationData: Conversation) => {
    setCurrentConversation(conversationData);
    setMessages(conversationData.messages);
    setIsChatVisible(true);
    refreshUnseenMessagesCount();
  };

  const isLastMessageFromDifferentUserUnseen = (
    data: Conversation,
    channel = conversationChannel
  ) =>
    data.last_message_unseen &&
    data.messages.at(-1)?.user_id !== user?.id &&
    channel;

  const { mutateAsync: updateConversation } = useMutation(
    (id: number) => updateConversationRequest(id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([QueryKeys.CONVERSATIONS]);
        activateChatWithUser(data);
        if (isLastMessageFromDifferentUserUnseen(data)) {
          queryClient.setQueryData(
            [QueryKeys.OPENED_CONVERSATION, data.id],
            () => ({
              ...data,
              unseen_messages_count: 0,
              last_message_unseen: false,
            })
          );
          updateChannelLastMessageUnseen(data);
        } else {
          queryClient.setQueryData(
            [QueryKeys.OPENED_CONVERSATION, data.id],
            () => data
          );
        }
      },
    }
  );

  const conversations = useMemo(
    () =>
      conservationsData
        ? conservationsData.map((conversation: Conversation) =>
            conversation.last_message &&
            conversation.last_message.user_id === user?.id
              ? { ...conversation, last_message_unseen: false }
              : conversation
          )
        : [],
    [conservationsData, user?.id]
  );

  const updateLastMessage = useCallback(
    (last_message: Message) => {
      queryClient.setQueryData<Conversation[]>(
        QueryKeys.CONVERSATIONS,
        (prevState) => {
          if (!prevState) {
            return [];
          }
          const updatedConversations = [...prevState];
          const conversationToEdit = updatedConversations.splice(0, 1)[0];
          if (user?.id === last_message?.user_id) {
            return [
              {
                ...conversationToEdit,
                last_message,
                last_message_unseen: false,
                viewed_time: null,
              },
              ...updatedConversations,
            ];
          }
          return [
            {
              ...conversationToEdit,
              last_message,
              last_message_unseen: true,
              unseen_messages_count: conversationToEdit
                ? conversationToEdit.unseen_messages_count
                : 0,
              viewed_time: null,
            },
            ...updatedConversations,
          ];
        }
      );
    },
    [queryClient, user?.id]
  );

  const updateSeenStatus = (conversationId: number) => {
    const updatedConversations = conversations.map(
      (conversation: Conversation) =>
        conversation.id === conversationId
          ? {
              ...conversation,
              unseen_messages_count: 0,
              last_message_unseen: false,
            }
          : conversation
    );
    queryClient.setQueryData<Conversation[]>(
      QueryKeys.CONVERSATIONS,
      () => updatedConversations
    );
  };

  const isCableAvaliable = !!cable;

  useEffect(() => {
    closeWindow();
  }, [closeWindow]);

  useEffect(() => {
    const checkUnseenMessagesCount = setInterval(() => {
      queryClient.invalidateQueries(QueryKeys.UNSEEN_MESSAGES_COUNT);
    }, 10_000);

    return () => clearInterval(checkUnseenMessagesCount);
  }, [queryClient]);

  const openConversation = async (userId: number) => {
    if (userId === user?.id) {
      toast.info('open-conversation-info');
      return;
    }
    const openedConversation = conversations.find(
      (conversation) =>
        conversation.invited.id === userId || conversation.owner.id === userId
    );
    if (
      currentConversation &&
      currentConversation?.id === openedConversation?.id
    ) {
      closeWindow();
      return;
    }
    try {
      setIsLoading(true);
      if (openedConversation) {
        const conversationData = await queryClient.fetchQuery(
          [QueryKeys.OPENED_CONVERSATION, openedConversation.id],
          () => getOpenedConversations(openedConversation.id)
        );
        queryClient.setQueryData<Conversation[]>(
          QueryKeys.CONVERSATIONS,
          (prevState) => {
            if (!prevState) {
              return [];
            }
            return prevState.map((conversation) =>
              conversation.id === openedConversation.id
                ? {
                    ...conversation,
                    last_message_unseen: false,
                    unseen_messages_count: 0,
                  }
                : conversation
            );
          }
        );
        activateChatWithUser(conversationData);
        if (isLastMessageFromDifferentUserUnseen(conversationData)) {
          queryClient.invalidateQueries([
            QueryKeys.OPENED_CONVERSATION,
            openedConversation.id,
          ]);
          updateChannelLastMessageUnseen(openedConversation);
        }
      } else {
        await updateConversation(userId);
      }
    } catch {
      toast.error('update-conversation-error');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isCableAvaliable && user?.id) {
      const channel = cable.subscriptions.create(
        {
          channel: `ConversationsChannel`,
        },
        {
          received: (data) => {
            if (data.type === 'new_message') {
              updateLastMessage(data.message);
              setMessages((prevState) => [...prevState, data.message]);
            } else {
              setSeenStatuses((prevState) => [...prevState, data]);
            }
          },
        }
      );
      setConversationChannel(channel);
      return () => channel.unsubscribe();
    }
    return () => {};
  }, [cable?.subscriptions, isCableAvaliable, updateLastMessage, user?.id]);

  useEffect(() => {
    if (isCableAvaliable && user?.id) {
      const channel = cable.subscriptions.create(
        {
          channel: `NotificationsChannel`,
        },
        {
          received: (data) => {
            queryClient.setQueryData<Conversation[]>(
              QueryKeys.CONVERSATIONS,
              () => data.conversation
            );
          },
        }
      );
      return () => channel.unsubscribe();
    }
    return () => {};
  }, [cable?.subscriptions, isCableAvaliable, queryClient, user?.id]);

  useEffect(() => {
    if (isChatVisible) {
      queryClient.invalidateQueries(QueryKeys.CONVERSATIONS);
    }
  }, [isChatVisible, queryClient]);

  const contextValue: ChatType = {
    isChatVisible,
    isConversationOpen,
    conversations,
    currentConversation,
    isLoading,
    messages,
    conversationChannel,
    chatNotification:
      !!unseenMessagesCount && unseenMessagesCount?.unseen_messages_count > 0,
    unseenMessagesCount: unseenMessagesCount?.unseen_messages_count || 0,
    refreshUnseenMessagesCount,
    seenStatuses,
    setIsChatVisible,
    updateSeenStatus,
    openConversation,
    setCurrentConversation,
    setSeenStatuses,
    closeConversation,
    closeWindow,
  };

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};
