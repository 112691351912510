import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AvatarList } from 'components/avatar-list';
import { Card } from 'components/card';
import { Company } from 'components/company';
import { Person } from 'components/person';
import { Client } from 'types/api';
import { ReactComponent as ProfileIcon } from 'assets/icons/avatar-2.svg';
import { ReactComponent as BookLine } from 'assets/icons/book-line.svg';
import './client-card.scss';

type ClientCardProps = {
  clientData: Client;
};

export const ClientCard: React.FC<ClientCardProps> = ({ clientData }) => {
  return (
    <Card className="client-detail" testid="client-card">
      <Link
        className="client-detail__card-link"
        to={`/companies/${clientData.id}`}
      >
        <div className="client-detail__group-top">
          <div className="client-detail__course-heading">
            <div className="client-detail__course-icon-text">
              <Company
                company={{
                  logo: clientData.logo,
                  id: clientData.id,
                  name: clientData.name,
                }}
                onlyLogo
                avatarSize="large"
              />
              <div className="client-detail__course-heading-name">
                {clientData.name}
              </div>
            </div>
            <div className="client-detail__icons">
              <Person type="participant" size={36} profile={clientData.hr} />
            </div>
          </div>
        </div>

        <div className="client-detail__participants">
          <div className="client-detail__icon-text">
            <div className="client-detail__icon-sec">
              <BookLine className="client-detail__book-icon" />
            </div>
            <div>
              <Trans i18nKey="coursesCount" count={clientData.courses_count}>
                <span className="client-detail__gray">courses</span>
              </Trans>
            </div>
          </div>
          <div className="client-detail__icon-text">
            <div className="client-detail__icon-sec">
              <ProfileIcon className="client-detail__profile-icon" />
            </div>
            <div>
              <Trans
                i18nKey="participants-count"
                count={clientData.participants_count}
                values={{ maxCount: 50 }}
              >
                <span className="client-detail__gray">/ 50 participants</span>
              </Trans>
            </div>
          </div>
        </div>
      </Link>
      <AvatarList
        avatarList={clientData.coaches}
        className="client-detail__avatar-list"
      />
    </Card>
  );
};
