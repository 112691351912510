import { t } from 'i18next';
import './error-fallback.scss';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) => {
  return (
    <div role="alert" className="error-fallback">
      <p>{t('error-occurred')}:</p>
      <pre>{error.message}</pre>
      <button className="error-fallback__button" onClick={resetErrorBoundary}>
        {t('reload-page')}
      </button>
    </div>
  );
};
