import { Card } from 'components/card';
import { ModuleContentHeader } from 'components/module-content-header';
import { CourseCoach } from 'types/api';
import './quiz-container.scss';

type QuizContainerProps = {
  moduleName: string;
  moduleOrder: string | number;
  coach?: CourseCoach;
};

export const QuizContainer: React.FC<QuizContainerProps> = ({
  children,
  moduleName,
  moduleOrder,
  coach,
}) => (
  <Card className="quiz-container">
    <ModuleContentHeader
      moduleName={moduleName}
      moduleOrder={moduleOrder}
      coach={coach}
    />
    {children}
  </Card>
);
