import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Player, { PlaybackRateEvent, TimeEvent } from '@vimeo/player';
import {
  DAYS_TO_EXPIRY,
  DEAFULT_PLAYBACK_RATE,
  DEFAULT_INITIAL_TIME,
  INDEX_OF_VIDEO_ID,
  UPDATE_INTERVAL,
} from 'constants/vimeo';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'helpers/local-storage';
import { useThrottle } from 'hooks/use-throttle';
import { useUpdateVideoWatchedTime } from 'hooks/useUpdateVideoWatchedTime';
import './video.scss';

type VideoType = {
  source?: string;
  initialTime?: number;
  lessonSlug?: string;
};

export const Video: React.FC<VideoType> = ({
  source = '',
  initialTime,
  lessonSlug,
}) => {
  const { t } = useTranslation();
  const playerRef = useRef<Player | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { updateVideoWatchedTime } = useUpdateVideoWatchedTime();

  const handleUpdateTime = useCallback(
    ({ seconds }: TimeEvent) => {
      if (lessonSlug) {
        updateVideoWatchedTime({
          lessonSlug,
          parameters: {
            video_watched_time: seconds,
          },
        });
      }
    },
    [lessonSlug, updateVideoWatchedTime]
  );

  const handlePlaybackChange = ({ playbackRate }: PlaybackRateEvent) => {
    setLocalStorageItem('playbackRate', String(playbackRate), DAYS_TO_EXPIRY);
  };

  const throttledChangeHandler = useThrottle(handleUpdateTime, UPDATE_INTERVAL);
  const videoId = source.split('/')[INDEX_OF_VIDEO_ID];

  useEffect(() => {
    if (iframeRef.current) {
      const vimeo = new Player(iframeRef.current);
      vimeo.setCurrentTime(initialTime ?? DEFAULT_INITIAL_TIME);
      vimeo.setPlaybackRate(
        Number(getLocalStorageItem('playbackRate')?.value) ||
          DEAFULT_PLAYBACK_RATE
      );
      playerRef.current = vimeo;
    }
  }, [initialTime]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.on('timeupdate', throttledChangeHandler);
      playerRef.current.on('playbackratechange', handlePlaybackChange);
    }
  }, [throttledChangeHandler]);

  return (
    <>
      {source.length > 0 ? (
        <iframe
          onMouseEnter={({ buttons }) => {
            if (buttons === 1) {
              iframeRef.current?.focus();
            }
          }}
          ref={iframeRef}
          title="vimeo"
          className="video"
          src={`https://player.vimeo.com/video/${videoId}`}
          frameBorder="0"
          allowFullScreen
          allow="autoplay; encrypted-media"
        />
      ) : (
        <div className="video video--unavailable">{t('video-unavailable')}</div>
      )}
    </>
  );
};
