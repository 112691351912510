import { useHistory, useLocation } from 'react-router-dom';

type QueryParamsObject = {
  key: string;
  value: string;
};

export const ALL_ITEMS_PARAM_VALUE = '0';

export const useQueryParameters = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);

  const get = (searchQueryParamName: string) => {
    return searchParams.get(searchQueryParamName);
  };

  const replace = (queryParams: QueryParamsObject[]) => {
    for (const { key, value } of queryParams) {
      if (!value) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }

    history.replace({ search: `${searchParams.toString()}` });
  };

  return { get, replace };
};
