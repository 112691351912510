import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { addReport as addReportRequest, getReports } from 'services/reports';

export const useReports = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: reports, ...restReportsQuery } = useQuery(
    QueryKeys.REPORTS,
    () => getReports()
  );

  const { mutate: addReport, ...restAddReportMutation } = useMutation(
    (report: Record<string, string | null>) => addReportRequest(report),
    {
      onError: () => {
        toast.error(t('reports-generate-error'));
      },
      onSuccess: () => {
        toast.info(t('report-added-to-queue'));
        queryClient.invalidateQueries(QueryKeys.REPORTS);
      },
    }
  );

  const isReportNameUnavailable = (report: Record<string, string>) =>
    reports &&
    reports.some(({ csv_filename }) => csv_filename === report.csv_filename);

  const submitReport = (formData: Record<string, string>) => {
    if (isReportNameUnavailable(formData)) {
      if (!restReportsQuery.isLoading) {
        toast.warning(t('unavailable-report-name'));
      }
      return;
    }

    const dataToSend: Record<string, string | null> = {
      ...formData,
    };

    Object.keys(dataToSend).forEach((key) => {
      if (dataToSend[key] === '-1' || !dataToSend[key]) {
        dataToSend[key] = null;
      }
    });

    addReport(dataToSend);
  };

  return {
    reports: reports || [],
    submitReport,
    restAddReportMutation,
    restReportsQuery,
  };
};
