import { percentage } from 'helpers/utils';
import './recorder-timeline.scss';

type RecorderTimelineProps = {
  remainingTime: number;
  timeInTotal: number;
};

export const RecorderTimeline: React.FC<RecorderTimelineProps> = ({
  remainingTime,
  timeInTotal,
}) => (
  <div className="recorder-timeline">
    <span
      style={{
        right: `${percentage(remainingTime, timeInTotal)}%`,
      }}
      className="recorder-timeline__indicator"
    />
  </div>
);
