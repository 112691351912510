import { useTranslation } from 'react-i18next';
import { CoursesGrid } from 'components/courses-grid';
import { CourseItem } from 'views/courses/components/item/course-item';

export const useCoursesNavItems = () => {
  const { t } = useTranslation();

  return [
    { url: '/courses', label: t('my-courses'), component: CoursesGrid },
    {
      url: '/courses/completed',
      label: t('finished-courses'),
      component: CoursesGrid,
    },
    {
      url: '/courses/:courseId/:contentType?/:contentId?',
      label: 'course-item',
      component: CourseItem,
      hidden: true,
    },
  ];
};
