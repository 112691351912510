import clsx from 'clsx';
import { Button } from 'components/button';
import './confirm-content.scss';

type ConfirmContentProps = {
  className?: string;
  title?: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmContent: React.FC<ConfirmContentProps> = ({
  className,
  title,
  description,
  onCancel,
  cancelButtonText,
  onConfirm,
  confirmButtonText,
}) => (
  <div className={clsx('confirm-content', className)}>
    <div className="confirm-content__text">
      {title && <h2 className="confirm-content__title">{title}</h2>}
      <p className="confirm-content__description">{description}</p>
    </div>

    <div className="confirm-content__buttons">
      <Button
        className="confirm-content__cancel-button"
        variant="secondary"
        onClick={onCancel}
      >
        {cancelButtonText}
      </Button>

      <Button onClick={onConfirm}>{confirmButtonText}</Button>
    </div>
  </div>
);
