import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCourseAside } from 'services/courses';
import { decipherId, encipherId, getActiveModule } from 'helpers/utils';
import { ModuleDetails } from 'types/api';
import { useRedirectContent } from './use-redirect-content';

export const CreateCourseAsizeKey = (courseId: string) => [
  QueryKeys.COURSE_ASIDE,
  courseId,
];

export const useCourseAside = () => {
  const {
    courseId: courseCipheredId,
    contentId,
    contentType,
  } = useParams<{
    courseId: string;
    contentId: string;
    contentType: string;
  }>();
  const courseId = decipherId(courseCipheredId);

  const { t } = useTranslation();

  const activeContentId = contentType === 'lesson' ? contentId : +contentId;
  const [activeModule, setActiveModule] = useState<ModuleDetails | null>(null);

  const queryClient = useQueryClient();
  const history = useHistory();

  const invalidateCourseAside = useCallback(
    async () => queryClient.invalidateQueries(CreateCourseAsizeKey(courseId)),
    [courseId, queryClient]
  );

  const moveToNext = useCallback(async () => {
    await invalidateCourseAside();
    queryClient.invalidateQueries([
      QueryKeys.DASHBOARD_COURSE_DETAILS,
      +courseId,
    ]);
    history.push(`/courses/${encipherId(courseId)}/lesson`);
  }, [courseId, history, invalidateCourseAside, queryClient]);

  const { data: courseAside } = useQuery(
    CreateCourseAsizeKey(courseId),
    () => getCourseAside(courseId),
    {
      onSuccess: () => {
        history.push(`/courses/${encipherId(courseId)}/lesson`);
      },
      onError: () => {
        toast.error(t('course-aside-error'));
      },
      enabled: !!courseId,
    }
  );

  useEffect(() => {
    if (courseAside) {
      setActiveModule(getActiveModule(activeContentId, courseAside.modules));
    }
  }, [activeContentId, contentId, courseAside]);

  useRedirectContent(courseAside?.modules || []);

  return {
    courseAside: courseAside || null,
    activeContentId,
    activeModule,
    setActiveModule,
    courseId,
    note: courseAside?.notepad || null,
    invalidateCourseAside,
    moveToNext,
  };
};
