import { useEffect } from 'react';
import { shouldDarkModeBeOn } from 'helpers/theme';
import { useTheme } from './use-theme';

export const useSetColorModeOnLoad = () => {
  const { isDarkModeOn, isDarkModeAutoOn } = useTheme();
  useEffect(() => {
    if (
      isDarkModeAutoOn &&
      (new Date().getHours() >= 20 || new Date().getHours() < 7)
    ) {
      shouldDarkModeBeOn(true);
    } else if (isDarkModeAutoOn) {
      shouldDarkModeBeOn(false);
    } else if (isDarkModeOn) {
      shouldDarkModeBeOn(true);
    }
    document.body.classList.add('white-mode');
  }, [isDarkModeAutoOn, isDarkModeOn]);
};
