import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu } from 'components/dropdown-menu';
import { DropdownMenuItem } from 'components/dropdown-menu-item';
import { KebabButton } from 'components/kebab-button';
import './edit-and-remove-menu.scss';

type TaskMenuProps = {
  onRemove?: () => void;
  onEdit: () => void;
  onChangeCoach?: () => void;
  dropdownClassName?: string;
  isModuleLesson?: boolean;
  buttonColor?: 'white' | 'blue';
  canEdit?: boolean;
  canChangeCoach?: boolean;
};

export const EditAndRemoveMenu: React.FC<TaskMenuProps> = ({
  onRemove,
  onEdit,
  onChangeCoach,
  dropdownClassName,
  isModuleLesson = false,
  buttonColor = 'blue',
  canEdit = true,
  canChangeCoach = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isDeleteButtonDisplayed = !isModuleLesson && !!onRemove;

  return (
    <div className="edit-and-remove-menu">
      <KebabButton
        className={`edit-and-remove-menu__button edit-and-remove-menu__button--${buttonColor}`}
        onClick={handleClick}
      />
      {isOpen && (
        <DropdownMenu className={dropdownClassName} onClose={handleClose}>
          {canEdit && (
            <DropdownMenuItem onClick={onEdit}>{t('edit')}</DropdownMenuItem>
          )}
          {canChangeCoach && (
            <DropdownMenuItem onClick={onChangeCoach}>
              {t('change-coach')}
            </DropdownMenuItem>
          )}
          {isDeleteButtonDisplayed && (
            <DropdownMenuItem onClick={onRemove}>
              {t('delete')}
            </DropdownMenuItem>
          )}
        </DropdownMenu>
      )}
    </div>
  );
};
