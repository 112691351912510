import clsx from 'clsx';
import { DOTS, FIRST_PAGE_INDEX } from 'hooks/use-pagination';
import { ReactComponent as PaginationArrow } from 'assets/icons/pagination-arrow.svg';
import { ReactComponent as ThreeDots } from 'assets/icons/three-dots.svg';
import './pagination-ui.scss';

type PaginationUiProps = {
  currentPage: number;
  totalPageCount: number;
  className?: string;
  goToPage: (pageToGo: number) => void;
  paginationRange: (string | number)[];
};

export const PaginationUi: React.FC<PaginationUiProps> = ({
  currentPage,
  totalPageCount,
  className,
  goToPage,
  paginationRange,
}) => {
  return (
    <nav className={clsx('pagination-ui', className)}>
      <ul className="pagination-ui__list">
        <li>
          <button
            className="pagination-ui__button pagination-ui__button--left"
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === FIRST_PAGE_INDEX}
          >
            <PaginationArrow className="pagination-ui__left-arrow" />
          </button>
        </li>

        {paginationRange.map((pageNumber, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li data-testid={pageNumber} key={`${pageNumber}-${index}`}>
            {pageNumber === DOTS ? (
              <span className="pagination-ui__button pagination-ui__button--dots">
                <ThreeDots />
              </span>
            ) : (
              <button
                className={clsx(
                  'pagination-ui__button',
                  pageNumber === currentPage && 'pagination-ui__button--active'
                )}
                onClick={() => {
                  goToPage(+pageNumber);
                }}
              >
                {pageNumber}
              </button>
            )}
          </li>
        ))}

        <li>
          <button
            className="pagination-ui__button pagination-ui__button--right"
            onClick={() => {
              goToPage(currentPage + 1);
            }}
            disabled={currentPage === totalPageCount}
          >
            <PaginationArrow />
          </button>
        </li>
      </ul>
    </nav>
  );
};
