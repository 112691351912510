/* eslint-disable jsx-a11y/media-has-caption */
import Tippy from '@tippyjs/react';
import { AudioPlayer } from 'components/audio-player';
import { getFileExtension } from 'helpers/utils';
import './attachment-item.scss';

type AttachmentItemProps = {
  url: string;
  name: string;
  fileExtension?: string;
};

const AUDIO_FILES = 'mp4 avi mpeg mov mp3 wav m4a'.split(' ');

export const AttachmentItem: React.FC<AttachmentItemProps> = ({
  url,
  name,
  fileExtension,
}) => {
  const extension = fileExtension || getFileExtension(name);

  return (
    <Tippy
      className="attachment-item"
      content={<span className="attachment-item__tippy-content">{name}</span>}
      theme="light-border"
    >
      <>
        <a
          className="attachment-item__link"
          href={url}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          {extension && (
            <span
              data-testid="extension"
              className="attachment-item__extension"
            >
              {extension}
            </span>
          )}
          <span data-testid="file-name" className="attachment-item__name">
            {name}
          </span>
        </a>
        {AUDIO_FILES.includes(extension) && (
          <AudioPlayer src={url} className="attachment-item__audio-player" />
        )}
      </>
    </Tippy>
  );
};
