import { DayContent, DayContentProps } from 'react-day-picker';
import { convertDateToApiCompatible } from 'helpers/coaching-session-calendar';
import './day-cell.scss';

type DayCellProps = DayContentProps & {
  meetingsCount: number;
};

export const DayCell: React.FC<DayCellProps> = ({
  meetingsCount,
  date,
  ...otherDayContentProps
}) => {
  const dateTime = convertDateToApiCompatible(date);
  const shouldDisplayMeetingsCount =
    date.getMonth() === otherDayContentProps.displayMonth.getMonth();

  return (
    <div className="day-cell">
      <time dateTime={dateTime}>
        <DayContent {...otherDayContentProps} date={date} />
      </time>

      {meetingsCount > 0 && shouldDisplayMeetingsCount && (
        <span className="day-cell__meetings">{meetingsCount}</span>
      )}
    </div>
  );
};
