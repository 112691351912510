import clsx from 'clsx';
import './buttons-wrapper.scss';

type ButtonsWrapperProps = {
  className?: string;
};

export const ButtonsWrapper: React.FC<ButtonsWrapperProps> = ({
  children,
  className,
}) => {
  return <div className={clsx('buttons-wrapper', className)}>{children}</div>;
};
