import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { sendAnswer as sendAnswerRequest } from 'services/lesson';
import { AnswerData } from 'types/api';

export interface AnswerPostData {
  id: number;
  data: AnswerData;
}

export const useAnswer = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: sendAnswer, ...rest } = useMutation(
    ({ id, data }: AnswerPostData) => sendAnswerRequest(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.TASKS);
      },
      onError: () => {
        toast.error(t('evaluate-error'));
      },
    }
  );
  return {
    sendAnswer,
    ...rest,
  };
};
