import { useContext, useMemo } from 'react';
import { AuthContext } from 'contexts';
import { ProgressItemStatus } from 'components/progress-item-button';
import { CompletedAttemptsCount, ModuleDetails } from 'types/api';

export const useQuizStatus = (module: ModuleDetails) => {
  const { isCoach, isHR } = useContext(AuthContext);

  const quizHasNoAttempts =
    module.quiz?.completed_attempts_count ===
    CompletedAttemptsCount.NO_ATTEMPTS;

  const someLessonsAreUnfinished = module.lessons.some(
    ({ completed }) => !completed
  );

  const preQuizStatus = quizHasNoAttempts
    ? ProgressItemStatus.IN_PROGRESS
    : ProgressItemStatus.COMPLETED;

  const postQuizStatus = useMemo(() => {
    const shouldDisable = someLessonsAreUnfinished || quizHasNoAttempts;

    const shouldSetInProgress =
      isCoach ||
      isHR ||
      (module.quiz &&
        module.quiz.completed_attempts_count <
          CompletedAttemptsCount.TWO_ATTEMPTS &&
        !shouldDisable);

    if (shouldSetInProgress) {
      return ProgressItemStatus.IN_PROGRESS;
    }
    if (shouldDisable) {
      return ProgressItemStatus.DISABLED;
    }
    return ProgressItemStatus.COMPLETED;
  }, [isCoach, isHR, module.quiz, quizHasNoAttempts, someLessonsAreUnfinished]);

  return { preQuizStatus, postQuizStatus };
};
