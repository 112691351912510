import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { QueryKeys } from 'constants/query-keys';
import { differenceInDays } from 'date-fns';
import { getParticipantModulesProgress } from 'services/participants';
import { Collapse } from 'components/collapse';
import { ModuleCollapse } from 'components/participants-table/participant-table-row/course-collapse/module-collapse';
import { isExpired, isExpiringSoon } from 'helpers/days';
import { format, today } from 'helpers/time';
import { roundPercentageNumber } from 'helpers/utils';
import { ParticipantProgressCourse } from 'types/api';
import { ReactComponent as ExpireIcon } from 'assets/icons/course-expire.svg';
import './course-collapse.scss';

type CourseCollapseProps = {
  course: ParticipantProgressCourse;
  participantId: number;
};

export const CourseCollapse: React.FC<CourseCollapseProps> = ({
  course: { name, percentage_progress: progress, end_date: endDate, id },
  participantId,
}) => {
  const { t } = useTranslation();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const { isLoading, data } = useQuery(
    [QueryKeys.COURSE_PROGRESS_DETAILS, participantId, id],
    () => getParticipantModulesProgress(participantId, id),
    {
      onError: () => toast.error(t('participant-course-progress-error')),
      enabled: isCollapseOpen,
    }
  );

  const daysToExpire = differenceInDays(new Date(endDate), today());
  const isCourseExpiring = isExpiringSoon(endDate);
  const isCourseExpired = isExpired(endDate);
  const shouldDisplayExpireInfo = isCourseExpired || isCourseExpiring;

  return (
    <div className="course-collapse">
      <Collapse
        open={isCollapseOpen}
        isAutoScroll={false}
        onClick={() => setIsCollapseOpen((prevState) => !prevState)}
        isLoading={isLoading}
        hiddenContent={
          <>
            {(data ?? []).map((module) => (
              <ModuleCollapse
                module={module}
                key={module.id}
                isAutoScroll={false}
              />
            ))}
          </>
        }
      >
        <div className="course-collapse__header">
          <div className="course-collapse__name">
            {name}
            {shouldDisplayExpireInfo && (
              <Tippy
                content={
                  isCourseExpired
                    ? t('course-expired', { date: format(endDate, 'onlyDate') })
                    : t('course-expire', { count: daysToExpire })
                }
              >
                <ExpireIcon
                  className={clsx(
                    'course-collapse__icon',
                    isCourseExpired && 'course-collapse__icon--expired'
                  )}
                />
              </Tippy>
            )}
          </div>
          <div
            className={clsx(
              'course-collapse__progress',
              isCourseExpiring && 'course-collapse__progress--expire',
              isCourseExpired && 'course-collapse__progress--expired'
            )}
          >
            {roundPercentageNumber(progress)}%
          </div>
        </div>
      </Collapse>
    </div>
  );
};
