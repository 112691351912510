import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Collapse } from 'components/collapse';
import { CalendarCoach } from 'types/api';
import './coach-collapse.scss';

type CoachCollapseProps = {
  userId: number;
  coaches: CalendarCoach[];
  setCoach: React.Dispatch<React.SetStateAction<number>>;
  isAdmin?: boolean;
};

export const CoachCollapse: React.FC<CoachCollapseProps> = ({
  userId,
  coaches,
  setCoach,
  isAdmin = false,
}) => {
  const { t } = useTranslation();
  const [chosenCoachId, setChosenCoachId] = useState<number | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleOnClick = (id: number) => {
    setChosenCoachId(id);
    setCoach(id);
  };
  const chosenCoach = coaches.find((coach) => coach.id === chosenCoachId);
  const collapseWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isAdmin) {
      setChosenCoachId(coaches[0]?.id ?? null);
    }
  }, [coaches, isAdmin]);

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (
        collapseWrapperRef.current &&
        !collapseWrapperRef.current.contains(target as Node) &&
        collapseWrapperRef.current !== target
      ) {
        setIsCollapsed(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [collapseWrapperRef]);

  return (
    <div className="coach-collapse">
      <div className="coach-collapse__wrapper" ref={collapseWrapperRef}>
        <Collapse
          className="coach-collapse__collapse"
          hiddenContent={
            <>
              {chosenCoachId !== null && !isAdmin && (
                <button
                  className="coach-collapse__coach-tile"
                  onClick={() => {
                    setChosenCoachId(null);
                    setCoach(userId);
                  }}
                >
                  {t('return-to-calendar')}
                </button>
              )}

              {coaches.map((coach) => (
                <button
                  className={clsx(
                    'coach-collapse__coach-tile',
                    chosenCoachId !== null &&
                      chosenCoachId === coach.id &&
                      'coach-collapse__coach-tile--chosen'
                  )}
                  onClick={() => handleOnClick(coach.id)}
                  key={coach.id}
                >
                  {coach.first_name} {coach.last_name}
                </button>
              ))}
            </>
          }
          closeOnSelection
          open={isCollapsed}
          onClick={() => setIsCollapsed(true)}
        >
          <div
            className={clsx(
              'coach-collapse__chosen-coach',
              chosenCoachId !== null && 'coach-collapse__chosen-coach--chosen'
            )}
          >
            {(chosenCoach &&
              `${chosenCoach.first_name} ${chosenCoach.last_name}`) ||
              t('choose-coach')}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
