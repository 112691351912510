import { ElementType } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import {
  PolymorphicButton,
  PolymorphicButtonProps,
} from 'components/polymorphic-button';
import { Progressbar } from 'components/progressbar';
import { MOBILE_MAX_WIDTH_QUERY } from 'hooks/use-media';
import { useScrollToElement } from 'hooks/use-scroll-to-element';
import { AnswerStatus, DateFormats } from 'types/enums';
import { ReactComponent as ChosenLessonArrow } from 'assets/icons/chosen-lesson-arrow.svg';
import { ReactComponent as QuizCover } from 'assets/icons/quiz-cover.svg';
import { LessonTaskIcon } from './task-icon';
import './lesson-tile.scss';

export enum ProgressItemStatus {
  DISABLED = 'disabled',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

type LessonTileProps<C extends ElementType> = Omit<
  PolymorphicButtonProps<C>,
  'children'
> & {
  isActive: boolean;
  status: ProgressItemStatus;
  isQuiz?: boolean;
  coverImage?: string | null;
  coverDescription?: string;
  videoLength?: number | null;
  videoWatchedTime?: number | null;
  taskStatus?: AnswerStatus | null;
};

export const LessonTile = <C extends ElementType>({
  name,
  isActive,
  scrollBlock,
  status,
  isQuiz = false,
  coverImage,
  coverDescription,
  disabled,
  className,
  videoLength,
  videoWatchedTime,
  taskStatus = null,
  ...props
}: LessonTileProps<C>) => {
  const buttonRef = useScrollToElement<HTMLButtonElement | HTMLLinkElement>({
    noScrollQuery: MOBILE_MAX_WIDTH_QUERY,
    isScrollDestination: isActive,
    scrollBlock,
  });

  const videoProgress =
    videoWatchedTime && videoLength
      ? (videoWatchedTime / videoLength) * 100
      : 0;

  const getVideoLength = (seconds: number) => {
    return format(seconds * 1000, DateFormats.DURATION);
  };

  const shouldRenderRightSide = !!videoLength || taskStatus;

  return (
    <PolymorphicButton
      ref={buttonRef}
      className={clsx(
        'lesson-tile',
        isActive && 'lesson-tile--active',
        className
      )}
      disabled={status === 'disabled' || disabled}
      {...props}
    >
      {isActive && (
        <div className="lesson-tile__arrow">
          <ChosenLessonArrow />
        </div>
      )}
      {isQuiz && <QuizCover className="lesson-tile__cover" />}
      <div className="lesson-tile__content">
        <p title={name} className="lesson-tile__text">
          {name}
        </p>

        <Progressbar
          progress={videoProgress}
          className={clsx(
            'lesson-tile__progressbar',
            !videoProgress && 'lesson-tile__progressbar--hidden'
          )}
        />
      </div>

      {shouldRenderRightSide && (
        <div className="lesson-tile__right-side">
          {videoLength && (
            <div className="lesson-tile__video-length">
              {getVideoLength(videoLength)}
            </div>
          )}
          {taskStatus && (
            <LessonTaskIcon
              isLessonDone={status === 'completed'}
              taskStatus={taskStatus}
            />
          )}
        </div>
      )}
    </PolymorphicButton>
  );
};
