import axios from 'helpers/axios';
import {
  CompanySubscription,
  ReportSelectsData,
  SubscriptionInfo,
} from 'types/api';

export async function getSubscriptions() {
  const { data } = await axios.get<SubscriptionInfo[]>('v1/subscriptions');
  return data;
}
export interface CompanySubscriptionParams {
  params: { subscription_id: number };
}
export async function getCompanySubscription(
  paramsObject?: CompanySubscriptionParams
) {
  const { data } = await axios.get<CompanySubscription[]>(
    'v1/company_subscriptions',
    {
      ...paramsObject,
    }
  );
  return data;
}

export async function getCompanyDetails() {
  const { data } = await axios.get<ReportSelectsData[]>(
    'v1/company_subscription_courses/company_details'
  );
  return data;
}
