import clsx from 'clsx';
import { ChatButton } from 'components/chat/chat-button';
import { LazyAvatar } from 'components/lazy-avatar';
import { CoachingSessionsButton } from 'components/person/coaching-sessions-button';
import { CoachProps } from 'components/person/roles';
import { PolymorphicButton } from 'components/polymorphic-button';
import { getUserInitials } from 'helpers/utils';
import { Company } from 'types/api';
import { ProfileType } from 'types/user';
import './large-layout.scss';

type LargeLayoutProps = Omit<ProfileType, 'description' | 'courses'> &
  Omit<CoachProps, 'showCalendar' | 'profile' | 'layout' | 'showName'> & {
    firstName?: string;
    lastName?: string;
    showDetails?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    hasCalendar?: boolean;
    company?: Company;
  };

export const LargeLayout: React.FC<LargeLayoutProps> = ({
  id,
  avatar,
  firstName,
  lastName,
  role,
  position,
  team,
  showDetails,
  chat,
  size,
  details,
  hasCalendar = false,
  company,
}) => {
  return (
    <div className="large-layout" title={`${firstName} ${lastName}`}>
      <PolymorphicButton
        as={details ? 'button' : 'div'}
        className={clsx(
          'large-layout__profile-details',
          details && 'large-layout__profile-details--button'
        )}
        {...(details && { onClick: showDetails })}
      >
        <div
          className="large-layout__avatar"
          style={{ width: `${size}px`, height: `${size}px` }}
        >
          <LazyAvatar
            userInitials={getUserInitials({
              first_name: firstName,
              last_name: lastName,
            })}
            imageClassName="large-layout__image"
            src={avatar}
            alt={`${firstName} ${lastName}`}
          />
        </div>

        <div className="large-layout__details">
          <div className="large-layout__name">{`${firstName} ${lastName}`}</div>

          <div className="large-layout__function">
            {company ? (
              <span>{company.name}</span>
            ) : (
              <>
                {(role || position) && <span>{role || position}</span>}
                {team && <span className="large-layout__team"> | {team}</span>}
              </>
            )}
          </div>
        </div>
      </PolymorphicButton>

      <div className="large-layout__tools">
        {hasCalendar && <CoachingSessionsButton />}
        {chat && role !== null && (
          <ChatButton
            buttonClassName="large-layout__button"
            userIdToChatWith={id}
            position={role}
          />
        )}
      </div>
    </div>
  );
};
