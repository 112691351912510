import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { dropdown } from 'constants/selectors/profile/dropdown';
import { AuthContext } from 'contexts';
import { LazyAvatar } from 'components/lazy-avatar';
import { getUserInitials } from 'helpers/utils';
import { useProfileRoutes } from 'hooks/use-profile-routes';
import { ProfileSidebar } from 'views/profile/components/profile-sidebar';
import './dropdown.scss';

export const Dropdown: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { routesPerRole } = useProfileRoutes();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={clsx('user-profile', dropdown.class)}>
      <div
        tabIndex={0}
        onClick={(e) => {
          if (
            !history.location.pathname.includes('/profile') &&
            e.nativeEvent.offsetY >= 0 // checks if pseudo element wasn't clicked
          ) {
            history.push('/profile');
          }
        }}
        onKeyDown={(e) => {
          e.preventDefault();
          if (
            e.key === 'Enter' &&
            !history.location.pathname.includes('/profile')
          ) {
            history.push('/profile');
          }
        }}
        role="button"
        className={clsx('user-profile__avatar', dropdown.avatarClass)}
      >
        <LazyAvatar
          src={user?.avatar.preview || null}
          alt={t('profile-photo')}
          userInitials={getUserInitials(user)}
        />
        <div className="user-profile__dropdown">
          <ProfileSidebar routes={routesPerRole} keyPrefix="dropdown" />
        </div>
      </div>
    </div>
  );
};
