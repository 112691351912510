import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from 'components/button';
import { useCoachSessions } from 'hooks/use-coach-sessions';
import { BookedSessionStatus } from 'types/api';
import './session-buttons.scss';

type SessionButtonsProps = {
  status: BookedSessionStatus;
  sessionId: number;
  canConfirm?: boolean;
};

export const SessionButtons: React.FC<SessionButtonsProps> = ({
  status,
  sessionId,
  canConfirm = true,
}) => {
  const { t } = useTranslation();
  const { isConfirming, isCanceling, rejectCoachSession, confirmCoachSession } =
    useCoachSessions();

  const canApplicantConfirm =
    status === BookedSessionStatus.PENDING ||
    status === BookedSessionStatus.APPLICANT_CONFIRMED;

  const canRecipientConfirm =
    status === BookedSessionStatus.PENDING ||
    status === BookedSessionStatus.RECIPIENT_CONFIRMED;

  const isCancelText =
    status === BookedSessionStatus.CONFIRMED ||
    status === BookedSessionStatus.RECIPIENT_CONFIRMED;

  const shouldDisplayConfirmButton =
    (canApplicantConfirm || canRecipientConfirm) && canConfirm;

  const shouldDisplayRejectButton = status !== BookedSessionStatus.REJECTED;

  const handleConfirm = async () => {
    await confirmCoachSession(sessionId);
  };

  const handleCancel = async () => {
    await rejectCoachSession(sessionId);
  };

  return shouldDisplayConfirmButton || shouldDisplayRejectButton ? (
    <div className="session-buttons">
      {shouldDisplayConfirmButton && (
        <Button
          loading={isConfirming}
          onClick={handleConfirm}
          className="session-buttons__button"
        >
          {t('confirm-session')}
        </Button>
      )}
      {shouldDisplayRejectButton && (
        <Button
          loading={isCanceling}
          onClick={handleCancel}
          className={clsx(
            'session-buttons__button',
            !isCancelText && 'session-buttons__button--reject'
          )}
        >
          {isCancelText ? t('cancel-session') : t('reject')}
        </Button>
      )}
    </div>
  ) : null;
};
