import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { formatDurationShort } from 'helpers/time';
import './course-stats.scss';

type CourseStatsProps = {
  lessonTime: number;
  tasksCount: number;
  quizzesCount: number;
  className?: string;
};

export const CourseStats: React.FC<CourseStatsProps> = ({
  lessonTime,
  tasksCount,
  quizzesCount,
  className,
}) => {
  return (
    <ul className={clsx('course-stats', className)}>
      <li className="course-stats__list-item">
        {formatDurationShort(lessonTime, ['hours', 'minutes']) || '0 min'}
        <span> video</span>
      </li>
      <li className="course-stats__list-item">|</li>
      <li className="course-stats__list-item">
        <Trans i18nKey="tasks" count={tasksCount}>
          <span>tasks</span>
        </Trans>
      </li>
      <li className="course-stats__list-item">|</li>
      <li className="course-stats__list-item">
        <Trans i18nKey="quizzes" count={quizzesCount}>
          <span>quizzes</span>
        </Trans>
      </li>
    </ul>
  );
};
