import { useTranslation } from 'react-i18next';
import { Card } from 'components/card';
import './course-error-handle.scss';

type CourseErrorHandlerProps = {
  lackOf?: string;
  courseUnavailable?: boolean;
};
export const CourseErrorHandle: React.FC<CourseErrorHandlerProps> = ({
  lackOf,
  courseUnavailable = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="course-error-handle">
      <Card className="course-error-handle__card">
        {lackOf && <div>{t('module-no-resource', { resource: lackOf })}</div>}
        {courseUnavailable && <div>{t('course-not-found')}</div>}
        {!lackOf && !courseUnavailable && <div>{t('module-not-found')}</div>}
      </Card>
    </div>
  );
};
