import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { MINUTE_MS, MINUTES_IN_HOUR } from 'constants/time';
import { ActionCableProvider, AuthProvider, ChatProvider } from 'contexts';
import { Loader } from 'components/loader';
import RouterApp from 'core/router';
import { useSetColorModeOnLoad } from 'hooks/use-set-color-mode-on-load';
import 'assets/scss/index.scss';

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: MINUTE_MS * MINUTES_IN_HOUR * 2,
      cacheTime: MINUTE_MS * MINUTES_IN_HOUR * 3,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchIntervalInBackground: false,
      suspense: false,
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

const App: React.FC = () => {
  useSetColorModeOnLoad();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ActionCableProvider>
          <AuthProvider>
            <ChatProvider>
              <Suspense fallback={<Loader fullHeight />}>
                <RouterApp />
              </Suspense>
            </ChatProvider>
          </AuthProvider>
        </ActionCableProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default Sentry.withProfiler(App);
