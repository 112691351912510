import axios from 'helpers/axios';
import {
  CompanyDetails,
  CourseDashboard,
  ParticipantDashboard,
} from 'types/api';

export async function getDashboard(
  courseId?: number
): Promise<CourseDashboard> {
  const { data } = await axios.get(`v1/dashboard/${courseId ?? ''}`);
  return data;
}

export async function getCompaniesDetails(): Promise<CompanyDetails[]> {
  const { data } = await axios.get(`v1/dashboard`);
  return data;
}

export async function getCourseDetails(
  courseId?: number
): Promise<ParticipantDashboard> {
  const { data } = await axios.get(`v1/dashboard/progress`, {
    params: { company_subscription_course_id: courseId },
  });
  return data;
}
