import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { SessionTypeIds } from 'constants/sessions';
import { AuthContext } from 'contexts';
import { getAvailabilityForModule } from 'services/calendar';
import { Roles } from 'types/enums';

export const useCoachesAvailabilities = (
  {
    moduleId,
    calendarStartAndEndDates,
  }: {
    moduleId: string;
    calendarStartAndEndDates: string[];
  },
  shouldFetch: boolean = true
) => {
  const { userRole } = useContext(AuthContext);
  const { t } = useTranslation();

  const meetingTypeId =
    userRole === Roles.HR
      ? SessionTypeIds.GROUP_SESSION_ID
      : SessionTypeIds.INDIVIDUAL_SESSION_ID;

  const { data, ...rest } = useQuery(
    [QueryKeys.COACH_AVAILABILITY, moduleId, calendarStartAndEndDates],
    () =>
      getAvailabilityForModule(
        moduleId,
        meetingTypeId,
        ...calendarStartAndEndDates
      ),
    {
      enabled: !(+moduleId < 0) && shouldFetch,
      onError: () => toast.error(t('availability-error')),
    }
  );

  return { coachesWithAvailability: data || [], ...rest };
};
