import { StyledScrolls } from 'components/styled-scrolls';
import { ModuleDetails } from 'types/api';
import { ProgressbarItem } from './progressbar-item';
import './course-progressbar.scss';

type ProgressbarProps = {
  modules: ModuleDetails[];
  goToLesson: (id: number) => void;
};

export const CourseProgressbar: React.FC<ProgressbarProps> = ({
  modules,
  goToLesson,
}) => {
  return (
    <StyledScrolls className="course-progressbar">
      <ul className="course-progressbar__container">
        {modules.map((module, index) => {
          return (
            <ProgressbarItem
              order={index + 1}
              key={module.id}
              module={module}
              goToLesson={goToLesson}
            />
          );
        })}
      </ul>
    </StyledScrolls>
  );
};
