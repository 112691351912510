import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { InvitationsSummary } from 'types/api';
import { ListSection } from './list-section';
import './summary.scss';

type SummaryProps = {
  data: InvitationsSummary;
  onClose: () => void;
};

export const Summary: React.FC<SummaryProps> = ({ data, onClose }) => {
  const { t } = useTranslation();

  return (
    <section className="summary">
      <div>
        <h2 className="summary__heading">
          {data.course_name?.substring(0, data.course_name?.indexOf('('))}
          <br />
          {t('changes-made')}
        </h2>

        <main className="summary__main">
          <ListSection
            title={t('invitations-sent-to')}
            list={data.sent_ivitation}
          />

          <ListSection
            title={t('users-added-to-course')}
            list={data.added_to_course}
          />

          <ListSection
            title={t('users-already-in-course')}
            list={data.already_in_course}
          />
        </main>
      </div>

      <Button className="summary__button" onClick={onClose}>
        {t('close')}
      </Button>
    </section>
  );
};
