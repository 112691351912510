import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCoachingSessions } from 'services/calendar';
import { SessionBooking } from 'types/api';
import { useCreateSession } from './use-create-session';

export interface CreateSessionWithCoachData {
  moduleId: string;
  data: SessionBooking;
}

export const useCoachingSessions = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { data: sessions, ...sessionsQuery } = useQuery(
    QueryKeys.COACHING_SESSIONS,
    () => getCoachingSessions(),
    {
      onError: () => {
        toast.error(t('availability-error'));
      },
    }
  );

  const { createSession, createSessionQuery } = useCreateSession(
    () => queryClient.invalidateQueries(QueryKeys.COACHING_SESSIONS),
    () => toast.error(t('session-booking-error'))
  );

  return {
    sessions: sessions || [],
    createSession,
    sessionsQuery,
    createSessionQuery,
  };
};
