import { ThemeComponent } from 'components/theme';
import './settings.scss';

const Settings: React.FC = () => {
  return (
    <div className="settings">
      <ThemeComponent />
    </div>
  );
};

export default Settings;
