import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Company } from 'components/company';
import { CoursesGrid } from 'components/courses-grid';
import { ParticipantsTable } from 'components/participants-table';
import { RenderedComponentProps, TabRouter } from 'components/tab-router';
import { useCompanyData } from 'hooks/use-company';
import { ReactComponent as IconArrow } from 'assets/icons/arrow-left-s-line.svg';
import { CompanyData } from './company-data';

export const CompanyView: React.FC<RenderedComponentProps> = ({
  setHeaderRenderer,
}) => {
  const { path } = useParams<{ path: string }>();
  const [companyId] = path.split('/');
  const { company, isLoading } = useCompanyData(companyId);
  const { t } = useTranslation();

  const HeaderRenderer = useCallback(() => {
    return (
      <header className="company-header">
        <div className="company-header__back">
          <Link className="company-header__link" to="/companies">
            <IconArrow className="company-header__icon" />
          </Link>
          {company && (
            <Company
              avatarSize="large"
              company={{
                id: company.id,
                name: company.name,
                logo: company.logo,
              }}
            />
          )}
        </div>
      </header>
    );
  }, [company]);

  useEffect(() => {
    setHeaderRenderer(() => <HeaderRenderer />);
    return () => {
      setHeaderRenderer(null);
    };
  }, [HeaderRenderer, setHeaderRenderer]);

  return (
    <TabRouter
      items={[
        {
          url: `/companies/${companyId}`,
          locationUrl: '/companies/:companyId',
          label: t('data'),
          component: () =>
            company && <CompanyData company={company} isLoading={isLoading} />,
        },
        {
          url: `/companies/${companyId}/participants`,
          locationUrl: '/companies/:companyId/participants',
          label: t('participants'),
          component: ParticipantsTable,
          exact: false,
        },
        {
          url: `/companies/${companyId}/courses`,
          locationUrl: '/companies/:companyId/courses',
          label: t('courses'),
          component: CoursesGrid,
        },
      ]}
    />
  );
};
