import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DropdownButton } from 'components/dropdown-button';
import { DropdownMenuItem } from 'components/dropdown-menu-item';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { TasksQueryParams, TasksSortOptions } from 'types/enums';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import './tasks-sort.scss';

type TasksSortProps = {
  onSortByChange: (sortBy: string, value: string) => void;
  sortDirection: string | null;
  sortBy: string | null;
};

export const TasksSort: React.FC<TasksSortProps> = ({
  onSortByChange,
  sortDirection,
  sortBy,
}) => {
  const { t } = useTranslation();

  const menuConfig = [
    {
      sort: '',
      direction: '',
      text: t('sort-by-newest'),
    },
    {
      sort: TasksSortOptions.UPDATED_AT,
      direction: 'asc',
      text: t('sort-by-oldest'),
    },
    {
      sort: TasksSortOptions.ANSWERS_COUNT,
      direction: 'desc',
      text: t('sort-by-the-highest-number-of-answers'),
    },
    {
      sort: TasksSortOptions.ANSWERS_COUNT,
      direction: 'asc',
      text: t('sort-by-the-lowest-number-of-answers'),
    },
  ];

  const { get } = useQueryParameters();

  const isParticipantNotChosen = !get(TasksQueryParams.PARTICIPANT);

  const menuItems = menuConfig
    .filter((menuItemConfig) => {
      return (
        menuItemConfig.sort !== TasksSortOptions.ANSWERS_COUNT ||
        isParticipantNotChosen
      );
    })
    .map(({ text, sort, direction }) => {
      const isActive = sort === sortBy && direction === sortDirection;

      return (
        <DropdownMenuItem
          wrapperClassName={clsx(
            'tasks-sort__item',
            isActive && 'tasks-sort__item--active'
          )}
          className="tasks-sort__menu-button"
          onClick={() => onSortByChange(sort, direction)}
          key={sort + direction}
        >
          {text}
        </DropdownMenuItem>
      );
    });

  return (
    <div className="tasks-sort">
      <DropdownButton
        listClassName="tasks-sort__list"
        menuClassName="tasks-sort__menu"
        buttonContent={
          <div data-text={t('sort')} className="tasks-sort__text">
            <SortIcon />
            {t('sort')}
          </div>
        }
      >
        {menuItems}
      </DropdownButton>
    </div>
  );
};
