/* eslint-disable jsx-a11y/media-has-caption */
import clsx from 'clsx';
import './audio-player.scss';

type AudioPlayerProps = {
  className?: string;
  src: string;
};

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ className, src }) => {
  return (
    <audio
      id="audio-player"
      controls
      src={src}
      className={clsx('audio-player', className)}
    />
  );
};
