import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Slide, toast, ToastContainer } from 'react-toastify';
import { AuthContext } from 'contexts';
import { Loader } from 'components/loader';
import { RequestMonitor } from 'components/request-monitor';
import { checkTokenExpiry, getAuthToken } from 'helpers/auth';
import { setAuthorization } from 'helpers/axios';
import { useConnectionStatus } from 'hooks/use-connection-status';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { useRoutes } from 'hooks/use-routes';
import PrivateRoutes from './private-routes';
import PublicRoutes from './public-routes';
import 'react-toastify/dist/ReactToastify.css';

const RouterApp: React.FC = () => {
  const { loading, userRole, logOut, setAuthToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const { privateRoutes, publicRoutes } = useRoutes();
  const { get } = useQueryParameters();
  const accessToken = get('access-token');
  const authToken = getAuthToken();

  useConnectionStatus();

  useEffect(() => {
    if (authToken && checkTokenExpiry(authToken.expiry)) {
      toast.error(t('session-expired'));
    } else {
      setAuthorization(authToken ? authToken.value : '');
    }
  }, [accessToken, authToken, logOut, setAuthToken, t]);

  useEffect(() => {
    if (accessToken && authToken) {
      logOut();
    }
  }, [accessToken, authToken, logOut]);

  if (loading) {
    return <Loader fullHeight />;
  }

  return (
    <>
      <ToastContainer
        transition={Slide}
        position="bottom-right"
        closeOnClick
        autoClose={5000}
        hideProgressBar
      />
      <RequestMonitor />
      {userRole ? (
        <PrivateRoutes privateRoutes={privateRoutes} userRole={userRole} />
      ) : (
        <PublicRoutes publicRoutes={publicRoutes} />
      )}
    </>
  );
};

export default RouterApp;
