import clsx from 'clsx';
import { Person } from 'components/person';
import { Profile } from 'types/api';
import './answer-note.scss';

type AnswerNoteProps = {
  owner: Profile | null;
  answerNote: string;
};

export const AnswerNote: React.FC<AnswerNoteProps> = ({
  owner,
  answerNote,
}) => {
  return (
    <div className="answer-note">
      {owner && (
        <Person
          key={owner.id}
          type="participant"
          profile={owner}
          layout="small"
          size={40}
        />
      )}

      <div
        className={clsx(
          'answer-note__note',
          owner && 'answer-note__note--with-owner'
        )}
      >
        {answerNote}
      </div>
    </div>
  );
};
