import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from 'contexts';
import { acceptInvitation as acceptInvitationRequest } from 'services/user';
import { Button } from 'components/button';
import { Input } from 'components/form';
import { Logo } from 'components/logo';
import { pushGTMEvent } from 'helpers/google-tag-manager';
import {
  FIRST_NAME as FIRST_NAME_REGEX,
  LAST_NAME as LAST_NAME_REGEX,
  PASSWORD as PASSWORD_REGEX,
  POSTION_AND_TEAM,
} from 'helpers/validate';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { QueryParamNameTypes } from 'types/enums';
import './authentication.scss';

export interface ConfirmEmailProps {
  access_token?: string;
  message: string;
  success: boolean;
}

export enum EndRegisterInputNames {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  POSITION = 'position',
  PASSWORD = 'password',
  REPEAT_PASSWORD = 'password_confirmation',
}

export const EndRegister: React.FC = () => {
  const { logOut } = useContext(AuthContext);
  const history = useHistory();
  const queryParameters = useQueryParameters();
  const token = queryParameters.get(QueryParamNameTypes.INVITATION_TOKEN) ?? '';
  const email = queryParameters.get(QueryParamNameTypes.EMAIL) ?? '';

  const [message, setMessage] = useState();
  const { register, handleSubmit, watch, errors } = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    logOut();
  }, [logOut]);

  const password = watch(EndRegisterInputNames.PASSWORD);

  const { mutate: acceptInvitation, isLoading } = useMutation(
    (invitationData: {
      user: Record<EndRegisterInputNames, string> & {
        invitation_token: string;
      };
    }) => acceptInvitationRequest(invitationData),
    {
      onSuccess: () => {
        pushGTMEvent({ event: 'sign_up' });
        toast.success(t('registration-success'));
        history.replace('/login');
      },
      onError: () => {
        setMessage(t('wrong-token'));
      },
    }
  );

  const submit = (data: Record<EndRegisterInputNames, string>) => {
    const dataToSend = {
      user: {
        ...data,
        invitation_token: token,
      },
    };

    acceptInvitation(dataToSend);
  };
  const registerValue = (regex: RegExp, registerMessage: string) =>
    register({
      required: true,
      minLength: { value: 2, message: `${t('min-2-chars')}` },
      maxLength: { value: 35, message: `${t('max-35-chars')}` },
      pattern: {
        value: regex,
        message: registerMessage,
      },
    });
  return (
    <div className="authentication">
      <Link to="/" className="authentication__logo">
        <Logo className="authentication__logo" />
      </Link>
      <span className="authentication__title">{t('end-registration')}</span>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="authentication__form"
      >
        {message !== '' && message && (
          <div className="authentication__error-message">{message}</div>
        )}

        <Input
          label={t('name')}
          name={EndRegisterInputNames.FIRST_NAME}
          type="text"
          placeholder={t('enter-name')}
          errors={errors}
          register={registerValue(FIRST_NAME_REGEX, t('name-only-chars'))}
        />
        <Input
          label={t('last-name')}
          name={EndRegisterInputNames.LAST_NAME}
          type="text"
          placeholder={t('enter-last-name')}
          errors={errors}
          register={registerValue(LAST_NAME_REGEX, t('last-name-only-chars'))}
        />
        <Input
          label={t('position')}
          name={EndRegisterInputNames.POSITION}
          type="text"
          placeholder={t('enter-position')}
          errors={errors}
          register={registerValue(POSTION_AND_TEAM, t('position-only-chars'))}
        />
      </form>
      <form
        onSubmit={handleSubmit(submit)}
        className="authentication__form authentication__credentials"
      >
        <Input
          name="login"
          type="text"
          className="authentication__email"
          value={email}
        />
        <Input
          autoComplete="new-password"
          label={t('password')}
          name={EndRegisterInputNames.PASSWORD}
          type="password"
          placeholder={t('min-length', { length: 8 })}
          errors={errors}
          register={registerValue(PASSWORD_REGEX, t('password-invalid'))}
        />
        <Input
          autoComplete="new-password"
          label={t('password-rewrite')}
          name={EndRegisterInputNames.REPEAT_PASSWORD}
          type="password"
          placeholder={t('password-rewrite')}
          errors={errors}
          register={register({
            required: true,
            validate: (value) =>
              value === password || `${t('passwords-differ')}`,
          })}
        />
        <Button
          className="authentication__submit"
          type="submit"
          variant="primary"
          loading={isLoading}
        >
          {t('save')}
        </Button>
      </form>
      <div className="authentication__login">
        <Trans i18nKey="have-account">
          <Link to="/" className="authentication__login--link">
            login
          </Link>
        </Trans>
      </div>
    </div>
  );
};

export default EndRegister;
