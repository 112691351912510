import { getHours } from 'date-fns';
import { Card } from 'components/card';
import { Loader } from 'components/loader';
import { StyledScrolls } from 'components/styled-scrolls';
import {
  getDateFromObj,
  getDayLong,
  getLocaleTimeFromDate,
} from 'helpers/coaching-session-calendar';
import { getRangeNumbers } from 'helpers/utils';
import { BookedSession, CoachBusinessHours } from 'types/api';
import { TimeTile } from 'views/calendar/components/time-tile';
import './timeline.scss';

type TimeLineProps = {
  sessions?: BookedSession[];
  selectedDay: Date;
  businessHours: CoachBusinessHours[];
  isAdmin: boolean;
  isAbleToChangeCalendar: boolean;
  isLoading: boolean;
  isOtherCoachCalendarView?: boolean;
};

const SESSION_DURATION = 1;

export const Timeline: React.FC<TimeLineProps> = ({
  selectedDay,
  sessions,
  businessHours,
  isLoading,
  isOtherCoachCalendarView,
  isAdmin,
  isAbleToChangeCalendar,
}) => {
  const findBookedSessionsAtHour = (hour: number) =>
    sessions
      ? sessions?.filter(
          (session) => getHours(new Date(session.start_time)) === hour
        )
      : [];

  const dayId = selectedDay.getDay() === 0 ? 6 : selectedDay.getDay() - 1;
  const dayDetails = businessHours.length ? businessHours[dayId] : null;

  let workHours: number[] = [];
  if (dayDetails?.is_active) {
    const startTime = parseInt(
      getLocaleTimeFromDate(new Date(dayDetails.start_time)),
      10
    );

    const endTime = parseInt(
      getLocaleTimeFromDate(new Date(dayDetails.end_time)),
      10
    );

    const lastSessionStartHour =
      startTime + (endTime - startTime) - SESSION_DURATION;

    workHours = getRangeNumbers(startTime, lastSessionStartHour);
  } else {
    workHours = getRangeNumbers(0, 23);
  }

  let sessionsCounter = 0;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Card className="timeline">
      <header className="timeline__header">
        <time dateTime={selectedDay.toISOString()}>
          {getDayLong(selectedDay)} {getDateFromObj(selectedDay)}
        </time>
      </header>

      <StyledScrolls className="timeline__scrolls">
        {workHours.map((hour) => {
          const workHourSessions = findBookedSessionsAtHour(hour);
          const timeTiles = (
            <TimeTile
              isAdmin={isAdmin}
              isAbleToChangeCalendar={isAbleToChangeCalendar}
              key={hour}
              startHour={hour}
              startCounter={sessionsCounter}
              sessions={workHourSessions}
              selectedDay={selectedDay}
              isSessionActive={!!dayDetails?.is_active}
              isOtherCoachCalendarView={isOtherCoachCalendarView}
            />
          );
          sessionsCounter += workHourSessions.length;
          return timeTiles;
        })}
      </StyledScrolls>
    </Card>
  );
};

export default Timeline;
