import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { CoachAvailability } from 'components/coach-availability/coach-availability';
import { MeetingStatusLegend } from 'components/meeting-status-legend';
import { Modal } from 'components/modal';
import { MonthlyCalendar } from 'components/monthly-calendar';
import { ViewWrapper } from 'components/view-wrapper';
import {
  convertDateToApiCompatible,
  getMonthlySessionPeriod,
  groupSessionsByDate,
  groupSessionsByDay,
} from 'helpers/coaching-session-calendar';
import { today } from 'helpers/time';
import { useBookedSessions } from 'hooks/use-booked-sessions';
import { useCoachBusinessHours } from 'hooks/use-coach-business-hours';
import { useCoachSessions } from 'hooks/use-coach-sessions';
import { useGetAllCoaches } from 'hooks/use-get-all-coaches';
import {
  BookedSession,
  BookedSessionStatus,
  CoachBusinessHours,
} from 'types/api';
import { Roles } from 'types/enums';
import { ReactComponent as AddSign } from 'assets/icons/sign-add.svg';
import { CoachCollapse } from './components/coach-collapse';
import { Timeline } from './components/timeline';
import './calendar.scss';

export type CoachSessions = {
  [key: string]: BookedSession[];
};

const initialSlot = {
  date: today(),
  id: -1,
};

type LocationType = {
  pathname: string;
  search: string;
  hash: string;
  state?: { upcomingSession?: string };
  key?: string;
};

const Calendar: React.FC = () => {
  const [groupedSessions, setGroupedSessions] = useState<CoachSessions>({});
  const [coachFutureSessions, setCoachFutureSessions] = useState<CoachSessions>(
    {}
  );
  const [selectedMonth, setSelectedMonth] = useState(today);
  const [selectedSlot, setSelectedSlot] = useState(initialSlot);
  const [availabilitiesVisible, setAvailabilitiesVisible] = useState(false);
  const [selectedSessions, setSelectedSessions] = useState<BookedSession[]>([]);
  const { t } = useTranslation();
  const { userRole, user } = useContext(AuthContext);
  const [selectedCoachId, setSelectedCoachId] = useState(user?.id || 0);
  const isAdmin = userRole === Roles.ADMIN;
  const isAbleToChangeCalendar = userRole === Roles.SUPER_COACH || isAdmin;
  const selectedSlotKey = convertDateToApiCompatible(selectedSlot.date);
  const location: LocationType = useLocation();
  const upcomingSession = location.state?.upcomingSession;
  const [coachHours, setCoachHours] = useState<CoachBusinessHours[]>([]);
  const [isOtherCoachCalendarView, setIsOtherCoachCalendarView] = useState(
    selectedCoachId !== user?.id
  );

  const dateRange = useMemo(
    () => getMonthlySessionPeriod(selectedMonth),
    [selectedMonth]
  );

  const { allCoaches } = useGetAllCoaches(isAdmin);
  const { bookedSessions } = useBookedSessions(
    selectedCoachId,
    isOtherCoachCalendarView,
    ...dateRange
  );
  const { coachSessions, isLoading, setCurrentDate } = useCoachSessions(
    isAdmin,
    selectedCoachId
  );
  const {
    businessHours,
    restBusinessHoursQuery,
    isProcessing,
    sendCoachBusinessHours,
  } = useCoachBusinessHours(isAdmin, selectedCoachId, user?.email);

  const hours = selectedCoachId !== user?.id ? coachHours : businessHours;

  useEffect(() => {
    setIsOtherCoachCalendarView(selectedCoachId !== user?.id);
  }, [selectedCoachId, user?.id]);

  useEffect(() => {
    if (
      selectedMonth.getMonth() === initialSlot.date.getMonth() &&
      selectedMonth.getFullYear() === initialSlot.date.getFullYear()
    ) {
      setSelectedSlot(initialSlot);
    } else {
      setSelectedSlot({ date: selectedMonth, id: -1 });
    }
    setCurrentDate(selectedMonth);
  }, [selectedMonth, setCurrentDate]);

  useEffect(() => {
    if (isAbleToChangeCalendar) {
      if (user && !isAdmin) {
        setSelectedCoachId(user.id);
      } else if (allCoaches) {
        setSelectedCoachId(allCoaches[0]?.id ?? 0);
      }
    }
  }, [allCoaches, isAbleToChangeCalendar, isAdmin, user]);

  useEffect(() => {
    if (bookedSessions) {
      const validSessions = bookedSessions.filter(
        (session) => session.status !== BookedSessionStatus.REJECTED
      );
      setCoachFutureSessions(groupSessionsByDay(validSessions));
    }
  }, [bookedSessions, t]);

  useEffect(() => {
    if (selectedCoachId !== user?.id && coachSessions) {
      const { meetings, business_hours: coachBusinessHours } = coachSessions;

      if (coachBusinessHours.length) {
        const newData = coachBusinessHours.map((day) => ({
          ...day,
          start_time: day.start_time,
          end_time: day.end_time,
        }));
        setCoachHours(newData);
      }
      setGroupedSessions(groupSessionsByDate(meetings));
    } else {
      const filteredSessions = bookedSessions?.filter(
        (session) => session.provider?.id === user?.id
      );
      setGroupedSessions(groupSessionsByDate(filteredSessions || []));
    }
  }, [bookedSessions, coachSessions, selectedCoachId, t, user?.id]);

  useEffect(() => {
    if (groupedSessions[selectedSlotKey]) {
      setSelectedSessions(Object.values(groupedSessions[selectedSlotKey]));
    } else {
      setSelectedSessions([]);
    }
  }, [groupedSessions, selectedSlotKey]);

  useEffect(() => {
    setSelectedSlot(
      upcomingSession
        ? { date: new Date(upcomingSession), id: -1 }
        : initialSlot
    );
  }, [upcomingSession]);

  return (
    <ViewWrapper
      className="calendar"
      loading={restBusinessHoursQuery.isLoading}
    >
      <Timeline
        isAdmin={isAdmin}
        isAbleToChangeCalendar={isAbleToChangeCalendar}
        selectedDay={selectedSlot.date}
        sessions={selectedSessions}
        businessHours={hours}
        isLoading={isLoading}
        isOtherCoachCalendarView={isOtherCoachCalendarView}
      />
      <div className="calendar__monthly-calendar-wrapper">
        {isAbleToChangeCalendar && user && (
          <CoachCollapse
            userId={user.id}
            coaches={allCoaches || []}
            setCoach={setSelectedCoachId}
            isAdmin={isAdmin}
          />
        )}
        <MonthlyCalendar
          coachSessions={groupedSessions}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          className="calendar__monthly-calendar"
        />
        <Button
          onClick={() => {
            setAvailabilitiesVisible(hours.length <= 7);
          }}
          className="calendar__add-button"
          variant="icon-text"
          icon={!isOtherCoachCalendarView && <AddSign />}
        >
          {isOtherCoachCalendarView
            ? t('check-other-coach-bussines-hours')
            : t('add-edit-hours')}
        </Button>
        <MeetingStatusLegend />
      </div>
      <Modal
        className="calendar__availability-modal"
        bodyClassName="calendar__availability-modal-body"
        maxWidth="800px"
        isOpen={availabilitiesVisible}
        onRequestClose={() => setAvailabilitiesVisible(false)}
      >
        {availabilitiesVisible && (
          <CoachAvailability
            setAvailabilitiesVisible={() => setAvailabilitiesVisible(false)}
            businessHours={hours}
            isLoading={restBusinessHoursQuery.isLoading}
            isOtherCoachCalendarView={isOtherCoachCalendarView}
            isProcessing={isProcessing}
            setCoachBusinessHours={sendCoachBusinessHours}
            futureSessions={coachFutureSessions}
          />
        )}
      </Modal>
    </ViewWrapper>
  );
};

export default Calendar;
