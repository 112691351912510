import { useContext } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { AuthContext } from 'contexts';
import { t } from 'i18next';
import { getAllCoaches } from 'services/calendar';
import { Roles } from 'types/enums';

export const useGetAllCoaches = (isAdmin?: boolean) => {
  const { userRole } = useContext(AuthContext);
  const isQueryEnabled = userRole === Roles.SUPER_COACH || isAdmin;
  const { data: allCoaches, ...rest } = useQuery(
    QueryKeys.ALL_COACHES,
    () => getAllCoaches(),
    {
      onError: () => toast.error(t('course-coaches-error')),
      enabled: isQueryEnabled,
    }
  );

  return { allCoaches, ...rest };
};
