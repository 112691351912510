import { useTranslation } from 'react-i18next';
import { Collapse } from 'components/collapse';
import { Person } from 'components/person';
import { CourseCoach, ModuleAttachment } from 'types/api';
import { AttachmentItem } from './attachment-item';
import './module-content.scss';

type ModuleContentProps = {
  coach?: CourseCoach;
  attachments?: ModuleAttachment[];
  courseId: number;
};

export const ModuleContent: React.FC<ModuleContentProps> = ({
  coach,
  children,
  attachments,
}) => {
  const { t } = useTranslation();
  const showButtons = !!(coach || children);
  const hasAttachemts = attachments?.length !== 0;

  return (
    <div className="module-content">
      <header className="module-content__header">
        {coach && (
          <div className="course-itemV2__header-coach">
            <div className="course-itemV2__avatar">
              <Person
                key={coach.id}
                profile={coach}
                layout="small"
                size={40}
                isAvatar
                details
                type="coach"
              />
            </div>

            <div className="course-itemV2__header-name">
              {coach.first_name} {coach.last_name}
            </div>
          </div>
        )}
        {showButtons && children}
      </header>
      {hasAttachemts && (
        <div className="module-content__collapse">
          <Collapse
            className="module-content__collapse-button"
            hiddenContent={
              <div className="module-content__collapse-content">
                {attachments?.map((attachment) => (
                  <AttachmentItem key={attachment.id} details={attachment} />
                ))}
              </div>
            }
            borderBottom
          >
            <div className="module-content__collapse-title">
              {t('lesson-materials')}
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
};
