import { SessionTypeIds } from 'constants/sessions';
import axios from 'helpers/axios';
import { getMonthlySessionPeriod } from 'helpers/coaching-session-calendar';
import {
  BookedSession,
  CalendarCoach,
  CoachAvailability,
  CoachBusinessHours,
  CoachCalendarData,
  CoachTimeslots,
  GroupSessionBooking,
  GroupSessionEditing,
  SessionBooking,
  SessionStartTime,
  SessionWithCoachNewTimeslots,
  UpdateMeetingParams,
} from 'types/api';

export async function getCoachingSessions(startTime?: SessionStartTime) {
  const { data } = await axios.get<BookedSession[]>(`v1/calendar/meetings`, {
    params: { start_time: startTime },
  });
  return data;
}

export async function getCoachTimeslots(
  moduleId: string,
  startTime?: string,
  endTime?: string
) {
  const { data } = await axios.get<CoachTimeslots>(`v1/calendar/time_slots`, {
    params: {
      start_time: startTime,
      end_time: endTime,
      course_module_id: moduleId,
    },
  });
  return data;
}

export async function getAvailabilityForModule(
  moduleId: string,
  meetingTypeId: SessionTypeIds,
  startDate?: string,
  endDate?: string
) {
  const { data } = await axios.get<CoachAvailability[]>(
    `v1/calendar/topics/${moduleId}/coaches_availability`,
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        meeting_type_id: meetingTypeId,
      },
    }
  );

  return data;
}

export async function createSessionWithCoach(
  moduleId: string,
  dataToSend: SessionBooking
) {
  const { data } = await axios.post<BookedSession>(
    `v1/calendar/topics/${moduleId}/schedule_meeting`,
    dataToSend
  );

  return data;
}

export async function createGroupSession(
  moduleId: string,
  dataToSend: GroupSessionBooking
) {
  const { data } = await axios.post<BookedSession>(
    `v1/calendar/topics/${moduleId}/schedule_group_meeting`,
    dataToSend
  );
  return data;
}

export async function createSession(dataToSend: SessionWithCoachNewTimeslots) {
  const { data } = await axios.post<BookedSession>(
    `v1/calendar/meetings/schedule`,
    dataToSend
  );
  return data;
}

export async function updateGroupSession(
  meetingId: string,
  dataToSend: GroupSessionEditing
) {
  const { data } = await axios.put<BookedSession>(
    `v1/calendar/meetings/${meetingId}?group_meetings_enabled=true`,
    dataToSend
  );
  return data;
}

export async function getBookedSessions(startDate?: string, endDate?: string) {
  const { data } = await axios.get<BookedSession[]>('/v1/calendar/meetings', {
    params: { start_time: startDate, end_time: endDate },
  });
  return data;
}

export async function getAllCoaches() {
  const { data } = await axios.get<CalendarCoach[]>(
    'v1/users/calendar_coaches'
  );
  return data;
}

export async function getCoachSessions(currentMonth: Date, coachId?: number) {
  const dateRange = getMonthlySessionPeriod(currentMonth);
  const firstDay = `${dateRange[0]}T00:00:00.000Z`;
  const lastDay = `${dateRange[1]}T00:00:00.000Z`;

  const { data } = await axios.get<CoachCalendarData>(
    'v1/calendar/meetings/coach_meetings',
    {
      params: {
        start_time: firstDay,
        end_time: lastDay,
        coach_id: coachId,
      },
    }
  );
  return data;
}

export async function confirmMeeting(id: number) {
  const { data } = await axios.post<BookedSession>(
    `/v1/calendar/meetings/${id}/confirm`
  );
  return data;
}

export async function rejectMeeting(id: number) {
  const { data } = await axios.post<BookedSession>(
    `/v1/calendar/meetings/${id}/reject`
  );
  return data;
}

export async function updateMeeting(id: number, params: UpdateMeetingParams) {
  const { data } = await axios.put<BookedSession>(
    `/v1/calendar/meetings/${id}`,
    params
  );
  return data;
}

export async function getCoachBusinessHours(email?: string) {
  const { data } = await axios.get<CoachBusinessHours[]>(
    'v1/calendar/business_hours',
    {
      params: {
        start_date: '20.11.2023',
        end_date: '25.11.2023',
        email,
        calendar_availability_enable: true,
      },
    }
  );

  return data;
}

export async function sendCoachBusinessHours(
  businessHours: { start_time: string; end_time: string; is_active: boolean }[]
) {
  await axios.post('v1/calendar/business_hours/set_availability', {
    business_hours_params: businessHours,
  });
}

export const changeCoachForMeeting = async (
  meeting_id: number,
  coach_id: number
) => {
  const { data } = await axios.put<BookedSession>(
    `/v1/calendar/meetings/${meeting_id}/change_coach`,
    { coach_id }
  );

  return data;
};
