import { useTranslation } from 'react-i18next';
import { StatusTile } from './status-tile';
import './meeting-status-legend.scss';

export const MeetingStatusLegend = () => {
  const { t } = useTranslation();

  const statuses = [
    { status: t('meeting-confirmed'), color: 'dark-blue' },
    { status: t('meeting-realized'), color: 'light-blue' },
    { status: t('meeting-waiting-for-accept'), color: 'yellow' },
    { status: t('meeting-rejected'), color: 'red' },
  ];

  return (
    <div className="status-legend">
      <div className="status-legend__header">{t('meeting-statuses')}</div>
      <div className="status-legend__body">
        {statuses.map(({ status, color }) => (
          <StatusTile status={status} color={color} key={status} />
        ))}
      </div>
    </div>
  );
};
