import clsx from 'clsx';
import './progressbar.scss';

type ProgressbarType = {
  progress: number;
  className?: string;
};

export const Progressbar: React.FC<ProgressbarType> = ({
  progress,
  className,
}) => {
  return (
    <div className={clsx('progressbar', className)}>
      <div className="progressbar__fill" style={{ width: `${progress}%` }} />
    </div>
  );
};
