import { FC } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { SelectOption } from 'components/form/select';

interface FormSelectProps {
  control: Control<FieldValues>;
  options: SelectOption[];
  name: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  isDisabled?: boolean;
  errors?: DeepMap<any, FieldError>;
  onInputChange?: (value: string) => void;
}

export const FormSelect: FC<FormSelectProps> = ({
  control,
  options,
  name,
  label,
  required,
  placeholder,
  isDisabled,
  errors = {},
  onInputChange,
}) => {
  const { t } = useTranslation();

  const errorMessage = name && errors[name] ? errors[name].message : null;
  const errorMessageRequired =
    errors[name] && errors[name].type === 'required'
      ? t('field-required')
      : null;

  return (
    <div className="form-select">
      {label && (
        <label className="form-select__label" htmlFor={name}>
          <span className="form-select__label-text">{label}</span>
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ value, onChange: controllerOnChange, ref }) => (
          <>
            <Select
              onChange={(option) => {
                if (option) {
                  if (onInputChange) {
                    onInputChange(option.value);
                  }
                  controllerOnChange(option.value);
                }
              }}
              ref={ref}
              name={name}
              inputId={name}
              isDisabled={isDisabled}
              noOptionsMessage={() => t('no-options')}
              value={options.find((option) => option.value === value) || null}
              placeholder={placeholder}
              classNamePrefix="react-select"
              options={options}
            />
            {errorMessageRequired || errorMessage ? (
              <span className="form-select__error">
                {errorMessageRequired || errorMessage}
              </span>
            ) : null}
          </>
        )}
      />
    </div>
  );
};
