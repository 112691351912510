import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts';
import { isFuture } from 'date-fns';
import { EditAndRemoveMenu } from 'components/edit-and-remove-menu';
import { EditGroupMeetingModal } from 'components/group-meeting-edit-modal';
import { NoResource } from 'components/no-resource';
import { SessionCard } from 'components/session-card';
import { BookedSession, BookedSessionStatus } from 'types/api';
import { EditMeetingModal } from 'views/calendar/components/time-tile/edit-meeting-modal';
import { SessionButtons } from 'views/calendar/components/time-tile/session-buttons';
import './coaching-sessions-list.scss';

type CoachingSessionsListProps = {
  list: BookedSession[];
};

export const CoachingSessionsList: React.FC<CoachingSessionsListProps> = ({
  list,
}) => {
  const { t } = useTranslation();
  const { isParticipant, isHR } = useContext(AuthContext);
  const [sessionToEdit, setSessionToEdit] = useState<BookedSession | null>(
    null
  );

  if (!list.length) {
    return (
      <NoResource className="sessions-list__message">
        {t('empty-sessions-list')}
      </NoResource>
    );
  }

  return (
    <>
      <ol className="sessions-list">
        {list.map((session, index) => {
          if (session.participants) {
            const isIndividualMeeting = session.participants.length === 1;
            const shouldManageGroupMeetings =
              (isParticipant && isIndividualMeeting) ||
              (isHR && !isIndividualMeeting);
            const isMeetingWithProperStatus =
              session.status === BookedSessionStatus.RECIPIENT_CONFIRMED ||
              session.status === BookedSessionStatus.CONFIRMED;
            const isFutureMeeting = isFuture(new Date(session.start_time));
            const shouldDisplayButtons =
              isFutureMeeting &&
              isMeetingWithProperStatus &&
              shouldManageGroupMeetings;
            const shouldDisplayMenu =
              shouldManageGroupMeetings && isFutureMeeting;

            return (
              <li key={session.id} className="sessions-list__single-session">
                <SessionCard
                  sessionDetails={session}
                  sessionsCount={index}
                  buttons={
                    shouldDisplayButtons && (
                      <SessionButtons
                        sessionId={session.id}
                        status={session.status}
                      />
                    )
                  }
                  menu={
                    shouldDisplayMenu && (
                      <EditAndRemoveMenu
                        buttonColor="white"
                        onEdit={() => {
                          setSessionToEdit(session);
                        }}
                      />
                    )
                  }
                />
              </li>
            );
          }
          return (
            <li key={session.id} className="sessions-list__single-session">
              <SessionCard sessionDetails={session} sessionsCount={index} />
            </li>
          );
        })}
      </ol>
      {!!sessionToEdit && isHR && sessionToEdit.participants.length > 1 && (
        <EditGroupMeetingModal
          isOpen={!!sessionToEdit}
          sessionToEdit={sessionToEdit}
          close={() => setSessionToEdit(null)}
        />
      )}
      {!!sessionToEdit && isParticipant && (
        <EditMeetingModal
          isOpen={!!sessionToEdit}
          sessionDetails={sessionToEdit}
          close={() => setSessionToEdit(null)}
        />
      )}
    </>
  );
};
