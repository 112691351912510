import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { ButtonsWrapper } from 'components/quiz/participant-quiz/buttons-wrapper';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left-s-line.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-s-line.svg';
import './buttons.scss';

type ControlButtonsProps = {
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  isSend: boolean;
  previousQuestion: () => void;
  nextQuestion: () => void;
  submit: () => void;
};

export const ControlButtons: React.FC<ControlButtonsProps> = ({
  isSend,
  isFirstQuestion,
  isLastQuestion,
  previousQuestion,
  nextQuestion,
  submit,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonsWrapper className="buttons">
      {!isFirstQuestion && (
        <Button variant="secondary" onClick={previousQuestion}>
          <ArrowLeft />
          {t('previous-question')}
        </Button>
      )}

      {isLastQuestion ? (
        <Button
          className="buttons__button buttons__button--primary"
          type="submit"
          onClick={() => {
            submit();
          }}
          loading={isSend}
        >
          {t('finish')}
        </Button>
      ) : (
        <Button
          variant="secondary"
          className="buttons__button buttons__button--primary"
          onClick={nextQuestion}
        >
          {t('next-question')}
          <ArrowRight />
        </Button>
      )}
    </ButtonsWrapper>
  );
};
