import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'contexts';
import { AttachmentsList } from 'components/attachments-list';
import { CourseProgress } from 'components/course-progress';
import { Loader } from 'components/loader';
import { TabSwitch } from 'components/tab-switch';
import { StateSetter } from 'helpers/types';
import {
  CourseAside as CourseAsideType,
  ModuleDetails,
  Notepad as NotepadType,
} from 'types/api';
import './course-aside.scss';

type TabItem = 'progress' | 'notepad' | 'attachments';

type CourseAsideProps = {
  courseAside: CourseAsideType | null;
  activeModule: ModuleDetails | null;
  activeContentId: number | string;
  initialNote: NotepadType | null;
  setActiveModule: StateSetter<ModuleDetails | null>;
};

export const CourseAside: React.FC<CourseAsideProps> = ({
  courseAside,
  activeModule,
  activeContentId,
  setActiveModule,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<TabItem>('progress');
  const { isParticipant } = useContext(AuthContext);

  if (!courseAside) {
    return <Loader />;
  }

  const attachmentList = activeModule?.attachments;

  const allTabs = [
    {
      content: <span className="course-aside__tab">{t('lessons')}</span>,
      value: 'progress',
    },
    {
      content: (
        <span
          className="course-aside__tab course-aside__tab--attachments"
          data-attachments-count={attachmentList?.length ?? 0}
        >
          {t('materials')}
        </span>
      ),
      value: 'attachments',
    },
  ];

  const lessonTab = [allTabs[0]];

  const tabsToRender = (() => {
    if (!isParticipant && !attachmentList?.length) {
      return lessonTab;
    }
    return allTabs;
  })();

  return (
    <div className="course-aside">
      <TabSwitch
        className="course-aside__switch"
        buttonClassName="course-aside__button"
        activeButtonClassName="course-aside__button--active"
        value={currentTab}
        onChange={(value: string) => {
          setCurrentTab(value as TabItem);
        }}
        tabs={tabsToRender}
      />

      {currentTab === 'attachments' && (
        <AttachmentsList list={attachmentList} />
      )}

      {currentTab === 'progress' && (
        <CourseProgress
          courseId={courseAside.id}
          activeContentId={activeContentId}
          modules={courseAside.modules}
          activeModule={activeModule}
          setActiveModule={setActiveModule}
        />
      )}
    </div>
  );
};
