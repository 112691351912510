import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { DropdownButton } from 'components/dropdown-button';
import { DropdownMenuItem } from 'components/dropdown-menu-item';
import { ReactComponent as FilterIcon } from 'assets/icons/funnel.svg';
import './tasks-filter.scss';

type TasksFilterProps = {
  status: string | null;
  onAnswerStatusChange: (answerStatus: string) => void;
};

export const TasksFilter: React.FC<TasksFilterProps> = ({
  status,
  onAnswerStatusChange,
}) => {
  const { t } = useTranslation();
  const menuConfig = [
    { key: '', text: t('answers-all') },
    {
      key: 'accepted',
      text: t('answers-accepted'),
    },
    {
      key: 'to_verify',
      text: t('answers-to-verify'),
    },
    {
      key: 'failed',
      text: t('answers-failed'),
    },
  ];

  const menuItems = menuConfig.map(({ text, key }) => (
    <DropdownMenuItem
      wrapperClassName={clsx(
        'tasks-filter__item',
        key === status && 'tasks-filter__item--active'
      )}
      className="tasks-filter__menu-button"
      onClick={() => onAnswerStatusChange(key)}
      key={key}
    >
      {text}
    </DropdownMenuItem>
  ));

  return (
    <div className="tasks-filter">
      <DropdownButton
        listClassName="tasks-filter__list"
        menuClassName="tasks-filter__menu"
        buttonContent={
          <div className="tasks-filter__text">
            <FilterIcon />
            <div data-text={t('filter')}>{t('filter')}</div>
          </div>
        }
      >
        {menuItems}
      </DropdownButton>
    </div>
  );
};
