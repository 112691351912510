import { useCallback, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { CompletedAttemptsCount, Lesson, ModuleDetails } from 'types/api';

const isLessonUncompleted = (lesson: Lesson) => !lesson.completed;

export const useRedirectContentV2 = (modules: ModuleDetails[]) => {
  const history = useHistory();
  const { isParticipant } = useContext(AuthContext);
  const { courseId, contentId, contentType } = useParams<{
    courseId: string;
    contentId: string;
    contentType: string;
  }>();

  const baseRedirectPath = `/courses/${courseId}`;
  const redirectContent = useCallback(() => {
    const shouldNotRedirect =
      !modules.length || contentId || contentType !== 'lesson';

    if (shouldNotRedirect) {
      return;
    }

    const uncompletedModule = modules?.find((module) => {
      const moduleHasIncompleteLesson = module.lessons.some(
        (lesson) => !lesson.completed
      );
      const moduleTooManyQuizAttempts =
        module.quiz &&
        module.quiz.completed_attempts_count <
          CompletedAttemptsCount.TWO_ATTEMPTS;

      return moduleHasIncompleteLesson || moduleTooManyQuizAttempts;
    });

    const shouldGoToFirstLesson = !isParticipant || !uncompletedModule;

    const shouldGoToPreQuiz =
      uncompletedModule?.quiz?.completed_attempts_count ===
      CompletedAttemptsCount.NO_ATTEMPTS;

    const shouldGoToPostQuiz =
      uncompletedModule?.quiz?.completed_attempts_count ===
      CompletedAttemptsCount.ONE_ATTEMPT;

    const areUnfinishedLessonInModule =
      uncompletedModule?.lessons?.some(isLessonUncompleted);

    if (shouldGoToPreQuiz) {
      history.replace(
        `${baseRedirectPath}/pre_quiz/${uncompletedModule?.quiz?.id}`
      );
      return;
    }

    if (shouldGoToPostQuiz) {
      history.replace(
        `${baseRedirectPath}/post_quiz/${uncompletedModule?.quiz?.id}`
      );
    }

    if (areUnfinishedLessonInModule) {
      const firstUncompletedLesson =
        uncompletedModule?.lessons.find(isLessonUncompleted);

      history.replace(
        `${baseRedirectPath}/lesson/${firstUncompletedLesson?.slug}`
      );
      return;
    }

    if (shouldGoToFirstLesson) {
      history.replace(
        `${baseRedirectPath}/lesson/${modules?.[0]?.lessons[0]?.slug}`
      );
    }
  }, [
    baseRedirectPath,
    contentId,
    contentType,
    history,
    isParticipant,
    modules,
  ]);

  useEffect(() => {
    redirectContent();
  }, [redirectContent]);
};
