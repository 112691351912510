import axios from 'helpers/axios';
import { Answer, AnswerData, CourseLesson } from 'types/api';

export async function finishLesson(
  lessonSlug: string,
  companySubscriptionCourseId: number
) {
  await axios.post(
    `v1/lessons/${lessonSlug}/update_lesson`,
    {
      slug: lessonSlug,
    },
    { params: { company_subscription_course_id: companySubscriptionCourseId } }
  );
}
export async function getLessonDetails(
  lessonSlug: string,
  companySubscriptionCourseId: number
) {
  const { data } = await axios.get<CourseLesson>(`v1/lessons/${lessonSlug}`, {
    params: {
      new_response: true,
      company_subscription_course_id: companySubscriptionCourseId,
    },
  });

  return data;
}

export type FinishQuizResponse = {
  questions: boolean[];
  scored_points: number;
  total_points: number;
};

export async function sendAnswer(
  id: number,
  dataToSend: AnswerData
): Promise<Answer> {
  const { data } = await axios.post(`v1/answers/${id}/verify`, dataToSend);
  return data;
}

export async function updateVideoWatchedTime(
  lessonSlug: string,
  parameters: { video_watched_time: number }
) {
  const { data } = await axios.post(
    `v1/lessons/${lessonSlug}/video_watched_time`,
    parameters
  );

  return data;
}
