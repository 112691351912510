import { useTranslation } from 'react-i18next';
import { Progressbar } from 'components/progressbar';
import { CourseDashboardDetails } from 'types/api';
import { ReactComponent as IconFlag } from 'assets/icons/flag-2-line.svg';
import './module-item.scss';

type PropsModuleItem = {
  course: CourseDashboardDetails;
};

export const ModuleItem: React.FC<PropsModuleItem> = ({
  course: {
    completed_lessons_count,
    display_order,
    lessons_count,
    name,
    progress,
  },
}) => {
  const { t } = useTranslation();

  return (
    <div className="module-item">
      <div className="module-item__header">
        <IconFlag className="module-item__stats-icon" />
        <span className="module-item__header--text">
          {display_order}{' '}
          <span className="module-item__header--gray">{t('module')}</span>
          <span className="module-item__header--bold">{name}</span>
        </span>
      </div>

      <div className="module-item__progress">
        {t('module-lessons-finished', {
          completedLessons: completed_lessons_count,
          allLessons: lessons_count,
        })}
      </div>
      <Progressbar progress={progress} />
    </div>
  );
};
