import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { StateSetter } from 'helpers/types';
import { encipherId } from 'helpers/utils';
import { ModuleDetails } from 'types/api';
import { ReactComponent as IconList } from 'assets/icons/list.svg';
import { ReactComponent as IconProgress } from 'assets/icons/progress.svg';
import { CourseProgressbar } from './course-progressbar';
import { ProgressList } from './progress-list';
import './course-progress.scss';

type ViewType = 'list' | 'progressbar';
type CourseProgressProps = {
  modules: ModuleDetails[];
  activeContentId?: number | string;
  courseId: number;
  activeModule: ModuleDetails | null;
  setActiveModule: StateSetter<ModuleDetails | null>;
};

const LAYOUT_STORAGE_KEY = 'sa-progress-type';

export const CourseProgress: React.FC<CourseProgressProps> = ({
  activeModule,
  setActiveModule,
  modules,
  activeContentId,
  courseId,
}) => {
  const history = useHistory();
  const { isParticipant } = useContext(AuthContext);
  const { t } = useTranslation();

  const [viewType, setViewType] = useState<ViewType>(
    (localStorage.getItem(LAYOUT_STORAGE_KEY) as ViewType) || 'list'
  );

  const courseProgress = (
    modules.reduce((acc, current) => acc + current.module_progress, 0) /
    modules.length
  ).toFixed(1);

  const goToLesson = (id: number) => {
    const isQuizCompleted = activeModule?.lessons.some((lesson) => {
      return lesson.type === 'Quiz' && lesson.id === id && lesson.completed;
    });
    const lessonSlug = modules
      .flatMap(({ lessons }) => lessons)
      .find((lesson) => lesson.id === id)?.slug;

    if (isQuizCompleted) {
      toast.info(t('quiz-solved'));
    } else {
      history.push(`/courses/${encipherId(courseId)}/lesson/${lessonSlug}`);
    }
  };

  const onTypeChange = (value: ViewType) => {
    localStorage.setItem(LAYOUT_STORAGE_KEY, value);
    setViewType(value);
  };

  if (!modules.length) {
    return null;
  }

  return (
    <div className="course-progress">
      {isParticipant && (
        <header className="course-progress__header">
          <Trans i18nKey="course-percentage" count={+courseProgress}>
            Finished: <span className="course-progress__percentage">0%</span>
          </Trans>
          <div className="course-progress__buttons">
            <Button
              variant="icon"
              className={clsx(
                'course-progress__button',
                viewType === 'list' && 'course-progress__button--active'
              )}
              onClick={() => {
                onTypeChange('list');
              }}
              icon={<IconList className="course-progress__icon" />}
            />
            <Button
              variant="icon"
              className={clsx(
                'course-progress__button',
                viewType === 'progressbar' && 'course-progress__button--active'
              )}
              onClick={() => {
                onTypeChange('progressbar');
              }}
              icon={<IconProgress />}
            />
          </div>
        </header>
      )}
      {viewType === 'list' ? (
        <ProgressList
          modules={modules}
          goToLesson={goToLesson}
          setActiveModule={setActiveModule}
          activeContentId={activeContentId ?? -1}
          activeModule={activeModule}
        />
      ) : (
        <CourseProgressbar modules={modules} goToLesson={goToLesson} />
      )}
    </div>
  );
};
