import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCourseAside } from 'services/courses';
import { decipherId, encipherId, getActiveModule } from 'helpers/utils';
import { CourseAside, Lesson, ModuleDetails, Quiz } from 'types/api';
import { QuizAttemptType } from 'types/enums';
import { CreateCourseAsizeKey } from './use-course-aside';
import { useRedirectContentV2 } from './use-redirect-contentV2';

export const useCourseAsideV2 = () => {
  const {
    courseId: courseCipheredId,
    contentId: activeContentId,
    contentType,
    lessonId,
  } = useParams<{
    courseId: string;
    contentId: string;
    contentType: string;
    lessonId: string;
  }>();
  const courseId = decipherId(courseCipheredId);
  const { t } = useTranslation();

  const [activeModule, setActiveModule] = useState<ModuleDetails | null>(null);

  const queryClient = useQueryClient();

  const { data: courseAside } = useQuery(
    CreateCourseAsizeKey(courseId),
    () => getCourseAside(courseId),
    {
      onError: () => {
        toast.error(t('course-aside-error'));
      },
      enabled: !!courseId,
    }
  );

  const history = useHistory();

  const isCurrentContentQuiz =
    contentType === QuizAttemptType.POST_QUIZ ||
    contentType === QuizAttemptType.PRE_QUIZ;

  const increaseQuizAttempts = (quiz?: Quiz | null) => {
    if (!quiz) {
      return quiz;
    }
    const quizAttempts = quiz.completed_attempts_count;
    const updatedQuiz = {
      ...quiz,
      completed_attempts_count: quizAttempts ? quizAttempts + 1 : 1,
    };
    return updatedQuiz;
  };

  const markLessonAsCompleted = (lessons: Lesson[], activeLessonSlug: string) =>
    lessons.map((lesson) =>
      lesson.slug === activeLessonSlug ? { ...lesson, completed: true } : lesson
    );

  const optimisticallyValidate = useCallback(async () => {
    const courseQueryKey = CreateCourseAsizeKey(courseId);
    await queryClient.cancelQueries(courseQueryKey);
    if (!activeModule) {
      return;
    }
    queryClient.setQueryData<CourseAside | undefined>(
      courseQueryKey,
      (previousData): CourseAside | undefined => {
        if (!previousData) {
          return previousData;
        }
        return {
          ...previousData,
          modules: previousData.modules.map((module) =>
            module.id === activeModule?.id
              ? {
                  ...module,
                  lessons: lessonId
                    ? markLessonAsCompleted(module.lessons, lessonId)
                    : module.lessons,
                  quiz: isCurrentContentQuiz
                    ? increaseQuizAttempts(module.quiz)
                    : module.quiz,
                }
              : module
          ),
        };
      }
    );
  }, [courseId, queryClient, activeModule, lessonId, isCurrentContentQuiz]);

  const moveToNext = useCallback(async () => {
    await optimisticallyValidate();
    queryClient.invalidateQueries([
      QueryKeys.DASHBOARD_COURSE_DETAILS,
      +courseId,
    ]);
    history.push(`/courses/${encipherId(courseId)}/lesson`);
  }, [courseId, history, optimisticallyValidate, queryClient]);

  useEffect(() => {
    if (courseAside) {
      setActiveModule(getActiveModule(activeContentId, courseAside.modules));
    }
  }, [activeContentId, courseAside]);

  useRedirectContentV2(courseAside?.modules || []);

  return {
    courseAside: courseAside || null,
    activeContentId,
    activeModule,
    courseId,
    optimisticallyValidate,
    moveToNext,
    note: courseAside?.notepad || null,
  };
};
