import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Collapse } from 'components/collapse';
import { StyledScrolls } from 'components/styled-scrolls';
import {
  getDateFromObj,
  getLocaleTimeFromDate,
} from 'helpers/coaching-session-calendar';
import { createSelectOptionsFromProfiles } from 'helpers/utils';
import { BookedSession, BookedSessionStatus } from 'types/api';
import { ReactComponent as Avatar3 } from 'assets/icons/avatar-3.svg';
import { ReactComponent as Flag } from 'assets/icons/flag-2-line.svg';
import { ReactComponent as Note } from 'assets/icons/report.svg';
import './session-card.scss';

export type SessionCardProps = {
  sessionDetails: BookedSession;
  sessionsCount: number;
  menu?: React.ReactNode;
  className?: string;
  buttons?: React.ReactNode;
};

export const SessionCard: React.FC<SessionCardProps> = ({
  sessionDetails: {
    course,
    start_time: startTime,
    provider,
    status,
    participants,
    note,
  },
  sessionsCount = 0,
  menu,
  className,
  buttons,
}) => {
  const participantsToDisplay = createSelectOptionsFromProfiles(
    participants ?? []
  );
  const isParticipantExist = !!participantsToDisplay.length;
  const isGroupSession = isParticipantExist && participantsToDisplay.length > 1;
  const sessionStartTime = new Date(startTime);
  const { t } = useTranslation();

  const simpleStatus =
    ((status === BookedSessionStatus.PENDING ||
      status === BookedSessionStatus.APPLICANT_CONFIRMED ||
      status === BookedSessionStatus.RECIPIENT_CONFIRMED) &&
      BookedSessionStatus.PENDING) ||
    (status === BookedSessionStatus.CONFIRMED &&
      BookedSessionStatus.CONFIRMED) ||
    (status === BookedSessionStatus.REJECTED && BookedSessionStatus.REJECTED);

  const groupMeetingParticipants = participantsToDisplay.map(
    ({ value, label }) => (
      <p key={value} className="session-card__participant">
        {label}
      </p>
    )
  );

  return (
    <div
      className={clsx(
        'session-card',
        `session-card--${simpleStatus}`,
        className
      )}
    >
      <header
        className={clsx(
          'session-card__header',
          `session-card__header--${simpleStatus}`
        )}
      >
        <div>
          <span className="session-card__course">{course?.name}</span>
          {provider
            ? `${provider.first_name} ${provider.last_name}`
            : t('coach-inactive')}
        </div>
        <div className="session-card__header-right">
          {t('session-date', {
            date: getDateFromObj(sessionStartTime),
            hour: getLocaleTimeFromDate(sessionStartTime),
          })}
          {!!menu && menu}
        </div>
      </header>
      <div
        className={clsx(
          'session-card__content',
          `session-card__content--${simpleStatus}`,
          buttons && 'session-card__content--grid'
        )}
      >
        <div className="session-card__rows">
          <div className="session-card__content-row">
            <Flag className="session-card__content-icon" />
            {isGroupSession
              ? t('group-session', { number: sessionsCount + 1 })
              : t('individual-session', { number: sessionsCount + 1 })}
          </div>
          <div className="session-card__content-row">
            {!isGroupSession ? (
              <>
                <Avatar3 className="session-card__content-icon" />
                {participantsToDisplay[0]?.label ?? t('participant-inactive')}
              </>
            ) : (
              <Collapse
                isAutoScroll={false}
                className="session-card__content-row"
                hiddenContent={
                  <StyledScrolls className="session-card__participants">
                    {groupMeetingParticipants}
                  </StyledScrolls>
                }
              >
                <Avatar3 className="session-card__content-icon" />
                {t('participants')}
              </Collapse>
            )}
          </div>
          {note && (
            <Collapse
              isAutoScroll={false}
              className="session-card__content-row"
              hiddenContent={
                <div className="session-card__content-note">{note}</div>
              }
            >
              <Note className="session-card__content-icon" />
              {t('session-note')}
            </Collapse>
          )}
        </div>
        {buttons}
      </div>
    </div>
  );
};
