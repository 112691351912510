import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { CourseStats } from 'components/course-stats';
import { encipherId } from 'helpers/utils';
import { useCourses } from 'hooks/use-courses';
import { AvailableCourses, SubscriptionCourse } from 'types/api';
import { CourseInfo } from './course-info';
import './course-tile.scss';

type CourseTileProps = {
  className?: string;
  subscriptionCourse?: SubscriptionCourse;
  availableCourses?: AvailableCourses;
  courseId?: number;
  disabled?: boolean;
};
export const CourseTile: React.FC<CourseTileProps> = ({
  subscriptionCourse,
  className,
  availableCourses,
  disabled = false,
  courseId,
}: CourseTileProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const { isSales } = useContext(AuthContext);
  const {
    course,
    id,
    total_progress: totalProgress,
  } = subscriptionCourse || {
    ...availableCourses,
    course: availableCourses,
    total_progress: 0,
  };

  const { courseModules: userCourseModules } = useCourses({
    areStatsVisible: isVisible,
    courseId,
  });

  return (
    <div className="course-tile-wrapper">
      {course && (
        <>
          <CourseInfo
            courseInfo={course}
            userCourseModules={userCourseModules}
            isSales={isSales}
            isVisible={isVisible}
            hideInfo={() => setIsVisible(false)}
          />
          <Card
            className={clsx(
              'course-tile',
              disabled && 'course-tile--disabled',
              course.background_url && 'course-tile--text-white',
              className
            )}
          >
            <div
              className={clsx('course-tile__background', className)}
              style={{ backgroundImage: `url(${course.background_url})` }}
            >
              <div className="course-tile__wrapper">
                <header
                  className={clsx(
                    'course-tile__group-top',
                    course.background_url && 'course-tile__group-top--white'
                  )}
                >
                  <h3 className="course-tile__name">{course.name}</h3>
                </header>

                <CourseStats
                  lessonTime={course.video_lessons_duration}
                  quizzesCount={course.quizzes_count}
                  tasksCount={course.tasks_count}
                />

                <div className="course-tile__bottom">
                  {!isSales && (
                    <>
                      <div className="course-tile__bottom--header">
                        {t('your-progress')}
                      </div>
                      <div className="course-tile__progress-bar">
                        <div
                          className="course-tile__fill"
                          style={{ width: `${totalProgress}%` }}
                        />
                      </div>
                    </>
                  )}
                  <div
                    className={clsx(
                      'course-tile__buttons-container',
                      isSales && 'course-tile__buttons-container--to-right'
                    )}
                  >
                    <Button
                      onClick={() => {
                        setIsVisible(true);
                      }}
                      className="course-tile__button"
                    >
                      {t('details')}
                    </Button>
                    {!isSales && (
                      <>
                        <Link
                          className={clsx(
                            'course-tile__link',
                            disabled && 'course-tile__link--disabled'
                          )}
                          to={{
                            pathname: `/courses/${encipherId(id ?? 0)}/lesson/`,

                            state: { linkedFrom: location.pathname },
                          }}
                        >
                          <Button className="course-tile__button">
                            {totalProgress ? t('continue') : t('begin')}
                          </Button>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};
