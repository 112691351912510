import { useEffect, useState } from 'react';
import SwitchComponent from 'react-switch';
import { TFunctionResult } from 'i18next';
import './switch.scss';

type PropsSwitch = {
  label?: string | boolean | TFunctionResult;
  name?: string;
  errorLabel?: string;
  errors?: Record<string, string>;
  disabled?: boolean;
  value?: boolean;
  onChange?: (param: any) => void;
};

export const Switch: React.FC<PropsSwitch> = ({
  label,
  name,
  errors = {},
  errorLabel = 'To pole jest wymagane',
  disabled = false,
  value = false,
  onChange = () => {},
}: PropsSwitch) => {
  const [checked, setChecked] = useState(value);

  const handleChange = (checkedValue: boolean) => {
    setChecked(checkedValue);
    onChange(checkedValue);
  };

  useEffect(() => {
    if (value !== null) {
      setChecked(value);
    }
  }, [value]);

  return (
    <div className="switch-component">
      <label>
        {label}
        <SwitchComponent
          checked={checked}
          onChange={handleChange}
          onColor="#86d3ff"
          onHandleColor="#2693e6"
          handleDiameter={22}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={20}
          width={46}
          className="react-switch"
          disabled={disabled}
        />
        {name && errors?.[name] && errorLabel}
      </label>
    </div>
  );
};
