import { differenceInCalendarDays } from 'date-fns';
import { format, today } from 'helpers/time';
import { Message } from 'types/api';

export const textToURL = (messageText: string): string => {
  const urlRegex = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
  return messageText.replace(
    urlRegex,
    (url) =>
      `<a class="message-block__link" rel="nofollow noopener noreferrer" target="_blank" href="${url}">${url}</a>`
  );
};

export const createMessageBlocks = (messages: Message[]): Message[][] => {
  const messagesBlocks: Message[][] = [];
  messages.forEach((message, index) => {
    const isFirstMessage = index === 0;
    const lastMessage = messages[index - 1];
    if (
      isFirstMessage ||
      (!isFirstMessage && message.user_id !== lastMessage.user_id)
    ) {
      messagesBlocks.push([]);
    }
    messagesBlocks[messagesBlocks.length - 1].push(message);
  });

  return messagesBlocks;
};

export const getFormattedDate = (
  date: string | Date,
  type?: 'onlyTime' | 'dateWithTime' | 'default' | 'weekDay' | 'onlyDate'
) => {
  const diffCount = differenceInCalendarDays(today(), new Date(date));
  const formatType = diffCount === 0 ? 'onlyTime' : type ?? 'dateWithTime';
  return format(date, formatType);
};

export const getFullName = (name: string, lastName: string) => {
  return `${name} ${lastName}`;
};
