import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ViewWrapper } from 'components/view-wrapper';
import { SetHeaderRenderer, useTabRouter } from './use-tab-router';
import './tab-router.scss';

export type RenderedComponentProps = {
  setHeaderRenderer: SetHeaderRenderer;
};

type TabRouterItem = {
  label: string;
  url: string;
  locationUrl?: string;
  component: React.FC<any>;
  unread?: boolean;
  hidden?: boolean;
  exact?: boolean;
};

type TabRouterProps = {
  items: TabRouterItem[];
  aside?: ReactNode;
  className?: string;
};

export const TabRouter: React.FC<TabRouterProps> = ({
  items,
  className,
  aside,
}) => {
  const { headerRenderer, setHeaderRenderer } = useTabRouter();
  const tabWrapper = useRef<HTMLElement>(null);
  const [runnerWidth, setRunnerWidth] = useState(0);
  const [runnerPosition, setRunnerPosition] = useState(0);
  const { pathname } = useLocation();

  const getActiveTab = useCallback(() => {
    if (!tabWrapper?.current) {
      return;
    }
    const activeElement = tabWrapper.current.querySelector(
      '.tab-router__link--active'
    );
    if (activeElement instanceof HTMLElement) {
      const activeElementWidth = activeElement.getBoundingClientRect().width;
      const activeElementPosition = activeElement.offsetLeft;

      setRunnerWidth(activeElementWidth);
      setRunnerPosition(activeElementPosition);
    }
  }, []);

  useEffect(() => {
    if (pathname) {
      getActiveTab();
    }
  }, [pathname, getActiveTab]);

  useEffect(() => {
    window.addEventListener('resize', getActiveTab);

    return () => window.removeEventListener('resize', getActiveTab);
  }, [getActiveTab]);

  const tabRouterHeader = headerRenderer ? (
    headerRenderer()
  ) : (
    <nav className="tab-router__navigation" ref={tabWrapper}>
      {items
        .filter((item) => !item.hidden)
        .map(({ url, exact = true, label }) => {
          return (
            <NavLink
              className="tab-router__link"
              activeClassName="tab-router__link--active"
              exact={exact}
              key={url}
              to={url}
            >
              {label}
            </NavLink>
          );
        })}
      <div
        className="tab-router__runner"
        style={{ width: `${runnerWidth}px`, left: `${runnerPosition - 1}px` }}
      />
    </nav>
  );
  return (
    <ViewWrapper
      header={tabRouterHeader}
      className={clsx('tab-router', className)}
    >
      <div className={clsx(!headerRenderer && 'tab-router__content')}>
        <main className="tab-router__main-content">
          <Switch>
            {items.map((path) => (
              <Route
                key={path.locationUrl || path.url}
                exact
                path={path.locationUrl || path.url}
                render={() => (
                  <path.component setHeaderRenderer={setHeaderRenderer} />
                )}
              />
            ))}
          </Switch>
        </main>
        {aside && <aside className="tab-router__aside-content">{aside}</aside>}
      </div>
    </ViewWrapper>
  );
};
