import { useRadioClassNames } from './useRadioClassNames';
import './radio-item.scss';

type RadioItemType = {
  name: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: () => void;
  correct?: boolean;
  disabled?: boolean;
};

export const RadioItem: React.FC<RadioItemType> = ({
  name,
  label,
  value,
  checked,
  onChange,
  correct = true,
  disabled = false,
}) => {
  const inputId = `radio-${name}-${value}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: inputValue },
    } = event;
    if (inputValue) {
      onChange();
    }
  };

  const getClassNames = useRadioClassNames({
    checked,
    disabled,
    correct,
  });

  return (
    <div className={getClassNames({ baseClassName: 'radio-item' })}>
      <div
        className={getClassNames({
          baseClassName: 'radio-item__wrapper',
          turnsRedOnError: true,
        })}
      >
        <input
          onChange={handleChange}
          className={getClassNames({
            baseClassName: 'radio-item__input',
            turnsRedOnError: true,
          })}
          type="radio"
          id={inputId}
          name={name}
          checked={checked}
          disabled={disabled}
        />
        <div
          className={getClassNames({
            baseClassName: 'radio-item__fill',
            turnsRedOnError: true,
          })}
        />
      </div>
      <label
        className={getClassNames({
          baseClassName: 'radio-item__label',
          turnsRedOnError: true,
        })}
        htmlFor={inputId}
      >
        {label}
      </label>
    </div>
  );
};
