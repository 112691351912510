import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { AvatarList } from 'components/avatar-list';
import { Card } from 'components/card';
import { Company } from 'components/company';
import { format, formatDurationShort } from 'helpers/time';
import { convertPackageClass, encipherId } from 'helpers/utils';
import { SubscriptionCourse } from 'types/api';
import { ReactComponent as ProfileIcon } from 'assets/icons/avatar-2.svg';
import { ReactComponent as FlagIcon } from 'assets/icons/flag-2-line.svg';
import './course-detail-card.scss';

type CourseDetailCardProps = {
  subscriptionCourse: SubscriptionCourse;
  disabled?: boolean;
};

export const CourseDetailCard: React.FC<CourseDetailCardProps> = ({
  subscriptionCourse,
  disabled = false,
}) => {
  const { course, subscription, company, id } = subscriptionCourse;

  const { companyId } = useParams<{ companyId?: string }>();
  const { isCoach } = useContext(AuthContext);

  const linkPath = `/courses/${encipherId(id)}`;

  return (
    <Card
      className={clsx('course-detail', disabled && 'course-detail--disabled')}
    >
      <Link
        className={clsx(
          'course-detail__link',
          disabled && 'course-detail__link--disabled'
        )}
        to={linkPath}
      >
        <header className="course-detail__header">
          <div className="course-detail__top-bar">
            <span
              className={clsx(
                'course-detail__type',
                subscription &&
                  `course-detail__type--${convertPackageClass(
                    subscription.name
                  )}`
              )}
            >
              {subscription?.name}
            </span>
            <span className="course-detail__dates">
              <time dateTime={subscriptionCourse.start_date}>
                {format(subscriptionCourse.start_date)}
              </time>{' '}
              -{' '}
              <time dateTime={subscriptionCourse.end_date}>
                {format(subscriptionCourse.end_date)}
              </time>
            </span>
          </div>
          <h2 className="course-detail__name">{course.name}</h2>
        </header>

        <div
          className={clsx(
            'course-detail__progressbar',
            isCoach && !companyId && 'course-detail__progressbar--coach'
          )}
        >
          <div className="course-detail__wrapper">
            <div
              className="course-detail__fill"
              style={{ width: `${subscriptionCourse.total_progress}%` }}
            />
          </div>
          <div
            className="course-detail__shadow"
            style={{ width: `${subscriptionCourse.total_progress}%` }}
          />
        </div>

        <section className="course-detail__statistics ">
          <section
            className={clsx(
              'course-detail__info',
              isCoach && !companyId && 'course-detail__info--coach'
            )}
          >
            <div className="course-detail__modules-count">
              <div className="course-detail__icon-wrapper">
                <FlagIcon className="course-detail__icon" />
              </div>
              <div>
                <Trans
                  i18nKey="modulesCount"
                  count={course.course_modules?.length}
                >
                  <span className="course-detail__gray">modules</span>
                </Trans>
              </div>
            </div>
            <div className="course-detail__modules-count">
              <div className="course-detail__icon-wrapper">
                <ProfileIcon className="course-detail__profile-icon" />
              </div>
              <div>
                <Trans
                  i18nKey="participants-count"
                  count={subscriptionCourse.participants_count}
                  values={{ maxCount: subscriptionCourse.slots }}
                >
                  <span className="course-detail__gray">/ 50 participants</span>
                </Trans>
              </div>
            </div>
          </section>

          {!!companyId && (
            <section className="course-detail__module-video-details">
              <div className="course-detail__video-details">
                {formatDurationShort(course.video_lessons_duration, [
                  'hours',
                  'minutes',
                ]) || '0 min'}{' '}
                <span className="course-link__span">video</span>
              </div>
              <div className="course-detail__video-details">
                <Trans i18nKey="tasks" count={course.tasks_count}>
                  <span className="course-detail__gray course-detail__gray--video">
                    tasks
                  </span>
                </Trans>
              </div>
              <div className="course-detail__video-details">
                <Trans i18nKey="quizzes" count={course.quizzes_count}>
                  <span className="course-detail__gray course-detail__gray--video">
                    quizzes
                  </span>
                </Trans>
              </div>
            </section>
          )}
        </section>
      </Link>

      <footer className="course-detail__footer">
        {isCoach && (
          <Company
            className="course-detail__company"
            avatarSize="small"
            company={company}
          />
        )}
        <div
          className={clsx(
            'course-detail__profiles',
            !isCoach && 'course-detail__profiles--hr-view'
          )}
        >
          <AvatarList
            avatarList={course.coaches}
            className="course-detail__avatar-list"
          />
        </div>
      </footer>

      <Link
        className={clsx(
          'course-detail__bottom-link',
          disabled && 'course-detail__bottom-link--disabled'
        )}
        to={linkPath}
      >
        <div className="course-detail__link-content" />
      </Link>
    </Card>
  );
};
