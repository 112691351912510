import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isBefore, isSameDay } from 'date-fns';
import {
  ChosenTimeslot,
  SelectedSlot,
} from 'components/coaching-session-modal/select-date-step';
import { StyledScrolls } from 'components/styled-scrolls';
import { isCoachAvailable } from 'helpers/coaching-session-calendar';
import { StateSetter } from 'helpers/types';
import { Availability, SortedTimeslot } from 'types/api';
import { SingleDay } from './single-day';
import './weekly-calendar.scss';

type WeeklyCalendarProps = {
  availability?: Availability[];
  coachTimeSlots?: SortedTimeslot[];
  maxDate?: Date;
  selectedSlot: SelectedSlot;
  chosenTimeSlot?: ChosenTimeslot;
  setSelectedSlot: StateSetter<SelectedSlot>;
  setChosenTimeslot?: StateSetter<ChosenTimeslot>;
  setErrorMessage?: (message: string) => void;
  className?: string;
};

export const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({
  availability,
  coachTimeSlots,
  selectedSlot,
  setSelectedSlot,
  setErrorMessage,
  className,
  maxDate,
  setChosenTimeslot,
  chosenTimeSlot,
}) => {
  const { t } = useTranslation();
  const isCoachAvailableInWeek = isCoachAvailable(availability);

  return (
    <>
      {isCoachAvailableInWeek && availability && (
        <StyledScrolls className={clsx('weekly-calendar', className)}>
          {availability
            .filter((day) => (maxDate ? new Date(day.date) <= maxDate : true))
            .map((day) => (
              <SingleDay
                key={day.date}
                date={new Date(day.date)}
                availability={availability}
                setSelectedSlot={setSelectedSlot}
                selectedSlot={selectedSlot}
                setErrorMessage={setErrorMessage}
              />
            ))}
        </StyledScrolls>
      )}
      {!isCoachAvailableInWeek && !availability && !coachTimeSlots && (
        <div className="weekly-calendar-unavailable-info">
          <h3>{t('coach-unavailable-in-week')}</h3>
        </div>
      )}
      {coachTimeSlots && setChosenTimeslot ? (
        <StyledScrolls className={clsx('weekly-calendar', className)}>
          {coachTimeSlots
            .filter((day) =>
              maxDate
                ? isBefore(new Date(day.date), maxDate) ||
                  isSameDay(new Date(day.date), maxDate)
                : true
            )
            .map((day) => (
              <SingleDay
                key={day.date}
                date={new Date(day.date)}
                coachTimeSlots={day}
                setSelectedSlot={setSelectedSlot}
                setChosenTimeslot={setChosenTimeslot}
                selectedSlot={selectedSlot}
                setErrorMessage={setErrorMessage}
                chosenTimeSlot={chosenTimeSlot}
              />
            ))}
        </StyledScrolls>
      ) : (
        <div className="weekly-calendar-unavailable-info">
          <h3>{t('coach-unavailable-in-week')}</h3>
        </div>
      )}
    </>
  );
};
