import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getFinishedCourses } from 'services/courses';

export const useCertificates = () => {
  const { t } = useTranslation();
  const { data: certificates, ...rest } = useQuery(
    QueryKeys.CERTIFICATES,
    getFinishedCourses,
    {
      onError: () => toast.error(t('certificates-error')),
    }
  );

  return { certificates, ...rest };
};
