import { useTranslation } from 'react-i18next';
import { Switch } from 'components/form/switch';
import { shouldDarkModeBeOn } from 'helpers/theme';
import { useTheme } from 'hooks/use-theme';
import './theme-component.scss';

export const ThemeComponent = () => {
  const { t } = useTranslation();
  const {
    isDarkModeOn,
    isDarkModeAutoOn,
    setLocalStorageDarkModeOn,
    setLocalStorageDarkModeAutoOn,
  } = useTheme();

  const handleChange = () => {
    setLocalStorageDarkModeAutoOn();
    if (new Date().getHours() >= 20 || new Date().getHours() < 7) {
      shouldDarkModeBeOn(true);
    } else {
      shouldDarkModeBeOn(false);
    }
  };

  return (
    <div className="theme-component">
      <h3 className="theme-component__header">{t('dark-mode')}</h3>
      <div className="theme-component__button-group">
        <div className="theme-component__switch">
          {t('dark-mode-switch')}
          <Switch
            value={isDarkModeAutoOn ? false : isDarkModeOn}
            disabled={isDarkModeAutoOn}
            onChange={() => setLocalStorageDarkModeOn()}
          />
        </div>

        <div className="theme-component__switch">
          {t('dark-mode-auto')}
          <Switch
            value={isDarkModeAutoOn}
            disabled={isDarkModeAutoOn ? false : isDarkModeOn}
            onChange={() => handleChange()}
          />
        </div>
      </div>
    </div>
  );
};
