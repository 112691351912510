import { addDays } from 'date-fns';

export const setLocalStorageItem = (
  key: string,
  value: string,
  days: number
): void => {
  const item = {
    value,
    expiry: addDays(Date.now(), days).getTime(),
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItem = (key: string) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  return JSON.parse(itemString);
};

export const deleteLocalStorageItem = (key: string): void => {
  localStorage.removeItem(key);
};
