import { Matcher } from 'react-day-picker';
import {
  ELEMENT,
  FACTOR,
  MIN_LENGTH,
  RADIX,
  ROT13_BASE,
} from 'constants/encipher';
import i18n from 'i18n';
import { format } from 'helpers/time';
import {
  CoachTasksData,
  CourseModules,
  ModuleDetails,
  ReportSelectsData,
  SelectOption,
  SubscriptionSelectOptions,
  Task,
} from 'types/api';

export const getFileExtension = (fileName: string): string => {
  return fileName && fileName.includes('.')
    ? (fileName.split('.').pop() as string)
    : '';
};

export const convertPackageClass = (packageName: string) => {
  return packageName.toLowerCase().replace(/\s/g, '-');
};

export const createCourseSelectOptions = (
  subscriptions: (SubscriptionSelectOptions | ReportSelectsData)[]
) => {
  return subscriptions.map(({ id, start_date, end_date, course: { name } }) => {
    const startDate = format(start_date);
    const endDate = format(end_date);
    return {
      value: id.toString(),
      label: `${name} (${startDate} - ${endDate})`,
    };
  });
};

export const createSelectOptions = (
  entries: SelectOption[] = [],
  firstValue?: SelectOption
) => {
  const selectOptions = entries.map(({ id, name }) => ({
    value: id.toString(),
    label: name,
  }));
  if (firstValue) {
    selectOptions.unshift({
      label: firstValue.name,
      value: firstValue.id.toString(),
    });
  }
  return selectOptions;
};

export const createSelectOptionsFromProfiles = <
  T extends { id: number; first_name: string; last_name: string }
>(
  profiles: T[]
) => {
  return profiles.map(({ id, first_name, last_name }) => ({
    value: id.toString(),
    label: `${first_name} ${last_name}`,
  }));
};

export const validateFileExtension = (file: File, allowedExtensions: string) =>
  allowedExtensions.includes(getFileExtension(file.name));

export const validateFilesExtensions = (
  fileList: FileList,
  allowedExtensions: string
) =>
  Array.from(fileList).every((file) =>
    validateFileExtension(file, allowedExtensions)
  );

export const sum = (a: number, b: number) => a + b;

export const percentage = (partialValue: number, totalValue: number) =>
  (partialValue / totalValue) * 100;

export const areRecordsDifferent = <T extends object, K extends keyof T>(
  a: T,
  b: T
) => Object.keys(b).some((key) => b[key as K] !== a[key as K]);

export const getActiveModule = (
  contentId: number | string,
  modules: ModuleDetails[]
) => {
  const currentModule =
    modules.find((module) =>
      module.lessons.some((lesson) => lesson.slug === contentId)
    ) || modules.find((module) => module.quiz?.id === +contentId);
  if (+contentId !== -1 && currentModule) {
    return currentModule;
  }

  const unfinishedModules = modules.filter(
    ({ module_progress }) => module_progress < 100
  );
  if (unfinishedModules.length) {
    return unfinishedModules[0];
  }

  return modules[0];
};

export const getRangeNumbers = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, index) => index + start);
};

export const isObjectWithFalsyValues = (object: Record<string, any>) => {
  return Object.values(object)
    .map((values) => !!values)
    .includes(false);
};

export const getFullName = <
  T extends { first_name: string; last_name: string }
>(
  person: T | null
) => (person ? `${person.first_name} ${person.last_name}` : '');

export const compareStrings = (a?: string, b?: string, order?: number) => {
  if (a && b) {
    return (
      a.localeCompare(b, i18n.language, { sensitivity: 'base' }) * (order || 1)
    );
  }
  if (b) {
    return 1;
  }
  return -1;
};

export const roundPercentageNumber = (persent: number) => {
  return Math.round(persent);
};

export const createDaysMatcher = (minDate?: Date, maxDate?: Date) => {
  const disabledDays: Matcher[] = [];
  if (minDate) {
    disabledDays.push({ before: minDate });
  }
  if (maxDate) {
    disabledDays.push({ after: maxDate });
  }
  return disabledDays;
};

export const getUserInitials = <
  T extends { first_name?: string | null; last_name?: string | null }
>(
  user: T | null
) =>
  user && user.first_name && user.last_name
    ? `${user.first_name[0]}${user.last_name[0]}`
    : null;

export const getTaskName = (task: Task) => {
  return task.name?.split('/').at(-1)?.trim();
};

export const findSelectedModules = (
  coursesList: CourseModules[] | CoachTasksData[],
  coursesIds: string[]
) => {
  return coursesList
    .filter((course) => coursesIds.includes(String(course.id)))
    .flatMap((course) => course.course_modules);
};

export const makeQueryValue = (values: string[]) => {
  return values.join(',');
};

export const getPathDestination = (path: string) => {
  const splitedPath = path.split('/');
  return splitedPath[splitedPath.length - 1].trim();
};

export const reverseString = (str: string) => {
  return str.split('').reverse().join('');
};

export const rot13 = (message: string) => {
  return message.replace(/[a-z]/gi, (letter) =>
    String.fromCharCode(
      letter.charCodeAt(0) +
        (letter.toLowerCase() <= 'm' ? ROT13_BASE : -ROT13_BASE)
    )
  );
};

export const encipherId = (id: number | string) => {
  if (!+id) {
    return '';
  }
  const cipheredId = rot13((+id * FACTOR + ELEMENT).toString(RADIX)).padStart(
    MIN_LENGTH,
    '0'
  );

  return reverseString(cipheredId);
};

export const decipherId = (cipher: string = '') => {
  if (!cipher.trim()) {
    return '';
  }
  const reversedCipher = reverseString(cipher);

  return (
    (parseInt(rot13(reversedCipher), RADIX) - ELEMENT) /
    FACTOR
  ).toString();
};

export const getPercentageValue = (value1: number, value2: number) => {
  if (value1 <= 0 || value2 <= 0) {
    return 0;
  }
  return Math.round((value1 * 100) / value2);
};
