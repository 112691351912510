import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card } from 'components/card';
import { Company } from 'components/company';
import { sum } from 'helpers/utils';
import { CompanyDetails } from 'types/api';
import { ReactComponent as IconAdvertise } from 'assets/icons/advertise-blue-big.svg';
import './meetings-card.scss';

type MeetingsCardProps = {
  type?: 'faceToFace' | 'group';
  status?: string;
  testId?: string;
  companies: CompanyDetails[];
};

export const MeetingsCard: React.FC<MeetingsCardProps> = ({
  type = 'faceToFace',
  status,
  companies,
  testId,
}) => {
  const { t } = useTranslation();

  const checkSingleCompanyMeetingsCount = useCallback(
    (company: CompanyDetails) => {
      const companyMeetingsCount =
        type === 'faceToFace'
          ? company.stats?.faceToFaceMeet
          : company.stats?.groupMeet;

      return companyMeetingsCount ?? 0;
    },
    [type]
  );

  const totalMeetingsCount = useMemo(
    () => companies.map(checkSingleCompanyMeetingsCount).reduce(sum, 0),
    [companies, checkSingleCompanyMeetingsCount]
  );

  const meetingsTypeDescription =
    type === 'faceToFace' ? (
      <Trans i18nKey="coach-meets" count={totalMeetingsCount}>
        {totalMeetingsCount}
        <span className="meetings-card__type">meets 1:1</span>
      </Trans>
    ) : (
      <Trans i18nKey="group-meets" count={totalMeetingsCount}>
        {totalMeetingsCount}
        <span className="meetings-card__type">group meets</span>
      </Trans>
    );

  return (
    <div className="meetings-card" data-testid={testId}>
      <Card className="meetings-card__card">
        <header className="meetings-card__header">
          <IconAdvertise className="meetings-card__icon" />

          <div className="meetings-card__description">
            {meetingsTypeDescription}
          </div>

          <div className="meetings-card__status">{status || t('done')}</div>
        </header>

        <div className="meetings-card__line" />

        <ul className="meetings-card__list">
          {companies.map((company) => (
            <li
              className="meetings-card__item"
              key={company.id}
              data-testid="company-card"
            >
              <Company avatarSize="medium" company={company} />

              <div className="meetings-card__count">
                {checkSingleCompanyMeetingsCount(company)}
              </div>
            </li>
          ))}
        </ul>
      </Card>
    </div>
  );
};
