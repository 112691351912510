import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { signUpSelectors } from 'constants/selectors/authentication/register';
import { signUp } from 'services/user';
import { Button } from 'components/button';
import { Input } from 'components/form';
import { Logo } from 'components/logo';
import {
  EMAIL as EMAIL_REGEX,
  FIRST_NAME as FIRST_NAME_REGEX,
  LAST_NAME as LAST_NAME_REGEX,
  PASSWORD as PASSWORD_REGEX,
  POSTION_AND_TEAM,
} from 'helpers/validate';
import './authentication.scss';

export enum RegisterInputNames {
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  POSITION = 'position',
  PASSWORD = 'password',
  REPEAT_PASSWORD = 'repeat_password',
}

const FinishRegister: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        'authentication',
        'authentication--top',
        signUpSelectors.feedbackClass
      )}
    >
      <Logo className="authentication__logo" />
      <span className="authentication__register-success">
        {t('registration-success')}
      </span>
      <span className="authentication__register-success">
        {t('registration-mail')}
      </span>
    </div>
  );
};

export const Register: React.FC = () => {
  const [message, setMessage] = useState('');
  const [isRegisterFinished, setIsRegisterFinished] = useState(false);
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();
  const { t } = useTranslation();
  const password = watch(RegisterInputNames.PASSWORD);

  const submit = async (dataForm: Record<RegisterInputNames, string>) => {
    setLoader(true);
    try {
      await signUp(dataForm);
      setIsRegisterFinished(true);
      setMessage('');
    } catch (error) {
      const axiosError = error as AxiosError;
      const serverMessage = axiosError.response
        ? axiosError.response.data.message
        : axiosError.message;
      if (serverMessage === 'User Already exists. Please login') {
        setMessage(t('user-exists'));
      } else {
        setMessage(t('undefined-error'));
      }
    }
    setLoader(false);
  };

  if (isRegisterFinished) {
    return <FinishRegister />;
  }
  const registerValue = (regex: RegExp, registerMessage: string) =>
    register({
      required: true,
      minLength: { value: 2, message: `${t('min-2-chars')}` },
      maxLength: { value: 35, message: `${t('max-35-chars')}` },
      pattern: {
        value: regex,
        message: registerMessage,
      },
    });
  return (
    <div className="authentication">
      <Logo className="authentication__logo" />
      <span className="authentication__title">{t('registration')}</span>
      <form onSubmit={handleSubmit(submit)} className="authentication__form">
        {message !== '' && message && (
          <div
            className={clsx(
              'authentication__error-message',
              signUpSelectors.feedbackClass
            )}
          >
            {message}
          </div>
        )}
        <Input
          inputClassName={signUpSelectors.emailInputClass}
          errorClassName={signUpSelectors.emailErrorClass}
          autoComplete="username"
          label="Login"
          name={RegisterInputNames.EMAIL}
          placeholder="E-mail"
          errors={errors}
          register={register({
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: `${t('invalid-email')}`,
            },
          })}
        />
        <Input
          inputClassName={signUpSelectors.firstNameInputClass}
          label={t('name')}
          name={RegisterInputNames.FIRST_NAME}
          type="text"
          placeholder={t('enter-name')}
          errors={errors}
          register={registerValue(FIRST_NAME_REGEX, t('name-only-chars'))}
        />
        <Input
          inputClassName={signUpSelectors.lastNameInputClass}
          label={t('last-name')}
          name={RegisterInputNames.LAST_NAME}
          type="text"
          placeholder={t('enter-last-name')}
          errors={errors}
          register={registerValue(LAST_NAME_REGEX, t('last-name-only-chars'))}
        />
        <Input
          className={signUpSelectors.positionInputClass}
          label={t('position')}
          name={RegisterInputNames.POSITION}
          type="text"
          placeholder={t('enter-position')}
          errors={errors}
          register={registerValue(POSTION_AND_TEAM, t('position-only-chars'))}
        />
        <Input
          className={signUpSelectors.passwordInputClass}
          errorClassName={signUpSelectors.passwordErrorClass}
          togglerClassName={signUpSelectors.togglerClass}
          autoComplete="new-password"
          label={t('password')}
          name={RegisterInputNames.PASSWORD}
          type="password"
          placeholder={t('min-length', { length: 8 })}
          errors={errors}
          register={registerValue(PASSWORD_REGEX, t('password-invalid'))}
        />
        <Input
          className={signUpSelectors.repeatPasswordInputClass}
          errorClassName={signUpSelectors.repeatPasswordErrorClass}
          autoComplete="new-password"
          label={t('password-rewrite')}
          name={RegisterInputNames.REPEAT_PASSWORD}
          type="password"
          placeholder={t('password-rewrite')}
          errors={errors}
          register={register({
            required: true,
            validate: (value) =>
              value === password || `${t('passwords-differ')}`,
          })}
        />
        <Button
          className={clsx(
            'authentication__submit',
            signUpSelectors.submitClass
          )}
          type="submit"
          variant="primary"
          loading={loader}
        >
          {t('register')}
        </Button>
      </form>
      <Link to="/" className="authentication__login-link">
        <Trans i18nKey="have-account">
          Have account? <span className="authentication__text-blue">Login</span>
        </Trans>
      </Link>
    </div>
  );
};

export default Register;
