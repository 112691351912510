import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import {
  addTask,
  deleteTask,
  editTask,
  getTasks,
  updateTask,
} from 'services/tasks';
import { TaskFormData } from 'components/task-modal/task-form';
import { Task, TaskData } from 'types/api';
import { ResourceTypes } from 'types/enums';

export type ApiParameters = {
  course_id: string[] | null;
  course_module_id: string[] | null;
  participant_id?: string[] | null;
  finished?: boolean | null;
  page: string;
  per_page?: number;
  status?: string | null;
};

export interface TaskEditData {
  task: { task: Omit<TaskFormData, 'course_id'> };
  id: number;
}

export interface UpdateTasksData {
  task: Task;
  ids: number[];
}

export const useTasks = (apiParameters: ApiParameters) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const apiKey = [QueryKeys.TASKS, apiParameters];

  const { data, ...restTasksQuery } = useQuery(
    apiKey,
    () => getTasks(apiParameters),
    { onError: () => toast.error(t('tasks-error')) }
  );

  const { mutate: removeTask, ...restRemoveTaskMutation } = useMutation(
    (id: number) => deleteTask(id),
    {
      onError: () => {
        toast.error(t('remove-task-error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.TASKS);
      },
    }
  );

  const { mutate: addNewTask, ...restAddNewTaskMutation } = useMutation(
    (task: TaskData) => addTask(task),
    {
      onError: () => {
        toast.error(t('create-task-error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.TASKS);
      },
    }
  );

  const { mutate: editTaskById, ...restEditTaskMutation } = useMutation(
    ({ task, id }: TaskEditData) => editTask(task, id),
    {
      onError: () => {
        toast.error(t('edit-task-error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.TASKS);
      },
    }
  );

  const { mutate: updateTasks, ...restUpdateTasksMutation } = useMutation(
    ({ task, ids }: UpdateTasksData) => updateTask(task, ids),
    {
      onError: () => {
        toast.error(t('assign-error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.TASKS);
      },
    }
  );
  const addTaskSubmit = (formData: TaskFormData) => {
    const dataToSend = {
      task: {
        ...formData,
        resource_type: ResourceTypes.COURSE_MODULE,
      },
    };

    addNewTask(dataToSend);
  };

  const editTaskSubmit = (formData: TaskFormData, taskId: number) => {
    const dataToSend = {
      task: formData,
    };
    editTaskById({ task: dataToSend, id: taskId });
  };

  return {
    tasks: data?.tasks || [],
    tasksMeta: data?.meta,
    removeTask,
    addTaskSubmit,
    updateTasks,
    editTaskSubmit,
    restTasksQuery,
    restAddNewTaskMutation,
    restEditTaskMutation,
    restUpdateTasksMutation,
    restRemoveTaskMutation,
  };
};
