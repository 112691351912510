import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatContext } from 'contexts';
import { Collapse } from 'components/collapse';
import { DropdownButton } from 'components/dropdown-button';
import { DropdownMenuItem } from 'components/dropdown-menu-item';
import { CourseCollapse } from 'components/participants-table/participant-table-row/course-collapse';
import { Person } from 'components/person';
import { ParticipantProgress } from 'types/api';
import { ReactComponent as KebabIcon } from 'assets/icons/kebab.svg';
import './participant-collapse.scss';

type ParticipantCollapseProps = {
  participant: ParticipantProgress;
};

export const ParticipantCollapse: React.FC<ParticipantCollapseProps> = ({
  participant,
}) => {
  const { t } = useTranslation();
  const { openConversation } = useContext(ChatContext);

  const onChat = () => {
    openConversation(participant.id);
  };

  return (
    <div className="participant-collapse">
      <Person
        size={48}
        type="participant"
        profile={participant}
        layout="large"
      />
      <div className="participant-collapse__dot-menu">
        <DropdownButton
          buttonContent={<KebabIcon />}
          menuClassName="participant-collapse__menu"
        >
          <DropdownMenuItem
            onClick={onChat}
            wrapperClassName="participant-collapse__menu-item"
          >
            {t('chat-with-participant')}
          </DropdownMenuItem>
        </DropdownButton>
      </div>
      <div className="participant-collapse__collapse">
        <Collapse
          isAutoScroll={false}
          className="participant-collapse__collapse-children"
          arrowClassName="participant-collapse__collapse-arrow"
          wrapperClassName="participant-collapse__collapse-wrapper"
          hiddenContent={
            <div className="participant-collapse__courses-wrapper">
              {participant.courses.map((course) => (
                <CourseCollapse
                  key={course.id}
                  course={course}
                  participantId={participant.id}
                />
              ))}
            </div>
          }
          isReverseOrder
        >
          <p className="participant-collapse__show-progress">
            {t('show-progress')}
          </p>
        </Collapse>
      </div>
    </div>
  );
};
