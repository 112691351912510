import { Loader } from 'components/loader';
import { QuizContainer } from 'components/quiz/quiz-container';
import { QuizQuestions } from 'components/quiz/quiz-questions';
import { useSupervisorQuiz } from 'hooks/use-supervisor';
import { CurrentModuleInfo } from 'types/quiz';

type SupervisorQuizProps = {
  currentModuleInfo: Omit<CurrentModuleInfo, 'isLast'>;
};

export const SupervisorQuiz: React.FC<SupervisorQuizProps> = ({
  currentModuleInfo,
}) => {
  const { isLoading, quiz } = useSupervisorQuiz();
  const { name, order, coach } = currentModuleInfo;

  if (isLoading) {
    <Loader fullHeight />;
  }

  return quiz ? (
    <QuizContainer moduleName={name} moduleOrder={order} coach={coach}>
      <QuizQuestions questions={quiz.questions} />
    </QuizContainer>
  ) : null;
};
