import { getLocalStorageItem } from 'helpers/local-storage';

export const getAuthToken = (): { value: string; expiry: number } | null =>
  getLocalStorageItem('skillsarena-auth');

export const checkTokenExpiry = (expiry: number): boolean => {
  const now = new Date();
  if (now.getTime() > expiry) {
    localStorage.removeItem('skillsarena-auth');
    return true;
  }
  return false;
};
