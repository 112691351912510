import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { resetPasswordSelectors } from 'constants/selectors/authentication/reset-password';
import { resetPassword as resetPasswordRequest } from 'services/user';
import { Button } from 'components/button';
import { Input } from 'components/form';
import { Logo } from 'components/logo';
import { EMAIL, PASSWORD } from 'helpers/validate';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { ResetPasswordData } from 'types/api';
import { QueryParamNameTypes } from 'types/enums';
import './authentication.scss';

type SubmitData = {
  confirm_password: string;
  password: string;
};

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const { get } = useQueryParameters();
  const token = get(QueryParamNameTypes.PASSWORD_TOKEN) ?? '';

  const { t } = useTranslation();
  const { register, handleSubmit, watch, errors } = useForm();

  const { mutate: resetPassword, isLoading } = useMutation(
    resetPasswordRequest,
    {
      onSuccess: () => {
        history.replace('/login');
        toast.success(t('reset-password-success'));
      },
      onError: () => {
        toast.error(t('password-token-expired'));
      },
    }
  );

  const passwordValue = watch('password');

  const submit = ({ password, confirm_password }: SubmitData) => {
    if (token.length) {
      const resetData: ResetPasswordData = {
        password,
        confirm_password,
        password_token: token,
      };
      resetPassword(resetData);
    }
  };

  return (
    <div className="authentication">
      <Logo className="authentication__logo" />
      <span className="authentication__title">{t('reset-password')}</span>
      <form onSubmit={handleSubmit(submit)} className="authentication__form">
        {token ? (
          <>
            <Input
              autoComplete="new-password"
              label={t('password')}
              name="password"
              type="password"
              placeholder={t('min-length', { length: 8 })}
              errors={errors}
              register={register({
                required: true,
                pattern: {
                  value: PASSWORD,
                  message: `${t('password-invalid')}`,
                },
              })}
            />
            <Input
              autoComplete="new-password"
              label={t('password-rewrite')}
              name="confirm_password"
              type="password"
              placeholder={t('password-rewrite')}
              errors={errors}
              register={register({
                required: true,
                validate: (value) =>
                  value === passwordValue || `${t('passwords-differ')}`,
              })}
            />
          </>
        ) : (
          <Input
            autoComplete="username"
            label={t('e-mail')}
            name="email"
            placeholder={t('e-mail')}
            errors={errors}
            register={register({ required: true, pattern: EMAIL })}
            inputClassName={resetPasswordSelectors.emailInputClass}
            errorClassName={resetPasswordSelectors.emailErrorClass}
          />
        )}
        <div className="authentication__button-wrapper">
          <Button
            type="submit"
            variant="primary"
            className={clsx(
              'authentication__submit',
              `${resetPasswordSelectors.submitClass}`
            )}
            loading={isLoading}
          >
            {t('confirm')}
          </Button>
          <Link
            to="/"
            className="button button--secondary authentication__cancel"
          >
            {t('cancel')}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
