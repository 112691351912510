import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getFaqs } from 'services/faqs';
import { Faq } from 'types/api';

const FAQ_LENGTH = 8;

export const useFaq = () => {
  const [faqShowedCount, setFaqShowedCount] = useState(FAQ_LENGTH);

  const { t } = useTranslation();

  const { data: faq, ...rest } = useQuery(QueryKeys.FAQ, getFaqs, {
    onError: () => toast.error(t('FAQ-error')),
  });

  const handleViewMore = () => {
    setFaqShowedCount(faqShowedCount + FAQ_LENGTH);
  };

  const filteredFaq = (faq || []).filter(
    (_item: Faq, index: number) => index < faqShowedCount
  );

  const isMoreFaqAvaliable = faq ? faq.length > faqShowedCount : false;

  return { faq: filteredFaq, ...rest, handleViewMore, isMoreFaqAvaliable };
};
