import axios from 'helpers/axios';
import { Client, Participant } from 'types/api';

export async function getCompany(companyId: number | string) {
  const { data } = await axios.get(`v1/companies/${companyId}`);
  return data;
}

export async function getCompanies() {
  const { data } = await axios.get<Client[]>(`v1/companies`);
  return data;
}

export async function getParticipantWithCompanyId(
  companyId: number,
  courseId?: number
) {
  const { data } = await axios.get<Participant[]>(
    `v1/companies/${companyId}/participants`,
    { params: { course_id: courseId ?? null } }
  );
  return data;
}
