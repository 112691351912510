import { DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { GroupBase, Props } from 'react-select';
import clsx from 'clsx';
import { Collapse } from 'components/collapse';
import { CustomOption } from './custom-option';
import './dropdown-select.scss';

export type OptionType = {
  value: string;
  label: string;
};

type DropdownSelectProps = {
  headingText: string;
  isMulti: boolean;
  errors?: DeepMap<any, FieldError>;
  name: string;
};

export const DropdownSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & DropdownSelectProps
) => {
  const { t } = useTranslation();
  const { isMulti, errors, name, headingText, value, onChange, options } =
    props;

  const labelToDisplay =
    ((!value || isMulti) && headingText) ||
    (Array.isArray(value) ? value : [value]).map(({ label }) => label);

  return (
    <div className="dropdown-select">
      <Collapse
        className={clsx(
          'dropdown-select__collapse',
          isMulti && 'dropdown-select__collapse--multi'
        )}
        isAutoScroll={false}
        borderBottom
        shouldCloseOnBlur
        closeOnSelection={!isMulti}
        hiddenContent={
          <Select
            {...props}
            options={options}
            value={value}
            onChange={onChange}
            classNamePrefix="dropdown-select"
            menuIsOpen
            isMulti={isMulti}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            noOptionsMessage={() => t('no-options')}
            components={{
              Option: (optionProps) => <CustomOption {...optionProps} />,
            }}
          />
        }
      >
        {labelToDisplay}
      </Collapse>
      {errors && errors[name] && (
        <div className="dropdown-select__error">{errors[name].message}</div>
      )}
    </div>
  );
};
