import React, { ReactChild } from 'react';
import { CookieBanner } from 'components/cookie-banner';

const DefaultLayout = React.lazy(() => import('./default'));
const WideLayout = React.lazy(() => import('./wide'));

export enum LayoutTypes {
  DEFAULT = 'default',
  WIDE = 'wide',
}

type DefaultLayoutProps = {
  menu: (JSX.Element | null)[];
  layout: LayoutTypes;
  children: ReactChild;
};

const Layout: React.FC<DefaultLayoutProps> = ({ layout, menu, children }) => (
  <>
    {layout === LayoutTypes.WIDE ? (
      <WideLayout>{children}</WideLayout>
    ) : (
      <DefaultLayout menu={menu}>{children}</DefaultLayout>
    )}
    <CookieBanner />
  </>
);

export default Layout;
