import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Collapse } from 'components/collapse';
import { Loader } from 'components/loader';
import { useFaq } from 'hooks/use-faq';
import { Faq } from 'types/api';
import './faq.scss';

const FAQScreen: React.FC = () => {
  const { faq, isLoading, handleViewMore, isMoreFaqAvaliable } = useFaq();
  const { t } = useTranslation();
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="faq">
      <div className="faq__header">FAQ</div>

      <Card className="faq__card">
        {faq.length > 0 ? (
          <>
            {faq.map((faqElement: Faq) => (
              <Collapse
                key={faqElement.id}
                borderBottom
                blueDot
                hiddenContent={
                  <div className="faq__answer">{faqElement.answer}</div>
                }
              >
                <div className="faq__question">{faqElement.question}</div>
              </Collapse>
            ))}
            {isMoreFaqAvaliable && (
              <Button
                variant="secondary"
                className="faq__show-more"
                onClick={handleViewMore}
              >
                {t('show-more')}
              </Button>
            )}
          </>
        ) : (
          <div className="faq__error-text">{t('FAQ-unavailable')}</div>
        )}
      </Card>
    </div>
  );
};

export default FAQScreen;
