import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as FlagIcon } from 'assets/icons/flag-2-line.svg';
import './progress-tile.scss';

type DashboardModuleType = {
  title: string;
  courseName: string;
  description?: string;
  progress?: number;
  buttonText: string;
  buttonLink: string;
  moduleId?: number | null;
  openTask?: number;
  courseId?: number | null;
};

export const ProgressTile: React.FC<DashboardModuleType> = ({
  title,
  courseName,
  description,
  progress,
  buttonText,
  buttonLink,
  moduleId,
  openTask,
  courseId,
}) => {
  const { t } = useTranslation();
  const searchLink = openTask
    ? `task-open=${openTask}${courseId ? `&course=${courseId}` : ''}`
    : '';

  return (
    <div className="progress-tile">
      <div className="progress-tile__header">
        <span className="progress-tile__header--title">{title}</span>
      </div>
      <div className="progress-tile__module-details">
        {moduleId && (
          <FlagIcon className="progress-tile__module-details--flag-icon" />
        )}
        <span className="progress-tile__module-details--text">
          {moduleId && (
            <Trans i18nKey="module-number" values={{ number: moduleId }}>
              <span className="progress-tile__module-details--text--gray">
                module
              </span>
            </Trans>
          )}
          {courseName}
        </span>
      </div>
      <div className="progress-tile__description">{description}</div>
      {Number(progress) >= 0 && (
        <div className="progress-tile__progress-bar">
          <div>{t('your-progress')}</div>
          <div className="progress-tile__progress-bar--wrapper">
            <div
              className="progress-tile__progress-bar--fill"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      )}

      <div className="progress-tile__button-wrapper">
        <Link
          to={{
            pathname: buttonLink,
            search: searchLink,
          }}
        >
          <button className="progress-tile__button-wrapper--button">
            {buttonText}
          </button>
        </Link>
      </div>
    </div>
  );
};
