export enum QueryKeys {
  USER = 'user',
  BUSINESS_HOURS = 'business-hours',
  TASKS = 'tasks',
  COACH_HR_QUIZ = 'coach-hr-quiz',
  COMPANIES = 'companies',
  COMPANY = 'company',
  PARTICIPANTS_BY_COMPANY_ID = 'participants-by-company-id',
  USER_COURSES = 'user-courses',
  SUBSCRIPTION_COURSES = 'subcription-courses',
  REPORTS = 'reports',
  PARTICIPANT_PROGRESS = 'participants-progress',
  COURSE_INTRO = 'course-intro',
  COACH_DASHBOARD = 'coach-dashboard',
  FAQ = 'faq',
  COURSE_PARTICIPANTS = 'course-participants',
  COURSE_LESSON = 'course-lesson',
  MODULES_PREVIEW = 'modules-preview',
  HEADER_DETAILS = 'header-details',
  COURSE_COACHES = 'course-coaches',
  CERTIFICATES = 'certificates',
  COACH_SESSIONS = 'coach-sessions',
  COACHING_SESSIONS = 'coaching_sessions',
  ONLINE_STATUS = 'status',
  COACH_AVAILABILITY = 'coach-availability',
  COACH_TIMESLOTS = 'coach-timeslots',
  HR_DASHBOARD = 'hr-dashboard',
  DASHBOARD_COURSE_DETAILS = 'dashboard-course-details',
  COMPANY_DETAILS = 'company-details',
  COMPANY_SUBSCRIPTIONS = 'company-subsriptions',
  SUBSCRIPTIONS = 'subscriptions',
  PARTICIPANT_QUIZ_INFO = 'participant-quiz-info',
  CONVERSATIONS = 'conversations',
  UNSEEN_MESSAGES_COUNT = 'unseen-messages-count',
  CURRENT_CONVERSATION = 'current-conversation',
  OPENED_CONVERSATION = 'opened-conversation',
  ALL_COACHES = 'all-coaches',
  BOOKED_SESSIONS = 'booked-sessions',
  COURSE_ASIDE = 'course-aside',
  AVAILABLE_COURSES = 'available-courses',
  COACH_DETAILS = 'coach-details',
  COURSE_PROGRESS_DETAILS = 'courseProgressDetails',
}
