import { useTranslation } from 'react-i18next';

export type DatePickerHook = {
  MONTHS: string[];
  WEEKDAYS_SHORT: string[];
  WEEKDAYS: string[];
};

export const useDatePicker = (): DatePickerHook => {
  const { t } = useTranslation();

  const MONTHS = [
    t('january'),
    t('february'),
    t('march'),
    t('april'),
    t('may'),
    t('june'),
    t('july'),
    t('august'),
    t('september'),
    t('october'),
    t('november'),
    t('december'),
  ];

  const WEEKDAYS_SHORT = [
    t('sun'),
    t('mon'),
    t('tue'),
    t('wed'),
    t('thu'),
    t('fri'),
    t('sat'),
  ];

  const WEEKDAYS = [
    t('sunday'),
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
  ];

  return {
    MONTHS,
    WEEKDAYS_SHORT,
    WEEKDAYS,
  };
};
