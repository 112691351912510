import axios from 'helpers/axios';
import { Conversation, UnseenMessagesCount } from 'types/api';

export async function getConversations() {
  const { data } = await axios.get<Conversation[]>(`v1/conversations`);
  return data;
}

export async function getUnseenMessagesCount() {
  const { data } = await axios.get<UnseenMessagesCount>(
    '/v1/conversations/unseen_messages'
  );
  return data;
}

export async function getOpenedConversations(conversationID: number) {
  const { data } = await axios.get<Conversation>(
    `v1/conversations/${conversationID}`
  );
  return data;
}

export async function updateConversation(userId: number) {
  const { data } = await axios.post(`v1/conversations?id=${userId}`);
  return data;
}
