import { TaskFormData } from 'components/task-modal/task-form';
import axios from 'helpers/axios';
import { ApiParameters } from 'hooks/use-tasks';
import { Task, TaskData, TasksAPIData } from 'types/api';

export async function getTasks(
  apiParameters: ApiParameters
): Promise<TasksAPIData> {
  const { data } = await axios.get('/v1/user_tasks', {
    params: apiParameters,
    paramsSerializer(params: ApiParameters) {
      const stringtifiedParams: string[] = [];
      Object.keys(params).forEach((key) => {
        const queryParam = params[key as keyof ApiParameters];
        if (Array.isArray(queryParam)) {
          queryParam.forEach((variable) => {
            stringtifiedParams.push(
              `${encodeURIComponent(key)}[]=${encodeURIComponent(variable)}`
            );
          });
        } else if (queryParam) {
          stringtifiedParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParam)}`
          );
        }
      });
      return stringtifiedParams.join('&');
    },
  });
  return data;
}

export async function addTask(dataToSend: TaskData) {
  const { data } = await axios.post('v1/user_tasks', dataToSend);
  return data;
}

export async function editTask(
  dataToSend: { task: Omit<TaskFormData, 'course_id'> },
  id: number
) {
  const { data } = await axios.put(`v1/user_tasks/${id}`, dataToSend);
  return data;
}

export async function updateTask(
  selectedTask: Task,
  participantsIds: number[]
) {
  await axios.put(`v1/user_tasks/${selectedTask?.id}`, {
    task: {
      ...selectedTask,
      resource_id: selectedTask.resource.id,
      participant_ids: participantsIds,
    },
  });
}

export async function deleteTask(id: number) {
  const { data } = await axios.delete(`/v1/user_tasks/${id}`);
  return data;
}

export async function sendTaskAnswer(id: number, dataToSend: FormData) {
  await axios.post(`/v1/user_tasks/${id}/answer`, dataToSend);
}

export async function updateAnswer(id: number, dataToSend: FormData) {
  await axios.put(`/v1/answers/${id}`, dataToSend);
}
