import clsx from 'clsx';
import './info-text.scss';

type InfoTextProps = {
  size?: 'small' | 'medium' | 'large';
  color?: 'gray' | 'black';
};

export const InfoText: React.FC<InfoTextProps> = ({
  children,
  size = 'medium',
  color = 'gray',
}) => (
  <p className={clsx('info-text', `info-text--${size}`, `info-text--${color}`)}>
    {children}
  </p>
);
