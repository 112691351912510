import { Collapse } from 'components/collapse';
import { CourseDashboardDetails } from 'types/api';
import { ModuleItem } from './module-item';
import './course-accordion.scss';

type PropsCourseAccordion = {
  name?: string;
  courseDetails?: CourseDashboardDetails[];
};

export const CourseAccordion: React.FC<PropsCourseAccordion> = ({
  name,
  courseDetails,
}) => {
  return (
    <div className="course-accordion">
      <Collapse
        hiddenContent={
          <div className="course-accordion__content">
            {(courseDetails || []).map((course) => (
              <ModuleItem key={course.id} course={course} />
            ))}
          </div>
        }
      >
        <div className="course-accordion__title">{name}</div>
      </Collapse>
    </div>
  );
};
