import { TabRouter } from 'components/tab-router';
import { useCoursesNavItemsV2 } from 'hooks/use-courses-nav-itemsV2';
import './courses.scss';

export const CoursesV2: React.FC = () => {
  const coursesNavItemsV2 = useCoursesNavItemsV2();

  return (
    <div className="courses">
      <TabRouter items={coursesNavItemsV2} />
    </div>
  );
};

export default CoursesV2;
