import { ReactComponent as IconActive } from 'assets/icons/active.svg';
import { ReactComponent as IconDone } from 'assets/icons/done.svg';
import { ReactComponent as IconUnavailable } from 'assets/icons/unavailable.svg';
import './status-icon.scss';

type StatusIconProps =
  | {
      status: 'completed' | 'in_progress' | 'disabled' | 'inactive';
      percentage?: never;
    }
  | {
      status?: never;
      percentage: number;
    };

export const StatusIcon: React.FC<StatusIconProps> = ({
  status,
  percentage,
}) => {
  if (status === 'completed' || percentage === 100) {
    return (
      <IconDone data-testid="done" className="status-icon status-icon--done" />
    );
  }

  if (
    status === 'in_progress' ||
    (percentage && percentage < 100 && percentage > 0)
  ) {
    return (
      <IconActive
        data-testid="active"
        className="status-icon status-icon--active"
      />
    );
  }

  return (
    <IconUnavailable
      data-testid="unavailable"
      className="status-icon status-icon--unavailable"
    />
  );
};
