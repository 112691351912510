import { useTranslation } from 'react-i18next';
import { useParticipantQuiz } from 'hooks/use-quiz';
import { CourseCoach } from 'types/api';
import { QuizAttemptType, QuizType } from 'types/enums';
import './module-content-header.scss';

type ModuleContentHeaderProps = {
  moduleOrder: number | string;
  moduleName: string;
  coach?: CourseCoach;
};

export const ModuleContentHeader: React.FC<ModuleContentHeaderProps> = ({
  moduleOrder,
  moduleName,
  coach,
  children,
}) => {
  const { t } = useTranslation();
  const showButtons = coach && children;
  const { quizInfo } = useParticipantQuiz();
  const quizType =
    quizInfo &&
    (quizInfo.attempt_type.includes(QuizAttemptType.PRE_QUIZ)
      ? QuizType.PRE_QUIZ
      : QuizType.POST_QUIZ);

  return (
    <header className="module-content-header">
      <section className="module-content-header__section">
        <h4 className="module-content-header__sub-header">
          {quizType}
          <div className="module-content-header__module-name">
            <p className="module-content-header__name-content">{`${t(
              'module'
            )} ${moduleOrder} - ${moduleName}`}</p>
          </div>
        </h4>

        {showButtons && (
          <div className="module-content-header__buttons">{children}</div>
        )}
      </section>
    </header>
  );
};
