import './module-collapse-row.scss';

type ModuleCollapseRowProps = {
  name: string;
  stats: string;
};

export const ModuleCollapseRow: React.FC<ModuleCollapseRowProps> = ({
  name,
  stats,
}) => {
  return (
    <div className="module-collapse-row">
      <div className="module-collapse-row__content">
        <p>{name}</p>
        <p>{stats}</p>
      </div>
    </div>
  );
};
