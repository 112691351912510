import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { MINUTE_MS } from 'constants/time';
import { t } from 'i18next';
import { getBookedSessions } from 'services/calendar';
import { convertDateToApiCompatible } from 'helpers/coaching-session-calendar';
import { today } from 'helpers/time';

export const useBookedSessions = (
  coachId?: number,
  isOtherCoachCalendarView?: boolean,
  startDate?: string,
  endDate?: string
) => {
  const { data: bookedSessions, ...rest } = useQuery(
    [QueryKeys.BOOKED_SESSIONS, { coachId, startDate, endDate }],
    () =>
      startDate || endDate
        ? getBookedSessions(startDate, endDate)
        : getBookedSessions(convertDateToApiCompatible(today())),
    {
      refetchInterval: MINUTE_MS,
      onError: () => toast.error(t('sessions-error')),
      enabled: !isOtherCoachCalendarView,
    }
  );

  return { bookedSessions, ...rest };
};
