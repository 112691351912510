import { useTranslation } from 'react-i18next';
import { Collapse } from 'components/collapse';
import { roundPercentageNumber } from 'helpers/utils';
import { ModuleProgress } from 'types/api';
import { ModuleCollapseRow } from './module-collapse-row';
import './module-collapse.scss';

type ModuleCollapseProps = {
  module: ModuleProgress;
  isAutoScroll?: boolean;
};

export const ModuleCollapse: React.FC<ModuleCollapseProps> = ({
  module: {
    course_module_name: name,
    total_lessons_count: totalLessons,
    completed_lessons_count: completedLessons,
    total_tasks_count: totalTasks,
    completed_tasks_count: completedTasks,
    pre_quiz_score: preQuizScore,
    module_progress: moduleProgress,
    post_quiz_score: postQuizScore,
    individual_meetings_count: individualMeetingsCount,
  },
  isAutoScroll = true,
}) => {
  const { t } = useTranslation();
  const scoreDiff =
    (postQuizScore && preQuizScore && postQuizScore - preQuizScore) ?? 0;
  const compareToPreQuizScoreText =
    (scoreDiff > 0 &&
      t('post-quiz-result-progress', { scoreDiff, score: postQuizScore })) ||
    (scoreDiff < 0 &&
      t('post-quiz-result-regress', { scoreDiff, score: postQuizScore })) ||
    `${postQuizScore}%`;

  const isPreQuizSolved = Number(preQuizScore) >= 0 && preQuizScore !== null;
  const isPostQuizSolved = Number(postQuizScore) >= 0 && postQuizScore !== null;

  return (
    <Collapse
      className="module-collapse"
      isAutoScroll={isAutoScroll}
      hiddenContent={
        <>
          <ModuleCollapseRow
            name={t('lessons')}
            stats={t('current-from-total', {
              current: completedLessons,
              total: totalLessons,
            })}
          />
          <ModuleCollapseRow
            name={t('exercises')}
            stats={t('current-from-total', {
              current: completedTasks,
              total: totalTasks,
            })}
          />
          <ModuleCollapseRow
            name={t('pre-quiz')}
            stats={isPreQuizSolved ? `${preQuizScore}%` : t('not-solved')}
          />
          <ModuleCollapseRow
            name={t('post-quiz')}
            stats={
              isPostQuizSolved ? compareToPreQuizScoreText : t('not-solved')
            }
          />
          <ModuleCollapseRow
            name={t('individual-meetings-count')}
            stats={`${individualMeetingsCount}`}
          />
        </>
      }
    >
      <div className="module-collapse__header">
        <div className="module-collapse__name">{name}</div>
        <div>{roundPercentageNumber(moduleProgress)}%</div>
      </div>
    </Collapse>
  );
};
