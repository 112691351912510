import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Input, Select } from 'components/form';
import { createSelectOptions, findSelectedModules } from 'helpers/utils';
import { RICHTEXT } from 'helpers/validate';
import { useSelectsData } from 'hooks/use-selects-data';
import 'react-quill/dist/quill.snow.css';
import './task-form.scss';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['link', 'image', 'code'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'code',
  'script',
  'color',
  'background',
  'align',
  'font',
  'bold',
  'italic',
  'size',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const DEFAULT_FORM_VALUES = {
  description: '',
  instruction: '',
  name: '',
  course_id: '',
  resource_id: '',
};

export type TaskFormData = {
  description: string;
  instruction: string;
  name: string;
  resource_id: string;
  course_id?: string;
  participant_ids?: number[];
  resource_type?: string;
};

type TaskFormProps = {
  onClose: () => void;
  initialFormValues: TaskFormData;
  submit: (data: TaskFormData) => void;
  isEditing: boolean;
};

export const TaskForm: React.FC<TaskFormProps> = ({
  onClose,
  initialFormValues,
  submit,
  isEditing,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, errors, register, watch, control } = useForm();

  const { courses } = useSelectsData();

  const watchCourseId = watch('course_id', initialFormValues.course_id);
  const coursesOptions = createSelectOptions(courses);
  const modulesOptions = createSelectOptions(
    findSelectedModules(courses, watchCourseId || [])
  );

  const selectedCourse =
    courses.find((course) => course.id === +watchCourseId) ?? courses[0];

  return (
    <form className="task-form" onSubmit={handleSubmit(submit)}>
      <Input
        errors={errors}
        register={register({ required: true })}
        defaultValue={initialFormValues.name}
        name="name"
        type="text"
        label={t('task-title')}
        placeholder={t('add-title')}
      />

      <div className="task-form__selects">
        <Select
          register={register({ required: true })}
          value={initialFormValues.course_id}
          label={t('course')}
          name="course_id"
          options={coursesOptions}
          className="task-form__select"
        />

        {selectedCourse && (
          <Select
            errors={errors}
            register={register({ required: true })}
            value={initialFormValues.resource_id}
            label={t('module')}
            name="resource_id"
            options={modulesOptions}
            className="task-form__select"
          />
        )}
      </div>

      <div className="task-form__textarea">
        <label className="task-form__label" htmlFor="description">
          {t('task-desc')}
        </label>
        <Controller
          render={({ value, onChange }) => (
            <ReactQuill
              modules={modules}
              formats={formats}
              theme="snow"
              placeholder={t('add-desc')}
              value={value}
              onChange={onChange}
              className={clsx(
                'task-input',
                errors.description && 'task-input--error'
              )}
            />
          )}
          control={control}
          name="description"
          errors={errors}
          defaultValue={initialFormValues.description}
          rules={{
            required: false,
            validate: {
              isEmpty: (description) =>
                description.replace(RICHTEXT, '').trim().length >= 0,
            },
          }}
        />
        {errors.description && (
          <span className="task-form__error">{t('field-required')}</span>
        )}
      </div>

      <div className="task-form__textarea">
        <label className="task-form__label" htmlFor="instruction">
          {t('task-instruction')}
        </label>
        <Controller
          render={({ value, onChange }) => (
            <ReactQuill
              modules={modules}
              formats={formats}
              theme="snow"
              placeholder={t('add-instruction')}
              value={value}
              onChange={onChange}
              className={clsx(
                'task-input',
                errors.instruction && 'task-input--error'
              )}
            />
          )}
          control={control}
          name="instruction"
          defaultValue={initialFormValues.instruction}
          rules={{
            required: true,
            validate: {
              isEmpty: (instruction) =>
                instruction.replace(/<(.|\n)*?>/g, '').trim().length > 0,
            },
          }}
        />
        {errors.instruction &&
          (errors.instruction.type === 'required' ? (
            <span className="task-form__error">{t('field-required')}</span>
          ) : (
            <span className="task-form__error">
              {t('field-description-required')}
            </span>
          ))}
      </div>

      <div className="task-form__buttons">
        <Button className="task-form__button" type="submit">
          {isEditing ? t('confirm') : t('assign-participants')}
        </Button>

        <Button
          className="task-form__button task-form__button--abort"
          variant="secondary"
          onClick={onClose}
        >
          {t('abort')}
        </Button>
      </div>
    </form>
  );
};
