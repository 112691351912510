import { RadioItem } from './radio-item';
import './radio.scss';

export type RadioType = {
  name: string;
  options:
    | { id: number; label: string }[]
    | {
        id: number;
        body: string;
      }[];
  value?: string;
  onChange?: (value: string) => void;
  correct?: boolean;
  disabled?: boolean;
};

export const Radio: React.FC<RadioType> = ({
  name,
  options,
  value = '',
  onChange = () => {},
  correct,
  disabled,
}) => {
  return (
    <div className="radio-group">
      {options.map((option) => (
        <RadioItem
          name={name}
          key={`radio-wrapper-${name}-${option.id}`}
          label={'body' in option ? option.body : option.label}
          value={option.id.toString()}
          checked={+value === option.id}
          correct={correct}
          disabled={disabled}
          onChange={() => onChange(option.id.toString())}
        />
      ))}
    </div>
  );
};
