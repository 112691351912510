import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getParticipantsProgress } from 'services/participants';

export const useParticipantsProgress = (companyId?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, ...rest } = useQuery(
    [QueryKeys.PARTICIPANT_PROGRESS, companyId],
    () => getParticipantsProgress(companyId),
    {
      onError: () => toast.error(t('participants-error')),
    }
  );

  const updateParticipantsProgress = () => {
    queryClient.invalidateQueries([QueryKeys.PARTICIPANT_PROGRESS, companyId]);
  };

  const participantsProgress = data ?? [];

  return { participantsProgress, updateParticipantsProgress, ...rest };
};
