import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import {
  fetchSubscriptionCourses,
  getAvailableCourses,
  getCourseModules,
} from 'services/courses';
import { SubscriptionCourseParams } from 'types/api';
import { QueryParamNameTypes } from 'types/enums';
import { useQueryParameters } from './use-query-parameters';

type UseCoursesParameters = {
  isFinished?: boolean;
  isSales?: boolean;
  courseId?: number;
  areStatsVisible?: boolean;
};

export const useCourses = ({
  isFinished,
  courseId,
  areStatsVisible,
  isSales = false,
}: UseCoursesParameters) => {
  const { t } = useTranslation();

  const { get } = useQueryParameters();
  const companyName = get(QueryParamNameTypes.NAME);
  const { companyId } = useParams<{ companyId: string }>();
  const params: SubscriptionCourseParams = {
    ...(!!(companyName || companyId) && {
      company_id: companyName || companyId,
    }),
    ...(isFinished && { finished: true }),
  };

  const { data: courses, ...rest } = useQuery(
    [QueryKeys.SUBSCRIPTION_COURSES, params],
    () => fetchSubscriptionCourses(params),
    {
      onError: () => toast.error(t('courses-error')),
      enabled: !isSales,
    }
  );

  const { data: availableCourses } = useQuery(
    [QueryKeys.AVAILABLE_COURSES],
    () => getAvailableCourses(),
    {
      enabled: isSales,
      onError: () => toast.error(t('courses-error')),
    }
  );
  const { data: courseModules } = useQuery(
    [QueryKeys.MODULES_PREVIEW, courseId],
    () => getCourseModules(courseId || -1),
    {
      enabled: !!areStatsVisible && !!courseId,
      onError: () => toast.error(t('modules-preview-error')),
    }
  );
  return { courses, availableCourses, courseModules, ...rest };
};
