import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import { Loader } from 'components/loader';
import { ModalParticipantsAnswers } from 'components/more-answers-modal';
import { NoResource } from 'components/no-resource';
import { Pagination } from 'components/pagination';
import { ParticipantTaskImplementationCard } from 'components/participant-task-implementation-card';
import { TaskAnswerModal } from 'components/task-answer-modal';
import { TaskAssignmentModal } from 'components/task-assignment-modal';
import { TaskFormData } from 'components/task-modal/task-form';
import { FIRST_PAGE_VALUE } from 'hooks/use-pagination';
import { useSelectsData } from 'hooks/use-selects-data';
import { UpdateTasksData } from 'hooks/use-tasks';
import { Answer, Task, TasksMeta } from 'types/api';
import './tasks.scss';

type TasksProps = {
  currentPage: string;
  tasks: Task[];
  isLoading: boolean;
  tasksMeta: TasksMeta | null;
  removeTask: UseMutateFunction<any, unknown, number, unknown>;
  updateTasks: UseMutateFunction<void, unknown, UpdateTasksData, unknown>;
  editTaskSubmit: (data: TaskFormData, taskId: number) => void;
};

export const Tasks: React.FC<any> = ({
  currentPage,
  tasks,
  isLoading,
  tasksMeta,
  updateTasks,
  removeTask,
  editTaskSubmit,
}: TasksProps) => {
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [assignmentModal, setAssignmentModal] = useState(false);
  const [answerModal, setAnswerModal] = useState(false);
  const [participantsAnswersModal, setParticipantsAnswersModal] =
    useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<Answer | null>(null);
  const { t } = useTranslation();
  const { companies } = useSelectsData();

  const handleAssignmentModal = (taskId: number) => {
    const taskToShow = tasks.find((task) => task.id === taskId);
    setSelectedTask(taskToShow ?? null);
    setAssignmentModal(true);
  };

  const handleAnswerModal = (answerId: number, taskId: number) => {
    const currentTask = tasks.find((task) => task.id === taskId);
    const currentAnswer = currentTask?.answers.find(
      (answer) => answer.id === answerId
    );
    setSelectedAnswer(currentAnswer ?? null);
    setSelectedTask(currentTask ?? null);
    setAnswerModal(true);
  };

  const handleParticipantsAnswersModal = (taskId: number) => {
    const currentTask = tasks.find((task) => task.id === taskId);
    setSelectedTask(currentTask ?? null);
    setParticipantsAnswersModal(true);
  };

  const handleRemoveParticipant = (id: number) => {
    setSelectedTask((prevState) => {
      if (prevState) {
        const updatedParticipants = prevState.participants.filter(
          (participant) => participant.id !== id
        );
        return {
          ...prevState,
          participants: updatedParticipants,
        };
      }
      return prevState;
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="tasks">
      <div className="tasks__list">
        {(tasks || []).map((data) => (
          <ParticipantTaskImplementationCard
            taskDetail={data}
            key={data.id}
            handleModal={handleAssignmentModal}
            handleAnswerModal={handleAnswerModal}
            handleParticipantsAnswersModal={handleParticipantsAnswersModal}
            removeTask={removeTask}
            editTaskSubmit={editTaskSubmit}
          />
        ))}
      </div>

      <ModalParticipantsAnswers
        show={participantsAnswersModal}
        onClose={() => setParticipantsAnswersModal(false)}
        handleAnswerModal={handleAnswerModal}
        currentTask={selectedTask}
      />

      <TaskAssignmentModal
        modal={assignmentModal}
        setModal={setAssignmentModal}
        selectedTask={selectedTask}
        companies={companies}
        updateTasks={updateTasks}
        handleRemoveParticipant={handleRemoveParticipant}
        setSelectedTask={setSelectedTask}
      />

      <TaskAnswerModal
        answerModal={answerModal}
        setAnswerModal={setAnswerModal}
        selectedTask={selectedTask}
        selectedAnswer={selectedAnswer}
      />

      {tasksMeta && tasksMeta.total_pages > 1 && (
        <Pagination
          totalPageCount={+tasksMeta.total_pages}
          isListEmpty={tasks.length === 0}
        />
      )}

      {tasks.length === 0 && currentPage === FIRST_PAGE_VALUE && (
        <NoResource>{t('no-active-tasks')}</NoResource>
      )}
    </div>
  );
};
