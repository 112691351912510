import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import {
  getCompanySubscription,
  getSubscriptions,
} from 'services/subscriptions';

export const useSubscriptions = (
  searchPhrase: string,
  selectedSubscriptionId: string
) => {
  const { t } = useTranslation();
  const { data: companySubscriptions, ...restCompanySubscriptionsQuery } =
    useQuery(QueryKeys.COMPANY_SUBSCRIPTIONS, () => getCompanySubscription(), {
      onError: () => {
        toast.error(t('subscription-error'));
      },
    });

  const { data: subscriptionOptions, ...restSubscriptionOptionsQuery } =
    useQuery(QueryKeys.SUBSCRIPTIONS, () => getSubscriptions(), {
      onError: () => {
        toast.error(t('subscription-error'));
      },
    });

  const filteredSubscriptions = useMemo(() => {
    const isAllSubscriptions = selectedSubscriptionId === '-1';
    const matchedSubscriptions = companySubscriptions
      ? companySubscriptions.filter(
          ({ subscription, company_subscription_courses }) => {
            const isSubscriptionMatches = company_subscription_courses.some(
              (subscriptionCourse) =>
                subscriptionCourse.course.name
                  .toLowerCase()
                  .includes(searchPhrase.toLowerCase()) &&
                (isAllSubscriptions ||
                  String(subscription.id) === selectedSubscriptionId)
            );
            return isSubscriptionMatches;
          }
        )
      : [];
    return matchedSubscriptions;
  }, [companySubscriptions, searchPhrase, selectedSubscriptionId]);

  const uniqueOptionsNames = subscriptionOptions
    ? subscriptionOptions.map((option) => ({
        value: String(option.id),
        label: option.name,
      }))
    : [];

  return {
    filteredSubscriptions,
    uniqueOptionsNames,
    restCompanySubscriptionsQuery,
    restSubscriptionOptionsQuery,
  };
};
