import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { resetPasswordSelectors } from 'constants/selectors/authentication/reset-password';
import { resetPasswordRequest } from 'services/user';
import { Button } from 'components/button';
import { Input } from 'components/form';
import { Logo } from 'components/logo';
import { EMAIL } from 'helpers/validate';
import { ResetPasswordRequestData } from 'types/api';
import './authentication.scss';

type SubmitData = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();

  const { mutate: sendResetPasswordRequest, isLoading } = useMutation(
    (resetData: ResetPasswordRequestData) => resetPasswordRequest(resetData),
    {
      onSuccess: () => {
        toast.success(t('check-email-password'));
      },
      onError: () => {
        // To do: change to more descriptive message
        toast.error(t('undefined-error'));
      },
    }
  );

  const submit = (data: SubmitData) => {
    const emailData: ResetPasswordRequestData = { email: data.email };
    sendResetPasswordRequest(emailData);
  };

  return (
    <div className="authentication">
      <Logo className="authentication__logo" />
      <span className="authentication__title">{t('reset-password')}</span>
      <span className="authentication__message">
        {t('reset-password-instructions')}
      </span>
      <form onSubmit={handleSubmit(submit)} className="authentication__form">
        <Input
          autoComplete="username"
          label={t('e-mail')}
          name="email"
          placeholder={t('e-mail')}
          errors={errors}
          register={register({
            required: true,
            pattern: {
              value: EMAIL,
              message: t('invalid-email'),
            },
          })}
        />
        <div className="authentication__button-wrapper">
          <Button
            type="submit"
            variant="primary"
            className={clsx(
              'authentication__submit',
              `${resetPasswordSelectors.submitClass}`
            )}
            loading={isLoading}
          >
            {t('confirm')}
          </Button>
          <Link
            to="/"
            className="button button--secondary authentication__cancel"
          >
            {t('cancel')}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
