import axios from 'helpers/axios';
import { Report } from 'types/api';

export async function getReports() {
  const { data } = await axios.get<Report[]>('v1/reports');
  return data;
}

export async function addReport(dataToSend: Record<string, string | null>) {
  const { data } = await axios.post<Report>('/v1/reports', dataToSend);
  return data;
}
