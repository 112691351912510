import { useContext, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { MINUTE_MS } from 'constants/time';
import { AuthContext } from 'contexts';
import { t } from 'i18next';
import {
  confirmMeeting,
  getCoachSessions,
  rejectMeeting,
  updateGroupSession,
  updateMeeting,
} from 'services/calendar';
import { GroupSessionEditing, UpdateMeetingParams } from 'types/api';
import { Roles } from 'types/enums';

export const useCoachSessions = (isAdmin?: boolean, coachId?: number) => {
  const [currentDate, setCurrentDate] = useState<Date>();
  const queryClient = useQueryClient();
  const { userRole, user } = useContext(AuthContext);
  const isQueryEnabled =
    !!coachId &&
    (isAdmin || userRole === Roles.SUPER_COACH) &&
    coachId !== user?.id;

  const invalidateSessions = () => {
    if (userRole === Roles.PARTICIPANT) {
      queryClient.invalidateQueries(QueryKeys.COACHING_SESSIONS);
      return;
    }
    queryClient.invalidateQueries(QueryKeys.COACH_SESSIONS);
    queryClient.invalidateQueries(QueryKeys.BOOKED_SESSIONS);
  };

  const { data: coachSessions, ...rest } = useQuery(
    [QueryKeys.COACH_SESSIONS, coachId, currentDate],
    () => getCoachSessions(currentDate || new Date(), coachId),
    {
      enabled: isQueryEnabled,
      onError: () => toast.error(t('sessions-error')),
      refetchInterval: MINUTE_MS,
    }
  );

  const { mutateAsync: confirmCoachSession, isLoading: isConfirming } =
    useMutation((sessionId: number) => confirmMeeting(sessionId), {
      onSuccess: () => {
        invalidateSessions();
        toast.success(t('confirm-session-success'));
      },
      onError: () => {
        toast.error(t('session-confirm-error'));
      },
    });

  const { mutateAsync: rejectCoachSession, isLoading: isCanceling } =
    useMutation((sessionId: number) => rejectMeeting(sessionId), {
      onSuccess: () => {
        invalidateSessions();
        toast.success(t('cancel-session-success'));
      },
      onError: () => {
        toast.error(t('cancel-session-error'));
      },
    });

  const { mutateAsync: updateCoachSession } = useMutation(
    ({
      meetingId,
      dataToSend,
    }: {
      meetingId: number;
      dataToSend: UpdateMeetingParams;
    }) => updateMeeting(meetingId, dataToSend),
    {
      onSuccess: () => {
        toast.success(t('edit-session-success'));
      },
      onError: () => {
        toast.error(t('edit-session-error'));
      },
    }
  );

  const { mutateAsync: updateCoachGroupSession } = useMutation(
    ({
      meetingId,
      dataToSend,
    }: {
      meetingId: string;
      dataToSend: GroupSessionEditing;
    }) => updateGroupSession(meetingId, dataToSend),
    {
      onSuccess: () => {
        invalidateSessions();
        toast.success(t('edit-session-success'));
      },
      onError: () => {
        toast.error(t('edit-session-error'));
      },
    }
  );

  return {
    coachSessions,
    ...rest,
    confirmCoachSession,
    isConfirming,
    rejectCoachSession,
    isCanceling,
    setCurrentDate,
    updateCoachSession,
    updateCoachGroupSession,
  };
};
