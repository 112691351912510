import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { Logo } from 'components/logo';
import './final-session-step.scss';

type FinalSessionStepProps = {
  resetForm: () => void;
  closeModal: () => void;
};

export const FinalSessionStep: React.FC<FinalSessionStepProps> = ({
  resetForm,
  closeModal,
}) => {
  const { t } = useTranslation();
  return (
    <div className="final-session-step">
      <Logo className="final-session-step__logo" />
      <div className="final-session-step__congrats-message">
        <Trans i18nKey="session-booking-success">
          <span className="final-session-step__congrats">Congratulations!</span>
          Your coach session is successfully reserved.
        </Trans>
      </div>
      <div className="final-session-step__info">
        {t('booking-session-info')}
      </div>
      <div className="final-session-step__buttons">
        <Button variant="secondary" onClick={closeModal}>
          {t('back-to-list')}
        </Button>
        <Button onClick={resetForm}>{t('book-next')}</Button>
      </div>
    </div>
  );
};
