import './time-indicator.scss';

type TimeIndicatorProps = {
  verticalPosition: string;
};

export const TimeIndicator: React.FC<TimeIndicatorProps> = ({
  verticalPosition,
}) => (
  <div
    className="time-indicator"
    style={{
      top: verticalPosition,
    }}
  />
);
