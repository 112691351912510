import { Route, Switch } from 'react-router-dom';
import { RouteType } from 'types/routes';

type PublicRoutesProps = {
  publicRoutes: RouteType[];
};

const PublicRoutes: React.FC<PublicRoutesProps> = ({ publicRoutes }) => (
  <Switch>
    {publicRoutes.map((route) => (
      <Route
        key={route.path}
        component={route.component}
        exact
        path={route.path}
      />
    ))}
  </Switch>
);

export default PublicRoutes;
