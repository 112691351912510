import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { TaskInfo } from 'components/task-info';
import { useCourseAsideV2 } from 'hooks/use-course-asideV2';
import { TaskBase } from 'types/api';
import './task-preview.scss';

type TaskPreviewProps = {
  isTaskModalOpen: boolean;
  onModalClose: () => void;
  onTaskOpen: () => void;
  task: TaskBase;
  moduleName: string;
  moduleOrder: number;
};

export const TaskPreview: React.FC<TaskPreviewProps> = ({
  isTaskModalOpen,
  task,
  moduleName,
  moduleOrder,
  onModalClose,
  onTaskOpen,
}) => {
  const { t } = useTranslation();
  const { moveToNext } = useCourseAsideV2();
  return (
    <Modal
      isOpen={isTaskModalOpen}
      maxWidth="1011px"
      onRequestClose={onModalClose}
    >
      <div className="task-preview">
        <h2 className="task-preview__heading">{t('task-awaitng')}</h2>
        <TaskInfo
          isModule
          resourceName={moduleName}
          resourceOrder={moduleOrder}
        />
        <h3 className="task-preview__name">{task.name}</h3>
        <div
          className="task-preview__text"
          dangerouslySetInnerHTML={{
            __html: String(task.description),
          }}
        />
        {!!task.instruction.length && (
          <>
            <p className="task-preview__title">{t('instruction')}:</p>
            <div
              className="task-preview__text"
              dangerouslySetInnerHTML={{
                __html: String(task.instruction),
              }}
            />
          </>
        )}
        <div className="task-preview__buttons-container">
          <Button onClick={onTaskOpen}>{t('solve-task')}</Button>
          <Button
            variant="secondary"
            onClick={() => {
              onModalClose();
              moveToNext();
            }}
          >
            {t('solve-later')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
