import { useMutation } from 'react-query';
import { createSession as createSessionRequest } from 'services/calendar';
import { SessionWithCoachNewTimeslots } from 'types/api';

export const useCreateSession = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  const { mutate: createSession, ...createSessionQuery } = useMutation(
    (sessionData: SessionWithCoachNewTimeslots) =>
      createSessionRequest(sessionData),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) {
          onError();
        }
      },
    }
  );
  return { createSession, createSessionQuery };
};
