import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChatContext } from 'contexts';
import { Button } from 'components/button';
import { LazyAvatar } from 'components/lazy-avatar';
import { getUserInitials } from 'helpers/utils';
import { CoachRoleType } from 'types/user';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar-line.svg';
import { ReactComponent as IconChat } from 'assets/icons/chat-2.svg';
import './coach-details.scss';

type DetailView = {
  chat: boolean;
  isCalendar?: boolean;
  additionalChatAction?: () => void;
  coachDetails: CoachRoleType;
};

export const CoachDetails: React.FC<DetailView> = ({
  coachDetails: {
    coach: {
      id,
      first_name: firstName,
      last_name: lastName,
      avatar,
      email,
      description,
    },
    courses,
  },
  chat,
  additionalChatAction,
  isCalendar,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { openConversation } = useContext(ChatContext);

  return (
    <article className="coach-details">
      <header className="coach-details__header">
        <div className="coach-details__profile">
          <div className="coach-details__avatar">
            <LazyAvatar
              imageClassName="coach-details__image"
              src={avatar}
              alt={`${firstName} ${lastName}`}
              userInitials={getUserInitials({
                first_name: firstName,
                last_name: lastName,
              })}
            />
          </div>

          <div className="coach-details__details">
            <h2 className="coach-details__name">
              {firstName} {lastName}
            </h2>
            <h3 className="coach-details__email">{email}</h3>
            <h3 className="coach-details__description">{description}</h3>
          </div>
        </div>
      </header>
      <section className="coach-details__responsibilities">
        <h4 className="coach-details__list-heading">
          {t('responsible-desc')}:
        </h4>

        <ul className="coach-details__courses">
          {(courses || []).map(({ id: courseID, name }) => (
            <li className="coach-details__course" key={courseID}>
              <div className="coach-details__course-name">{name}</div>
            </li>
          ))}
        </ul>
      </section>
      <section>
        <div className="coach-details__tools">
          {chat && (
            <Button
              variant={isCalendar ? 'secondary' : 'primary'}
              className="coach-details__button"
              onClick={() => {
                openConversation(id);
                if (additionalChatAction) {
                  additionalChatAction();
                }
              }}
            >
              <IconChat />
              {t('chat-with-coach')}
            </Button>
          )}
          {isCalendar && (
            <Button
              onClick={() =>
                history.push({
                  pathname: '/coaching-sessions',
                  state: { coachId: id },
                })
              }
              className="coach-details__button"
            >
              <IconCalendar />
              {t('book-appointment')}
            </Button>
          )}
        </div>
      </section>
    </article>
  );
};
