import clsx from 'clsx';
import { ReactComponent as KebabIcon } from 'assets/icons/kebab.svg';
import './kebab-button.scss';

type KebabButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const KebabButton: React.FC<KebabButtonProps> = ({
  className,
  ...otherProps
}) => (
  <button className={clsx('kebab-button', className)} {...otherProps}>
    <KebabIcon />
  </button>
);
