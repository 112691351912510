import { useRef } from 'react';
import ReactModal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';
import { StyledScrolls } from 'components/styled-scrolls';
import { ReactComponent as IconClose } from 'assets/icons/close-line.svg';
import './modal.scss';

export type PropModal = Omit<
  ReactModal.Props,
  'closeTimeoutMS' | 'style' | 'ariaHideApp' | 'shouldReturnFocusAfterClose'
> & {
  noPadding?: boolean;
  maxWidth?: string;
  isCloseButton?: boolean;
  bodyClassName?: string;
};
const ANIMATION_TIME = 350;

export const Modal: React.FC<PropModal> = ({
  children,
  noPadding = false,
  maxWidth = '570px',
  className,
  isCloseButton = true,
  overlayClassName,
  bodyClassName,
  onAfterClose,
  onAfterOpen,
  onRequestClose,
  ...otherProps
}) => {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();
  const history = useHistory();

  return (
    <ReactModal
      className={clsx('modal', className)}
      overlayClassName={clsx('modal__overlay', overlayClassName)}
      onRequestClose={onRequestClose}
      onAfterOpen={() => {
        if (onAfterOpen) {
          onAfterOpen();
        }
        disableBodyScroll(modalContainerRef.current as HTMLElement);
      }}
      onAfterClose={() => {
        if (onAfterClose) {
          onAfterClose();
        }

        clearAllBodyScrollLocks();
      }}
      ariaHideApp={false}
      style={{
        content: {
          maxWidth,
        },
      }}
      closeTimeoutMS={ANIMATION_TIME}
      shouldReturnFocusAfterClose={false}
      {...otherProps}
    >
      <div className={clsx('modal__body', bodyClassName)}>
        {isCloseButton && (
          <button
            className="modal__close"
            type="button"
            onClick={(event) => {
              if (onRequestClose) {
                onRequestClose(event);
              }

              if (location?.search) {
                history.push(location.pathname);
              }
            }}
          >
            <IconClose className="modal__close-icon" />
          </button>
        )}
        <StyledScrolls className="modal__scrollable">
          <div
            data-testid="inner-wrapper"
            ref={modalContainerRef}
            className={clsx('modal__modal-inner-wrapper', {
              'modal__modal-inner-wrapper--no-padding': noPadding,
            })}
          >
            {children}
          </div>
        </StyledScrolls>
      </div>
    </ReactModal>
  );
};
