import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Person } from 'components/person';
import {
  ProgressItemButton,
  ProgressItemStatus,
} from 'components/progress-item-button';
import { StateSetter } from 'helpers/types';
import { useGetLessonStatus } from 'hooks/use-get-lesson-status';
import { useQuizStatus } from 'hooks/use-quiz-status';
import { ModuleDetails } from 'types/api';
import { QuizAttemptType } from 'types/enums';
import './progress-item.scss';

type ListItemProps = {
  module: ModuleDetails;
  activeContentId: number | string;
  activeModule: ModuleDetails | null;
  goToLesson: (id: number) => void;
  setActiveModule: StateSetter<ModuleDetails | null>;
  order: number;
};

export const ListItem: React.FC<ListItemProps> = ({
  module,
  activeContentId,
  activeModule,
  goToLesson,
  setActiveModule,
  order,
}) => {
  const { isParticipant } = useContext(AuthContext);
  const getLessonStatus = useGetLessonStatus();
  const { preQuizStatus, postQuizStatus } = useQuizStatus(module);

  const { courseId, contentType, contentId } = useParams<{
    courseId: string;
    contentType: QuizAttemptType.POST_QUIZ | QuizAttemptType.PRE_QUIZ;
    contentId: string;
  }>();

  return (
    <li className="progress-item">
      <div
        className={clsx(
          'progress-item__header',
          module.id === activeModule?.id && 'progress-item__header--active'
        )}
      >
        {module.coach && (
          <div className="progress-item__wrapper">
            <Person
              className="progress-item__coach"
              profile={module.coach}
              showName
              layout="small"
              size={40}
              details
              type="coach"
            />
            <button
              aria-label="open-module"
              className="progress-item__button progress-item__button--empty"
              onClick={() => {
                setActiveModule(module);
              }}
            />
          </div>
        )}
        <button
          className="progress-item__button"
          onClick={() => {
            setActiveModule(module);
          }}
        >
          <div className="progress-item__collaterals">
            <Trans i18nKey="video-count" count={module.video_count}>
              <span className="progress-item__collateral">video</span>
            </Trans>
            <Trans i18nKey="quizzes" count={module.quizzes_count}>
              <span className="progress-item__collateral">quiz</span>
            </Trans>
          </div>
          <h3 className="progress-item__name">
            {order}. {module.name}
          </h3>
        </button>
      </div>
      {module.id === activeModule?.id && (
        <div className="progress-item__lessons">
          {module.quiz && isParticipant && (
            <ProgressItemButton
              as={Link}
              name={module.quiz.name}
              status={preQuizStatus}
              isActive={
                contentType === QuizAttemptType.PRE_QUIZ &&
                +contentId === module.quiz?.id
              }
              to={`/courses/${courseId}/${QuizAttemptType.PRE_QUIZ}/${module.quiz?.id}`}
            />
          )}

          {module.lessons.map((lesson, index, array) => (
            <ProgressItemButton
              key={lesson.id}
              name={`${index + 1}. ${lesson.name}`}
              isActive={lesson.slug === activeContentId}
              status={getLessonStatus(
                index,
                array,
                module.quiz?.completed_attempts_count
              )}
              onClick={() => goToLesson(lesson.id)}
            />
          ))}

          {module.quiz && (
            <ProgressItemButton
              as={Link}
              name={module.quiz.name}
              status={postQuizStatus}
              isActive={
                contentType === QuizAttemptType.POST_QUIZ &&
                +contentId === module.quiz?.id
              }
              to={`/courses/${courseId}/${QuizAttemptType.POST_QUIZ}/${module.quiz?.id}`}
              className={clsx(
                'progress-item__post-quiz',
                postQuizStatus === ProgressItemStatus.DISABLED &&
                  'progress-item__post-quiz--disabled'
              )}
            />
          )}
        </div>
      )}
    </li>
  );
};
