import clsx from 'clsx';
import './loader.scss';

type LoaderProps = {
  className?: string;
  fullHeight?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({
  className,
  fullHeight,
}: LoaderProps) => {
  return (
    <div
      className={clsx('loader', fullHeight && 'loader--full-height', className)}
    >
      <div className="ball-triangle-path">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
