import { StyledScrolls } from 'components/styled-scrolls';
import { StateSetter } from 'helpers/types';
import { ModuleDetails } from 'types/api';
import { ListItem } from './list-item';
import './progress-list.scss';

type ProgressListProps = {
  modules: ModuleDetails[];
  goToLesson: (id: number) => void;
  setActiveModule: StateSetter<ModuleDetails | null>;
  activeContentId: number | string;
  activeModule: ModuleDetails | null;
};

export const ProgressList: React.FC<ProgressListProps> = ({
  modules,
  goToLesson,
  setActiveModule,
  activeContentId,
  activeModule,
}) => {
  return (
    <StyledScrolls className="progress-list">
      <ul className="progress-list__container">
        {modules.map((module, index) => {
          return (
            <ListItem
              order={index + 1}
              key={module.id}
              module={module}
              goToLesson={goToLesson}
              setActiveModule={setActiveModule}
              activeContentId={activeContentId}
              activeModule={activeModule}
            />
          );
        })}
      </ul>
    </StyledScrolls>
  );
};
