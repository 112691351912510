import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { CoachingSessionModal } from 'components/coaching-session-modal';
import { CoachingSessionsList } from 'components/coaching-sessions-list';
import { Loader } from 'components/loader';
import { MeetingStatusLegend } from 'components/meeting-status-legend';
import { StyledScrolls } from 'components/styled-scrolls';
import { useCoachingSessions } from 'hooks/use-coaching-sessions';
import './index.scss';

const CoachingSessions = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isParticipant } = useContext(AuthContext);
  const { state } = useLocation<{ coachId: number } | undefined>();
  const { sessionsQuery, createSessionQuery, sessions, createSession } =
    useCoachingSessions();

  const history = useHistory();
  useEffect(() => {
    if (state?.coachId) {
      setIsModalOpen(true);
    }
  }, [state?.coachId]);

  if (sessionsQuery.isLoading) {
    return <Loader />;
  }

  return (
    <div className="coaching-sessions">
      <Card className="coaching-sessions__list">
        <StyledScrolls className="coaching-sessions__scroll">
          <CoachingSessionsList list={sessions} />
        </StyledScrolls>
        <Button
          className="coaching-sessions__button"
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          {t('make-appointment-with-coach')}
        </Button>

        {isParticipant && (
          <CoachingSessionModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              history.replace({
                ...history.location,
                state: { ...state, coachId: undefined },
              });
            }}
            sessionsCount={sessions?.length || 0}
            createSession={createSession}
            isSessionCreating={createSessionQuery.isLoading}
          />
        )}
      </Card>
      <div className="coaching-sessions__legend">
        <MeetingStatusLegend />
      </div>
    </div>
  );
};

export default CoachingSessions;
