import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { changeCoachForMeeting } from 'services/calendar';
import { Button } from 'components/button';
import { Textarea } from 'components/form';
import { DropdownSelect, OptionType } from 'components/form/dropdown-select';
import { Logo } from 'components/logo';
import { Modal } from 'components/modal';
import { getFullName } from 'helpers/utils';
import { useCoachesAvailabilities } from 'hooks/use-coaches-availabilities';
import { BookedSession, CoachAvailability } from 'types/api';
import './session-action-modal.scss';

export interface SessionActionModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sessionDetails: BookedSession;
}

const createCoachesOptions = (coaches: CoachAvailability[]): OptionType[] => {
  return coaches.map((coach) => ({
    value: String(coach.id),
    label: getFullName(coach),
  }));
};

export const SessionActionModal: React.FC<SessionActionModalProps> = ({
  isOpen,
  setOpen,
  sessionDetails: { course_module, id, start_time },
}) => {
  const params = {
    moduleId: String(course_module.id),
    calendarStartAndEndDates: [start_time],
  };

  const { t } = useTranslation();
  const { coachesWithAvailability } = useCoachesAvailabilities(params, isOpen);

  const [notes, setNotes] = useState('');
  const coachesOptions = createCoachesOptions(coachesWithAvailability);
  const [changeCoachMessageModal, setChangeCoachMessageModal] = useState(false);

  const queryClient = useQueryClient();

  const { mutateAsync: changeCoach } = useMutation(
    ({ meetingId, coachId }: { meetingId: number; coachId: number }) =>
      changeCoachForMeeting(meetingId, coachId),
    {
      onSuccess: () => {
        setChangeCoachMessageModal(true);
        toast.success(t('coach-is-changes'));
        queryClient.invalidateQueries([
          QueryKeys.BOOKED_SESSIONS,
          QueryKeys.COACH_SESSIONS,
        ]);
      },
      onError: () => {
        toast.error(t('failed-to-change-coach'));
      },
    }
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleChangeCoach = async ({ coach }: { coach: OptionType }) => {
    await changeCoach({ meetingId: id, coachId: +coach.value });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        className="change-coach-modal"
      >
        <form onSubmit={handleSubmit(handleChangeCoach)}>
          <h1 className="change-coach-modal__title">{t('change-coach')}</h1>
          <Controller
            control={control}
            rules={{
              required: { value: true, message: t('field-required') },
            }}
            name="coach"
            defaultValue={null}
            render={(props) => (
              <DropdownSelect
                {...props}
                options={coachesOptions}
                headingText={t('choose-coach')}
                className="change-coach-modal__select"
                isMulti={false}
                errors={errors}
              />
            )}
          />
          <Textarea
            name="notes"
            value={notes}
            className="change-coach-modal__text-area"
            inputClassName="change-coach-modal__text-area-input"
            labelClassName="change-coach-modal__text-area-label"
            label={t('coaching-session-note')}
            onChange={(value) => setNotes(value)}
          />
          <div className="change-coach-modal__buttons">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="icon-text"
              className="change-coach-modal__button"
            >
              {t('back-to-list')}
            </Button>
            <Button type="submit" className="change-coach-modal__button">
              {t('save')}
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={changeCoachMessageModal}
        onRequestClose={() => {
          setChangeCoachMessageModal(false);
          setOpen(false);
        }}
      >
        <div className="change-coach-modal-message__container">
          <Logo className="change-coach-modal-message__logo" />
          <p className="change-coach-modal-message__accept">
            {t('accept-change-coach')}
          </p>
          <p className="change-coach-modal-message__send-email">
            {t('send-acceptation-by-email')}
          </p>
          <Button
            onClick={() => {
              setChangeCoachMessageModal(false);
              setOpen(false);
            }}
          >
            {t('back-to-calendar')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
