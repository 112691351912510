import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getDashboard } from 'services/dashboard';
import { AvatarList } from 'components/avatar-list';
import { Card } from 'components/card';
import { Select } from 'components/form';
import { Loader } from 'components/loader';
import { NoResource } from 'components/no-resource';
import { Person } from 'components/person';
import { ProgressCard } from 'components/progress-card';
import { SubscriptionName } from 'components/subscription-name';
import { getPercentageValue } from 'helpers/utils';
import { useSelectOptions } from 'hooks/use-select-options';
import { CourseDashboard } from 'types/api';
import { ReactComponent as MeetingsIcon } from 'assets/icons/book-line.svg';
import { ReactComponent as IconFlag } from 'assets/icons/flag-blue-big.svg';
import { ReactComponent as IconProfile } from 'assets/icons/profile-line-icon.svg';
import './hr-dashboard.scss';

const HRDashboard: React.FC = () => {
  const [selectedCourseId, setSelectedCourseId] = useState(0);

  const { selectOptions, isLoading } = useSelectOptions({
    onSuccess: (options) => {
      if (options.length) {
        setSelectedCourseId(+options[0].value);
      }
    },
  });

  const { t } = useTranslation();

  const { data: currentCourse, isLoading: isCourseLoading } =
    useQuery<CourseDashboard>(
      [QueryKeys.HR_DASHBOARD, selectedCourseId],
      () => getDashboard(selectedCourseId),
      {
        onError: () => toast.error('course-error'),
        enabled: !!selectedCourseId,
      }
    );

  if (isLoading) {
    return <Loader />;
  }

  if (!selectOptions.length) {
    return (
      <NoResource className="hr-dashboard__info-card">
        {t('hr-without-courses')}
      </NoResource>
    );
  }

  return (
    <div className="hr-dashboard">
      <header className="hr-dashboard__header">
        <Select
          options={selectOptions}
          onChange={(value) => setSelectedCourseId(+value)}
        />
      </header>
      {currentCourse && !isCourseLoading ? (
        <div className="hr-dashboard__body">
          <div className="hr-dashboard__body-header">
            <div>
              <span className="hr-dashboard__total-progress">
                {t('total-progress')}
              </span>
              <div className="hr-dashboard__bar-with-label">
                <div className="hr-dashboard__progress-bar">
                  <div
                    className="hr-dashboard__progress-bar-filling"
                    style={{
                      width: `${currentCourse.course_stats.total_progress}%`,
                    }}
                  />
                </div>
                <div className="hr-dashboard__progress-label">
                  {currentCourse.course_stats.total_progress}%
                </div>
                <SubscriptionName
                  name={currentCourse.subscription.name}
                  className="hr-dashboard__subscription-name"
                />
              </div>
            </div>
            <AvatarList
              className="hr-dashboard__avatar-list"
              avatarList={currentCourse?.course.coaches}
            />
          </div>
          <div className="hr-dashboard__body-content">
            <div>
              <div className="hr-dashboard__progress-in-numbers">
                {t('course-progress')}
              </div>
              <div className="hr-dashboard__card-grid">
                <ProgressCard
                  text={t('started-by')}
                  count={currentCourse.course_stats.participants_stats.active}
                  maxCount={currentCourse.course_stats.participants_stats.count}
                  percentageValue={
                    currentCourse.course_stats.participants_stats
                      .percentage_active
                  }
                  icon={IconProfile}
                />
                <ProgressCard
                  text={t('lessons-finished')}
                  count={currentCourse.course_stats.lessons_stats.completed}
                  maxCount={currentCourse.course_stats.lessons_stats.count}
                  percentageValue={
                    currentCourse.course_stats.lessons_stats.finished_percentage
                  }
                  circleColor="blue"
                  icon={IconFlag}
                />
                <ProgressCard
                  text={t('booked-meetings')}
                  count={
                    currentCourse.course_stats.meetings_stats.individual
                      .scheduled
                  }
                  maxCount={
                    currentCourse.course_stats.meetings_stats.individual.count
                  }
                  percentageValue={getPercentageValue(
                    currentCourse.course_stats.meetings_stats.individual
                      .scheduled,
                    currentCourse.course_stats.meetings_stats.individual.count
                  )}
                  circleColor="blue"
                  icon={MeetingsIcon}
                />
              </div>
            </div>
            <Card className="hr-dashboard__participants">
              <div className="hr-dashboard__avatar-group">
                {currentCourse.participants.map((participant) => (
                  <div
                    className="hr-dashboard__avatar"
                    key={participant.id}
                    data-testid="course-participants"
                  >
                    <Person
                      size={58}
                      type="participant"
                      profile={participant}
                      layout="small"
                    />
                    <div className="hr-dashboard__blue-dot" />
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default HRDashboard;
