import './grid-container.scss';

enum Variant {
  WITH_PADDING = 'no-padding',
}

type GridContainerProps = {
  className?: string;
  variant?: Variant;
};

export const GridContainer: React.FC<GridContainerProps> = ({
  className,
  variant,
  children,
}) => {
  return (
    <div className={`grid-container grid-container--${variant} ${className}`}>
      {children}
    </div>
  );
};
