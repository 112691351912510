import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getAvailableUserCourses } from 'services/courses';

export const useMeetingSelectsData = () => {
  const { t } = useTranslation();

  const queries = useQueries([
    {
      queryKey: [QueryKeys.USER_COURSES],
      queryFn: () => getAvailableUserCourses(1), // TODO: After implementation group meetings, this argument should be assigned dynamically
      onError: () => {
        toast.error(t('courses-error'));
      },
    },
  ]);

  const [courseModulesQuery] = queries;

  return {
    courses: courseModulesQuery.data || [],
    courseModulesQuery,
  };
};
