import { Loader } from 'components/loader';

type CourseItemContentProps = {
  isLoading?: boolean;
  isResponseSuccess: boolean;
  children: React.ReactNode;
  errorMessage: React.ReactNode;
};

export const CourseItemContentV2: React.FC<CourseItemContentProps> = ({
  isLoading,
  isResponseSuccess,
  children,
  errorMessage,
}) => {
  if (isLoading) {
    return <Loader />;
  }

  if (isResponseSuccess) {
    return <>{children}</>;
  }

  return <>{errorMessage}</>;
};
