import { Tooltip } from 'components/tooltip';
import { Company } from 'types/api';
import { CoachRole, CoachType, HRRole, UserRole } from './roles';

type PersonType = CoachType & {
  profile: Record<string, any>;
  type: string;
  details?: boolean;
  chat?: boolean;
  isCalendar?: boolean;
  isAvatar?: boolean;
  className?: string;
  company?: Company;
};

export const Person: React.FC<PersonType> = ({ type, className, ...props }) => {
  const email = props.profile?.email;

  return (
    <div className={className}>
      <Tooltip title={email} disabled={!email}>
        {
          {
            hr: <HRRole {...props} />,
            participant: <UserRole {...props} />,
            coach: <CoachRole {...props} />,
          }[type]
        }
      </Tooltip>
    </div>
  );
};
