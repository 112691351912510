import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { profileSidebar } from 'constants/selectors/profile/profile-sidebar';
import { AuthContext, ChatContext } from 'contexts';
import { Button } from 'components/button';
import { ProfileRoute } from 'types/routes';
import { ReactComponent as IconClose } from 'assets/icons/close-line.svg';
import { ReactComponent as IconLogout } from 'assets/icons/logout-circle-line.svg';
import './profile-sidebar.scss';

type SidebarType = {
  routes: ProfileRoute[];
  keyPrefix: string;
  withCloseIcon?: boolean;
};

export const ProfileSidebar: React.FC<SidebarType> = ({
  routes,
  keyPrefix,
  withCloseIcon = false,
}) => {
  const { user, logOut } = useContext(AuthContext);
  const { closeWindow } = useContext(ChatContext);
  const { t } = useTranslation();
  const { state, pathname } = useLocation<{ linkedFrom: string }>();
  const linkedFrom = state && state.linkedFrom;
  const history = useHistory();

  return (
    <div className={clsx('profile-sidebar', profileSidebar.class)}>
      <div className="profile-sidebar__menu-list">
        <div className="profile-sidebar__info">
          <span className="text">{t('profile')}</span>
          {withCloseIcon && (
            <Link to={linkedFrom || '/'}>
              <IconClose
                className={clsx(
                  'profile-sidebar__icon',
                  profileSidebar.iconClass
                )}
              />
            </Link>
          )}
        </div>
        {user && (
          <div className="profile-sidebar__info profile-sidebar__info--user">
            <p className="profile-sidebar__user-name">
              {user.first_name} {user.last_name}
            </p>
            <p>{user.position}</p>
            <p>{user.email}</p>
          </div>
        )}

        {routes.map((route) => (
          <NavLink
            to={{
              pathname: route.path,
              state: {
                linkedFrom: withCloseIcon ? linkedFrom || '/' : pathname,
              },
            }}
            activeClassName="profile-sidebar__option--active"
            exact
            className={clsx(
              'profile-sidebar__option',
              profileSidebar.editLinkClass
            )}
            key={`${route.path}-${keyPrefix}`}
          >
            {route.name}
          </NavLink>
        ))}
        <Button
          variant="icon-text"
          className={clsx(
            'profile-sidebar__logout',
            profileSidebar.logoutButtonClass
          )}
          onClick={(e) => {
            e.stopPropagation();
            closeWindow();
            logOut();
            history.push('/');
          }}
          icon={<IconLogout />}
        >
          {t('logout')}
        </Button>
      </div>
    </div>
  );
};
