import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Logo } from 'components/logo';
import './authentication.scss';

export const RegisterSuccess: React.FC = () => {
  return (
    <div className="authentication authentication--top">
      <Logo className="authentication__logo" />
      <Trans i18nKey="registration-finished">
        <h1 className="authentication__register-success">
          Congrats! Registration success.
        </h1>
      </Trans>
      <Link
        to="/"
        className="authentication__login-link authentication__login-link--center"
      >
        <Trans i18nKey="redirect-login">
          Go to <span className="authentication__text-blue">Login</span>
        </Trans>
      </Link>
    </div>
  );
};
