import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import clsx from 'clsx';
import { addSeconds } from 'date-fns';
import { Button } from 'components/button';
import { today } from 'helpers/time';
import { useMediaRecorder } from 'hooks/use-media-recorder';
import { RecordingStatuses } from 'types/enums';
import { ReactComponent as Microphone } from 'assets/icons/microphone.svg';
import { ReactComponent as Pause } from 'assets/icons/pause.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Wastebasket } from 'assets/icons/wastebasket.svg';
import { RecorderTimeline } from './recorder-timeline';
import './voice-recorder.scss';

type VoiceRecorderProps = {
  onStop: (blobUrl: string, blob: Blob) => void;
  className?: string;
  maxLengthInSeconds: number;
};

export const VoiceRecorder: React.FC<VoiceRecorderProps> = ({
  onStop,
  maxLengthInSeconds,
  className,
}) => {
  const { t } = useTranslation();

  const {
    status,
    startRecording,
    pauseRecording,
    resumeRecording,
    deleteRecording,
    stopRecording,
    clearBlobUrl,
  } = useMediaRecorder({
    onStop,
  });

  const canStartRecording =
    status === RecordingStatuses.IDLE || status === RecordingStatuses.STOPPED;
  const isRecordingInProgress =
    status === RecordingStatuses.RECORDING ||
    status === RecordingStatuses.PAUSED;

  const getExpiryTimestamp = () => addSeconds(today(), maxLengthInSeconds);

  const handleRestartTimer = (
    callback: (newExpiryTimestamp: Date, autoStart?: boolean) => void
  ) => callback(getExpiryTimestamp(), false);

  const {
    seconds,
    minutes,
    start: startTimer,
    pause: pauseTimer,
    resume: resumeTimer,
    restart: restartTimer,
  } = useTimer({
    expiryTimestamp: getExpiryTimestamp(),
    onExpire: () => {
      stopRecording();
      handleRestartTimer(restartTimer);
    },
    autoStart: false,
  });

  const handleRemove = () => {
    clearBlobUrl();
    handleRestartTimer(restartTimer);
    deleteRecording();
  };

  const handleStartAndStopClick = async () => {
    if (canStartRecording) {
      await startRecording();
      startTimer();
    } else {
      stopRecording();
      handleRestartTimer(restartTimer);
    }
  };

  const handlePauseAndResumeClick = () => {
    if (status === RecordingStatuses.PAUSED) {
      resumeRecording();
      resumeTimer();
    } else {
      pauseRecording();
      pauseTimer();
    }
  };

  const remainingTimeInSeconds = minutes * 60 + seconds;

  return (
    <div className={clsx('voice-recorder', className)}>
      {isRecordingInProgress && (
        <>
          <Button
            variant="icon"
            onClick={handleRemove}
            icon={<Wastebasket />}
            className="voice-recorder__button"
          />

          <div className="voice-recorder__container">
            <Button
              variant="icon"
              onClick={handlePauseAndResumeClick}
              icon={status === RecordingStatuses.PAUSED ? <Play /> : <Pause />}
              className="voice-recorder__button voice-recorder__button--inner"
            />

            <RecorderTimeline
              remainingTime={remainingTimeInSeconds}
              timeInTotal={maxLengthInSeconds}
            />

            <div className="voice-recorder__timer">
              {minutes}:{seconds}
            </div>
          </div>
        </>
      )}

      <Button
        variant={canStartRecording ? 'icon' : 'secondary'}
        onClick={handleStartAndStopClick}
        icon={canStartRecording && <Microphone />}
        className="voice-recorder__button voice-recorder__button--right"
      >
        {!canStartRecording && t('add')}
      </Button>
    </div>
  );
};
