import { CirclePicker } from 'react-color';

type ColorPickerType = {
  colors?: string[];
  onChangeComplete?: (color: { hex: string }) => void;
};

export const ColorPicker: React.FC<ColorPickerType> = ({
  ...props
}: ColorPickerType) => {
  return <CirclePicker {...props} />;
};

ColorPicker.defaultProps = {
  colors: [
    '#D9E3F0',
    '#F47373',
    '#697689',
    '#37D67A',
    '#2CCCE4',
    '#555555',
    '#dce775',
    '#ff8a65',
    '#ba68c8',
    'transparent',
  ],
};
