import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientCard } from 'components/client-card';
import { Input } from 'components/form';
import { Loader } from 'components/loader';
import { NoResource } from 'components/no-resource';
import { ViewTransition } from 'components/view-transition';
import { useCompanies } from 'hooks/use-companies';

export const CompanyList: React.FC = () => {
  const [searchPhrase, setSearchPhrase] = useState('');

  const { t } = useTranslation();

  const { companies, isLoading } = useCompanies();
  const filteredCompanies = useMemo(() => {
    return companies.filter((company) =>
      company.name.toUpperCase().includes(searchPhrase.toUpperCase().trim())
    );
  }, [searchPhrase, companies]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ViewTransition resolved>
      <div className="company-list">
        {companies.length ? (
          <>
            <div className="company-list__search">
              <Input
                type="search"
                value={searchPhrase}
                placeholder={t('find-company')}
                onChange={(value) => {
                  setSearchPhrase(value);
                }}
              />
            </div>
            <div className="company-list__companies">
              {filteredCompanies.map((company) => (
                <ClientCard key={company.id} clientData={company} />
              ))}
            </div>
          </>
        ) : (
          <NoResource>{t('no-clients')}</NoResource>
        )}
      </div>
    </ViewTransition>
  );
};
