import { useMutation } from 'react-query';
import { updateVideoWatchedTime as updateVideoWatchedTimeRequest } from 'services/lesson';

export interface UpdateVideoWatchedTimeData {
  lessonSlug: string;
  parameters: { video_watched_time: number };
}

export const useUpdateVideoWatchedTime = () => {
  const { mutate: updateVideoWatchedTime, ...rest } = useMutation(
    (data: UpdateVideoWatchedTimeData) =>
      updateVideoWatchedTimeRequest(data.lessonSlug, data.parameters)
  );
  return { updateVideoWatchedTime, ...rest };
};
