import './stats-content.scss';

type StatsContentProps = {
  target?: string;
  image: string;
  description?: string;
};

export const StatsContent: React.FC<StatsContentProps> = ({
  target,
  image,
  description,
}) => (
  <div className="stats-content">
    <div className="stats-content__image">
      <img src={image} alt="tile" />
    </div>

    <div className="stats-content__text">
      {target && <div className="stats-content__header">{target}</div>}

      {description && (
        <div className="stats-content__description">{description}</div>
      )}
    </div>
  </div>
);
