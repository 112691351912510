import { FIRST_PAGE_VALUE } from 'hooks/use-pagination';
import { TasksQueryParams } from 'types/enums';
import {
  ALL_ITEMS_PARAM_VALUE,
  useQueryParameters,
} from './use-query-parameters';

export const DEFAULT_VALUE = '';

export const useTasksFilters = () => {
  const { get, replace } = useQueryParameters();

  const {
    MODULE,
    COURSE,
    PAGE,
    COMPANY,
    PARTICIPANT,
    STATUS,
    SORT_BY,
    SORT_DIRECTION,
  } = TasksQueryParams;

  const onCourseChange = (courseId: string) => {
    replace([
      {
        key: COURSE,
        value: courseId === ALL_ITEMS_PARAM_VALUE ? '' : courseId,
      },
      { key: MODULE, value: '' },
      { key: PAGE, value: '' },
      { key: COMPANY, value: '' },
      { key: PARTICIPANT, value: '' },
      { key: SORT_BY, value: '' },
      { key: SORT_DIRECTION, value: '' },
    ]);
  };

  const onCompanyChange = (companyId: string) => {
    replace([
      {
        key: COMPANY,
        value: companyId === ALL_ITEMS_PARAM_VALUE ? '' : companyId,
      },
      { key: PAGE, value: '' },
      { key: PARTICIPANT, value: '' },
    ]);
  };

  const handleParamsChange = (key: TasksQueryParams, value: string) => {
    replace([
      {
        key,
        value: value === ALL_ITEMS_PARAM_VALUE ? '' : value,
      },
      { key: PAGE, value: '' },
    ]);
  };

  const onModuleChange = (moduleId: string) => {
    handleParamsChange(MODULE, moduleId);
  };

  const onParticipantChange = (participantId: string) =>
    handleParamsChange(PARTICIPANT, participantId);

  const onAnswerStatusChange = (answerStatus: string) =>
    handleParamsChange(STATUS, answerStatus);

  const onSortByChange = (sortBy: string, value: string) => {
    handleParamsChange(SORT_BY, sortBy);
    handleParamsChange(SORT_DIRECTION, value);
  };

  const courseIdQuery = get(COURSE);
  const moduleIdQuery = get(MODULE);
  const currentPageQuery = get(PAGE) || FIRST_PAGE_VALUE;
  const companyIdQuery = get(COMPANY);
  const participantIdQuery = get(PARTICIPANT);
  const statusQuery = get(STATUS);
  const sortByQuery = get(SORT_BY);
  const sortDirectionQuery = get(SORT_DIRECTION);

  return {
    onCourseChange,
    onCompanyChange,
    onModuleChange,
    onParticipantChange,
    onAnswerStatusChange,
    onSortByChange,
    courseIds: courseIdQuery ? courseIdQuery.split(',') : null,
    moduleIds: moduleIdQuery ? moduleIdQuery.split(',') : null,
    currentPage: currentPageQuery,
    companyId: companyIdQuery,
    participantIds: participantIdQuery ? participantIdQuery?.split(',') : null,
    status: statusQuery,
    sortBy: sortByQuery,
    sortDirection: sortDirectionQuery,
  };
};
