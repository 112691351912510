import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { ParticipantAssignment } from 'components/participant-assignment';
import { CompanyDetails, Profile } from 'types/api';
import { TaskCreateStep } from 'types/enums';
import { DEFAULT_FORM_VALUES, TaskForm, TaskFormData } from './task-form';
import { TaskModalHeader } from './task-modal-header';
import './task-modal.scss';

type TaskModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialFormValues?: TaskFormData;
  onSubmit: (data: TaskFormData) => void;
  companies?: CompanyDetails[];
  isEditing?: boolean;
};

export const TaskModal: React.FC<TaskModalProps> = ({
  isOpen,
  onClose,
  initialFormValues = DEFAULT_FORM_VALUES,
  onSubmit,
  companies,
  isEditing = false,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<TaskFormData>(initialFormValues);
  const [currentStep, setCurrentStep] = useState(TaskCreateStep.CREATE);
  const [assignedParticipants, setAssignedParticipants] = useState<Profile[]>(
    []
  );

  const assignParticipant = (participant: Profile) => {
    setAssignedParticipants((prevState) => [participant, ...prevState]);
  };

  const removeParticipant = (participantId: number) => {
    setAssignedParticipants((prevState) =>
      prevState.filter((participant) => participant.id !== participantId)
    );
  };

  const goToNextStep = (data: TaskFormData) => {
    setFormData(data);
    setCurrentStep(TaskCreateStep.ASSIGN);
  };

  const onModalClose = () => {
    setFormData(initialFormValues);
    setCurrentStep(TaskCreateStep.CREATE);
    setAssignedParticipants([]);
    onClose();
  };

  const handleTaskSubmit = () => {
    const participantsIds = assignedParticipants.map(
      (participant) => participant.id
    );
    onSubmit({ ...formData, participant_ids: participantsIds });
    onModalClose();
  };

  const handleEditSubmit = (data: TaskFormData) => {
    onSubmit({ ...data, resource_id: formData.resource_id });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onModalClose}
      className="task-modal"
      bodyClassName="task-modal__body"
      noPadding
      maxWidth="1052px"
    >
      <div className="task-modal__wrapper">
        <TaskModalHeader>{t('new-task')}</TaskModalHeader>

        {currentStep === TaskCreateStep.CREATE && (
          <TaskForm
            onClose={onModalClose}
            initialFormValues={formData}
            submit={isEditing ? handleEditSubmit : goToNextStep}
            isEditing={isEditing}
          />
        )}

        {currentStep === TaskCreateStep.ASSIGN &&
          formData.course_id &&
          !isEditing && (
            <section>
              <ParticipantAssignment
                companies={companies}
                assignedParticipants={assignedParticipants}
                handleAssignParticipant={assignParticipant}
                handleUnassignParticipant={removeParticipant}
                courseId={+formData.course_id}
              />
              <p className="task-modal__information">
                {t('task-assignment-info')}
              </p>
              <div className="task-modal__buttons">
                <Button onClick={handleTaskSubmit}>{t('confirm')}</Button>
                <Button
                  onClick={() => setCurrentStep(TaskCreateStep.CREATE)}
                  variant="secondary"
                >
                  {t('back')}
                </Button>
              </div>
            </section>
          )}
      </div>
    </Modal>
  );
};
