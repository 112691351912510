import clsx from 'clsx';
import './styled-scrolls.scss';

type StyledScrollsProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const StyledScrolls: React.FC<StyledScrollsProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div {...props} className={clsx('styled-scrolls', className)}>
      {children}
    </div>
  );
};
