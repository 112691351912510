import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChosenTimeslot } from 'components/coaching-session-modal/select-date-step';
import { ConfirmContent } from 'components/confirm-modal';
import { Modal } from 'components/modal';
import { today } from 'helpers/time';
import { useCoachSessions } from 'hooks/use-coach-sessions';
import { BookedSession } from 'types/api';
import { NewDateSelect } from './new-date-select';
import './edit-meeting-modal.scss';

const initialSlot: ChosenTimeslot = {
  startTime: today().toISOString(),
  endTime: today().toISOString(),
};

enum EditMeetingSteps {
  EDIT_DATE = 'edit',
  CONFIRM = 'confirm',
}

type EditMeetingModalProps = {
  isOpen: boolean;
  close: () => void;
  sessionDetails: BookedSession;
};

export const EditMeetingModal: React.FC<EditMeetingModalProps> = ({
  isOpen,
  close,
  sessionDetails: { id, course_module, provider, participants },
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(EditMeetingSteps.EDIT_DATE);
  const [updatedSlot, setUpdatedSlot] = useState<ChosenTimeslot>(initialSlot);
  const { updateCoachSession } = useCoachSessions();

  const submit = async () => {
    const participantIds = participants.map((participant) => participant.id);
    await updateCoachSession({
      meetingId: id,
      dataToSend: {
        start_time: updatedSlot.startTime,
        end_time: updatedSlot.endTime,
        duration: 60,
        user_ids: participantIds,
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="edit-meeting-modal"
      bodyClassName="edit-meeting-modal__body"
      noPadding
      maxWidth="1011px"
    >
      <div className="edit-meeting">
        <form onSubmit={(event) => event.preventDefault()}>
          <h2 className="edit-meeting-modal__title">
            {t('edit-coaching-session')}
          </h2>

          {step === EditMeetingSteps.EDIT_DATE && (
            <NewDateSelect
              moduleId={course_module?.id.toString() || '-1'}
              provider={provider}
              participant={participants[0]}
              updatedSlot={updatedSlot}
              setUpdatedSlot={setUpdatedSlot}
              goForward={() => setStep(EditMeetingSteps.CONFIRM)}
              goBack={close}
            />
          )}

          {step === EditMeetingSteps.CONFIRM && (
            <ConfirmContent
              description={t('confirm-edit-session')}
              confirmButtonText={t('next')}
              cancelButtonText={t('cancel-edit')}
              onConfirm={() => {
                close();
                setStep(EditMeetingSteps.EDIT_DATE);
                submit();
              }}
              onCancel={() => setStep(EditMeetingSteps.EDIT_DATE)}
            />
          )}
        </form>
      </div>
    </Modal>
  );
};
