import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { TasksListItem } from 'components/tasks-list-item';
import { LessonTask as Task } from 'types/api';
import { AnswerStatus } from 'types/enums';
import { LessonTaskIcon } from './lesson-task-icon';

type LessonTaskProps = {
  isTaskModalOpen: boolean;
  onModalOpen: () => void;
  onModalClose: () => void;
  onSend: () => void;
  task: Task;
  isLessonCompleted: boolean;
};

export const LessonTask: React.FC<LessonTaskProps> = ({
  isTaskModalOpen,
  onModalOpen,
  onModalClose,
  onSend,
  task,
  isLessonCompleted,
}) => {
  const { t } = useTranslation();
  const { isCoach, isHR } = useContext(AuthContext);

  const answerStatus = task.participant_answer?.status;

  const isAnswerAccepted = answerStatus === AnswerStatus.ACCEPTED;
  const isAnswerVerifying = answerStatus === AnswerStatus.TO_VERIFY;
  const isAnswerRejected = answerStatus === AnswerStatus.FAILED;

  const isTaskButtonDisabled =
    !(isCoach || isHR) &&
    (!isLessonCompleted || isAnswerAccepted || isAnswerVerifying);

  const tippyContent =
    (isAnswerAccepted && t('task-accepted')) ||
    (isAnswerVerifying && t('task-verifying')) ||
    (isAnswerRejected && t('task-to-improve')) ||
    ((isCoach || isHR || isLessonCompleted) && t('task-preview')) ||
    t('task-tippy');

  const taskAnswer = task.participant_answer;

  return (
    <>
      {!isAnswerVerifying && (
        <Modal
          isOpen={isTaskModalOpen}
          onRequestClose={onModalClose}
          maxWidth="1000px"
          noPadding={!isCoach && isTaskModalOpen}
        >
          <TasksListItem
            onSend={onSend}
            {...task}
            taskAnswer={
              taskAnswer
                ? { ...taskAnswer, answer_date: taskAnswer.created_at }
                : null
            }
            hideModal={onModalClose}
          />
        </Modal>
      )}

      <Tippy offset={[0, 10]} content={tippyContent}>
        <div className="lesson-details__button-with-tippy">
          <Button
            variant="icon"
            disabled={isTaskButtonDisabled}
            className={clsx(
              isTaskButtonDisabled &&
                'lesson-details__exercise-button--approved'
            )}
            icon={<LessonTaskIcon status={answerStatus} />}
            onClick={onModalOpen}
          />
        </div>
      </Tippy>
    </>
  );
};
