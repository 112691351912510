import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { Button } from 'components/button';
import { Card } from 'components/card';
import TaskIcon from 'assets/img/dashboardTile3.png';
import './task-to-evaluation-card.scss';

export const TaskToEvaluationCard = () => (
  <Card className="task-to-evaluation-card">
    <div className="task-to-evaluation">
      <div className="task-to-evaluation__header">
        <span className="task-to-evaluation__header--title">
          {t('task-to-evaluate')}
        </span>
      </div>

      <img
        className="task-to-evaluation__image"
        src={TaskIcon}
        alt={t('task-preview')}
      />

      <Link
        className="task-to-evaluation__link"
        to={{
          pathname: '/tasks',
        }}
      >
        <Button className="primary">{t('go-to-tasks')}</Button>
      </Link>
    </div>
  </Card>
);
