import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import clsx from 'clsx';
import { SessionTypeIds } from 'constants/sessions';
import { AuthContext } from 'contexts';
import { Loader } from 'components/loader';
import { Modal } from 'components/modal';
import { useMeetingSelectsData } from 'hooks/use-meeting-selects-data';
import {
  AvailableCourseModule,
  BookedSession,
  BookedSessionStatus,
  SessionWithCoachNewTimeslots,
} from 'types/api';
import { ConfirmSessionStep } from './confirm-session-step';
import { FinalSessionStep } from './final-session-step';
import { SelectCourseStep } from './select-course-step';
import { SelectDateStep } from './select-date-step';
import './coaching-session-modal.scss';

type CoachingSessionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sessionsCount: number;
  createSession: UseMutateFunction<
    BookedSession,
    unknown,
    SessionWithCoachNewTimeslots,
    unknown
  >;
  isSessionCreating: boolean;
};
export enum SessionModalStep {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
}
export type FirstStepFormProps = {
  course: string;
  moduleId: string;
};

export type SecondStepFormProps = {
  coach: { id: number; firstName: string; lastName: string; avatar?: string };
  start_time: string;
  end_time: string;
};

const initialFirstStepValue = {
  course: '-1',
  moduleId: '-1',
  avatar: undefined,
};
const initialSecondStepValue = {
  coach: { id: -1, firstName: 'Not', lastName: 'found' },
  start_time: '-1',
  end_time: '-1',
};

export const CoachingSessionModal: React.FC<CoachingSessionModalProps> = ({
  isOpen,
  onClose,
  sessionsCount,
  createSession,
  isSessionCreating,
}) => {
  const { user } = useContext(AuthContext);

  const meetingUser = {
    id: user?.id ?? -1,
    email: user?.email ?? '',
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    avatar: user?.avatar.preview ?? '',
    company: undefined,
  };

  const [step, setStep] = useState<SessionModalStep>(SessionModalStep.FIRST);
  const [firstStepValue, setFirstStepValue] = useState<FirstStepFormProps>(
    initialFirstStepValue
  );
  const [secondStepValue, setSecondStepValue] = useState<SecondStepFormProps>(
    initialSecondStepValue
  );
  const { t } = useTranslation();
  const [selectedCourse, setSelectedCourse] =
    useState<AvailableCourseModule | null>(null);
  const { courses, courseModulesQuery } = useMeetingSelectsData();

  const isCoursesLoading = courseModulesQuery.isLoading;

  useEffect(() => {
    setSelectedCourse(courses[0] || null);
  }, [courses]);

  const resetForm = () => {
    setSelectedCourse(courses[0] || null);
    setFirstStepValue(initialFirstStepValue);
    setSecondStepValue(initialSecondStepValue);
    setStep(SessionModalStep.FIRST);
  };

  const closeModalHandler = () => {
    onClose();
    resetForm();
  };

  const sessionDetails: (BookedSession & { end_time: string }) | null =
    selectedCourse &&
    secondStepValue.end_time !== initialSecondStepValue.end_time
      ? {
          id: -1,
          course: { id: selectedCourse.id, name: selectedCourse.course.name },
          course_module: {
            id: firstStepValue.moduleId,
            name:
              selectedCourse.course.course_modules.find(
                (module) => module.id === +firstStepValue.moduleId
              )?.name ?? '',
          },
          start_time: secondStepValue.start_time,
          end_time: secondStepValue.end_time,
          company_subscription_course_id: null,
          status: BookedSessionStatus.CONFIRMED,
          provider: {
            id: -1,
            email: '',
            first_name: secondStepValue.coach.firstName,
            last_name: secondStepValue.coach.lastName,
            avatar: secondStepValue.coach.avatar,
          },
          participants: [meetingUser],
        }
      : null;

  const handleSendData = (note: string | null) => {
    if (selectedCourse) {
      const dataToSend: SessionWithCoachNewTimeslots = {
        coach_id: secondStepValue.coach.id,
        start_time: secondStepValue.start_time,
        end_time: secondStepValue.end_time,
        course_module_id: +firstStepValue.moduleId,
        company_subscription_course_id: selectedCourse?.id,
        meeting_type_id: SessionTypeIds.INDIVIDUAL_SESSION_ID,
        note,
      };
      createSession(dataToSend, {
        onSuccess: () => {
          setStep(SessionModalStep.FOURTH);
        },
      });
    }
  };

  if (isCoursesLoading && isOpen) {
    return <Loader />;
  }
  if (!courses.length) {
    return (
      <Modal isOpen={isOpen} onRequestClose={closeModalHandler}>
        <div className="coaching-session__fallback">
          {t('not-assigned-to-any-course')}
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      maxWidth="1011px"
      className="coaching-session-modal"
      bodyClassName="coaching-session-modal__body"
      noPadding
      onRequestClose={closeModalHandler}
    >
      <div className="coaching-session">
        {step !== SessionModalStep.FOURTH && (
          <div className="coaching-session__title">
            {t('new-coaching-session')}
          </div>
        )}

        <div
          className={clsx(
            'coaching-session__step',
            step === SessionModalStep.FIRST && 'coaching-session__step--active'
          )}
        >
          <SelectCourseStep
            setSelectedCourse={setSelectedCourse}
            setStepFormValue={setFirstStepValue}
            courses={courses}
            selectedCourse={selectedCourse}
            setStep={setStep}
          />
        </div>

        <div
          className={clsx(
            'coaching-session__step',
            step === SessionModalStep.SECOND && 'coaching-session__step--active'
          )}
        >
          <SelectDateStep
            selectedCourse={selectedCourse}
            moduleId={firstStepValue?.moduleId}
            setStep={setStep}
            setSecondStepValue={setSecondStepValue}
          />
        </div>

        <div
          className={clsx(
            'coaching-session__step',
            step === SessionModalStep.THIRD && 'coaching-session__step--active'
          )}
        >
          {sessionDetails && (
            <ConfirmSessionStep
              sessionDetails={sessionDetails}
              sessionsCount={sessionsCount}
              handleSendData={handleSendData}
              setStep={setStep}
              isProcessing={isSessionCreating}
            />
          )}
        </div>
        <div
          className={clsx(
            'coaching-session__step',
            step === SessionModalStep.FOURTH && 'coaching-session__step--active'
          )}
        >
          <FinalSessionStep
            closeModal={closeModalHandler}
            resetForm={resetForm}
          />
        </div>
      </div>
    </Modal>
  );
};
