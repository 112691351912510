import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCompany } from 'services/companies';

export const useCompanyData = (companyId: string) => {
  const { t } = useTranslation();
  const { data, ...rest } = useQuery(
    [QueryKeys.COMPANY, companyId],
    () => getCompany(companyId),
    {
      onError: () => {
        toast.error(t('company-error'));
      },
    }
  );

  return { company: data || null, ...rest };
};
