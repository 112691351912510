import { SessionTypeIds } from 'constants/sessions';
import { TaskFormData } from 'components/task-modal/task-form';
import {
  AnswerStatus,
  Position,
  QuizAttemptType,
  ResourceTypes,
} from './enums';

export type Answer = {
  answer: string;
  answer_date: string;
  collateral: AnswerAttachment;
  id: number;
  note: string;
  status: AnswerStatus;
  user: ProfileWithCompany;
};

export type LessonDetailsAnswer = {
  id: number;
  answer: string;
  status: AnswerStatus;
  note: string;
  created_at: string;
  updated_at: string | null;
  completed_at: string | null;
  task_id: number;
  user_id: number;
};

export type AnswerData = {
  task_answer: {
    note: string;
    status: AnswerStatus;
  };
};

export interface TaskBase {
  id: number;
  name: string;
  description?: string;
  instruction: string;
}

export interface LessonTask {
  id: number;
  name: string;
  description?: string;
  instruction: string;
  participant_answer: LessonDetailsAnswer | null;
  owner: Profile | null;
}

export interface Task extends TaskBase {
  completed: boolean;
  course: { id: number; name: string };
  owner: Profile | null;
  participants: Profile[];
  resource: { id: number; name: string };
  resource_type?: ResourceTypes;
  answers: Answer[];
  module_lesson?: { id: number; name: string };
  resource_number: number;
  additional: boolean;
}

export type TaskData = {
  task: TaskFormData & { resource_type: ResourceTypes };
};

export type TasksMeta = {
  current_page: number;
  next_page: number;
  per_page: number;
  prev_page: number | null;
  total_count: number;
  total_pages: number;
};

export type TasksAPIData = {
  tasks: Task[];
  meta: TasksMeta;
};

export type CourseCoach = {
  id: number;
  first_name: string;
  last_name: string;
  avatar: string | null;
  team: string;
  email?: string;
  position: null | Position;
  description: string;
};

export type AnswerAttachment = {
  id: number;
  name: string;
  content: string;
  description: string;
  document_type: string;
  url: string;
};

export type Profile = {
  avatar: string | null;
  first_name: string;
  id: number;
  last_name: string;
  email?: string;
  position: string;
  team: string;
  title: string;
  description: string;
  role: string;
  could_be_notified?: boolean;
  confirmed?: boolean;
  department?: string;
};

export type Participant = Profile & {
  avgProgress?: number;
  company: Company;
};

export type LessonType = 'Quiz' | 'Wideo';

export type CourseLesson = {
  id: number;
  slug: string;
  course_module_id: number;
  name: string;
  order: number;
  duration: null | number;
  user_lesson: UserLesson | null;
  video_url: string;
  task: LessonTask | null;
  module_lesson_attachments: ModuleAttachment[];
  course_module_attachments: ModuleAttachment[];
};

interface UserLesson {
  id: number;
  completed: boolean;
  video_watched_time: number;
}

export type Notepad = {
  id: number;
  content: string;
  resource_id: number;
  resource_type: string;
};

export type NotepadData = {
  note: {
    content: string;
  };
};

export type Message = {
  created_at: string;
  id: number;
  text: string;
  user_id: number;
};

export type ProfileWithCompany = Profile & { company: Company | null };

export type Conversation = {
  messages: Message[];
  channel_sid: string;
  id: number;
  last_message: Message;
  last_message_unseen: boolean;
  invited: ProfileWithCompany;
  owner: ProfileWithCompany;
  unseen_messages_count: number;
  viewed_time: string | null;
  viewed_id: number | null;
};

export type UnseenMessagesCount = {
  unseen_messages_count: number;
};

export type MeetingsStats = {
  individual: {
    scheduled: number;
    count: number;
  };
  certificated: {
    scheduled: number;
    count: number;
  };
  group: {
    scheduled: number;
    count: number;
  };
};

export type CourseStats = {
  lessons_stats: {
    completed: number;
    count: number;
    finished_percentage: number;
  };
  participants_stats: {
    active: number;
    count: number;
    percentage_active: number;
  };
  total_progress: number;
  meetings_stats: MeetingsStats;
};

export type CourseDashboard = {
  course: {
    coaches: CourseCoach[];
    id: number;
    name: string;
  };
  course_stats: CourseStats;
  participants: Participant[];
  subscription: SubscriptionInfo;
};

export type CourseDashboardDetails = {
  completed_lessons_count: number;
  display_order: number;
  id?: number;
  lessons_count: number;
  name: string;
  progress: number;
};

export type ParticipantDashboard = {
  lesson_to_do: {
    course_module: {
      id: number;
      module_progress: number;
      name: string;
      display_order: number;
    };
    id: number;
    display_order: number;
    name: string;
  } | null;

  quiz_to_do: {
    completed_attempts_count: number;
    course_module: {
      display_order: number;
      id: number;
      name: string;
    };
    id: number;
    name: string;
  } | null;

  task_to_do: {
    id: number;
    name: string;
    resource: {
      slug: string;
      id: number;
      name: string;
    };
    resource_type: string;
  } | null;

  course_modules: CourseDashboardDetails[];

  tiles_stats: {
    uncompleted_lessons_count: number;
    uncompleted_modules_count: number;
    uncompleted_quizzes_count: number;
    uncompleted_tasks_count: number;
  };
};

type ReportStatus = 'queued' | 'in-progress' | 'completed';

export type Report = {
  completed_at: string | null;
  csv_filename: string | null;
  id: number;
  status: ReportStatus;
  url: string;
};

export type CompanyDetails = {
  description?: string | null;
  id: number;
  is_active?: boolean;
  name: string;
  url?: string;

  logo: string | null;
  stats?: {
    groupMeet: number;
    faceToFaceMeet: number;
  };
};

export type CourseModulePreview = {
  id: number;
  name: string;
  display_order: number;
};

export type CourseModules = {
  name: string;
  id: number;
  coaches: Coach[];
  course_modules: { name: string; id: number }[];
  video_lessons_duration: number;
  tasks_count: number;
  quizzes_count: number;
  background_url: string | null;
  company_subscription_course_id: number;
  end_date: string;
};

export type AvailableCourseModule = {
  id: number;
  end_date: Date;
  course: {
    id: number;
    name: string;
    course_modules: {
      id: number;
      name: string;
      display_order: number;
    }[];
  };
};

export interface SessionWithCoach {
  coach_id: number;
  selected_date: string;
  time_slot_id: number;
  meeting_type_id: SessionTypeIds;
  course_module_id: number;
  company_subscription_course_id: number;
  note: string | null;
  user_ids?: number[];
}

export type Client = {
  coaches: CourseCoach[];
  courses_count: number;
  courses: number[];
  hr: Profile & { email: string };
  id: number;
  logo: string | null;
  name: string;
  participants_count: number;
};

export type SubscriptionInfo = {
  id: number;
  name: string;
};
export type CompanySubscriptionCourses = {
  id: number;
  slots: number;
  start_date: string;
  participants_count: number;
  total_progress: number | null;
  end_date: string;
  course: SubscriptionInfo;
};
export type CompanySubscription = {
  id: number;
  subscription: SubscriptionInfo;
  company_subscription_courses: CompanySubscriptionCourses[];
};

export type ResetPasswordRequestData = {
  email: string;
};

export type ResetPasswordData = {
  password_token: string;
  password: string;
  confirm_password: string;
};

export enum ModuleStatus {
  INACTIVE = 'inactive',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export type Module = {
  id: number;
  name: string;
  status: ModuleStatus;
  first_lesson_id: number | null;
  video_lessons_duration: number;
};

export type CourseItem = {
  id: number;
  name: string;
  coaches?: CourseCoach[];
  video_lessons_duration: number;
  tasks_count: number;
  quizzes_count: number;
  course_modules?: Module[];
  background_url: string | null;
};

export type Company = {
  id: number;
  name: string;
  url: string;
  logo: string | null;
  description?: string;
  is_active?: boolean;
};

export type SubscriptionCourse = {
  id: number;
  course: CourseItem;
  start_date: string;
  end_date: string;
  total_progress: number;
  participants_count?: number;
  slots: number;
  subscription?: {
    id: number;
    name: string;
  };
  company?: Company;
};

export type SelectOption = {
  id: number | string;
  name: string;
};

export interface OrderedSelectOption extends SelectOption {
  display_order: number;
}

export type SubscriptionSelectOptions = {
  id: number;
  slots: number;
  start_date: string;
  end_date: string;
  participants_count: number;
  total_progress: number;
  course: SelectOption;
  company: SelectOption;
  subscription: SelectOption;
};

export type SubscriptionCourseParams = {
  select?: boolean;
  finished?: boolean;
  company_id?: string;
};

export type UserSelect = {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
};

export type ReportSelectsData = {
  id: number;
  start_date: string;
  end_date: string;
  course: SelectOption & {
    course_modules: OrderedSelectOption[];
  };
  users: UserSelect[];
  company: {
    id: number;
    name: string;
  };
};

export type TimeSlot = {
  id: number;
  time: string;
  busy: boolean;
};

export type Availability = {
  date: string;
  time_slots: TimeSlot[];
};

export type CoachAvailability = {
  id: number;
  first_name: string;
  last_name: string;
  avatar: string;
  availability: Availability[];
};

export type TimeslotsAvailability = {
  start_time: string;
  end_time: string;
};

export type CoachTimeslots = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  availability: TimeslotsAvailability[];
};

export type SessionWithCoachNewTimeslots = Omit<
  SessionWithCoach,
  'selected_date' | 'time_slot_id'
> &
  TimeslotsAvailability;

export type SortedTimeslot = {
  date: string;
  timeSlots: TimeslotsAvailability[];
};

export type SessionStartTime = 'today' | 'this_week' | 'this_month';

export type SessionBooking = {
  selected_date: string;
  coach_id: number;
  time_slot_id: number;
};

export type GroupSessionBooking = SessionBooking & {
  user_ids: number[];
  note?: string;
};

export type GroupSessionEditing = {
  user_ids?: number[];
  course_id?: number;
  selected_date?: string;
  time_slot_id?: number;
};

export interface Faq {
  id: number;
  created_at: string;
  active: boolean;
  answer: string;
  category: string;
  question: string;
  updated_at: string;
}

export interface FaqApiData {
  success: boolean;
  response: Faq[];
}

export enum BookedSessionStatus {
  PENDING = 'pending',
  APPLICANT_CONFIRMED = 'applicant_confirmed',
  RECIPIENT_CONFIRMED = 'recipient_confirmed',
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
}

export type MeetingUser = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: string;
  company?: Omit<Company, 'url'>;
};

export type BookedSession = {
  id: number;
  course: SelectOption;
  course_module: SelectOption;
  start_time: string;
  meeting_duration?: number;
  status: BookedSessionStatus;
  provider: MeetingUser | null;
  participants: MeetingUser[];
  company_subscription_course_id: number | null;
  note?: string;
};

export type CoachBusinessHours = {
  day_name: string;
  day_id?: number;
  start_time: string;
  end_time: string;
  is_active: boolean;
  id?: number;
  name?: string;
  status?: string;
};

export type CoachCalendarData = {
  meetings: BookedSession[];
  business_hours: CoachBusinessHours[];
};

export type Lesson = {
  id: number;
  slug?: string;
  name: string;
  course_module_id: number;
  cover_image: string | null;
  cover_description: string;
  type: string;
  video_length: number | null;
  video_watched_time: number | null;
  task_status: AnswerStatus | null;
  completed: boolean;
};

export type ModuleAttachment = {
  id: number;
  name: string;
  document_type: string;
  url: string;
};

export enum CompletedAttemptsCount {
  NO_ATTEMPTS = 0,
  ONE_ATTEMPT = 1,
  TWO_ATTEMPTS = 2,
}

export type Quiz = {
  completed_attempts_count: CompletedAttemptsCount;
  id: number;
  name: string;
};

export type ModuleDetails = {
  coach: CourseCoach;
  name: string;
  id: number;
  video_count: number;
  quizzes_count: number;
  quiz?: Quiz | null;
  status: string;
  lessons: Lesson[];
  module_lesson_attachements?: ModuleAttachment[];
  course_module_attachments?: ModuleAttachment[];
  attachments: ModuleAttachment[];
  module_progress: number;
};

export type CourseAside = {
  course_id: number;
  id: number;
  notepad: Notepad | null;
  modules: ModuleDetails[];
};

export type CourseHeader = {
  id: number;
  course: SelectOption;
  subscription: SelectOption;
  start_date: string;
  end_date: string;
  slots: number;
};

export type Intro = {
  intro_video_url: string;
};

export type UpdateMeetingParams = {
  start_time: string;
  end_time: string;
  duration: number;
  user_ids: number[];
};

export type InvitationsSummary = {
  added_to_course: string[];
  already_in_course: string[];
  // should be: "sent_invitation", but must be fixed on BE side first
  sent_ivitation: string[];
  course_name?: string;
};

export type QuizAnswer = {
  question_id: number;
  answer_option_id: number;
  correct: boolean;
};

export type QuizAttempt = {
  id: number;
  attempt_type: QuizAttemptType;
  completed_at: string;
  total_answers_count: number;
  correct_answers_count: number;
  score: number;
  percentage_growth?: number;
  questions?: {
    id: number;
    body: string;
    answer_options: { id: number; body: string; correct?: boolean }[];
  }[];
  answers?: QuizAnswer[];
};

export type QuizInfo = {
  attempt_type: QuizAttemptType;
  can_attempt: boolean;
  quiz: {
    id: number;
    name: string;
  };
  quiz_attempt: QuizAttempt | null;
};

export type QuizQuestion = {
  id: number;
  question: string;
  kind: string;
  display_order: number;
  answers: {
    id: number;
    label: string;
  }[];
};

export type QuizQuestionV2 = {
  id: number;
  body: string;
  answer_options: {
    id: number;
    body: string;
  }[];
};

export type StartQuizResponse = {
  id: number;
  quiz: {
    id: number;
    name: string;
  };
  questions: QuizQuestionV2[];
};

export type CoachAndHrQuizResponse = {
  id: number;
  name: string;
  questions: QuizQuestionV2[];
};

export interface Coach {
  id: number;
  first_name: string;
  last_name: string;
  avatar: string | null;
  position: Position;
  title: string;
}

export interface CalendarCoach {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export type CourseCertificate = {
  id: number;
  name: string;
  finished_at: string;
};

export type ParticipantProgressCourse = {
  id: number;
  name: string;
  percentage_progress: number;
  end_date: string;
};

export type ParticipantProgress = Omit<Profile, 'department' | 'role'> & {
  courses: ParticipantProgressCourse[];
};

export type ModuleProgress = {
  id: number;
  course_module_id: number;
  course_module_name: string;
  module_progress: number;
  total_lessons_count: number;
  completed_lessons_count: number;
  total_tasks_count: number;
  completed_tasks_count: number;
  pre_quiz_score: number | null;
  post_quiz_score: number | null;
  individual_meetings_count: number;
};

export type SendNotificationResponse = { message: string };

export type AvailableCourses = Omit<
  CourseItem,
  'coaches' | 'course_modules'
> & {
  course_modules: {
    id: number;
    name: string;
    video_lessons_duration: number;
  }[];
};

export type CoachTasksData = Pick<
  CourseModules,
  'id' | 'name' | 'course_modules'
>;
