import { usePagination } from 'hooks/use-pagination';
import { PaginationUi } from './pagination-ui';

type PaginationProps = {
  totalPageCount: number;
  isListEmpty?: boolean;
  className?: string;
};

export const Pagination = ({
  totalPageCount,
  isListEmpty,
  className,
}: PaginationProps) => {
  const { paginationRange, goToPage, currentPage } = usePagination({
    totalPageCount,
    isListEmpty,
  });

  return (
    <PaginationUi
      currentPage={currentPage}
      totalPageCount={totalPageCount}
      className={className}
      goToPage={goToPage}
      paginationRange={paginationRange}
    />
  );
};
