import { ChatButton } from 'components/chat/chat-button';
import { LazyAvatar } from 'components/lazy-avatar';
import { getUserInitials } from 'helpers/utils';
import { ProfileType } from 'types/user';
import './hr-details.scss';

type DetailView = {
  chat: boolean;
  hrDetails: ProfileType;
};

export const HrDetails: React.FC<DetailView> = ({
  hrDetails: {
    id,
    first_name: firstName,
    last_name: lastName,
    avatar,
    role,
    team,
  },
  chat,
}) => {
  return (
    <article className="hr-details">
      <header className="hr-details__header">
        <div className="hr-details__profile">
          <div className="hr-details__avatar">
            <LazyAvatar
              imageClassName="hr-details__image"
              src={avatar}
              alt={`${firstName} ${lastName}`}
              userInitials={getUserInitials({
                first_name: firstName,
                last_name: lastName,
              })}
            />
          </div>

          <div className="hr-details__details">
            <h2 className="hr-details__name">
              {firstName} {lastName}
            </h2>

            <h3 className="hr-details__title">
              {role}{' '}
              {team && <span className="hr-details__team"> | {team}</span>}
            </h3>
          </div>
        </div>

        {chat && (
          <div className="hr-details__tools">
            <ChatButton
              buttonClassName="hr-details__button"
              userIdToChatWith={id}
            />
          </div>
        )}
      </header>
    </article>
  );
};
