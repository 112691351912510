import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'helpers/axios';

export const RequestMonitor: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const interceptorIdentifier = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status >= 500) {
          toast.error(
            t(`Nieoczekiwany błąd serwera! [${error.response.status}]`)
          );
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptorIdentifier);
    };
  }, [t]);

  return <></>;
};
