import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TFunctionResult } from 'i18next';
import './textarea.scss';

type TextareaProps = {
  type?: string;
  label?: string | boolean | TFunctionResult;
  name?: string;
  placeholder?: string;
  visiblePassword?: boolean;
  register?: any;
  hasCounter?: boolean;
  errors?: FieldErrors<Record<string, string>>;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  redTextColor?: boolean;
  className?: string;
  labelClassName?: string;
  rows?: number;
  inputClassName?: string;
};

export const Textarea = ({
  label,
  name,
  placeholder,
  register,
  hasCounter,
  errors = {},
  disabled = false,
  value,
  onChange,
  redTextColor,
  className,
  labelClassName,
  rows,
  inputClassName,
}: TextareaProps) => {
  const MAX_INPUT_CHARS = 500;

  const getCharsNumber = (
    currentValueLength: number | undefined,
    maxLength: number
  ) => {
    if (currentValueLength) {
      const valueToReturn = maxLength - currentValueLength;
      if (valueToReturn < 0) {
        return 0;
      }
      return valueToReturn;
    }
    return maxLength;
  };

  const { t } = useTranslation();
  const errorLabelWithFallback =
    (name && errors[name]?.message) || `${t('field-required')}`;
  const computedProps = onChange
    ? {
        value,
        onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange(event.target.value as string);
        },
      }
    : { defaultValue: value };

  return (
    <div className={clsx('form-textarea', className)}>
      <label
        className={clsx('form-textarea__label', labelClassName)}
        htmlFor={`form-${name}`}
      >
        {label}
      </label>
      <textarea
        {...computedProps}
        rows={rows}
        name={name}
        placeholder={placeholder}
        ref={register}
        disabled={disabled}
        id={`form-${name}`}
        className={clsx(
          'form-textarea__textarea',
          {
            'form-textarea__textarea--red': redTextColor,
            'form-textarea__textarea--error':
              name && errors?.[name] && errorLabelWithFallback,
          },
          inputClassName
        )}
      />
      {name && errors?.[name] && errorLabelWithFallback && (
        <span className="form-textarea__error">{errorLabelWithFallback}</span>
      )}
      {hasCounter && (
        <span className="form-textarea__counter">
          {t('chars-number-left', {
            number: getCharsNumber(value?.length, MAX_INPUT_CHARS),
          })}
        </span>
      )}
    </div>
  );
};
