import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/card';
import { GridContainer } from 'components/grid-container';
import { Loader } from 'components/loader';
import { Modal } from 'components/modal';
import { MultiSelect } from 'components/multiselect';
import { NoResource } from 'components/no-resource';
import { Pagination } from 'components/pagination';
import { TaskInfo } from 'components/task-info';
import { TasksListItem } from 'components/tasks-list-item';
import { ViewTransition } from 'components/view-transition';
import {
  createSelectOptions,
  findSelectedModules,
  getTaskName,
  makeQueryValue,
} from 'helpers/utils';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { useSelectsData } from 'hooks/use-selects-data';
import { useTasks } from 'hooks/use-tasks';
import { useTasksFilters } from 'hooks/use-tasks-filters';
import { Task } from 'types/api';
import { AnswerStatus, ResourceTypes } from 'types/enums';
import './tasks-list.scss';

type TaskListProps = {
  apiParameters?: Record<string, unknown>;
  onlyTasksToImprove?: boolean;
};

export const TasksList: React.FC<TaskListProps> = ({
  apiParameters,
}: TaskListProps) => {
  const { courseIds, moduleIds, currentPage, onCourseChange, onModuleChange } =
    useTasksFilters();
  const { get } = useQueryParameters();
  const selectedTaskId = get('task-open') || '';
  const [openedTask, setOpenedTask] = useState<Task | null>(null);
  const [afterSent, setAfterSent] = useState(false);
  const { t } = useTranslation();

  const parameters = {
    course_id: courseIds || null,
    course_module_id: moduleIds || null,
    ...apiParameters,
    page: currentPage,
  };

  const { tasks, restTasksQuery, tasksMeta } = useTasks(parameters);

  const areTasksLoading = restTasksQuery.isLoading;
  const isTaskListEmpty = tasks.length === 0;
  const isOnlyOnePage = tasksMeta?.total_pages === 1;
  const isMoreThanOnePage = tasksMeta && tasksMeta?.total_pages > 1;

  const shouldPaginationRender =
    isMoreThanOnePage || (isOnlyOnePage && isTaskListEmpty);

  useEffect(() => {
    setOpenedTask(
      tasks.find((task) => task.id === Number(selectedTaskId)) || null
    );
  }, [selectedTaskId, tasks]);

  const onSave = () => {
    setOpenedTask(null);

    if (afterSent) {
      setAfterSent(false);
    }
  };

  const { courseModulesQuery, courses } = useSelectsData();

  const coursesOptions = createSelectOptions(courses);

  const modulesOptions = createSelectOptions(
    findSelectedModules(courses, courseIds || [])
  );
  const isCoursesLoading = courseModulesQuery.isLoading;

  const taskAnswer = openedTask?.answers[0];

  if (areTasksLoading) {
    return <Loader />;
  }

  return (
    <ViewTransition resolved={!areTasksLoading}>
      <div className="tasks-list">
        <div className="tasks-list__selects">
          {!isCoursesLoading && courses.length > 0 && (
            <>
              <MultiSelect
                className="tasks-list__selects__select tasks-list__selects__select--large"
                values={courseIds || []}
                options={coursesOptions}
                onMenuClose={(values) => onCourseChange(makeQueryValue(values))}
                placeholder={t('filter-by-course')}
              />

              <MultiSelect
                className="tasks-list__selects__select tasks-list__selects__select--large"
                values={moduleIds || []}
                options={modulesOptions}
                onMenuClose={(values) => onModuleChange(makeQueryValue(values))}
                placeholder={t('filter-by-module')}
              />
            </>
          )}
        </div>
        {tasks.length ? (
          <>
            <Modal
              isOpen={!!openedTask}
              noPadding
              maxWidth="1000px"
              onRequestClose={onSave}
            >
              {!!openedTask && (
                <TasksListItem
                  isFinished={!!apiParameters?.finished}
                  {...openedTask}
                  taskAnswer={taskAnswer || null}
                  hideModal={() => {
                    setOpenedTask(null);
                  }}
                  onSave={() => {
                    setAfterSent(true);
                  }}
                />
              )}
            </Modal>
            <GridContainer>
              {tasks.map((task) => (
                <Card key={task.id}>
                  <div className="task">
                    <button
                      onClick={() => {
                        setOpenedTask(task);
                      }}
                      className="task__name-button"
                    >
                      <div className="task__ellipsis-wrapper">
                        {getTaskName(task)}
                      </div>
                    </button>
                    <div className="task__course-name">
                      <div>{task.course.name}</div>
                      {task.answers.length > 0 &&
                        task.answers[0].status === 'failed' && (
                          <div className="task__rejected">{t('rejected')}</div>
                        )}
                    </div>
                    <TaskInfo
                      className="task__task-info"
                      isModule={
                        task.resource_type === ResourceTypes.COURSE_MODULE
                      }
                      resourceOrder={task.resource_number}
                      resourceName={task.resource.name}
                      owner={task.owner}
                    />
                    <div
                      className="task__description"
                      dangerouslySetInnerHTML={{
                        __html: `${task.instruction}`,
                      }}
                    />
                  </div>
                </Card>
              ))}
            </GridContainer>
            {tasksMeta && shouldPaginationRender && (
              <Pagination
                totalPageCount={+tasksMeta.total_pages}
                isListEmpty={tasks.length === 0}
                className="tasks-list__pagination"
              />
            )}
          </>
        ) : (
          <NoResource>
            {apiParameters?.to_do && t('no-active-tasks')}
            {apiParameters?.finished && t('no-finished-tasks')}
            {apiParameters?.to_verify && t('no-send-tasks')}
            {apiParameters?.status === AnswerStatus.FAILED &&
              t('no-tasks-to-improve')}
          </NoResource>
        )}
      </div>
    </ViewTransition>
  );
};
