import { useCallback, useEffect, useMemo, useState } from 'react';

type MediaQueries<T> = Record<string, T>;

type UseMediaParameters<T> = {
  mediaQueries: MediaQueries<T>;
  defaultValue: T;
};

export const MOBILE_MAX_WIDTH_QUERY = '(max-width: 760px)';
const EVENT_NAME = 'change';

export const useMedia = <T>({
  mediaQueries,
  defaultValue,
}: UseMediaParameters<T>) => {
  const mediaWatchers = useMemo(
    () => Object.keys(mediaQueries).map((query) => matchMedia(query)),
    [mediaQueries]
  );

  const getValue = useCallback(() => {
    const matchingWatcher = mediaWatchers.find((watcher) => watcher.matches);
    return matchingWatcher ? mediaQueries[matchingWatcher.media] : defaultValue;
  }, [defaultValue, mediaQueries, mediaWatchers]);

  const [currentValue, setCurrentValue] = useState(getValue);

  useEffect(() => {
    const handleMediaChange = () => setCurrentValue(getValue);

    mediaWatchers.forEach((watcher) =>
      watcher.addEventListener(EVENT_NAME, handleMediaChange)
    );

    return () =>
      mediaWatchers.forEach((watcher) =>
        watcher.removeEventListener(EVENT_NAME, handleMediaChange)
      );
  }, [getValue, mediaWatchers]);

  return currentValue;
};
