import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { Card } from 'components/card';
import { Loader } from 'components/loader';
import { Person } from 'components/person';
import { StyledScrolls } from 'components/styled-scrolls';
import { ViewTransition } from 'components/view-transition';
import { decipherId } from 'helpers/utils';
import { useCourseCoaches } from 'hooks/use-course-coaches';
import { CourseErrorHandle } from './course-error-handle';
import './course-coaches.scss';

export const CourseCoaches: React.FC = () => {
  const { courseId: courseCipheredId } = useParams<{ courseId: string }>();
  const courseId = decipherId(courseCipheredId);
  const { t } = useTranslation();
  const { isLoading, coaches } = useCourseCoaches(courseId);
  const { isParticipant, user } = useContext(AuthContext);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ViewTransition resolved={!isLoading}>
      {coaches?.length ? (
        <div className="course-coaches">
          <Card className="course-coaches__card">
            <StyledScrolls className="course-coaches__scrolls">
              <div className="course-coaches__list">
                {coaches.map((coach) => (
                  <Person
                    key={coach.id}
                    className="person-component"
                    layout="large"
                    profile={coach}
                    details
                    chat={coach.id !== user?.id}
                    isCalendar={isParticipant}
                    type="coach"
                  />
                ))}
              </div>
            </StyledScrolls>
          </Card>
        </div>
      ) : (
        <CourseErrorHandle lackOf={t('of-coaches')} />
      )}
    </ViewTransition>
  );
};
