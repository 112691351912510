import { useContext } from 'react';
import { AuthContext } from 'contexts';
import { useRoutes } from './use-routes';

export const useProfileRoutes = () => {
  const { profileRoutes } = useRoutes();
  const { userRole } = useContext(AuthContext);

  const routesPerRole =
    (userRole &&
      profileRoutes.filter(
        (route) => route.roles?.includes(userRole) || !route.roles
      )) ||
    [];

  return { routesPerRole };
};
