import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import './no-certificates.scss';

export const NoCertificates = () => {
  return (
    <section className="no-certificates">
      <Trans i18nKey="no-certificates">
        <h2 className="no-certificates__heading">
          You don't have any certifcates of acomplishment course
        </h2>
        <p>
          Finish course and get your first certificate. Go to &nbsp;
          <Link className="no-certificates__link" to="/courses">
            courses
          </Link>
        </p>
      </Trans>
    </section>
  );
};
