import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { AuthContext } from 'contexts';
import { getCompanies, getParticipantWithCompanyId } from 'services/companies';
import { getCoachCourses } from 'services/courses';
import { useQueryParameters } from './use-query-parameters';

export const useSelectsData = () => {
  const { t } = useTranslation();
  const { get } = useQueryParameters();
  const companyId = get('company');

  const user = useContext(AuthContext);

  const queries = useQueries([
    {
      queryKey: [QueryKeys.USER_COURSES],
      queryFn: () => getCoachCourses(),
      onError: () => {
        toast.error(t('courses-error'));
      },
    },
    {
      queryKey: [QueryKeys.COMPANIES],
      queryFn: () => getCompanies(),
      onError: () => {
        toast.error(t('companies-error'));
      },
      enabled: user.isCoach || user.isHR,
    },
    {
      queryKey: [QueryKeys.PARTICIPANTS_BY_COMPANY_ID, companyId],
      queryFn: () => getParticipantWithCompanyId(+companyId!),
      enabled: !!companyId,
      onError: () => {
        toast.error(t('participants-error'));
      },
    },
  ]);

  const [courseModulesQuery, companyDetailsQuery, participantsQuery] = queries;

  return {
    courses: courseModulesQuery.data || [],
    companies: companyDetailsQuery.data || [],
    participants: participantsQuery.data || [],
    courseModulesQuery,
    companyDetailsQuery,
    participantsQuery,
  };
};
