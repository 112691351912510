import { TabRouter } from 'components/tab-router';
import { useCoursesNavItems } from 'hooks/use-courses-nav-items';
import './courses.scss';

export const Courses: React.FC = () => {
  const coursesNavItems = useCoursesNavItems();

  return (
    <div className="courses">
      <TabRouter items={coursesNavItems} />
    </div>
  );
};

export default Courses;
