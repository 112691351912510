import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getParticipantWithCompanyId } from 'services/companies';
import { Profile } from 'types/api';

enum TypeView {
  ASSIGNED_PARTICIPANTS = 'assigned_participants',
  COMPANY_PARTICIPANTS = 'company_participants',
}

export const useParticipants = (
  compId: number,
  courseId: number | null,
  assignedParticipants: Profile[]
) => {
  const { t } = useTranslation();

  const [participantListView, setParticipantsListView] = useState<TypeView>(
    TypeView.COMPANY_PARTICIPANTS
  );

  const [searchPhrase, setSearchPhrase] = useState('');
  const [companyId, setCompanyId] = useState(compId);

  const { data: companyParticipants, ...restCompanyParticipantsQuery } =
    useQuery(
      [QueryKeys.PARTICIPANTS_BY_COMPANY_ID, companyId, courseId],
      () => getParticipantWithCompanyId(companyId, courseId!),
      {
        onError: () => {
          toast.error(t('participants-error'));
        },
        onSuccess: () => {
          setParticipantsListView(TypeView.COMPANY_PARTICIPANTS);
        },
        enabled: !!(companyId && courseId),
      }
    );

  const participantList: Profile[] = useMemo(() => {
    if (participantListView === TypeView.ASSIGNED_PARTICIPANTS) {
      return assignedParticipants;
    }

    const unassignedParticipants = companyParticipants
      ? companyParticipants.filter(
          (particicipant) =>
            !assignedParticipants.some(
              (assignedParticipant) =>
                assignedParticipant.id === particicipant.id
            )
        )
      : [];

    return unassignedParticipants;
  }, [assignedParticipants, companyParticipants, participantListView]);

  const filteredParticipants = useMemo(
    () =>
      participantList?.filter((participant) => {
        const fullName = `${participant.first_name} ${participant.last_name}`;

        return fullName.toUpperCase().includes(searchPhrase.toUpperCase());
      }),
    [participantList, searchPhrase]
  );

  return {
    filteredParticipants,
    searchPhrase,
    setSearchPhrase,
    participantListView,
    setParticipantsListView,
    setCompanyId,
    restCompanyParticipantsQuery,
  };
};
