import clsx from 'clsx';
import './radio-button.scss';

type RadioButtonProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'type'
> & {
  label: string;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  checked,
  className,
  ...otherProps
}) => (
  <label className={clsx('radio-button', className)}>
    <input
      className="radio-button__input"
      type="radio"
      checked={checked}
      {...otherProps}
    />

    <span
      className={clsx(
        'radio-button__label',
        checked && 'radio-button__label--checked'
      )}
    >
      {label}
    </span>
  </label>
);
