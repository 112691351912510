import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { AuthContext } from 'contexts';
import { Loader } from 'components/loader';
import { ViewTransition } from 'components/view-transition';
import { format } from 'helpers/time';
import { useCertificates } from 'hooks/use-certificates';
import { Certificate } from './components/certifcate';
import { CertificateWrapper } from './components/certificate-wrapper';
import { NoCertificates } from './components/no-certificates';
import './certificates.scss';

const Certificates: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { certificates, isLoading } = useCertificates();

  if (isLoading) {
    return <Loader />;
  }

  const renderCertificates = certificates?.map((certificate) => (
    <CertificateWrapper
      key={certificate.id}
      courseName={certificate.name}
      certificate={(isPdf: boolean) => (
        <Certificate
          isPdf={isPdf}
          courseName={certificate.name}
          date={format(certificate.finished_at, 'onlyDate')}
          fullName={`${user?.first_name} ${user?.last_name}`}
        />
      )}
    />
  ));

  return (
    <ViewTransition resolved={!isLoading}>
      {certificates?.length ? (
        <section className="certificates-view">
          <h2 className="certificates-view__header">
            <Trans i18nKey="certificates-count" count={certificates.length}>
              You got
              <span className="certificates-view__text-bold">
                {` ${certificates.length} `}
              </span>
              certifcates of acomplishment course
            </Trans>
          </h2>
          <main className="certificates-view__main">{renderCertificates}</main>
        </section>
      ) : (
        <NoCertificates />
      )}
    </ViewTransition>
  );
};

export default Certificates;
