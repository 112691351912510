import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { ChatContext } from 'contexts';
import { Button } from 'components/button';
import { Position } from 'types/enums';
import { ReactComponent as IconChat } from 'assets/icons/chat-2.svg';
import './chat-button.scss';

type ChatButtonProps = {
  userIdToChatWith: number;
  disabled?: boolean;
  buttonClassName?: string;
  position?: string;
  additionalChatAction?: () => void;
};

export const ChatButton: React.FC<ChatButtonProps> = ({
  userIdToChatWith,
  disabled = false,
  buttonClassName,
  position,
  additionalChatAction,
}) => {
  const { openConversation } = useContext(ChatContext);
  const { t } = useTranslation();

  if (position === Position.PARTICIPANT) {
    position = t('with-participant');
  } else if (position === Position.HR) {
    position = 'HR';
  } else {
    position = t('with-coach');
  }

  const clickHandler = () => {
    if (additionalChatAction) {
      additionalChatAction();
    }
    openConversation(userIdToChatWith);
  };

  return (
    <Tippy content={t('chat-with', { position })}>
      <div className="button-wrapper">
        <Button
          disabled={disabled}
          className={clsx('blue-icon', disabled && 'disabled', buttonClassName)}
          onClick={clickHandler}
          variant="icon"
          icon={<IconChat />}
        />
      </div>
    </Tippy>
  );
};
