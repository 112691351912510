import { useTranslation } from 'react-i18next';
import { Person } from 'components/person';
import { ViewTransition } from 'components/view-transition';
import './style-guide.scss';

const COACH_MOCK = {
  id: 1,
  first_name: 'John',
  last_name: 'Doe',
  avatar: 'https://thispersondoesnotexist.com/image',
  position: 'Trener Motywacyjny',
  role: 'CEO',
  team: 'SkillsArena',
};

const requireModule = require.context('assets/icons/', true, /\.svg$/);

const icons = requireModule
  .keys()
  .filter((fileName) => {
    const isRelativeImport = fileName.startsWith('./');

    return !isRelativeImport;
  })
  .map((fileName: string) => {
    const url = requireModule(fileName) as string;

    return {
      url,
      name: fileName
        .replace('./', '')
        .replace('.svg', '')
        .replace('assets/icons/', ''),
    };
  });

const StyleGuide: React.FC = () => {
  const { t } = useTranslation();

  const textImportIcon = (name: string): string => {
    const nameIcon = name
      .split('-')
      .map((val) => val[0].toUpperCase() + val.slice(1))
      .toString()
      .replaceAll(',', '');
    return `import { ReactComponent as ${nameIcon} } from 'assets/icons/${name}.svg';`;
  };

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const VideoText = `
    <Video source="https://cutt.ly/ejxxvS5" />
    `;

  return (
    <ViewTransition>
      <div className="style-guide">
        <div className="style-guide__section">
          <div className="style-guide__title">{t('icons')}</div>
          <div className="style-guide__subtitle">{t('click-icon')}</div>
          <div className="style-guide__icon-grid">
            {icons.map((icon) => (
              <button
                key={icon.url}
                onClick={() => copy(textImportIcon(icon.name))}
                className="style-guide__icon-wrapper"
              >
                <img
                  src={icon.url}
                  className="style-guide__icon"
                  alt={icon.name}
                />
                <span className="style-guide__icon-name">{icon.name}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="style-guide__section">
          <div className="style-guide__title">{t('controls')}</div>
          <div className="style-guide__subtitle">{t('click-code')}</div>
          <br />
          <div className="style-guide__controls-columns">
            <button
              className="style-guide__video-source"
              onClick={() => copy(VideoText)}
            >
              {VideoText}
            </button>
          </div>
          <div className="style-guide__subtitle">{t('profiles')}</div>
          <div className="style-guide__controls-column">
            <div>
              Small
              <Person profile={COACH_MOCK} type="coach" />
            </div>
            <div>
              Small with name
              <Person profile={COACH_MOCK} type="coach" showName />
            </div>
            <div>
              Small with name and width
              <Person profile={COACH_MOCK} type="coach" showName size={60} />
            </div>
            <div>
              Small disable details
              <Person
                profile={COACH_MOCK}
                type="coach"
                showName
                size={90}
                details={false}
              />
            </div>
          </div>
          <div className="style-guide__controls-column">
            <div>
              Large
              <Person profile={COACH_MOCK} type="coach" layout="large" />
            </div>
            <div>
              Large with tools
              <Person profile={COACH_MOCK} type="coach" layout="large" chat />
            </div>
          </div>
        </div>
      </div>
    </ViewTransition>
  );
};

export default StyleGuide;
