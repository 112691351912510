import { useTranslation } from 'react-i18next';
import { AttachmentItem } from 'components/attachment-item';
import { Card } from 'components/card';
import { StyledScrolls } from 'components/styled-scrolls';
import { ModuleAttachment } from 'types/api';
import './attachments-list.scss';

type AttachmentsListProps = {
  list?: ModuleAttachment[];
};

export const AttachmentsList: React.FC<AttachmentsListProps> = ({
  list = [],
}) => {
  const { t } = useTranslation();

  return (
    <Card className="attachments-list">
      {list.length ? (
        <StyledScrolls className="attachments-list__scrolls">
          <div className="attachments-list__wrapper">
            <span className="attachments-list__description">
              {t('click-download')}
            </span>
            <ul className="attachments-list__list">
              {list.map(({ id, url, name, document_type }) => (
                <li key={id}>
                  <AttachmentItem
                    url={url}
                    name={name}
                    fileExtension={document_type}
                  />
                </li>
              ))}
            </ul>
          </div>
        </StyledScrolls>
      ) : (
        <div className="attachments-list__empty">{t('no-attachments')}</div>
      )}
    </Card>
  );
};
