import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { getCurrentModuleInfo } from 'helpers/quiz';
import { ModuleDetails } from 'types/api';
import { ParticipantQuiz } from './participant-quiz';
import { SupervisorQuiz } from './supervisor-quiz';

type QuizProps = {
  modules: ModuleDetails[];
  moveToNextQuiz: () => Promise<void>;
};

export const Quiz: React.FC<QuizProps> = ({ modules, moveToNextQuiz }) => {
  const { isParticipant } = useContext(AuthContext);
  const { quizId } = useParams<{ quizId: string }>();

  const currentModuleInfo = getCurrentModuleInfo(modules, +quizId);

  return isParticipant ? (
    <ParticipantQuiz
      currentModuleInfo={currentModuleInfo}
      moveToNextQuiz={moveToNextQuiz}
    />
  ) : (
    <SupervisorQuiz currentModuleInfo={currentModuleInfo} />
  );
};
