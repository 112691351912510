import clsx from 'clsx';
import './separator.scss';

type SeparatorProps = {
  className?: string;
};

export const Separator: React.FC<SeparatorProps> = ({ className }) => (
  <div className={clsx('separator', className)} />
);
