import { useTranslation } from 'react-i18next';
import { TabRouter } from 'components/tab-router';
import { ViewTransition } from 'components/view-transition';
import { CompanyList } from './components/item/company-list';
import { CompanyView } from './components/item/company-view';
import './coach-companies.scss';

const CoachCompanies: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ViewTransition resolved>
      <TabRouter
        items={[
          {
            url: '/companies',
            label: t('courses'),
            component: CompanyList,
            hidden: true,
          },
          {
            url: '/companies/:path*',
            label: 'hidden',
            component: CompanyView,
            hidden: true,
          },
        ]}
      />
    </ViewTransition>
  );
};

export default CoachCompanies;
