import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { SessionModalStep } from 'components/coaching-session-modal';
import { Textarea } from 'components/form';
import { SessionCard, SessionCardProps } from 'components/session-card';
import { StateSetter } from 'helpers/types';
import './confirm-session-step.scss';

type ConfirmSessionStepProps = SessionCardProps & {
  handleSendData: (note: string | null) => void;
  setStep: StateSetter<SessionModalStep>;
  isProcessing: boolean;
};

export const ConfirmSessionStep: React.FC<ConfirmSessionStepProps> = ({
  handleSendData,
  setStep,
  isProcessing,
  sessionDetails,
  sessionsCount,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset, errors } = useForm();
  const submit = async (data: { note: string }) => {
    await handleSendData(data.note || null);
    reset();
  };
  return (
    <div className="confirm-session-step">
      <p className="confirm-session-step__sub-title">{t('session-details')}</p>
      <div className="confirm-session-step__session">
        <SessionCard
          sessionDetails={sessionDetails}
          sessionsCount={sessionsCount}
        />
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <Textarea
          className="confirm-session-step__note"
          rows={3}
          name="note"
          register={register({
            maxLength: { value: 500, message: t('max-500-chars') },
          })}
          errors={errors}
          label={`${t('coaching-session-note')}`}
        />

        <div className="confirm-session-step__buttons">
          <Button
            onClick={() => {
              reset();
              setStep(SessionModalStep.SECOND);
            }}
            variant="secondary"
          >
            {t('back')}
          </Button>
          <Button loading={isProcessing} type="submit">
            {t('confirm')}
          </Button>
        </div>
      </form>
    </div>
  );
};
