import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { AnswerStatus } from 'types/enums';
import { ReactComponent as IconTaskApproved } from 'assets/icons/task-approved.svg';
import { ReactComponent as IconTaskRejected } from 'assets/icons/task-rejected.svg';
import { ReactComponent as IconTaskInReview } from 'assets/icons/task-review.svg';
import { ReactComponent as IconTaskToDo } from 'assets/icons/task-to-do.svg';
import './task-icon.scss';

type LessonTaskIconProps = {
  taskStatus?: AnswerStatus | null;
  isLessonDone?: boolean;
};

export const LessonTaskIcon: React.FC<LessonTaskIconProps> = ({
  taskStatus,
  isLessonDone,
}) => {
  const { t } = useTranslation();

  switch (taskStatus) {
    case AnswerStatus.ACCEPTED:
      return <IconTaskApproved className="task-icon task-icon--approved" />;

    case AnswerStatus.FAILED:
      return <IconTaskRejected className="task-icon task-icon--rejected" />;

    case AnswerStatus.TO_VERIFY:
      return <IconTaskInReview className="task-icon task-icon--in-review" />;

    case AnswerStatus.TO_DO:
      return isLessonDone ? (
        <IconTaskToDo className="task-icon task-icon--to-do" />
      ) : (
        <Tippy content={t('task-tippy')}>
          <IconTaskToDo
            style={{ height: 25 }}
            className="task-icon task-icon--to-do"
          />
        </Tippy>
      );

    default:
      return null;
  }
};
