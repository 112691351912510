export const signUpSelectors = {
  emailInputClass: 'cypress-sign-up-email-input',
  emailErrorClass: 'cypress-sign-up-email-error',
  firstNameInputClass: 'cypress-sign-up-first-name-input',
  lastNameInputClass: 'cypress-sign-up-last-name-input',
  positionInputClass: 'cypress-sign-up-position-input',
  passwordInputClass: 'cypress-sign-up-password-input',
  passwordErrorClass: 'cypress-sign-up-password-error',
  repeatPasswordInputClass: 'cypress-sign-up-repeat-password-input',
  repeatPasswordErrorClass: 'cypress-sign-up-repeat-password-error',
  submitClass: 'cypress-sign-up-submit',
  togglerClass: 'cypress-sign-up-toggler',
  feedbackClass: 'cypress-sign-up-feedback',
};
