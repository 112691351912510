import { initReactI18next } from 'react-i18next';
import 'intl-pluralrules';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-http-backend
i18n.use(Backend);

if (process.env.NODE_ENV !== 'test') {
  // pass the i18n instance to react-i18next.
  i18n.use(initReactI18next);

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  i18n.init({
    initImmediate: false,
    preload: ['pl'],
    fallbackLng: 'pl',
    compatibilityJSON: 'v4',
  });
}

export default i18n;
