import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Card } from 'components/card';
import { ReactComponent as Logo } from 'assets/img/logo_small.svg';
import pustelnik from 'assets/img/pustelnik.png';
import './certificate.scss';

type CertificateProps = {
  fullName: string;
  date: string;
  courseName: string;
  isPdf: boolean;
};

export const Certificate: React.FC<CertificateProps> = ({
  fullName,
  date,
  courseName,
  isPdf = false,
}) => {
  const { t } = useTranslation();
  return (
    <Card className={clsx('certificate', isPdf && 'certificate--pdf')}>
      <Logo className="certificate__logo" />
      <header>
        <h2 className="certificate__heading">{t('certificate')}</h2>
        <h3 className="certificate__heading certificate__heading--small">
          {t('complete-course')}
        </h3>
      </header>
      <main className="certificate__main">
        <p>{t('certification')}</p>
        <p className="certificate__text">{fullName}</p>
        <p>{t('finished-course')}</p>
        <p className="certificate__text certificate__text--blue">
          &ldquo;{courseName}&rdquo;
        </p>
      </main>
      <footer className="certificate__footer">
        <p>
          <Trans i18nKey="certificate-date" values={{ date }}>
            Date: <time dateTime="22.03.19">{date}</time>r.
          </Trans>
        </p>
        <div className="certificate__sign">
          <img
            alt={t('sign')}
            className="certificate__sign-icon"
            src={pustelnik}
          />
          <p className="certificate__sign-text">{t('coach-sign')}</p>
        </div>
      </footer>
    </Card>
  );
};
