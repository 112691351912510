import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { edit } from 'constants/selectors/profile/edit';
import { AuthContext } from 'contexts';
import {
  deleteUserAvatar as deleteUserAvatarRequest,
  updateProfile as updateProfileRequest,
} from 'services/user';
import { v4 as uuid } from 'uuid';
import { Button } from 'components/button';
import { ConfirmContent } from 'components/confirm-modal';
import { Input } from 'components/form';
import { ImageCropper } from 'components/image-cropper/image-cropper';
import { Modal } from 'components/modal';
import {
  FIRST_NAME as FIRST_NAME_REGEX,
  LAST_NAME as LAST_NAME_REGEX,
  POSTION_AND_TEAM,
} from 'helpers/validate';
import { useFormPersist } from 'hooks/use-form-persist';
import './edit.scss';

const PROFILE_PICTURE_FILE_EXTENSIONS = '.jpg, .jpeg, .png';

enum ValuesNames {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  POSITION = 'position',
  TEAM = 'team',
  EMAIL = 'email',
  PROFILE_IMAGE = 'profile_image',
  COMPANY = 'company_name',
}

type LocalStorageValuesProps = {
  first_name: string;
  last_name: string;
  position: string;
  team: string;
  email: string;
};

const Edit: React.FC = () => {
  const { user, updateUserData } = useContext(AuthContext);
  const [avatar, setAvatar] = useState<Blob | null>(null);
  const [isAvatarDeleted, setIsAvatarDeleted] = useState<boolean>(false);

  const defaultPersistedValues = useMemo(
    () => ({
      [ValuesNames.FIRST_NAME]: user?.first_name,
      [ValuesNames.LAST_NAME]: user?.last_name,
      [ValuesNames.POSITION]: user?.position,
      [ValuesNames.TEAM]: user?.team,
      [ValuesNames.EMAIL]: user?.email,
    }),
    [user?.first_name, user?.last_name, user?.position, user?.team, user?.email]
  );

  const persistedValueRef = useRef(localStorage.getItem('profileForm'));
  const localStorageValues: LocalStorageValuesProps | null =
    persistedValueRef.current && JSON.parse(persistedValueRef.current);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultPersistedValues,
  });
  const { t } = useTranslation();
  const registerValue = (regex: RegExp, message: string, isRequired = false) =>
    isRequired
      ? register({
          required: true,
          minLength: { value: 2, message: `${t('min-2-chars')}` },
          maxLength: { value: 35, message: `${t('max-35-chars')}` },
          pattern: {
            value: regex,
            message,
          },
        })
      : register({
          pattern: {
            value: regex,
            message,
          },
        });

  const { mutate: updateProfile, isLoading: isProfileUpdating } = useMutation(
    (formData: FormData) => updateProfileRequest(formData),
    {
      onSuccess: (data) => {
        updateUserData(data);
        toast.success(t('changes-saved'));
      },
      onError: () => {
        toast.error(t('saving-error'));
      },
      onSettled: () => {
        setAvatar(null);
      },
    }
  );

  const { mutate: deleteUserAvatar, isLoading: isAvatarDeleting } = useMutation(
    () => deleteUserAvatarRequest(),
    {
      onError: () => {
        toast.error(t('saving-error'));
      },
    }
  );

  const submit = (data: {
    first_name: string;
    last_name: string;
    profile_image: File;
    position: string;
    team: string;
  }) => {
    const formData = new FormData();

    const updatedRows: Record<string, string> = {
      first_name: data.first_name,
      last_name: data.last_name,
      position: data.position,
      team: data.team,
    };

    Object.entries(updatedRows).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (avatar) {
      const file = new File([avatar], `avatar-${uuid()}`, {
        type: avatar.type,
      });
      formData.append('image_collateral_attributes[image]', file);
    }

    if (isAvatarDeleted) {
      deleteUserAvatar();
    }
    updateProfile(formData);
  };

  const [isOpen, setIsOpen] = useState(false);
  const watchFormValues = watch(Object.keys(defaultPersistedValues));

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = () => setIsOpen(false);

  const { confirm } = useFormPersist({
    name: 'profileForm',
    defaultValues: defaultPersistedValues,
    values: watchFormValues,
    setValue,
    onPreviousSessionIsDirty: openModal,
  });

  const handleRetrieveSession = () => {
    confirm();
    closeModal();
  };

  if (!user) {
    return null;
  }

  return (
    <div className="edit">
      {localStorageValues?.email === defaultPersistedValues.email && (
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          noPadding
          maxWidth="790px"
        >
          <ConfirmContent
            className="edit__confirm"
            title={t('restoring-changes')}
            description={t('should-restore-changes')}
            confirmButtonText={t('yes')}
            cancelButtonText={t('no')}
            onConfirm={handleRetrieveSession}
            onCancel={closeModal}
          />
        </Modal>
      )}
      <form onSubmit={handleSubmit(submit)} className="edit__form">
        {user && (
          <ImageCropper
            defaultPhotoUrl={user.avatar.preview}
            setFile={setAvatar}
            accept={PROFILE_PICTURE_FILE_EXTENSIONS}
            setIsAvatarDeleted={setIsAvatarDeleted}
          />
        )}
        <Input
          label={t('name')}
          name={ValuesNames.FIRST_NAME}
          errors={errors}
          register={registerValue(FIRST_NAME_REGEX, t('name-only-chars'), true)}
          inputClassName={edit.firstNameInputClass}
          errorClassName={edit.firstNameErrorClass}
        />
        <Input
          label={t('last-name')}
          name={ValuesNames.LAST_NAME}
          errors={errors}
          register={registerValue(
            LAST_NAME_REGEX,
            t('last-name-only-chars'),
            true
          )}
          inputClassName={edit.lastNameInputClass}
          errorClassName={edit.lastNameErrorClass}
        />
        <Input
          label={`${t('position')} ${t('optional')}`}
          name={ValuesNames.POSITION}
          errors={errors}
          register={register({
            minLength: { value: 2, message: `${t('min-2-chars')}` },
            maxLength: { value: 35, message: `${t('max-35-chars')}` },
            pattern: {
              value: POSTION_AND_TEAM,
              message: `${t('position-only-chars')}`,
            },
          })}
          inputClassName={edit.positionInputClass}
          errorClassName={edit.positionErrorClass}
        />
        <Input
          label={`${t('team')} ${t('optional')}`}
          name={ValuesNames.TEAM}
          errors={errors}
          register={register({
            minLength: { value: 2, message: `${t('min-2-chars')}` },
            maxLength: { value: 35, message: `${t('max-35-chars')}` },
            pattern: {
              value: POSTION_AND_TEAM,
              message: `${t('team-only-chars')}`,
            },
          })}
          inputClassName={edit.teamInputClass}
          errorClassName={edit.teamErrorClass}
        />
        <Input
          label={t('company')}
          name={ValuesNames.COMPANY}
          value={user?.company_name}
          disabled
        />
        <Input label={t('e-mail')} disabled value={user?.email} />
        <div className="edit__button-group">
          <Button
            className={edit.submitClass}
            type="submit"
            variant="primary"
            loading={isProfileUpdating || isAvatarDeleting}
          >
            {t('save-changes')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Edit;
