import { useEffect, useState } from 'react';
import { MINUTE_MS } from 'constants/time';
import { today } from 'helpers/time';

export const useNow = (refreshCycle = MINUTE_MS) => {
  const [now, setNow] = useState(today());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(today());
    }, refreshCycle);

    return () => clearInterval(intervalId);
  }, [refreshCycle]);

  return now;
};
