import clsx from 'clsx';
import { t } from 'i18next';
import { LazyAvatar } from 'components/lazy-avatar';
import { CoachProps } from 'components/person/roles';
import { PolymorphicButton } from 'components/polymorphic-button';
import { getFullName, getUserInitials } from 'helpers/utils';
import { Company } from 'types/api';
import { ProfileType } from 'types/user';
import './small-layout.scss';

type SmallLayoutProps = Pick<ProfileType, 'avatar'> &
  Pick<CoachProps, 'showName' | 'size' | 'details'> & {
    showDetails?: (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    firstName?: string;
    lastName?: string;
    isAvatar?: boolean;
    company?: Company;
  };

export const SmallLayout: React.FC<SmallLayoutProps> = ({
  avatar,
  firstName,
  lastName,
  showDetails,
  size,
  isAvatar,
  showName,
  details,
  company,
}) => {
  const fullName =
    firstName && lastName
      ? getFullName({ first_name: firstName ?? '', last_name: lastName ?? '' })
      : t('account-inactive');

  return (
    <PolymorphicButton
      as={details ? 'button' : 'div'}
      className={clsx('small-layout', details && 'small-layout--button')}
      title={company ? `${fullName} - ${company.name}` : fullName}
      {...(details && { onClick: showDetails })}
    >
      <div
        className={clsx(
          'small-layout__avatar',
          isAvatar && 'small-layout__avatar--circle'
        )}
        style={{ width: size, height: size }}
      >
        <LazyAvatar
          userInitials={getUserInitials({
            first_name: firstName,
            last_name: lastName,
          })}
          imageClassName="small-layout__image"
          src={avatar}
          alt={`${firstName} ${lastName}`}
        />
      </div>
      {showName && (
        <div className="small-layout__profile-name">
          {firstName} {lastName}
        </div>
      )}
    </PolymorphicButton>
  );
};
