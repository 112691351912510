import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

type ViewTransitionProps = {
  resolved?: boolean;
};

export const ViewTransition: React.FC<ViewTransitionProps> = ({
  resolved,
  children,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <CSSTransition
      in={resolved ?? mounted}
      timeout={250}
      classNames="view-animation"
    >
      {children}
    </CSSTransition>
  );
};
