export enum ResourceTypes {
  COURSE_MODULE = 'CourseModule',
  MODULE_LESSON = 'ModuleLesson',
}

export enum QueryParamNameTypes {
  COURSE = 'course',
  MODULE = 'module',
  INVITATION_TOKEN = 'invitation_token',
  EMAIL = 'email',
  NAME = 'name',
  PASSWORD_TOKEN = 'reset_password_token',
  PAGE = 'page',
}

export enum TasksQueryParams {
  COURSE = 'course',
  MODULE = 'module',
  PAGE = 'page',
  PARTICIPANT = 'participant',
  STATUS = 'status',
  COMPANY = 'company',
  SORT_BY = 'sort_by',
  SORT_DIRECTION = 'sort_direction',
}

export enum TasksSortOptions {
  ANSWERS_COUNT = 'answers_count',
  UPDATED_AT = 'updated_at',
}

export enum DateFormats {
  YEAR_MONTH_DAY = 'yyyy-MM-dd',
  DAY_MONTH_YEAR = 'dd.MM.yyyy',
  DAY_MONTH_YEAR_DASHED = 'dd-MM-yyyy',
  DAY_LONG = 'EEEE',
  LOCALE_TIME = 'p',
  TIME_SHORT = 'HH:mm',
  DURATION = 'mm:ss',
  WEEK_DAY = 'eeee P',
  DATE_WITH_TIME = 'Pp',
  DEFAULT = 'P',
}

export enum Position {
  PARTICIPANT = 'participant',
  HR = 'hr',
  COACH = 'Coach',
  SUPERCOACH = 'supercoach',
}

export enum Roles {
  PARTICIPANT = 'participant',
  HR = 'hr',
  COACH = 'coach',
  SUPER_COACH = 'super_coach',
  ADMIN = 'admin',
  SALES = 'sales',
}

export enum RecordingStatuses {
  MEDIA_ABORTED = 'media_aborted',
  PERMISSION_DENIED = 'permission_denied',
  NO_SPECIFIED_MEDIA_FOUND = 'no_specified_media_found',
  MEDIA_IN_USE = 'media_in_use',
  INVALID_MEDIA_CONSTRAINTS = 'invalid_media_constraints',
  NO_CONSTRAINTS = 'no_constraints',
  RECORDER_ERROR = 'recorder_error',
  IDLE = 'idle',
  ACQUIRING_MEDIA = 'acquiring_media',
  DELAYED_START = 'delayed_start',
  RECORDING = 'recording',
  STOPPING = 'stopping',
  STOPPED = 'stopped',
  PAUSED = 'paused',
}

export enum QuizAttemptType {
  PRE_QUIZ = 'pre_quiz',
  POST_QUIZ = 'post_quiz',
  POST_QUIZ_RECTIFICATION = 'post_quiz_rectification',
}

export enum QuizType {
  PRE_QUIZ = 'Prequiz',
  POST_QUIZ = 'Postquiz',
}

export enum AnswerStatus {
  ACCEPTED = 'accepted',
  FAILED = 'failed',
  TO_VERIFY = 'to_verify',
  TO_DO = 'to_do',
}

export enum TaskCreateStep {
  CREATE = 'create',
  ASSIGN = 'assign',
}
