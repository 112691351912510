import differenceInDays from 'date-fns/differenceInDays';
import { today } from 'helpers/time';

export const EXPIRE_SOON_THRESHOLD_IN_DAYS = 7;

export const isExpiringSoon = (
  endDate: string,
  todayDate = today(),
  expiryThreshold = EXPIRE_SOON_THRESHOLD_IN_DAYS
) => {
  const difference = differenceInDays(new Date(endDate), todayDate);
  return difference >= 0 && difference <= expiryThreshold;
};

export const isExpired = (endDate: string, todayDate = today()) =>
  differenceInDays(new Date(endDate), todayDate) < 0;
