import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCompanies } from 'services/companies';

export const useCompanies = (isEnabled = true) => {
  const { data, ...rest } = useQuery(
    QueryKeys.COMPANIES,
    () => getCompanies(),
    { onError: () => toast.error('companies-error'), enabled: isEnabled }
  );

  return { companies: data || [], ...rest };
};
