import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { MINUTE_MS } from 'constants/time';
import { AuthContext } from 'contexts';
import {
  getCoachBusinessHours,
  sendCoachBusinessHours as sendCoachBusinessHoursRequest,
} from 'services/calendar';

export type CoachBusinessHoursData = {
  start_time: string;
  end_time: string;
  day_name: string;
  day_id?: number;
  is_active: boolean;
};

export const useCoachBusinessHours = (
  isAdmin: boolean,
  coachId: number,
  coachEmail?: string
) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, ...restBusinessHoursQuery } = useQuery(
    [QueryKeys.BUSINESS_HOURS, coachId],
    () => getCoachBusinessHours(coachEmail),
    {
      enabled: !isAdmin && user?.id === coachId,
      onError: () => toast.error(t('business-hours-error')),
      refetchInterval: MINUTE_MS,
    }
  );
  const businessHours = data
    ? data.map((date) => ({
        ...date,
        start_time: date.start_time,
        end_time: date.end_time,
      }))
    : [];

  const {
    mutate: sendBusinessHoursMutation,
    isLoading: isProcessing,
    ...restSendBusinessHoursMutation
  } = useMutation(
    (
      hoursData: { start_time: string; end_time: string; is_active: boolean }[]
    ) => sendCoachBusinessHoursRequest(hoursData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.BUSINESS_HOURS);
        toast.success(t('business-hours-success'));
      },
      onError: (error: string) => {
        toast.error(error);
      },
    }
  );

  const sendCoachBusinessHours = (
    newBusinessHours: {
      start_time: string;
      end_time: string;
      is_active: boolean;
    }[]
  ) => {
    const convertedBusinessHours: {
      start_time: string;
      end_time: string;
      is_active: boolean;
    }[] = newBusinessHours.map((businessHour) => ({
      ...businessHour,
      start_time: new Date(businessHour.start_time).toISOString(),
      end_time: new Date(businessHour.end_time).toISOString(),
    }));
    sendBusinessHoursMutation(convertedBusinessHours);
  };

  return {
    isProcessing,
    businessHours,
    sendCoachBusinessHours,
    restBusinessHoursQuery,
    restSendBusinessHoursMutation,
  };
};
