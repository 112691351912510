import { useEffect, useState } from 'react';
import { getLocalStorageItem } from 'helpers/local-storage';
import { shouldDarkModeBeOn } from 'helpers/theme';

export const useTheme = () => {
  const [isDarkModeOn, setIsDarkModeOn] = useState<boolean>(false);
  const [isDarkModeAutoOn, setIsDarkModeAutoOn] = useState<boolean>(false);

  useEffect(() => {
    setIsDarkModeOn(getLocalStorageItem('dark-mode'));
    setIsDarkModeAutoOn(getLocalStorageItem('dark-mode-auto'));
  }, []);

  const setLocalStorageDarkModeOn = () => {
    setIsDarkModeOn((prevState) => {
      localStorage.setItem('dark-mode', JSON.stringify(!prevState));
      shouldDarkModeBeOn(!prevState);
      return !prevState;
    });
  };

  const setLocalStorageDarkModeAutoOn = () => {
    setIsDarkModeAutoOn((prevState) => {
      localStorage.setItem('dark-mode-auto', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return {
    isDarkModeOn,
    isDarkModeAutoOn,
    setLocalStorageDarkModeOn,
    setLocalStorageDarkModeAutoOn,
  };
};
