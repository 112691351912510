import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TFunctionResult } from 'i18next';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import './select.scss';

export type SelectOption = {
  value: string;
  label: string;
};

export const Select: React.FC<SelectProps> = ({
  label,
  options,
  name,
  register,
  errors = {},
  errorLabel,
  disabled = false,
  value,
  onChange,
  className,
}) => {
  const { t } = useTranslation();

  const computedProps = onChange
    ? {
        value,
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
          onChange(event.target.value as string);
        },
      }
    : { defaultValue: value };

  const errorLabelWithFallback = errorLabel || `${t('field-required')}`;

  return (
    <div
      className={clsx(
        'form-select',
        name && errors?.[name] && errorLabelWithFallback && 'error',
        className
      )}
    >
      <label className="form-select__label" htmlFor={`form-${name}`}>
        <span className="form-select__label-text">{label}</span>
      </label>
      <div className="form-select__wrapper">
        <select
          className="form-select__select"
          {...computedProps}
          name={name}
          ref={register}
          disabled={disabled}
          id={`form-${name}`}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ChevronDown className="form-select__chevron-down" />
      </div>
      {name && errors?.[name] && errorLabelWithFallback && (
        <span className="form-select__error">{errorLabelWithFallback}</span>
      )}
    </div>
  );
};

type SelectProps = {
  options: SelectOption[];
  type?: string;
  label?: string | boolean | TFunctionResult;
  name?: string;
  errorLabel?: string;
  visiblePassword?: boolean;
  register?: any;
  errors?: Record<string, string>;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
};
