import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCourseIntro } from 'services/courses';
import { decipherId } from 'helpers/utils';

export const useCourseIntro = () => {
  const { courseId: courseCipheredId } = useParams<{ courseId: string }>();
  const { t } = useTranslation();
  const courseId = decipherId(courseCipheredId);

  const { data: courseIntro, ...rest } = useQuery(
    [QueryKeys.COURSE_INTRO, courseId],
    () => getCourseIntro(courseId),
    {
      onError: () => {
        toast.error(t('course-intro-error'));
      },
    }
  );

  return { courseIntro, ...rest };
};
