import { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import html2pdf from 'html2pdf.js';
import { Button } from 'components/button';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-2-line.svg';
import './certificate-wrapper.scss';

type CertificateWrapperProps = {
  certificate: (isPdf: boolean) => ReactElement;
  courseName: string;
};

const PDF_SCALE = 6;

export const CertificateWrapper: React.FC<CertificateWrapperProps> = ({
  certificate,
  courseName,
}) => {
  const { t } = useTranslation();

  const downloadPdfDocument = () => {
    html2pdf()
      .set({
        margin: [0, 0],
        filename: `certificate-${courseName}.pdf`,
        html2canvas: { scale: PDF_SCALE },
        jsPDF: { orientation: 'landscape', unit: 'px', format: [842, 595] },
      })
      .from(ReactDOMServer.renderToString(certificate(true)))
      .save();
  };

  return (
    <section className="certificate-wrapper">
      {certificate(false)}
      <p className="certificate-wrapper__text">
        {t('course-certificate', { name: courseName })}
      </p>
      <Button
        variant="icon-text"
        className="certificate-wrapper__button"
        icon={<DownloadIcon />}
        onClick={downloadPdfDocument}
      >
        {t('download-pdf')}
      </Button>
    </section>
  );
};
