import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { LayoutTypes } from 'core/layouts';
import { Roles } from 'types/enums';
import { ProfileRoute, RouteType } from 'types/routes';
import EndRegister from 'views/authentication/templates/end-register';
import ForgotPassword from 'views/authentication/templates/forgot-password';
import Login from 'views/authentication/templates/login';
import Register from 'views/authentication/templates/register';
import { RegisterSuccess } from 'views/authentication/templates/register-success';
import ResetPassword from 'views/authentication/templates/reset-password';
import Calendar from 'views/calendar';
import Certificates from 'views/certificates';
import CoachCompanies from 'views/coach-companies';
import CoachCourses from 'views/coach-courses';
import CoachDashboard from 'views/coach-dashboard';
import CoachingSessions from 'views/coaching-sessions';
import Courses from 'views/courses';
import CoursesV2 from 'views/coursesV2';
import Dashboard from 'views/dashboard';
import FAQScreen from 'views/faq-screen';
import HRDashboard from 'views/hr-dashboard';
import NotFound from 'views/not-found';
import ParticipantTask from 'views/participant-task';
import Participants from 'views/participants';
import Profile from 'views/profile';
import Edit from 'views/profile/views/edit';
import Settings from 'views/profile/views/settings';
import ReportScreen from 'views/report-screen';
import Settlements from 'views/settlements';
import StyleGuide from 'views/style-guide';
import Subscription from 'views/subscription';
import Tasks from 'views/tasks';
import { ReactComponent as IconAvatar } from 'assets/icons/avatar-3.svg';
import { ReactComponent as IconCourses } from 'assets/icons/book-line.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar-line.svg';
import { ReactComponent as IconChart } from 'assets/icons/chart-2.svg';
import { ReactComponent as IconSettlements } from 'assets/icons/dollar.svg';
import { ReactComponent as FAQIcon } from 'assets/icons/faq.svg';
import { ReactComponent as ProfileLine } from 'assets/icons/profile-line.svg';
import { ReactComponent as IconReport } from 'assets/icons/report.svg';
import { ReactComponent as IconSubscription } from 'assets/icons/subscription.svg';
import { ReactComponent as IconTask } from 'assets/icons/task.svg';
import { ReactComponent as IconCoachCompanies } from 'assets/icons/users.svg';

const loggedUsersRoutes = [
  '/courses*',
  '/companies*',
  '/participants*',
  '/calendar*',
  '/report*',
  '/subscription*',
  '/coaching-sessions*',
  '/tasks*',
  '/certificates*',
  '/faq*',
  '/settlements*',
  '/style-guide*',
  '/profile*',
];

export const useRoutes = () => {
  const { t } = useTranslation();
  const { user, isHR, userRole, isSales } = useContext(AuthContext);
  const isHRWithCompany = (isHR && user?.company_id) || isSales;
  const isSuperCoach = userRole === Roles.SUPER_COACH;

  // If feature flag have to be disabled just go back to previous commit
  const isHRWithDesiredCompanies =
    user &&
    isHR &&
    ['Sales', 'Browar Pogórza', 'Testing'].includes(user.company_name);

  const publicRoutes: RouteType[] = [
    {
      component: Login,
      path: '/login',
    },
    {
      component: Login,
      path: '/',
    },
    {
      component: Register,
      path: '/register',
    },
    {
      component: RegisterSuccess,
      path: '/register-success',
    },
    {
      component: EndRegister,
      path: '/end-register',
    },
    {
      component: ForgotPassword,
      path: '/forgot-password',
    },
    {
      component: ResetPassword,
      path: '/reset-password',
    },
    ...loggedUsersRoutes.map((path) => ({
      path,
      component: () => <Redirect to="/login" />,
    })),
    {
      component: NotFound,
      path: '*',
    },
  ];

  const privateRoutes: RouteType[] = [
    {
      component: EndRegister,
      path: '/end-register',
      roles: [...Object.values(Roles)],
    },
    {
      component: Dashboard,
      path: '/',
      menu: {
        icon: IconChart,
        name: t('start'),
      },
      roles: [Roles.PARTICIPANT],
      exact: true,
    },
    {
      path: '/login',
      component: () => <Redirect to="/" />,
      exact: true,
      roles: [...Object.values(Roles)],
    },
    {
      component: HRDashboard,
      path: '/',
      menu: {
        icon: IconChart,
        name: t('start'),
      },
      roles: [Roles.HR],
      exact: true,
    },
    {
      component: CoachDashboard,
      path: '/',
      menu: { icon: IconChart, name: t('start') },
      roles: [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN, Roles.SALES],
      exact: true,
    },
    {
      component: CoachCourses,
      path: '/courses',
      menu: { icon: IconCourses, name: t('courses') },
      roles: [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN],
    },
    {
      component: Courses,
      path: '/courses',
      menu: {
        icon: IconCourses,
        name: t('courses'),
      },
      roles: [Roles.HR],
      hidden: isHR ? !isHRWithCompany : false,
    },
    {
      component: CoursesV2,
      path: '/courses',
      menu: {
        icon: IconCourses,
        name: t('courses'),
      },
      roles: [Roles.PARTICIPANT, Roles.SALES],
    },
    {
      component: CoachCompanies,
      path: '/companies',
      menu: { icon: IconCoachCompanies, name: t('companies') },
      roles: [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN, Roles.SALES],
    },
    {
      component: Participants,
      path: '/participants',
      menu: {
        icon: IconAvatar,
        name: t('participants-list'),
      },
      roles: [Roles.HR, Roles.SUPER_COACH],
      hidden: !(isHRWithCompany || isSuperCoach),
    },
    {
      component: Calendar,
      path: '/calendar',
      menu: {
        icon: IconCalendar,
        name: t('calendar'),
      },
      roles: [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN],
    },

    {
      component: ReportScreen,
      path: '/report',
      menu: {
        icon: IconReport,
        name: t('reports'),
      },
      roles: [Roles.HR, Roles.SUPER_COACH],
      hidden: !(isHRWithCompany || isSuperCoach),
    },

    {
      component: Subscription,
      path: '/subscription',
      menu: {
        icon: IconSubscription,
        name: t('subscriptions'),
      },
      roles: [Roles.HR],
      hidden: !isHRWithCompany,
    },
    {
      component: CoachingSessions,
      path: '/coaching-sessions',
      menu: { icon: IconCalendar, name: t('coaching-sessions') },
      roles: [Roles.PARTICIPANT],
    },
    {
      component: CoachingSessions,
      path: '/coaching-sessions',
      menu: { icon: IconCalendar, name: t('coaching-sessions') },
      roles: [Roles.HR],
      hidden: !isHRWithDesiredCompanies,
    },
    {
      component: Tasks,
      path: '/tasks',
      menu: {
        icon: IconTask,
        name: t('tasks'),
      },
      roles: [Roles.PARTICIPANT],
    },
    {
      component: Certificates,
      path: '/certificates',
      menu: {
        icon: ProfileLine,
        name: t('certificates'),
      },
      roles: [Roles.PARTICIPANT],
    },
    {
      component: FAQScreen,
      path: '/faq',
      menu: {
        icon: FAQIcon,
        name: t('FAQ'),
      },
      roles: [Roles.PARTICIPANT],
    },

    {
      component: ParticipantTask,
      path: '/tasks',
      menu: {
        icon: IconTask,
        name: t('tasks'),
      },
      roles: [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN],
    },
    {
      component: Settlements,
      path: '/settlements',
      menu: {
        icon: IconSettlements,
        name: t('settlements'),
      },
      roles: [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN, Roles.SALES],
    },
    {
      component: Profile,
      path: '/profile',
      roles: [...Object.values(Roles)],
      layout: LayoutTypes.WIDE,
    },

    {
      component: StyleGuide,
      path: '/style-guide',
      roles: [Roles.PARTICIPANT],
    },
    {
      component: NotFound,
      path: '*',
    },
  ];

  const profileRoutes: ProfileRoute[] = [
    {
      path: '/profile',
      component: Edit,
      name: t('edit-profile'),
    },
    {
      component: Settings,
      path: '/profile/settings',
      name: t('settings'),

      roles: [
        Roles.ADMIN,
        Roles.SUPER_COACH,
        Roles.COACH,
        Roles.HR,
        Roles.SALES,
        Roles.PARTICIPANT,
      ],
    },
  ];

  return { privateRoutes, publicRoutes, profileRoutes };
};
