import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';
import {
  createNote as createNoteRequest,
  updateNote as updateNoteRequest,
} from 'services/courses';
import { Button } from 'components/button';
import { StateSetter } from 'helpers/types';
import { Notepad as NotepadType, NotepadData } from 'types/api';
import 'tippy.js/dist/tippy.css';
import './notepad.scss';

type NotepadProps = {
  note: NotepadType | null;
  setNote: StateSetter<NotepadType | null>;
  courseId: number;
};

export const Notepad: React.FC<NotepadProps> = ({
  note,
  setNote,
  courseId,
}) => {
  const defaultNote = note ? note.content : '';
  const [content, setContent] = useState(defaultNote);
  const quillRef = useRef<ReactQuill>(null);
  const { t } = useTranslation();
  const isNoteSaved = defaultNote === content;

  const { mutate: createNote } = useMutation(
    (data: NotepadData) => createNoteRequest(data, courseId),
    {
      onSuccess: (data) => {
        toast.success(t('notes-saved'));
        setNote(data);
      },
      onError: () => {
        toast.error(t('notes-unsaved'));
      },
    }
  );

  const { mutate: updateNote } = useMutation(
    (data: NotepadData) => updateNoteRequest(data, note?.id!),
    {
      onSuccess: (data) => {
        toast.success(t('notes-saved'));
        setNote(data);
      },
      onError: () => {
        toast.error(t('notes-unsaved'));
      },
    }
  );

  const downloadPdfDocument = () => {
    html2pdf()
      .set({
        margin: [5, 10],
        filename: 'skills-arena-note.pdf',
        pagebreak: {
          mode: 'avoid-all',
        },
      })
      .from(`<article style="line-height: 2.3;">${defaultNote}</article>`)
      .save();
  };

  const onNotepadSave = async (noteContent: string) => {
    const data = {
      note: { content: noteContent },
    };

    if (!note) {
      createNote(data);
    } else {
      updateNote(data);
    }
  };

  const restoreDefaultValue = () => {
    setContent(defaultNote);
  };

  const handlePrimaryButtonClick = () => {
    if (isNoteSaved) {
      downloadPdfDocument();
    } else {
      onNotepadSave(content);
    }
  };

  const isEmptyQuill = (quilContent: string) => {
    return quilContent.replace(/<(.|\n)*?>/g, '').trim().length === 0;
  };

  const isEmpty = isEmptyQuill(defaultNote) && isEmptyQuill(content);

  return (
    <div
      className="notepad"
      onMouseLeave={({ buttons }) => {
        if (buttons === 1) quillRef.current?.getEditor().setSelection(0, 0);
      }}
    >
      <ReactQuill
        ref={quillRef}
        onChange={setContent}
        value={content}
        modules={{
          toolbar: [[{ color: [] }], ['bold', 'italic', 'underline']],
        }}
      />

      <div className="notepad__buttons">
        <Button
          disabled={isEmpty}
          variant="primary"
          onClick={handlePrimaryButtonClick}
        >
          {isNoteSaved ? t('generate-pdf') : t('save')}
        </Button>

        <Button variant="secondary" onClick={restoreDefaultValue}>
          {t('cancel')}
        </Button>
      </div>
    </div>
  );
};
