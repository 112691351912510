import { useMemo } from 'react';
import {
  createCourseSelectOptions,
  createSelectOptions,
  createSelectOptionsFromProfiles,
} from 'helpers/utils';
import { useCourseParticipants } from 'hooks/use-course-participants';
import { useCourses } from 'hooks/use-courses';
import { ReportSelectsData, SubscriptionSelectOptions } from 'types/api';

export const useGroupMeetingsData = (courseSubscriptionId: string) => {
  const { courses } = useCourses({});
  const selectedSubscription = useMemo(
    () =>
      courses?.find(
        (courseSubscription) => courseSubscription.id === +courseSubscriptionId
      ),
    [courseSubscriptionId, courses]
  );

  const { participants } = useCourseParticipants(
    selectedSubscription && String(selectedSubscription.id)
  );

  const courseOptions = createCourseSelectOptions(
    (courses ?? []) as (SubscriptionSelectOptions | ReportSelectsData)[]
  );

  const moduleOptions = createSelectOptions(
    selectedSubscription?.course.course_modules ?? []
  );

  const participantOptions = createSelectOptionsFromProfiles(
    participants ?? []
  );

  return { courseOptions, moduleOptions, participantOptions };
};
