import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCoachTimeslots } from 'services/calendar';

export const useCoachTimeslots = ({
  moduleId,
  ISODates,
}: {
  moduleId: string;
  ISODates: string[];
}) => {
  const { t } = useTranslation();

  const { data, ...rest } = useQuery(
    [QueryKeys.COACH_TIMESLOTS, moduleId, ISODates],
    () => getCoachTimeslots(moduleId, ...ISODates),
    {
      enabled: +moduleId > 0,
      onError: () => toast.error(t('timeslots-error')),
    }
  );
  return { coachTimeslots: data, ...rest };
};
