import React, {
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { QueryKeys } from 'constants/query-keys';
import { getUser, logOutUser } from 'services/user';
import { getAuthToken } from 'helpers/auth';
import { removeAuthorization, setAuthorization } from 'helpers/axios';
import { pushGTMEvent } from 'helpers/google-tag-manager';
import { deleteLocalStorageItem } from 'helpers/local-storage';
import { isCoachRole, isHrRole } from 'helpers/roles';
import { Roles } from 'types/enums';
import { User } from 'types/user';
import { ActionCableContext } from './action-cable';

export interface AuthType {
  user: null | User;
  loading: boolean;
  isHR: boolean;
  isParticipant: boolean;
  isCoach: boolean;
  userRole?: Roles;
  isSales: boolean;
  logOut: () => void;
  updateUserData: (data: User) => void;
  setAuthToken: (value: string) => void;
}

type AuthProps = {
  children: ReactChild;
};

export const AuthContext = React.createContext<AuthType>({
  user: null,
  loading: true,
  isHR: false,
  isCoach: false,
  isSales: false,
  isParticipant: false,
  logOut: () => {},
  updateUserData: () => {},
  setAuthToken: () => {},
});

export const AuthProvider: React.FC<AuthProps> = ({ children }: AuthProps) => {
  const { createWSConnection, removeWSConnection } =
    useContext(ActionCableContext);
  const queryClient = useQueryClient();

  const [authToken, setAuthToken] = useState<string | null>(
    getAuthToken()?.value || null
  );

  useEffect(() => {
    if (authToken) {
      createWSConnection(authToken);
      setAuthorization(authToken);
    }
  }, [authToken, createWSConnection]);

  const logOut = useCallback(() => {
    logOutUser();
    deleteLocalStorageItem('skillsarena-auth');
    queryClient.clear();
    setAuthToken(null);
    removeAuthorization();
    removeWSConnection();
    pushGTMEvent({ event: 'log_out' });
  }, [removeWSConnection, queryClient]);

  const { data: user, isLoading: loading } = useQuery(
    QueryKeys.USER,
    () => getUser(),
    {
      enabled: !!authToken,
      onError: (error: AxiosError) => {
        if (!error.code?.match(/5??/)?.length) {
          logOut();
        }
      },
    }
  );

  const updateUserData = (data: User) => {
    queryClient.setQueryData(QueryKeys.USER, () => data);
  };

  const isHR = !!user && isHrRole(user.roles);
  const isCoach = !!user && isCoachRole(user.roles);
  const isSales = !!user && user.roles.includes(Roles.SALES);
  const isParticipant = !isHR && !isCoach && !isSales;
  const userRole = user?.roles[0];

  const contextValue = {
    userRole,
    user: user || null,
    loading,
    isHR,
    isCoach,
    isSales,
    isParticipant,
    updateUserData,
    setAuthToken,
    logOut,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
