import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'constants/query-keys';
import { getCourseHeader } from 'services/courses';
import { decipherId } from 'helpers/utils';

export const useCourseHeader = () => {
  const { courseId: courseCipheredId } = useParams<{ courseId: string }>();
  const courseId = decipherId(courseCipheredId);
  const {
    data: headerDetails,
    isSuccess,
    ...rest
  } = useQuery([QueryKeys.HEADER_DETAILS, courseId], () =>
    getCourseHeader(courseId)
  );

  return { headerDetails: headerDetails || null, isSuccess, ...rest };
};
