import axios from 'helpers/axios';
import { ResetPasswordData, ResetPasswordRequestData } from 'types/api';
import { User } from 'types/user';
import {
  ConfirmEmailProps,
  EndRegisterInputNames,
  LoginData,
  RegisterInputNames,
} from 'views/authentication';

export async function getUser() {
  const {
    data: { response },
  } = await axios.get<{ response: User }>('v1/users/me');
  return response;
}

export function logOutUser() {
  axios.post('v1/users/sign_out');
}

export async function getCoachMyCourses(paramsObj = {}) {
  const { data } = await axios.get('v1/users/my_courses', {
    ...paramsObj,
  });
  return data;
}
export async function getUserMyCourses() {
  const {
    data: { response },
  } = await axios.get('v1/users/my_courses');
  return response;
}

export async function acceptInvitation(formData: {
  user: Record<EndRegisterInputNames, string> & {
    invitation_token: string;
  };
}) {
  const { data } = await axios.put<ConfirmEmailProps>(
    'v1/invitation',
    formData
  );
  return data;
}

export async function updateProfile(formData: FormData) {
  const {
    data: { response },
  } = await axios.put('/v1/users/update_profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
}

export async function deleteUserAvatar() {
  const { data } = await axios.post(`/v1/users/remove_photo`);
  return data;
}

export async function signUp(dataForm: Record<RegisterInputNames, string>) {
  await axios.post('/v1/users/sign_up', dataForm);
}

export async function login(loginData: LoginData) {
  const {
    data: { access_token: accessToken },
  } = await axios.post('v1/users/sign_in', loginData);
  return accessToken;
}

export async function resetPasswordRequest(data: ResetPasswordRequestData) {
  await axios.post('v1/users/forgot_password', data);
}

export async function resetPassword(data: ResetPasswordData) {
  await axios.post('v1/users/reset_password', data);
}
