import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { Input, Select } from 'components/form';
import { Person } from 'components/person';
import { StyledScrolls } from 'components/styled-scrolls';
import { Tab } from 'components/tab';
import { TabSwitch } from 'components/tab-switch';
import { createSelectOptions } from 'helpers/utils';
import { useParticipants } from 'hooks/use-participants';
import { CompanyDetails, Profile } from 'types/api';
import './participant-assignment.scss';

type ParticipantAssignmentProps = {
  companies?: CompanyDetails[];
  assignedParticipants?: Profile[];
  handleAssignParticipant: (profile: Profile) => void;
  handleUnassignParticipant: (id: number) => void;
  courseId?: number;
};

enum TypeView {
  ASSIGNED_PARTICIPANTS = 'assigned_participants',
  COMPANY_PARTICIPANTS = 'company_participants',
}

export const ParticipantAssignment: React.FC<ParticipantAssignmentProps> = ({
  companies = [],
  assignedParticipants = [],
  handleAssignParticipant,
  handleUnassignParticipant,
  courseId,
}) => {
  const { t } = useTranslation();
  const {
    filteredParticipants,
    searchPhrase,
    setSearchPhrase,
    setCompanyId,
    participantListView,
    setParticipantsListView,
  } = useParticipants(companies[0].id, courseId || null, assignedParticipants);

  return (
    <section className="participant-assignment">
      <header>
        <div className="participant-assignment__dropdown">
          <Select
            label={t('select-company')}
            options={
              companies
                ? createSelectOptions(companies)
                : [{ value: '-1', label: `${t('lack-companies')}` }]
            }
            onChange={(value) => setCompanyId(+value)}
          />
        </div>

        <Input
          type="search"
          value={searchPhrase}
          placeholder={t('find-participant')}
          onChange={(value) => {
            setSearchPhrase(value);
          }}
        />
      </header>

      <main className="participant-assignment__main">
        <TabSwitch
          buttonClassName="testButton participant-assignment__tab-button"
          value={participantListView}
          onChange={(value: string) => {
            setParticipantsListView(value as TypeView);
          }}
          tabs={[
            {
              content: (
                <Tab
                  text={t('assigned-participants')}
                  isActive={
                    participantListView === TypeView.ASSIGNED_PARTICIPANTS
                  }
                />
              ),
              value: TypeView.ASSIGNED_PARTICIPANTS,
            },
            {
              content: (
                <Tab
                  text={t('company-members')}
                  isActive={
                    participantListView === TypeView.COMPANY_PARTICIPANTS
                  }
                />
              ),
              value: TypeView.COMPANY_PARTICIPANTS,
            },
          ]}
        />
        <ul className="participant-assignment__profiles">
          <StyledScrolls className="participant-assignment__scrolls">
            {filteredParticipants.map((participant) => {
              const isAssigned = assignedParticipants.includes(participant);
              const shouldItemRender = !(
                isAssigned &&
                participantListView === TypeView.COMPANY_PARTICIPANTS
              );

              return (
                shouldItemRender && (
                  <li
                    key={participant.id}
                    className="participant-assignment__profile"
                  >
                    <Person
                      type="participant"
                      profile={participant}
                      layout="large"
                      size={42}
                    />

                    {!isAssigned &&
                      participantListView === TypeView.COMPANY_PARTICIPANTS && (
                        <Button
                          className="participant-assignment__button"
                          onClick={() => handleAssignParticipant(participant)}
                          variant="secondary"
                        >
                          {t('add')}
                        </Button>
                      )}

                    {isAssigned && (
                      <Button
                        className="participant-assignment__button"
                        onClick={() =>
                          handleUnassignParticipant(participant.id)
                        }
                        variant="secondary"
                      >
                        {t('delete')}
                      </Button>
                    )}
                  </li>
                )
              );
            })}
          </StyledScrolls>
        </ul>
      </main>
    </section>
  );
};
