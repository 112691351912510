import { useCallback, useEffect } from 'react';
import { Loader } from 'components/loader';
import { QuizContainer } from 'components/quiz/quiz-container';
import { useParticipantQuiz } from 'hooks/use-quiz';
import { QuizAttemptType } from 'types/enums';
import { CurrentModuleInfo } from 'types/quiz';
import { Form } from './form';
import { Result } from './result';

type ParticipantQuizProps = {
  currentModuleInfo: CurrentModuleInfo;
  moveToNextQuiz: () => Promise<void>;
};

export const ParticipantQuiz: React.FC<ParticipantQuizProps> = ({
  currentModuleInfo,
  moveToNextQuiz,
}) => {
  const {
    quizInfo,
    isQuizInProgress,
    questions,
    initiate,
    submit,
    restQuizInfoQuery,
    restStartQuizMutation,
  } = useParticipantQuiz();

  const isQuizLoading =
    restQuizInfoQuery.isLoading || restStartQuizMutation.isLoading;

  const { name, order, coach, isLast } = currentModuleInfo;

  const quizAttemptType = quizInfo?.attempt_type;

  const isQuizCanAttempt = quizInfo?.can_attempt;

  const passHandleFinishQuizToResult =
    !isLast || quizAttemptType === QuizAttemptType.PRE_QUIZ;

  const handleSubmit = useCallback(
    (answers: { answer_ids: number[] }) => {
      submit(answers);
    },
    [submit]
  );

  useEffect(() => {
    const isQuizShouldStart =
      quizAttemptType !== QuizAttemptType.POST_QUIZ_RECTIFICATION &&
      isQuizCanAttempt;

    if (isQuizShouldStart) {
      initiate();
    }
  }, [initiate, quizAttemptType, isQuizCanAttempt]);

  if (isQuizLoading) {
    return <Loader />;
  }

  return (
    <QuizContainer moduleName={name} moduleOrder={order} coach={coach}>
      {isQuizInProgress && questions && (
        <Form questions={questions} onSubmit={handleSubmit} />
      )}

      {!isQuizInProgress && quizInfo?.quiz_attempt && (
        <Result
          quizAttempt={quizInfo.quiz_attempt}
          onRetakeClick={initiate}
          {...(passHandleFinishQuizToResult && {
            onFinish: moveToNextQuiz,
          })}
        />
      )}
    </QuizContainer>
  );
};
