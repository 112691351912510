import axios from 'axios';

const instanceAxios = axios.create({
  headers: { 'Content-type': 'application/json', Accept: 'application/json' },
  baseURL: process.env.REACT_APP_API_URL,
});

export default instanceAxios;

export const setAuthorization = (token: string): void => {
  instanceAxios.defaults.headers = {
    ...instanceAxios.defaults.headers,
    Authorization: `Bearer ${token}`,
  };
};

export const removeAuthorization = (): void => {
  delete instanceAxios.defaults.headers.Authorization;
};
