import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { sendNotification as sendNotificationRequest } from 'services/notification';

export const useNotification = (userId: number) => {
  const { t } = useTranslation();
  const { mutate: sendNotification, isSuccess } = useMutation(
    () => sendNotificationRequest(userId),
    {
      onSuccess: () => {
        toast.success(t('reminder-sent'));
      },
      onError: () => {
        toast.error(t('failed-to-send-reminder'));
      },
    }
  );
  return {
    sendNotification,
    isSuccess,
  };
};
