import { ElementType } from 'react';
import clsx from 'clsx';
import {
  PolymorphicButton,
  PolymorphicButtonProps,
} from 'components/polymorphic-button';
import { StatusIcon } from 'components/status-icon';
import { MOBILE_MAX_WIDTH_QUERY } from 'hooks/use-media';
import { useScrollToElement } from 'hooks/use-scroll-to-element';
import './progress-item-button.scss';

export enum ProgressItemStatus {
  DISABLED = 'disabled',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

type ProgressItemButtonProps<C extends ElementType> = Omit<
  PolymorphicButtonProps<C>,
  'children'
> & {
  isActive: boolean;
  status: ProgressItemStatus;
};

export const ProgressItemButton = <C extends ElementType>({
  name,
  isActive,
  status,
  disabled,
  className,
  ...props
}: ProgressItemButtonProps<C>) => {
  const buttonRef = useScrollToElement<HTMLButtonElement | HTMLLinkElement>({
    noScrollQuery: MOBILE_MAX_WIDTH_QUERY,
    isScrollDestination: isActive,
  });

  return (
    <PolymorphicButton
      ref={buttonRef}
      className={clsx(
        'progress-item-button',
        isActive && 'progress-item-button--active',
        className
      )}
      disabled={status === 'disabled' || disabled}
      {...props}
    >
      <div className="progress-item-button__name">
        <span className="progress-item-button__icon">
          <StatusIcon status={status} />
        </span>{' '}
        {name}
      </div>
    </PolymorphicButton>
  );
};
