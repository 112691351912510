import clsx from 'clsx';
import { LazyAvatar } from 'components/lazy-avatar';
import { Tooltip } from 'components/tooltip';
import { getUserInitials } from 'helpers/utils';
import { ProfileWithCompany } from 'types/api';
import { AnswerStatus } from 'types/enums';
import './task-answer-button.scss';

type TaskAnswerButtonProps = {
  className?: string;
  layout?: 'small' | 'large';
  user: ProfileWithCompany;
  status: AnswerStatus;
  onClick: () => void;
  withDetails?: boolean;
};

export const TaskAnswerButton: React.FC<TaskAnswerButtonProps> = ({
  className,
  user,
  status,
  onClick,
  withDetails = false,
}) => {
  return (
    <>
      <button
        className={clsx(`task-answer-button`, className)}
        onClick={onClick}
        type="button"
      >
        <Tooltip disabled={!user.email} title={user.email}>
          <LazyAvatar
            src={user.avatar}
            userInitials={getUserInitials(user)}
            imageClassName={`task-answer-button__avatar task-answer-button__avatar--${status}`}
          />
        </Tooltip>
        {withDetails && (
          <div>
            <p className="task-answer-button__user-name">{`${user.first_name} ${user.last_name}`}</p>
            <p className="task-answer-button__user-company">{`${user.company?.name}`}</p>
          </div>
        )}
      </button>
    </>
  );
};
