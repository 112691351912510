import { useTranslation } from 'react-i18next';
import { TabRouter } from 'components/tab-router';
import { CompletedTasks, UncompletedTasks } from './views';
import { ToImproveTaks } from './views/to-improve-tasks';
import { ToVerifyTasks } from './views/to-verify-tasks';

const Tasks: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="module-tasks">
      <TabRouter
        items={[
          {
            url: '/tasks',
            label: t('tasks-todo'),
            component: UncompletedTasks,
          },
          {
            url: '/tasks/to-verify-tasks',
            label: t('tasks-to-verify'),
            component: ToVerifyTasks,
          },
          {
            url: '/tasks/to-improve-tasks',
            label: t('tasks-to-improve'),
            component: ToImproveTaks,
          },
          {
            url: '/tasks/done-tasks',
            label: t('tasks-done'),
            component: CompletedTasks,
          },
        ]}
      />
    </div>
  );
};

export default Tasks;
