import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Collapse } from 'components/collapse';
import { ProgressItemStatus } from 'components/progress-item-button';
import { Progressbar } from 'components/progressbar';
import { useGetLessonStatus } from 'hooks/use-get-lesson-status';
import { useQuizStatus } from 'hooks/use-quiz-status';
import { ModuleDetails } from 'types/api';
import { QuizAttemptType } from 'types/enums';
import { LessonTile } from './lesson-tile';
import './module-lessons.scss';

type ModuleLessonsProps = {
  module: ModuleDetails;
  activeContentId: number | string;
  goToLesson: (id: number) => void;
  modulesCount: number;
  order: number;
};

export const ModuleLessons: React.FC<ModuleLessonsProps> = ({
  module,
  activeContentId,
  goToLesson,
  modulesCount,
  order,
}) => {
  const { t } = useTranslation();
  const { isParticipant } = useContext(AuthContext);
  const getLessonStatus = useGetLessonStatus();
  const { preQuizStatus, postQuizStatus } = useQuizStatus(module);

  const {
    courseId,
    contentType,
    contentId: lessonSlug,
  } = useParams<{
    courseId: string;
    contentType: QuizAttemptType.POST_QUIZ | QuizAttemptType.PRE_QUIZ;
    contentId: string;
  }>();
  const { module_progress: moduleProgress, lessons, name, quiz } = module;
  const completedLessons = lessons.reduce(
    (count, lesson) => count + Number(lesson.completed),
    0
  );
  const allLessons = lessons.length;
  const shouldCollapseOpen =
    module.lessons.some((lesson) => lesson.slug === activeContentId) ||
    module.quiz?.id === activeContentId;

  const lessonId = module.lessons.find(
    (lesson) => lesson.slug === lessonSlug
  )?.id;

  return (
    <Collapse
      open={shouldCollapseOpen}
      isAutoScroll={false}
      arrowClassName="module-lessons__arrow"
      hiddenContent={
        <div className="module-lessons__lessons">
          {quiz && isParticipant && (
            <LessonTile
              as={Link}
              name={`${quiz.name} - Prequiz`}
              status={preQuizStatus}
              isActive={
                contentType === QuizAttemptType.PRE_QUIZ &&
                lessonId! === quiz?.id
              }
              to={`/courses/${courseId}/${QuizAttemptType.PRE_QUIZ}/${quiz?.id}`}
              isQuiz
            />
          )}

          {lessons.map((lesson, index, array) => (
            <LessonTile
              key={lesson.id}
              name={`${t('lesson')} ${index + 1} - ${lesson.name}`}
              isActive={lesson.slug === activeContentId}
              scrollBlock="nearest"
              status={getLessonStatus(
                index,
                array,
                quiz?.completed_attempts_count
              )}
              onClick={() => goToLesson(lesson.id)}
              coverImage={lesson.cover_image}
              coverDescription={lesson.cover_description}
              taskStatus={lesson.task_status}
              videoLength={lesson.video_length}
              videoWatchedTime={lesson.video_watched_time}
            />
          ))}

          {quiz && (
            <LessonTile
              as={Link}
              name={`${quiz.name} - Postquiz`}
              status={postQuizStatus}
              isActive={
                contentType === QuizAttemptType.POST_QUIZ &&
                lessonId! === quiz?.id
              }
              to={`/courses/${courseId}/${QuizAttemptType.POST_QUIZ}/${quiz?.id}`}
              className={clsx(
                'module-lessons__post-quiz',
                postQuizStatus === ProgressItemStatus.DISABLED &&
                  'module-lessons__post-quiz--disabled'
              )}
              isQuiz
            />
          )}
        </div>
      }
    >
      <li className="module-lessons">
        <header className="module-lessons__header">
          <h3 className="module-lessons__name">
            <span className="module-lessons__name--capitalize">
              {t('module')}
            </span>
            {modulesCount > 1 ? ` ${order}` : ''} - {name}
          </h3>
          <div className="module-lessons__progress">
            {t('module-lessons-progress', {
              completedLessons,
              allLessons,
            })}
          </div>
          <Progressbar
            className="module-lessons__progressbar"
            progress={moduleProgress}
          />
        </header>
      </li>
    </Collapse>
  );
};
