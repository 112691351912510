import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Person } from 'components/person';
import { ReactComponent as Arrow } from 'assets/icons/arrow-left-s-line.svg';
import './avatar-list.scss';

type AvatarListProps = {
  avatarList?: any[];
  className?: string;
};

const AVATAR_TILE_WIDTH = 48;
const LEFT_BUTTON_WIDTH = 46;
const RIGHT_BUTTON_WIDTH = 46;
const GAP = 10;
const FALLBACK_VALUE = 0;

export const AvatarList: React.FC<AvatarListProps> = ({
  avatarList,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [profilesPerPage, setProfilesPerPage] = useState(FALLBACK_VALUE);
  const [maxProfileIndex, setMaxProfileIndex] = useState(FALLBACK_VALUE);

  const isPrevButtonVisible = profilesPerPage < maxProfileIndex;
  const isNextButtonVisible = avatarList && avatarList.length > maxProfileIndex;

  const initializeAvatarList = useCallback(() => {
    const totalWidth = containerRef.current
      ? containerRef.current.offsetWidth
      : FALLBACK_VALUE;
    const avatarListLength = avatarList ? avatarList.length : 0;

    const availableSpaceWithoutButtons =
      (totalWidth + GAP) / (AVATAR_TILE_WIDTH + GAP);
    const availableSpaceWithButton =
      (totalWidth + GAP - RIGHT_BUTTON_WIDTH) / (AVATAR_TILE_WIDTH + GAP);

    if (availableSpaceWithoutButtons < avatarListLength) {
      const possibleNumberOfTiles = Math.floor(availableSpaceWithButton);

      setProfilesPerPage(possibleNumberOfTiles);
      setMaxProfileIndex(possibleNumberOfTiles);
    } else {
      const possibleNumberOfTiles = Math.floor(availableSpaceWithoutButtons);

      setProfilesPerPage(possibleNumberOfTiles);
      setMaxProfileIndex(possibleNumberOfTiles);
    }
  }, [avatarList]);

  const switchToNextPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const totalWidth = containerRef.current
        ? containerRef.current.offsetWidth
        : FALLBACK_VALUE;
      const avatarListLength = avatarList ? avatarList.length : 0;
      const availableSpaceWithButton =
        (totalWidth + GAP - RIGHT_BUTTON_WIDTH) / (AVATAR_TILE_WIDTH + GAP);
      const availableSpaceWithBothButtons =
        (totalWidth + GAP - LEFT_BUTTON_WIDTH - RIGHT_BUTTON_WIDTH) /
        (AVATAR_TILE_WIDTH + GAP);

      if (maxProfileIndex + availableSpaceWithButton < avatarListLength) {
        const possibleNumberOfTiles = Math.floor(availableSpaceWithBothButtons);

        setProfilesPerPage(possibleNumberOfTiles);
        setMaxProfileIndex((prevState) => prevState + possibleNumberOfTiles);
      } else {
        const possibleNumberOfTiles = Math.floor(availableSpaceWithButton);

        setProfilesPerPage(possibleNumberOfTiles);
        setMaxProfileIndex(avatarListLength);
      }
    },
    [avatarList, maxProfileIndex]
  );

  const switchToPreviousPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const totalWidth = containerRef.current
        ? containerRef.current.offsetWidth
        : FALLBACK_VALUE;
      const availableSpaceWithButton =
        (totalWidth + GAP - RIGHT_BUTTON_WIDTH) / (AVATAR_TILE_WIDTH + GAP);
      const availableSpaceWithBothButtons =
        (totalWidth + GAP - LEFT_BUTTON_WIDTH - RIGHT_BUTTON_WIDTH) /
        (AVATAR_TILE_WIDTH + GAP);
      if (
        maxProfileIndex - availableSpaceWithButton >=
        availableSpaceWithButton
      ) {
        const possibleNumberOfTiles = Math.floor(availableSpaceWithBothButtons);
        setMaxProfileIndex((prevState) => prevState - possibleNumberOfTiles);
        setProfilesPerPage(possibleNumberOfTiles);
      } else {
        const possibleNumberOfTiles = Math.floor(availableSpaceWithButton);
        setProfilesPerPage(possibleNumberOfTiles);
        setMaxProfileIndex(possibleNumberOfTiles);
      }
    },
    [maxProfileIndex]
  );
  useEffect(() => initializeAvatarList(), [initializeAvatarList]);

  return (
    <div className={clsx('avatar-list', className)} ref={containerRef}>
      <ul className="avatar-list__list">
        {isPrevButtonVisible && (
          <li>
            <Button
              className="avatar-list__pagination-button"
              onClick={(event) => switchToPreviousPage(event)}
              variant="icon"
              icon={<Arrow />}
            />
          </li>
        )}

        {(avatarList || [])
          .slice(maxProfileIndex - profilesPerPage, maxProfileIndex)
          .map((profile) => (
            <li className="avatar-list__picture" key={profile.id}>
              <Person size={36} profile={profile} layout="small" type="coach" />
            </li>
          ))}

        {isNextButtonVisible && (
          <li>
            <Button
              className={clsx(
                'avatar-list__pagination-button',
                'avatar-list__pagination-button--next'
              )}
              onClick={(event) => switchToNextPage(event)}
              variant="icon"
              icon={<Arrow />}
            />
          </li>
        )}
      </ul>
    </div>
  );
};
