import { useCallback, useEffect, useMemo } from 'react';
import { getRangeNumbers } from 'helpers/utils';
import { MOBILE_MAX_WIDTH_QUERY, useMedia } from 'hooks/use-media';
import { useQueryParameters } from 'hooks/use-query-parameters';
import { QueryParamNameTypes } from 'types/enums';

export const DOTS = '…';
export const FIRST_PAGE_VALUE = '1';
export const FIRST_PAGE_INDEX = 1;
const MAX_NUMBER_OF_DOTS = 2;
const LEFT_DOTS_INDEX = 2;
const NUMBER_OF_CONSTANT_ELEMENTS = 3;
const NUMBER_OF_SIBLINGS_TO_SUBTRACT_ON_MOBILE = 1;

type UsePaginationParameters = {
  totalPageCount: number;
  initialOneSideSiblingCount?: number;
  isListEmpty?: boolean;
};

export const usePagination = ({
  totalPageCount,
  initialOneSideSiblingCount = 1,
  isListEmpty,
}: UsePaginationParameters) => {
  const { replace, get } = useQueryParameters();

  const currentPage = Number(get(QueryParamNameTypes.PAGE) || FIRST_PAGE_VALUE);

  const mobileOneSideSiblingCount =
    initialOneSideSiblingCount - NUMBER_OF_SIBLINGS_TO_SUBTRACT_ON_MOBILE;

  const mediaQueries = useMemo(
    () => ({ [MOBILE_MAX_WIDTH_QUERY]: mobileOneSideSiblingCount }),
    [mobileOneSideSiblingCount]
  );

  const oneSideSiblingCount = useMedia({
    mediaQueries,
    defaultValue: initialOneSideSiblingCount,
  });

  const paginationRange = useMemo(() => {
    const bothSidesSiblingCount = oneSideSiblingCount * 2;
    const sideItemsMaxCount =
      bothSidesSiblingCount + NUMBER_OF_CONSTANT_ELEMENTS;

    const leftSiblingIndex = Math.max(
      currentPage - oneSideSiblingCount,
      FIRST_PAGE_INDEX
    );
    const rightSiblingIndex = Math.min(
      currentPage + oneSideSiblingCount,
      totalPageCount
    );

    const rightDotsIndex = totalPageCount - 2;

    const shouldShowLeftDots = leftSiblingIndex > LEFT_DOTS_INDEX;
    const shouldShowRightDots = rightSiblingIndex < rightDotsIndex;

    if (
      bothSidesSiblingCount + NUMBER_OF_CONSTANT_ELEMENTS + MAX_NUMBER_OF_DOTS >
      totalPageCount
    ) {
      return getRangeNumbers(FIRST_PAGE_INDEX, totalPageCount);
    }

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = getRangeNumbers(FIRST_PAGE_INDEX, sideItemsMaxCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = getRangeNumbers(
        totalPageCount - sideItemsMaxCount + FIRST_PAGE_INDEX,
        totalPageCount
      );

      return [FIRST_PAGE_INDEX, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getRangeNumbers(leftSiblingIndex, rightSiblingIndex);

      return [FIRST_PAGE_INDEX, DOTS, ...middleRange, DOTS, totalPageCount];
    }

    return [];
  }, [currentPage, totalPageCount, oneSideSiblingCount]);

  const goToPage = useCallback(
    (pageNumber: number) =>
      replace([
        {
          key: QueryParamNameTypes.PAGE,
          value: pageNumber === 1 ? '' : pageNumber.toString(),
        },
      ]),
    [replace]
  );

  useEffect(() => {
    const shouldGoToNewPage =
      isListEmpty &&
      currentPage !== FIRST_PAGE_INDEX &&
      currentPage !== totalPageCount;

    if (shouldGoToNewPage) {
      const newPageIndex = currentPage - 1;
      goToPage(newPageIndex);
    }
  }, [currentPage, goToPage, isListEmpty, totalPageCount]);

  return { paginationRange, goToPage, currentPage };
};
