import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  addHours,
  eachDayOfInterval,
  endOfWeek,
  format,
  set,
  startOfWeek,
} from 'date-fns';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Loader } from 'components/loader';
import { getWeekDays } from 'helpers/coaching-session-calendar';
import { BookedSessionStatus, CoachBusinessHours } from 'types/api';
import { CoachSessions } from 'views/calendar';
import { SingularDay } from './singular-day';
import './coach-availability.scss';

const generateEmptyBussinessHours = () => {
  const start = startOfWeek(set(new Date(), { hours: 0, minutes: 0 }), {
    weekStartsOn: 1,
  });
  const end = endOfWeek(new Date(), {
    weekStartsOn: 1,
  });
  return eachDayOfInterval({ start, end }).map((day) => ({
    start_time: addHours(day, 8).toISOString(),
    end_time: addHours(day, 16).toISOString(),
    day_name: format(day, 'EEEE'),
    day_id: day.getDay(),
  }));
};

type CoachAvailabilityProps = {
  setAvailabilitiesVisible: () => void;
  isLoading: boolean;
  isProcessing: boolean;
  isOtherCoachCalendarView: boolean;
  businessHours: CoachBusinessHours[];
  setCoachBusinessHours: (
    newBusinessHours: {
      start_time: string;
      end_time: string;
      is_active: boolean;
    }[]
  ) => void;
  futureSessions: CoachSessions;
};

const daysOfWeek = getWeekDays();

export const CoachAvailability: React.FC<CoachAvailabilityProps> = ({
  setAvailabilitiesVisible,
  isLoading,
  businessHours,
  isProcessing,
  isOtherCoachCalendarView,
  setCoachBusinessHours,
  futureSessions,
}) => {
  const { t } = useTranslation();

  const bussinessHoursDays: CoachBusinessHours[] =
    generateEmptyBussinessHours().map((emptyDay) => {
      const foundBussinessHours = businessHours.find(
        (businessHoursDay) =>
          new Date(businessHoursDay.start_time).getDay() === emptyDay.day_id
      );
      if (foundBussinessHours) {
        return {
          end_time: foundBussinessHours.end_time,
          start_time: foundBussinessHours.start_time,
          day_name: emptyDay.day_name,
          is_active: true,
          day_id: foundBussinessHours.id,
        };
      }

      return { ...emptyDay, is_active: false, day_id: emptyDay.day_id };
    });
  const [newBusinessHours, setNewBusinessHours] =
    useState<CoachBusinessHours[]>(bussinessHoursDays);

  if (isLoading) {
    return <Loader />;
  }

  const onConfirm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const areBusinessHoursValid = newBusinessHours.some((day, index) => {
      const dayId = index + 1 === 7 ? 0 : index + 1;
      const startTime = new Date(day.start_time).getHours();
      const endTime = new Date(day.end_time).getHours();
      if (!(startTime && endTime)) {
        return false;
      }
      if (day.is_active && startTime >= endTime) {
        toast.error(t('validate-error-change-start-hour'));
        return true;
      }

      if (day.day_id !== undefined && futureSessions[dayId]) {
        return futureSessions[dayId].some((session) => {
          const sessionStart = new Date(session.start_time).getHours();
          const hasSessionInRange =
            !newBusinessHours.find(
              (hours) =>
                new Date(session.start_time).getDay() ===
                new Date(hours.start_time).getDay()
            )?.is_active ||
            sessionStart > endTime - 1 ||
            sessionStart < startTime;

          if (
            hasSessionInRange &&
            session.status !== BookedSessionStatus.REJECTED
          ) {
            toast.error(t('validate-error-session-in-range'));
            return true;
          }
          return false;
        });
      }
      return false;
    });

    if (!areBusinessHoursValid) {
      if (
        !(JSON.stringify(businessHours) === JSON.stringify(newBusinessHours))
      ) {
        setCoachBusinessHours(
          newBusinessHours.map(({ start_time, end_time, is_active }) => ({
            start_time,
            end_time,
            is_active,
          }))
        );
      }
      setAvailabilitiesVisible();
    }
  };

  const changeDay = (dayName: string) => {
    return (changedDay: CoachBusinessHours) => {
      const changedBusinessHours = newBusinessHours.map((day) =>
        day.day_name === dayName ? changedDay : day
      );
      setNewBusinessHours(changedBusinessHours);
    };
  };

  return (
    <form onSubmit={onConfirm}>
      <Card className="coach-availability">
        <header className="coach-availability__header">
          <div className="coach-availability__activate">{t('activate')}</div>
          <div className="coach-availability__day">{t('day')}</div>
          <div className="coach-availability__range">
            <div className="coach-availability__range-item">{t('start')}</div>
            <div className="coach-availability__range-item">{t('end')}</div>
          </div>
        </header>
        {newBusinessHours.map((day, index) => (
          <SingularDay
            key={daysOfWeek[index]?.name}
            day={day}
            isOtherCoachCalendarView={isOtherCoachCalendarView}
            handleInput={changeDay(day.day_name)}
            futureSessions={
              day.day_id !== undefined ? futureSessions[day.day_id] : []
            }
          />
        ))}
        {!isOtherCoachCalendarView && (
          <div className="coach-availability__buttons">
            <Button variant="secondary" onClick={setAvailabilitiesVisible}>
              {t('back')}
            </Button>
            <Button loading={isProcessing} type="submit">
              {t('confirm')}
            </Button>
          </div>
        )}
      </Card>
    </form>
  );
};
