import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownSelect, OptionType } from 'components/form/dropdown-select';
import { MonthlyCalendar } from 'components/monthly-calendar';
import { TabSwitch } from 'components/tab-switch';
import { WeeklyCalendar } from 'components/weekly-calendar';
import { today } from 'helpers/time';
import { createSelectOptionsFromProfiles } from 'helpers/utils';
import { useCalendarViews } from 'hooks/use-calendar-view';
import { useCoachesAvailabilities } from 'hooks/use-coaches-availabilities';
import './select-date.scss';

enum CalendarView {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export type SelectDateValue = {
  coachId: string;
  slotId: string;
  date: Date;
};

type SelectDateProps = {
  moduleId: string;
  coachId?: number;
  onChange: (newValue: SelectDateValue) => void;
  value: SelectDateValue;
};

const initialSlot = {
  date: today(),
  id: -1,
};

const currentDate = new Date();

export const SelectDate: React.FC<SelectDateProps> = ({
  moduleId,
  onChange,
  coachId,
}) => {
  const [selectedCoach, setSelectedCoach] = useState<OptionType | null>(null);
  const [timeSlot, setTimeSlot] = useState(initialSlot);
  const { t } = useTranslation();
  const {
    month,
    setMonth,
    viewType,
    calendarStartAndEndDates,
    tabs,
    changeTab,
  } = useCalendarViews(timeSlot.date);

  const { coachesWithAvailability } = useCoachesAvailabilities({
    moduleId,
    calendarStartAndEndDates,
  });

  useEffect(() => {
    if (coachesWithAvailability) {
      let coachIndex = 0;
      if (coachId) {
        coachIndex = coachesWithAvailability.findIndex(
          (coach) => coach.id === +coachId
        );
      }
      if (!selectedCoach) {
        setSelectedCoach(
          createSelectOptionsFromProfiles(coachesWithAvailability)[coachIndex]
        );
      }
    }
  }, [coachId, coachesWithAvailability, selectedCoach]);

  useEffect(() => {
    onChange({
      date: timeSlot.date,
      slotId: String(timeSlot.id),
      coachId: selectedCoach?.value ?? '',
    });
  }, [onChange, selectedCoach, timeSlot]);

  const coachAvailability =
    coachesWithAvailability.find(
      (coach) => String(coach.id) === selectedCoach?.value
    )?.availability ?? [];
  const coachOptions = createSelectOptionsFromProfiles(coachesWithAvailability);

  return (
    <div className="select-date">
      {!coachId && (
        <DropdownSelect
          options={coachOptions}
          defaultValue={coachOptions[0]}
          headingText={t('choose-coach')}
          name="coach"
          value={selectedCoach}
          isMulti={false}
          onChange={(newValue) => setSelectedCoach(newValue)}
        />
      )}
      <TabSwitch value={viewType} onChange={changeTab} tabs={tabs} />
      {viewType === CalendarView.WEEKLY && (
        <WeeklyCalendar
          className="select-date__weekly"
          selectedSlot={timeSlot}
          setSelectedSlot={setTimeSlot}
          availability={coachAvailability}
        />
      )}
      {viewType === CalendarView.MONTHLY && (
        <MonthlyCalendar
          className="select-date__monthly"
          selectedSlot={timeSlot}
          setSelectedSlot={setTimeSlot}
          selectedMonth={month}
          setSelectedMonth={setMonth}
          minDate={currentDate}
          availabilities={coachAvailability}
        />
      )}
    </div>
  );
};
