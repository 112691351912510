import { ReactNode } from 'react';
import clsx from 'clsx';
import './card.scss';

type CardProps = {
  children: ReactNode;
  className?: string;
  testid?: string;
};
export const Card = ({ children, className, testid }: CardProps) => {
  return (
    <div data-testid={testid} className={clsx('card', className)}>
      {children}
    </div>
  );
};
