import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import {
  FirstStepFormProps,
  SessionModalStep,
} from 'components/coaching-session-modal';
import { Select } from 'components/form';
import { today } from 'helpers/time';
import { StateSetter } from 'helpers/types';
import { AvailableCourseModule } from 'types/api';
import './select-course-step.scss';

type SelectCourseStepProps = {
  setStepFormValue: StateSetter<FirstStepFormProps>;
  setSelectedCourse: StateSetter<AvailableCourseModule | null>;
  courses: AvailableCourseModule[];
  selectedCourse: AvailableCourseModule | null;
  courseEndDate?: string;
  setStep: StateSetter<SessionModalStep>;
};

export const SelectCourseStep: React.FC<SelectCourseStepProps> = ({
  setStepFormValue,
  courses,
  selectedCourse,
  setSelectedCourse,
  setStep,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm();

  const submit = (data: FirstStepFormProps) => {
    setStepFormValue(data);
    setStep(SessionModalStep.SECOND);
  };

  const isNextStepDisabled = selectedCourse
    ? new Date(selectedCourse.end_date) < today()
    : false;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="select-course__sub-title">{t('choose-course')}</div>
      <Select
        name="course"
        onChange={(value) => {
          const newCourse = courses.find((course) => course.id === +value);
          if (newCourse) {
            setSelectedCourse(newCourse);
          }
        }}
        register={register({ required: true })}
        options={courses.map((course) => ({
          value: course.id.toString(),
          label: course.course.name,
        }))}
      />
      <div className="select-course__sub-title">{t('choose-module')}</div>
      {selectedCourse && (
        <Select
          errors={errors}
          register={register({ required: true })}
          name="moduleId"
          options={selectedCourse.course.course_modules.map((module) => ({
            value: module.id.toString(),
            label: module.name,
          }))}
        />
      )}
      <div className="select-course__button-wrapper">
        {isNextStepDisabled && (
          <p className="select-course__error-message">{t('course-ended')}</p>
        )}
        <Button
          className="select-course__button"
          disabled={isNextStepDisabled}
          type="submit"
        >
          {t('next')}
        </Button>
      </div>
    </form>
  );
};
