import { ModuleAttachment } from 'types/api';
import { ReactComponent as IconDownload } from 'assets/icons/download-2-line.svg';
import './attachment-item.scss';

type PropsAttachmentItem = {
  details: ModuleAttachment;
};

export const AttachmentItem: React.FC<PropsAttachmentItem> = ({
  details: { name, url },
}) => {
  return (
    <div className="attachment-itemV2">
      <div className="attachment-itemV2__name">{name}</div>

      <a href={url} target="_blank" rel="nofollow noopener noreferrer">
        <IconDownload className="attachment-itemV2__icon" />
      </a>
    </div>
  );
};
