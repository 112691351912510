import {
  format as formatDate,
  formatDuration,
  intervalToDuration,
  secondsToMilliseconds,
} from 'date-fns';
import { pl } from 'date-fns/locale';
import i18n from 'i18n';
import { DateFormats } from 'types/enums';

export const today = () => new Date();

const dateFormats = {
  default: DateFormats.DEFAULT,
  weekDay: DateFormats.WEEK_DAY,
  dateWithTime: `${DateFormats.DAY_MONTH_YEAR_DASHED}, ${DateFormats.TIME_SHORT}`,
  onlyTime: DateFormats.TIME_SHORT,
  onlyDate: DateFormats.DAY_MONTH_YEAR_DASHED,
};

const locales: Record<string, Locale> = {
  'pl-PL': pl,
};

export const format = (
  time: string | Date,
  type:
    | 'default'
    | 'onlyDate'
    | 'weekDay'
    | 'dateWithTime'
    | 'onlyTime' = 'default'
) => {
  const { language } = i18n;
  return formatDate(new Date(time), dateFormats[type], {
    locale: locales[language],
  });
};

const durationShortFormats = {
  xHours: (count: number) => `${count} h`,
  xMinutes: (count: number) => `${count} min`,
  xSeconds: (count: number) => `${count} s`,
};

const durationShortLocale = {
  formatDistance: (key: keyof typeof durationShortFormats, count: number) =>
    durationShortFormats[key](count),
};

type TimeUnits = 'hours' | 'minutes' | 'seconds';

const formatCustomDuration = (
  seconds: number,
  durationFormat: TimeUnits[],
  customLocale: Locale
) => {
  const duration = intervalToDuration({
    start: 0,
    end: secondsToMilliseconds(seconds),
  });

  return formatDuration(duration, {
    format: durationFormat,
    locale: customLocale,
  });
};

export const formatDurationShort = (
  seconds: number,
  durationFormat: TimeUnits[] = ['hours', 'minutes', 'seconds']
) => formatCustomDuration(seconds, durationFormat, durationShortLocale);

export const formatDurationModule = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: secondsToMilliseconds(seconds),
  });

  const zeroPad = (num: string) => String(num).padStart(2, '0');

  const formatted = formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  });

  return formatted;
};
