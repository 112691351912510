import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { TasksListItem } from 'components/tasks-list-item';
import { LessonTask as Task, ModuleDetails } from 'types/api';
import { AnswerStatus } from 'types/enums';
import './lesson-task.scss';

type LessonTaskProps = {
  isTaskModalOpen: boolean;
  onModalOpen: () => void;
  onModalClose: () => void;
  task: Task;
  isLessonCompleted: boolean;
  currentModule: ModuleDetails | undefined;
  invalidateLesson: () => void;
};

export const LessonTask: React.FC<LessonTaskProps> = ({
  isTaskModalOpen,
  onModalOpen,
  onModalClose,
  task,
  isLessonCompleted,
  currentModule,
  invalidateLesson,
}) => {
  const { t } = useTranslation();
  const { isCoach } = useContext(AuthContext);

  const answerStatus = task.participant_answer
    ? task.participant_answer.status
    : AnswerStatus.TO_DO;

  const isAnswerAccepted = answerStatus === AnswerStatus.ACCEPTED;
  const isAnswerVerifying = answerStatus === AnswerStatus.TO_VERIFY;
  const isAnswerRejected = answerStatus === AnswerStatus.FAILED;

  const isTaskButtonDisabled =
    !isCoach && (!isLessonCompleted || isAnswerAccepted || isAnswerVerifying);

  const buttonContent =
    (isAnswerAccepted && t('exercise-accepted')) ||
    (isAnswerVerifying && t('send-to-review')) ||
    (isAnswerRejected && t('task-to-improve')) ||
    ((isCoach || isLessonCompleted) && t('task-preview')) ||
    t('solve-now');

  const taskAnswer = task.participant_answer;

  return (
    <>
      <Modal
        isOpen={isTaskModalOpen}
        onRequestClose={onModalClose}
        maxWidth="1000px"
        noPadding={!isCoach && isTaskModalOpen}
      >
        <TasksListItem
          {...task}
          isFinished={task.participant_answer?.status === AnswerStatus.ACCEPTED}
          taskAnswer={
            taskAnswer
              ? { ...taskAnswer, answer_date: taskAnswer.created_at }
              : null
          }
          onSave={() => {
            invalidateLesson();
          }}
          hideModal={onModalClose}
          currentModule={currentModule}
        />
      </Modal>

      <div className="lesson-task">
        {t('first-task')}
        <div className="lesson-task__tippy-wrapper">
          <Button
            variant="secondary"
            disabled={isTaskButtonDisabled}
            onClick={onModalOpen}
            className={clsx(
              'lesson-task__button',
              isAnswerAccepted && 'lesson-task__button--accepted',
              isAnswerVerifying && 'lesson-task__button--verifying',
              isAnswerRejected && 'lesson-task__button--rejected'
            )}
          >
            {buttonContent}
          </Button>
        </div>
      </div>
    </>
  );
};
