import clsx from 'clsx';
import './tab.scss';

type TabProps = {
  text: string;
  isActive: boolean;
};

export const Tab: React.FC<TabProps> = ({ text, isActive }) => {
  return (
    <div className="tab">
      <div className={clsx('tab__item', isActive && 'tab__item--active')}>
        {text}
      </div>

      <div
        className={clsx('tab__runner', !isActive && 'tab__runner--hidden')}
      />
    </div>
  );
};
