import { NavLink } from 'react-router-dom';
import './menu-item.scss';

type MenuItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  exact?: boolean;
  to: string;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  to,
  icon,
  children,
  exact = false,
}) => (
  <li className="menu-item">
    <NavLink
      className="menu-item__link"
      activeClassName="menu-item__link--active"
      to={to}
      exact={exact}
    >
      <span className="menu-item__icon">{icon}</span>
      <span className="menu-item__name">{children}</span>
    </NavLink>
  </li>
);
