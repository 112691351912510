import { createContext, ReactChild, useRef } from 'react';
import actionCable, { Cable } from 'actioncable';

interface ActionCableType {
  cable: Cable | null;
  createWSConnection: (token: string) => void;
  removeWSConnection: () => void;
}

type ActionCableProps = {
  children: ReactChild;
};

const getCableAddress = (token: string) =>
  `${process.env.REACT_APP_CABLE_URL}?access_token=${token}`;

export const ActionCableContext = createContext<ActionCableType>({
  cable: null,
  createWSConnection: () => {},
  removeWSConnection: () => {},
});

export const ActionCableProvider: React.FC<ActionCableProps> = ({
  children,
}) => {
  const contextValue = useRef<ActionCableType>({
    cable: null,
    createWSConnection: () => {},
    removeWSConnection: () => {},
  });

  contextValue.current.createWSConnection = (token: string) => {
    const createdCable = actionCable.createConsumer(getCableAddress(token));
    contextValue.current.cable = createdCable;
  };

  contextValue.current.removeWSConnection = () => {
    contextValue.current.cable?.disconnect();
    contextValue.current.cable = null;
  };

  return (
    <ActionCableContext.Provider value={contextValue.current}>
      {children}
    </ActionCableContext.Provider>
  );
};
