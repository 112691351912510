import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { Company } from 'components/company';
import { Select } from 'components/form';
import { SubscriptionName } from 'components/subscription-name';
import { ViewWrapper } from 'components/view-wrapper';
import { CompanyDetails } from 'types/api';
import { ReactComponent as IconFilter } from 'assets/icons/arrow-up-down.svg';
import Mercedes from 'assets/img/mercedes.png';
import './settlements.scss';

type Settlement = {
  id: number;
  date: string;
  course: string;
  subscription: string;
  company: CompanyDetails;
};

const data: Settlement[] = [
  {
    id: 1,
    date: '11.02.2021',
    course: 'Akademia Menadżera',
    subscription: 'Open Max',
    company: {
      description: '',
      is_active: true,
      url: '',
      id: 2,
      logo: Mercedes,
      name: 'Mercedes',
      stats: {
        groupMeet: 3,
        faceToFaceMeet: 6,
      },
    },
  },
  {
    id: 2,
    date: '12.02.2021',
    course: 'Motywacja od A do Z',
    subscription: 'Open Plus',
    company: {
      description: '',
      is_active: true,
      url: '',
      id: 2,
      logo: Mercedes,
      name: 'Mercedes',
      stats: {
        groupMeet: 1,
        faceToFaceMeet: 5,
      },
    },
  },
  {
    id: 3,
    date: '11.02.2021',
    course: 'Akademia Menadżera',
    subscription: 'Team Standard',
    company: {
      description: '',
      is_active: true,
      url: '',
      id: 2,
      logo: Mercedes,
      name: 'Mercedes',
      stats: {
        groupMeet: 3,
        faceToFaceMeet: 6,
      },
    },
  },
];

export const Settlements: React.FC = () => {
  const { t } = useTranslation();
  const [isOrderASC, setIsOrderASC] = useState(true);
  const [settlements, setSettlements] = useState(data);

  const sortData = () => {
    setIsOrderASC((prevState) => !prevState);
    setSettlements((prevState) => {
      const sortedData = [...prevState].sort((a, b) => {
        const sortSign = isOrderASC ? -1 : 1;
        if (a.course < b.course) {
          return -sortSign;
        }
        if (a.course > b.course) {
          return sortSign;
        }
        return 0;
      });
      return sortedData;
    });
  };

  const calculateSummary = () => {
    const subscriptionTypes = [
      ...Array.from(new Set(data.map((settlement) => settlement.subscription))),
    ];
    const summary = subscriptionTypes.map((sub) => {
      const filteredCompanies = data
        .filter((settlement) => settlement.subscription === sub)
        .map((settlement) => settlement.company);
      return {
        type: sub,
        companies: filteredCompanies,
        individual: filteredCompanies.reduce(
          (acc, { stats }) => (stats ? acc + stats.faceToFaceMeet : 0),
          0
        ),
        group: filteredCompanies.reduce(
          (acc, { stats }) => (stats ? acc + stats.groupMeet : 0),
          0
        ),
      };
    });
    return summary;
  };

  return (
    <ViewWrapper className="settlements">
      <div className="settlements__select-container">
        <Select options={[{ label: t('current-week'), value: '-1' }]} />
      </div>
      <div className="settlements__content">
        <Card className="settlements__table-wrapper">
          <table className="settlements__table">
            <thead>
              <tr className="settlements__thead-tr">
                <th className="settlements__th settlements__th--padding">
                  {t('date')}
                </th>
                <th className="settlements__th">{t('client')}</th>
                <th className="settlements__th">
                  <div className="settlements__filter">
                    {t('course')}
                    <Button
                      variant="icon"
                      onClick={sortData}
                      icon={<IconFilter />}
                    />
                  </div>
                </th>
                <th className="settlements__th">{t('subscription')}</th>
                <th className="settlements__th">{t('individual')}</th>
                <th className="settlements__th">{t('group')}</th>
              </tr>
            </thead>
            <tbody>
              {(settlements || []).map((settlement) => (
                <tr className="settlements__tbody-tr" key={settlement.id}>
                  <td className="settlements__td"> {settlement.date}</td>
                  <td className="settlements__td">
                    <Company company={settlement.company} />
                  </td>
                  <td className="settlements__td"> {settlement.course}</td>

                  <td className="settlements__td">
                    {settlement.subscription.replaceAll('_', ' ')}
                  </td>
                  {settlement.company.stats && (
                    <td>{settlement.company.stats.faceToFaceMeet}</td>
                  )}
                  {settlement.company.stats && (
                    <td className="settlements__td">
                      {settlement.company.stats.groupMeet}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <div className="settlements-summary">
          <header className="settlements-summary__header">
            <div>{t('summary')}</div>
            <div />
            <div>{t('individual-short')}</div>
            <div>{t('group-short')}</div>
          </header>
          {calculateSummary().map((sub) => (
            <main className="settlements-summary__content" key={sub.type}>
              <div className="settlements-summary__logos">
                {sub.companies.map((company, index) => (
                  <div
                    key={company.id + sub.type}
                    className="settlements-summary__logo"
                    style={{ zIndex: -10 + index }}
                  >
                    <Company avatarSize="small" onlyLogo company={company} />
                  </div>
                ))}
              </div>

              <SubscriptionName name={sub.type} />
              <div className="settlements-summary__count">
                {sub.individual}h
              </div>
              <div className="settlements-summary__count">{sub.group}h</div>
            </main>
          ))}
        </div>
      </div>
    </ViewWrapper>
  );
};

export default Settlements;
