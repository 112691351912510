import { useEffect, useMemo } from 'react';
import throttle from 'lodash.throttle';

export const useThrottle = <T extends unknown>(
  changeHandler: (value: T) => void,
  interval: number,
  flushOnUnmount = true
) => {
  const throttledChangeHandler = useMemo(
    () => throttle(changeHandler, interval),
    [changeHandler, interval]
  );

  useEffect(
    () => () => {
      if (flushOnUnmount) {
        throttledChangeHandler.flush();
      }
      throttledChangeHandler.cancel();
    },
    [throttledChangeHandler, flushOnUnmount]
  );

  return throttledChangeHandler;
};
