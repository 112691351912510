import { useEffect, useState } from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import clsx from 'clsx';
import { useScrollToElement } from 'hooks/use-scroll-to-element';
import { ReactComponent as IconArrow } from 'assets/icons/menu-up-arrow.svg';
import { ReactComponent as IconLoader } from 'assets/icons/puff.svg';
import './collapse.scss';

type CollapseProps = {
  hiddenContent: React.ReactNode;
  shouldCloseOnBlur?: boolean;
  className?: string;
  blueDot?: boolean;
  borderBottom?: boolean;
  isAutoScroll?: boolean;
  closeOnSelection?: boolean;
  open?: boolean;
  arrowClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  isReverseOrder?: boolean;
  wrapperClassName?: string;
  contentWrapperClassName?: string;
};

export const Collapse: React.FC<CollapseProps> = ({
  children,
  className,
  wrapperClassName,
  contentWrapperClassName,
  hiddenContent,
  blueDot = false,
  borderBottom = false,
  isAutoScroll = true,
  closeOnSelection = false,
  open = false,
  disabled = false,
  arrowClassName,
  onClick = () => {},
  shouldCloseOnBlur = false,
  isLoading = false,
  isReverseOrder = false,
}) => {
  const [isOpened, setIsOpened] = useState(open);

  useEffect(() => {
    setIsOpened(open);
  }, [open]);

  const collapseRef = useScrollToElement<HTMLButtonElement>({
    isScrollDestination: isAutoScroll ? isOpened : false,
  });

  const handleClick = () => {
    setIsOpened((previousIsOpened) => !previousIsOpened);
    onClick();
  };

  const handleOnBlur = () => {
    if (shouldCloseOnBlur) {
      setIsOpened(false);
    }
  };

  const collapseChildren = isReverseOrder ? (
    <>
      <div className="collapse__arrow-container">
        {isLoading ? (
          <IconLoader className="collapse__loader" />
        ) : (
          <IconArrow
            className={clsx(
              'collapse__arrow',
              isOpened && 'collapse__arrow--up',
              arrowClassName
            )}
          />
        )}
      </div>
      <div className="collapse__children">{children}</div>
    </>
  ) : (
    <>
      <div className="collapse__children">{children}</div>

      <div className="collapse__arrow-container">
        {isLoading ? (
          <IconLoader className="collapse__loader" />
        ) : (
          <IconArrow
            className={clsx(
              'collapse__arrow',
              isOpened && 'collapse__arrow--up',
              arrowClassName
            )}
          />
        )}
      </div>
    </>
  );

  return (
    <div
      className={clsx(
        wrapperClassName,
        borderBottom && 'collapse--border-bottom'
      )}
    >
      <button
        onBlur={handleOnBlur}
        type="button"
        disabled={disabled}
        ref={collapseRef}
        className={clsx(
          'collapse',
          isLoading && 'collapse--loading',
          className
        )}
        onClick={handleClick}
      >
        {blueDot && isOpened && (
          <div className="collapse__blue">
            <div className="collapse__dot" />
          </div>
        )}

        {collapseChildren}
      </button>

      <ReactCollapse isOpened={!isLoading && isOpened}>
        <div className={contentWrapperClassName}>
          {closeOnSelection ? (
            <button
              type="button"
              disabled={disabled}
              className="collapse__hidden-content"
              onClick={handleClick}
            >
              {hiddenContent}
            </button>
          ) : (
            hiddenContent
          )}
        </div>
      </ReactCollapse>
    </div>
  );
};
