import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { getFileExtension } from 'helpers/utils';
import { ReactComponent as CloseBoldLine } from 'assets/icons/close-bold-line.svg';
import { ReactComponent as IconPaperClip } from 'assets/icons/paper-clip.svg';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import './file-input.scss';

type Files = File & { preview?: string; url?: string };

export enum TypeView {
  PROFILE = 'profile',
  PLUS_ICON = 'plus-icon',
}

type PropsFile = {
  accept?: string;
  maxSize?: number;
  maxOneSize?: number;
  label?: string;
  preview?: boolean;
  typeView?: TypeView;
  maxCount?: number;
  onDrop?: (...any: any[]) => void;
  errors?: FieldErrors<Record<string, string>>;
  name?: string;
  value?: File[];
  children?: React.ReactNode;
};

const ONE_MB = 1024 * 1024;
const ACCEPTED_FILES = [
  '.mp4 video/mp4',
  '.avi video/x-msvideo',
  '.mpeg video/mpeg',
  '.mov video/quicktime',
  '.mp3 audio/mpeg',
  '.wav audio/wav',
  '.m4a audio/mp4',
  '.pdf application/pdf',
  '.doc application/msword',
  '.docx application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls application/vnd.ms-excel',
  '.xlsx application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.txt text/plain',
];
export const FileInput: React.FC<PropsFile> = ({
  children,
  accept = '.pdf, .jpg, .jpeg, .png',
  maxOneSize = 4,
  maxSize = 30,
  label = '',
  preview = true,
  typeView = TypeView.PROFILE,
  maxCount = 10,
  errors = {},
  onDrop = () => {},
  name = 'files',
  value = [],
}) => {
  const [allFiles, setAllFiles] = useState<File[]>(value);
  const [errorMessageOneFile, setErrorMessageOneFile] = useState('');
  const [errorMessageAllFiles, setErrorMessageAllFiles] = useState('');

  const [stateAction, setStateAction] = useState<string | boolean>(false);
  const inputElement = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const errorLabelWithFallback =
    errors[name]?.message || `${t('field-required')}`;

  useEffect(() => {
    if (inputElement.current?.files && value.length > 0) {
      setAllFiles(value);
    }
  }, [value, t]);

  useEffect(() => {
    try {
      const files = typeView === TypeView.PROFILE ? allFiles[0] : allFiles;
      if (!(files as []).length) {
        onDrop(false);
      }
      onDrop(files);
    } catch (e) {
      onDrop([]);
    }
  }, [allFiles, onDrop, typeView]);

  useEffect(() => {
    setAllFiles([]);
  }, [accept]);

  const filesSize = useMemo(
    () => allFiles.reduce((result, file) => result + file.size, 0),
    [allFiles]
  );

  useEffect(() => {
    if (!maxSize) {
      return;
    }
    const totalSizeInMB = filesSize / ONE_MB;
    if (maxSize < totalSizeInMB) {
      setErrorMessageAllFiles(t('multiple-size-error', { maxSize }));
    } else {
      setErrorMessageAllFiles('');
    }
  }, [filesSize, maxSize, t]);

  const onFile = async (
    e: React.ChangeEvent<HTMLInputElement> & React.DragEvent<HTMLLabelElement>
  ): Promise<void> => {
    e.preventDefault();
    setErrorMessageOneFile('');
    setStateAction(false);
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    if (files.length > maxCount) {
      toast.error(t('file-limit', { fileLimit: maxCount }));
      return;
    }

    const fileArray: Files[] = [];
    const previewPromises: Promise<string>[] = [];
    let error = false;

    const previewImage = async (file: File): Promise<string> => {
      const reader = new FileReader();
      if (!file || file?.type.indexOf('image') === -1) {
        return '';
      }
      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
      });
    };

    Object.keys(files).forEach((key: any) => {
      const file = files[key];
      const size = file.size / 1024 / 1024;

      if (size < maxOneSize) {
        fileArray.push(file);
        if (preview) {
          previewPromises.push(previewImage(file));
        }
      } else {
        const errorText =
          size > maxOneSize && t('single-size-error', { maxOneSize });
        if (errorText) {
          error = true;
          setErrorMessageOneFile(errorText);
        }
      }
    });

    if (error) {
      return;
    }

    const resultPreview = await Promise.all(previewPromises);

    resultPreview.forEach((image, index) => {
      fileArray[index].preview = image;
    });

    if (allFiles.length + files.length > maxCount) {
      const countToRemove = allFiles.length + files.length - maxCount;
      allFiles.splice(allFiles.length - countToRemove, countToRemove);
    }

    const concatFiles = allFiles.concat(fileArray);
    setAllFiles(concatFiles);
    toast.success(t('file-added'));
  };

  const remove = (index: number) => {
    if (maxCount === 1 && inputElement.current) {
      inputElement.current.value = '';
      setAllFiles([]);
    }
    const filteredFiles = allFiles.filter((file, i) => i !== index);
    setAllFiles(filteredFiles);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setStateAction('dragOver');
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setStateAction('');
  };

  const isAnyFileLoaded = allFiles?.length > 0;

  return (
    <div className="file">
      {label && (
        <label className="file__label" data-testid="label-from-props">
          <span className="file__label-text">{label}</span>
        </label>
      )}
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={onFile}
        className={clsx(
          `file__${typeView}`,
          stateAction !== false && stateAction,
          isAnyFileLoaded && `file__${typeView}--active`
        )}
      >
        <div className="files-list">
          {allFiles.map((file: Files, index) => (
            <Card
              // eslint-disable-next-line react/no-array-index-key
              key={`${file.lastModified}${index}`}
              className={clsx(
                typeView === TypeView.PLUS_ICON && 'file__element'
              )}
            >
              <span
                className={clsx(
                  'file__extension',
                  typeView === TypeView.PROFILE && 'file__extension--hidden'
                )}
              >
                {getFileExtension(file.name) || '(?)'}
              </span>
              <Tippy
                className="file__tooltip"
                theme="light-border"
                content={
                  <span className="file__tippy-content">{file.name}</span>
                }
              >
                <a
                  className={clsx(
                    'file__name',
                    typeView === TypeView.PROFILE && 'file__name--hidden'
                  )}
                  href={file.url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {file.name}
                </a>
              </Tippy>
              <span className="file__size">
                ({(file.size / ONE_MB).toFixed(2)} Mb){' '}
              </span>
              {file.preview && (
                <img
                  className={clsx(
                    typeView === TypeView.PROFILE && 'file__preview'
                  )}
                  src={file.preview}
                  alt=""
                  width="200"
                />
              )}
              <Button
                className={clsx(
                  'file__remove',
                  typeView === TypeView.PROFILE && 'file__remove--hidden'
                )}
                onClick={() => remove(index)}
                variant="icon"
                icon={<CloseBoldLine className="file__close-icon" />}
              />
            </Card>
          ))}
        </div>
        <Tippy
          className="file__tooltip"
          content={
            <>
              <span className="file__bold">{t('choose-file-type-task')}</span>
              {ACCEPTED_FILES.map((type) => (
                <div className="line" key={type}>
                  {type}
                </div>
              ))}
            </>
          }
          theme="light-border"
          placement="bottom"
        >
          <div
            className={clsx(
              'file__input-header',
              isAnyFileLoaded && 'file__input-header--hidden'
            )}
          >
            <IconPaperClip className="file__paper-clip-icon" />
            {t('add-attachment')}
          </div>
        </Tippy>
        <div
          className={clsx(
            'file__input-field',
            isAnyFileLoaded && 'file__input-field--hidden'
          )}
        >
          <label
            className={clsx(
              typeView === TypeView.PROFILE && `file__profile-input`,
              typeView === TypeView.PROFILE &&
                isAnyFileLoaded &&
                'file__profile-input--without-plus'
            )}
          >
            <Trans i18nKey="import-file">
              <span className="file__import-button">import</span>
            </Trans>
            <input
              ref={inputElement}
              className="file__input"
              type="file"
              onChange={onFile}
              multiple={maxCount > 1}
              accept={accept}
            />
            {children}
          </label>
        </div>
      </div>
      {errorMessageOneFile && (
        <span className="file__error">{errorMessageOneFile}</span>
      )}
      {errorMessageAllFiles && (
        <span className="file__error">{errorMessageAllFiles}</span>
      )}
      {errors[name] && errorLabelWithFallback && (
        <span className="file__error">
          {errors[name] && errorLabelWithFallback}
        </span>
      )}
    </div>
  );
};
