import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCoachDetails } from 'services/coaches';

export const useCoachDetails = (
  profileId: number | null,
  params?: { onError?: () => void; onSuccess?: () => void }
) => {
  const { t } = useTranslation();

  const { data: coachDetails, ...rest } = useQuery(
    [QueryKeys.COACH_DETAILS, profileId],
    () => getCoachDetails(profileId!),
    {
      onError: () => {
        toast.error(t('coach-details-error'));
        if (params?.onError) {
          params.onError();
        }
      },
      onSuccess: () => {
        if (params?.onSuccess) {
          params.onSuccess();
        }
      },
      enabled: !!profileId,
    }
  );

  return {
    coachDetails,
    ...rest,
  };
};
