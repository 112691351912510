import { useForm } from 'react-hook-form/dist/useForm';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import './checkbox.scss';

type PropsInput = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange'
> & {
  checked?: boolean;
  label?: string | boolean;
  errorLabel?: string;
  onChange?: (value: boolean) => void;
  register?: ReturnType<typeof useForm>['register'];
  errors?: ReturnType<typeof useForm>['errors'];
};

export const Checkbox: React.FC<PropsInput> = ({
  label = false,
  name = '',
  className,
  register = undefined,
  errors = {},
  errorLabel,
  disabled = false,
  defaultValue,
  onChange,
  checked = false,
  ...props
}) => {
  const { t } = useTranslation();
  const errorMessage = name && errors[name] ? errors[name].message : null;
  const errorLabelWithFallback =
    errorMessage || errorLabel || `${t('field-required')}`;

  const computedProps = onChange
    ? {
        checked,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.checked);
        },
      }
    : { checked };

  return (
    <div className={clsx('form-checkbox', className)}>
      <label className="form-checkbox__label" htmlFor={`form-${name}`}>
        <span className="form-checkbox__label-text">{label}</span>
      </label>
      <input
        className={clsx(
          'form-checkbox__checkbox',
          errors?.[name] &&
            errorLabelWithFallback &&
            'form-checkbox__input--error'
        )}
        {...computedProps}
        name={name}
        type="checkbox"
        ref={register}
        disabled={disabled}
        id={`form-${name}`}
        {...props}
      />
      {errors?.[name] && errorLabelWithFallback && (
        <span className="form-checkbox__error">{errorLabelWithFallback}</span>
      )}
    </div>
  );
};
