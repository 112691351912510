import { CoursesGridV2 } from 'views/coursesV2/components/courses-gridV2';
import { CourseItemV2 } from 'views/coursesV2/components/item/course-itemV2';

export const useCoursesNavItemsV2 = () => {
  return [
    {
      url: '/courses',
      component: CoursesGridV2,
      label: 'CoursesGridV2',
      hidden: true,
    },

    {
      url: '/courses/:courseId/:contentType?/:contentId?',
      label: 'course-item',
      component: CourseItemV2,
      hidden: true,
    },
  ];
};
