import { useTranslation } from 'react-i18next';
import { Card } from 'components/card';
import { Video } from 'components/video';
import { useCourseIntro } from 'hooks/use-intro';
import { CourseErrorHandle } from './course-error-handle';
import { CourseItemContentV2 } from './course-item-contentV2';
import './course-intro.scss';

export const CourseIntro: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading, isSuccess, courseIntro } = useCourseIntro();

  return (
    <CourseItemContentV2
      isLoading={isLoading}
      isResponseSuccess={isSuccess}
      errorMessage={<CourseErrorHandle lackOf="intro" />}
    >
      <div className="course-intro">
        <Card className="course-intro__card">
          <div className="course-intro__title">{t('course-intro')}</div>
          <Video source={courseIntro?.intro_video_url} />
        </Card>
      </div>
    </CourseItemContentV2>
  );
};
