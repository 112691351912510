import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from 'components/card';
import { Company } from 'components/company';
import { Loader } from 'components/loader';
import { NoResource } from 'components/no-resource';
import { useBookedSessions } from 'hooks/use-booked-sessions';
import { useDashboard } from 'hooks/use-dashboard';
import { useTasks } from 'hooks/use-tasks';
import {
  MeetingsCard,
  TaskToEvaluationCard,
  UpcomingSessionCard,
} from './components';
import './coach-dashboard.scss';

const apiParameters = {
  course_id: null,
  course_module_id: null,
  page: '1',
  participant_id: null,
  company_id: null,
  status: 'to_verify',
  sort_by: 'updated_at',
  sort_direction: 'desc',
};

const CoachDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { tasks } = useTasks(apiParameters);
  const { companies, isLoading } = useDashboard();
  const { bookedSessions } = useBookedSessions();
  const isNewTaskToEvalutation = tasks?.find((task) => task.answers.length > 0);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className="coach-dashboard">
      <section>
        <h2 className="coach-dashboard__title">{t('active-clients')}</h2>

        {companies?.length ? (
          <Card className="coach-dashboard__companies">
            {companies.map((company) => (
              <Link
                key={company.id}
                to={`/companies/${company.id}`}
                className="coach-dashboard__link"
              >
                <Company
                  avatarSize="extra-large"
                  displayColumn
                  company={company}
                  className="coach-dashboard__company"
                />
              </Link>
            ))}
          </Card>
        ) : (
          <NoResource>{t('no-clients')}</NoResource>
        )}
      </section>

      <section>
        <h2 className="coach-dashboard__title">{t('general-stats')}</h2>
        {companies && (
          <div className="coach-dashboard__statistics">
            <div className="coach-dashboard__meetings">
              <MeetingsCard
                testId="face-to-face-meeting-card"
                companies={companies}
              />
              <MeetingsCard
                testId="group-meeting-card"
                type="group"
                companies={companies}
              />
            </div>
            <div className="coach-dashboard__task-session">
              {isNewTaskToEvalutation && <TaskToEvaluationCard />}
              {!!bookedSessions?.length && (
                <UpcomingSessionCard sessions={bookedSessions} />
              )}
            </div>
          </div>
        )}
      </section>
    </main>
  );
};

export default CoachDashboard;
