import { useContext, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import {
  ASPECT_RATIO,
  CROP_SIZE,
  MAX_ZOOM,
  MIN_ZOOM,
  START_POSITION,
  ZOOM_STEP,
} from 'constants/image-cropper';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { LazyAvatar } from 'components/lazy-avatar';
import { Loader } from 'components/loader';
import { Modal } from 'components/modal';
import getCroppedImg from 'helpers/cropper';
import { StateSetter } from 'helpers/types';
import { getUserInitials } from 'helpers/utils';
import { ReactComponent as IconPlus } from 'assets/icons/icon-finder-plus-line.svg';
import './image-cropper.scss';

type ImageCropperProps = {
  defaultPhotoUrl?: string;
  setFile: StateSetter<Blob | null>;
  setIsAvatarDeleted: StateSetter<boolean>;
  accept: string;
};

export const ImageCropper: React.FC<ImageCropperProps> = ({
  defaultPhotoUrl = '',
  setFile,
  setIsAvatarDeleted,
  accept,
}) => {
  const { user } = useContext(AuthContext);
  const [crop, setCrop] = useState(START_POSITION);
  const [zoom, setZoom] = useState(MIN_ZOOM);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteAvatarRequest, setIsDeleteAvatarRequest] = useState(false);
  const { t } = useTranslation();

  const [basePhotoUrl, setBasePhotoUrl] = useState(defaultPhotoUrl);
  const [croppedPhotoURL, setCroppedPhotoURL] = useState(defaultPhotoUrl);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isCropOpen, setIsCropOpen] = useState(false);

  const handleUploadedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      setBasePhotoUrl(URL.createObjectURL(newFile));
      setIsCropOpen(true);
    }
  };

  const onCropClose = (isCloseOnConfirm?: boolean) => {
    setIsCropOpen(false);
    setZoom(MIN_ZOOM);
    setIsAvatarDeleted(false);
    if (!isCloseOnConfirm && !croppedPhotoURL) {
      setCroppedPhotoURL('');
      setBasePhotoUrl('');
    }
  };

  const cropImage = async () => {
    setIsLoading(true);
    try {
      if (!croppedAreaPixels) {
        return;
      }
      const croppedImg = await getCroppedImg(basePhotoUrl, croppedAreaPixels);

      if (!croppedImg) {
        return;
      }
      setCroppedPhotoURL(croppedImg.url);
      setFile(croppedImg.file);
      onCropClose(true);
    } catch (error) {
      toast.error(t('image-cropping-error'));
    }
    setIsLoading(false);
  };

  const cropComplete = (croppedArea: Area, newCroppedAreaPixels: Area) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  };

  const deleteHandler = () => {
    setCroppedPhotoURL('');
    setBasePhotoUrl('');
    setIsAvatarDeleted(true);
    setIsDeleteAvatarRequest(false);
  };

  return (
    <div className="image-cropper">
      {isLoading ? (
        <Loader />
      ) : (
        <Tippy
          className="file__tooltip"
          content={
            <>
              <span className="file__bold">{t('choose-one-file')}</span>
              <br />
              {accept}
            </>
          }
          theme="light-border"
          placement="bottom"
        >
          <label className="image-cropper__input" htmlFor="profilePhoto">
            <input
              accept="image/*"
              id="profilePhoto"
              type="file"
              onChange={handleUploadedImage}
            />
            <LazyAvatar
              imageClassName="image-cropper__avatar"
              src={croppedPhotoURL || basePhotoUrl}
              alt="Avatar"
              userInitials={getUserInitials(user)}
            />
            {!basePhotoUrl && <IconPlus className="image-cropper__plus" />}
          </label>
        </Tippy>
      )}
      {basePhotoUrl && (
        <>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => setIsCropOpen(true)}
          >
            {t('crop-image')}
          </Button>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => setIsDeleteAvatarRequest(true)}
          >
            {t('delete-avatar')}
          </Button>
        </>
      )}
      <Modal
        bodyClassName="image-cropper__modal"
        isOpen={isCropOpen}
        onRequestClose={() => {
          onCropClose(false);
        }}
        maxWidth="800"
      >
        <div className="image-cropper__content-wrapper">
          <h2>{t('crop-image')}</h2>

          <Cropper
            cropSize={{ width: CROP_SIZE, height: CROP_SIZE }}
            image={basePhotoUrl}
            crop={crop}
            zoom={zoom}
            aspect={ASPECT_RATIO}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
            onZoomChange={setZoom}
            classes={{
              containerClassName: 'image-cropper__cropper-container',
            }}
          />
          <input
            type="range"
            value={zoom}
            min={MIN_ZOOM}
            max={MAX_ZOOM}
            step={ZOOM_STEP}
            onChange={(e) => {
              setZoom(+e.target.value);
            }}
            className="image-cropper__zoom"
          />
          <div className="image-cropper__button-group">
            <Button
              variant="secondary"
              onClick={() => {
                onCropClose(false);
              }}
            >
              {t('cancel')}
            </Button>
            <Button onClick={cropImage}>{t('confirm')}</Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isDeleteAvatarRequest}
        onRequestClose={() => {
          setIsDeleteAvatarRequest(false);
        }}
        maxWidth="504px"
        className="image-cropper__delete-modal"
      >
        <div className="image-cropper__modal-content">
          <h1 className="image-cropper__delete-heading">
            {t('photo-deleting')}
          </h1>
          <p>{t('sure-to-delete-photo')}</p>
          <div className="image-cropper__buttons-delete">
            <Button variant="primary" onClick={() => deleteHandler()}>
              {t('delete')}
            </Button>
            <Button
              variant="secondary"
              onClick={() => setIsDeleteAvatarRequest(false)}
            >
              {t('cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
