import { useTranslation } from 'react-i18next';
import { Quiz } from 'components/quiz';
import { RenderedComponentProps, TabRouter } from 'components/tab-router';
import { encipherId } from 'helpers/utils';
import { useCourseAsideV2 } from 'hooks/use-course-asideV2';
import { useCourseHeader } from 'hooks/use-course-header';
import { CourseAsideV2 } from 'views/coursesV2/components/course-asideV2';
import { LessonDetailsV2 } from 'views/coursesV2/components/lesson-detailsV2';
import { CourseErrorHandle } from './course-error-handle';
import { CourseIntro } from './course-intro';
import './course-itemV2.scss';

export const CourseItemV2: React.FC<RenderedComponentProps> = () => {
  const { t } = useTranslation();
  const { headerDetails } = useCourseHeader();
  const { courseAside, activeModule, activeContentId, moveToNext } =
    useCourseAsideV2();

  const isCourseNotEmpty = courseAside && activeModule;

  return (
    activeModule && (
      <div className="course-itemV2__wrapper">
        <div className="course-itemV2">
          <h1 className="course-itemV2__header-course-name">
            {headerDetails?.course.name}
          </h1>

          {headerDetails && (
            <TabRouter
              className="course-itemV2__router"
              items={[
                {
                  url: `/courses/${encipherId(headerDetails.id)}`,
                  locationUrl: '/courses/:courseId',
                  label: t('Intro'),
                  component: CourseIntro,
                  hidden: true,
                },
                {
                  url: `/courses/${encipherId(headerDetails.id)}/lesson/`,
                  locationUrl: '/courses/:courseId/lesson/:lessonId',
                  label: t('lesson'),
                  component: () =>
                    isCourseNotEmpty && (
                      <LessonDetailsV2
                        courseId={courseAside.course_id}
                        courseSubscriptionId={courseAside.id}
                        modules={courseAside.modules}
                      />
                    ),
                  exact: false,
                  hidden: true,
                },
                {
                  url: `/courses/${encipherId(
                    headerDetails.id
                  )}/lesson-not-found`,
                  locationUrl: '/courses/:courseId/lesson-not-found',
                  label: t('lesson-not-found'),
                  hidden: true,
                  component: CourseErrorHandle,
                  exact: true,
                },
                {
                  url: `/courses/${encipherId(
                    headerDetails.id
                  )}/:quizType/:quizId`,
                  locationUrl: `/courses/:courseId/:quizType/:quizId`,
                  label: t('quiz'),
                  hidden: true,
                  component: () =>
                    isCourseNotEmpty && (
                      <Quiz
                        modules={courseAside.modules}
                        moveToNextQuiz={moveToNext}
                      />
                    ),
                  exact: true,
                },
              ]}
            />
          )}
        </div>
        <div className="course-itemV2__aside">
          <CourseAsideV2
            courseAside={courseAside}
            activeModule={activeModule}
            activeContentId={activeContentId}
            initialNote={courseAside?.notepad ?? null}
          />
        </div>
      </div>
    )
  );
};
