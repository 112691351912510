import { LargeLayout, SmallLayout } from 'components/person/layouts';
import { Company } from 'types/api';
import { ProfileType } from 'types/user';

interface UserType {
  profile: ProfileType;
  layout?: 'small' | 'large';
  position?: string;
  details?: boolean;
  chat?: boolean;
  size?: number;
  company?: Company;
}

export const UserRole: React.FC<UserType> = ({
  profile,
  layout = 'small',
  details = false,
  chat = false,
  ...props
}) => {
  return (
    <>
      {
        {
          small: (
            <SmallLayout
              {...props}
              {...profile}
              firstName={profile?.first_name}
              lastName={profile?.last_name}
              details={details}
              avatar={profile?.avatar}
            />
          ),
          large: (
            <LargeLayout
              {...props}
              firstName={profile?.first_name}
              lastName={profile?.last_name}
              {...profile}
              details={details}
              chat={chat}
            />
          ),
        }[layout]
      }
    </>
  );
};
