import { t } from 'i18next';
import { Button } from 'components/button';
import { Collapse } from 'components/collapse';
import { MultiSelect } from 'components/multiselect';
import { SelectComponent } from 'components/select';
import { TaskModal } from 'components/task-modal';
import { TaskFormData } from 'components/task-modal/task-form';
import {
  createSelectOptions,
  findSelectedModules,
  makeQueryValue,
} from 'helpers/utils';
import { useMedia } from 'hooks/use-media';
import { useModal } from 'hooks/use-modal';
import { useSelectsData } from 'hooks/use-selects-data';
import { useTasksFilters } from 'hooks/use-tasks-filters';
import { TasksSortOptions } from 'types/enums';
import { TasksFilter } from 'views/participant-task/components/tasks-filter';
import { TasksSort } from 'views/participant-task/components/tasks-sort';
import { ReactComponent as IconPlus } from 'assets/icons/icon-finder-plus-line.svg';
import './tasks-header.scss';

type TasksHeaderProps = {
  isSelectsDataLoading: boolean;
  addTaskSubmit: (data: TaskFormData) => void;
};

export const TasksHeader = ({
  isSelectsDataLoading,
  addTaskSubmit,
}: TasksHeaderProps) => {
  const shouldShowCollapse = useMedia({
    mediaQueries: { '(max-width: 1024px)': true },
    defaultValue: false,
  });

  const {
    onCourseChange,
    onCompanyChange,
    onModuleChange,
    onParticipantChange,
    onAnswerStatusChange,
    onSortByChange,
    courseIds,
    moduleIds,
    companyId,
    participantIds,
    status,
    sortBy,
    sortDirection,
  } = useTasksFilters();

  const { courses, participants, companies } = useSelectsData();

  const [isTaskModalOpen, handleOpenTaskModalClick, handleTaskModalClose] =
    useModal();

  const modulesOptions = courseIds
    ? createSelectOptions(findSelectedModules(courses, courseIds))
    : [];

  const avaliableCompanies = companies?.filter((company) => {
    if (!courseIds) {
      return true;
    }
    return courseIds.some((selectedCourseId) =>
      selectedCourseId ? company.courses.includes(+selectedCourseId) : false
    );
  });

  const coursesOptions = createSelectOptions(courses);

  const companiesOptions = createSelectOptions(avaliableCompanies, {
    id: '',
    name: t('all'),
  });

  const participantsAsEntries = participants?.map(
    ({ id, first_name, last_name }) => ({
      id,
      name: `${first_name} ${last_name}`,
    })
  );

  const participantsOptions = createSelectOptions(participantsAsEntries);

  const headerContent = !isSelectsDataLoading && courses.length > 0 && (
    <div className="tasks-header-content">
      <div className="tasks-header-buttons">
        <TasksFilter
          status={status}
          onAnswerStatusChange={onAnswerStatusChange}
        />
        <TasksSort
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSortByChange={onSortByChange}
        />
        <Button
          className="tasks-header-buttons__add-button"
          variant="icon-text"
          icon={<IconPlus className="tasks-header__button-icon" />}
          onClick={handleOpenTaskModalClick}
        >
          {t('add')}
        </Button>
        <TaskModal
          isOpen={isTaskModalOpen}
          onClose={handleTaskModalClose}
          onSubmit={addTaskSubmit}
          companies={companies}
        />
      </div>
      <div className="tasks-header-selects">
        <MultiSelect
          className="tasks-header-selects__select tasks-header-selects__select--large"
          options={coursesOptions}
          values={courseIds || []}
          onMenuClose={(values) => onCourseChange(makeQueryValue(values))}
          placeholder={t('filter-by-course')}
        />
        <MultiSelect
          className="tasks-header-selects__select tasks-header-selects__select--small"
          options={modulesOptions}
          values={moduleIds || []}
          onMenuClose={(values) => onModuleChange(makeQueryValue(values))}
          placeholder={t('filter-by-module')}
        />
        <SelectComponent
          className="tasks-header-selects__select tasks-header-selects__select--small"
          options={companiesOptions}
          value={companyId || ''}
          onChange={onCompanyChange}
        />
        {sortBy !== TasksSortOptions.ANSWERS_COUNT && (
          <MultiSelect
            className="tasks-header-selects__select tasks-header-selects__select--medium"
            options={participantsOptions}
            values={participantIds || []}
            onMenuClose={(values) =>
              onParticipantChange(makeQueryValue(values))
            }
            placeholder={t('filter-by-participant')}
          />
        )}
      </div>
    </div>
  );

  return (
    <header className="tasks-header">
      <div className="tasks-header-collapse">
        <Collapse
          hiddenContent={headerContent}
          isAutoScroll={false}
          open={!shouldShowCollapse}
          disabled={!shouldShowCollapse}
          arrowClassName="tasks-header-collapse__arrow"
          className="tasks-header-collapse__button"
        >
          <h1>{t('tasks')}</h1>
        </Collapse>
      </div>
    </header>
  );
};
