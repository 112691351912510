import { Route, Switch } from 'react-router-dom';
import { MenuItem } from 'components/menu-item';
import Layout, { LayoutTypes } from 'core/layouts';
import { Roles } from 'types/enums';
import { RouteType } from 'types/routes';
import NotFound from 'views/not-found';

type PrivateRoutesProps = {
  privateRoutes: RouteType[];
  userRole: Roles;
};

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({
  privateRoutes,
  userRole,
}) => {
  const { routesPerRole: routes, menuPerRole: menu } = (() => {
    const routesPerRole = privateRoutes.filter((route) =>
      route.roles?.includes(userRole)
    );

    const menuPerRole = routesPerRole.map((route) =>
      route.menu && !route.hidden ? (
        <MenuItem
          key={route.path}
          icon={<route.menu.icon />}
          to={route.path}
          exact={route.exact}
        >
          {route.menu.name}
        </MenuItem>
      ) : null
    );
    return { routesPerRole, menuPerRole };
  })();

  return (
    <Switch>
      {routes.map(({ path, exact, layout, component: Component }) => (
        <Route key={path} exact={exact} path={path}>
          <Layout menu={menu} layout={layout || LayoutTypes.DEFAULT}>
            <Component />
          </Layout>
        </Route>
      ))}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default PrivateRoutes;
