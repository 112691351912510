import React from 'react';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { Person } from 'components/person';
import { Profile } from 'types/api';
import { ReactComponent as FlagIcon } from 'assets/icons/flag-2-line.svg';
import './task-info.scss';

type TaskInfoProps = {
  isModule?: boolean;
  resourceOrder: number;
  owner?: Profile | null;
  resourceName: string;
  className?: string;
};

export const TaskInfo: React.FC<TaskInfoProps> = ({
  isModule = false,
  resourceOrder,
  owner = null,
  resourceName,
  className,
}) => {
  return (
    <div className={clsx('task-info', className)}>
      <div className="task-info__module">
        <FlagIcon className="task-info__flag" />

        <div className="task-info__icon-text">
          {isModule ? (
            <Trans i18nKey="module-number" values={{ number: resourceOrder }}>
              <span className="task-info__span">module</span>
            </Trans>
          ) : (
            <Trans i18nKey="lesson-number" values={{ number: resourceOrder }}>
              <span className="task-info__span">lesson</span>
            </Trans>
          )}
        </div>
        <h4 className="task-info__resource-name">{resourceName}</h4>
      </div>

      {owner && (
        <Person details key={owner.id} profile={owner} type={owner.role} />
      )}
    </div>
  );
};
