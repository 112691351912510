import { Trans } from 'react-i18next';
import { Person } from 'components/person';
import { StatusIcon } from 'components/status-icon';
import { useGetLessonStatus } from 'hooks/use-get-lesson-status';
import { ModuleDetails } from 'types/api';
import './progressbar-item.scss';

type ProgressbarItemProps = {
  module: ModuleDetails;
  goToLesson: (id: number) => void;
  order: number;
};

export const ProgressbarItem: React.FC<ProgressbarItemProps> = ({
  module,
  goToLesson,
  order,
}) => {
  const getLessonStatus = useGetLessonStatus();

  return (
    <li
      className="progressbar-item"
      style={{ height: module.lessons.length * 20 }}
    >
      <div className="progressbar-item__module-info">
        <Trans i18nKey="modulesCount_one" count={order}>
          <span className="progressbar-item__module">module</span>
        </Trans>
        <Person
          profile={module.coach}
          layout="small"
          size={40}
          details
          type="coach"
        />
        <div className="progressbar-item__status-container">
          <StatusIcon percentage={module.module_progress} />
        </div>
      </div>
      <div className="progressbar-item__progress">
        <div
          className="progressbar-item__progress-fill"
          style={{ height: `${module.module_progress}%` }}
        />
        <div className="progressbar-item__lessons">
          {module.lessons.map((lesson, index, array) => {
            return (
              <button
                key={lesson.id}
                className="progressbar-item__lesson"
                disabled={getLessonStatus(index, array) === 'disabled'}
                onClick={() => goToLesson(lesson.id)}
              >
                <div className="progressbar-item__tooltip">
                  <h3>{lesson.name}</h3>
                  <p className="desc">{lesson.cover_description}</p>
                  {lesson.cover_image && (
                    <img
                      className="cover"
                      src={lesson.cover_image}
                      alt={lesson.name}
                    />
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </div>
      {module.name}
    </li>
  );
};
