import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChatWithPersonButton } from 'components/chat-with-person-button';
import { Modal } from 'components/modal';
import { PeopleList } from 'components/people-list';
import { CoachDetails } from 'components/person/coach-details-modal/coach-details';
import { decipherId } from 'helpers/utils';
import { useCoachDetails } from 'hooks/use-coach-details';
import { useCourseCoaches } from 'hooks/use-course-coaches';
import './coaches-list.scss';

export const CoachesList = () => {
  const { t } = useTranslation();
  const { courseId: courseCipheredId } = useParams<{ courseId: string }>();
  const courseId = decipherId(courseCipheredId);
  const { isLoading, coaches } = useCourseCoaches(courseId);
  const [coachModalDataId, setCoachModalDataId] = useState<number | null>(null);

  const { coachDetails } = useCoachDetails(coachModalDataId);

  const handleCloseModal = () => setCoachModalDataId(null);
  return (
    <>
      <PeopleList
        isLoading={isLoading}
        title={t('coaches-of-modules')}
        subtitle={t('contact-with-coach')}
        handleClick={(id) => setCoachModalDataId(id)}
        button={(props) => (
          <ChatWithPersonButton {...props}>
            {t('chat-with-coach')}
          </ChatWithPersonButton>
        )}
        people={coaches}
      />
      {coachDetails && coachModalDataId && (
        <Modal
          isOpen
          onAfterClose={handleCloseModal}
          onRequestClose={handleCloseModal}
          noPadding
          maxWidth="603px"
          className="coaches-list__modal"
        >
          <CoachDetails
            coachDetails={coachDetails}
            chat
            isCalendar
            additionalChatAction={handleCloseModal}
          />
        </Modal>
      )}
    </>
  );
};
