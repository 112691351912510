import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MINUTES_IN_HOUR } from 'constants/time';
import { getHours, getMinutes, isToday } from 'date-fns';
import isFuture from 'date-fns/isFuture';
import { Card } from 'components/card';
import { EditAndRemoveMenu } from 'components/edit-and-remove-menu';
import { SessionCard } from 'components/session-card';
import { TimeIndicator } from 'components/time-indicator';
import { percentage } from 'helpers/utils';
import { useCoachSessions } from 'hooks/use-coach-sessions';
import { useModal } from 'hooks/use-modal';
import { useNow } from 'hooks/use-now';
import { BookedSession, BookedSessionStatus } from 'types/api';
import { EditMeetingModal } from './edit-meeting-modal';
import { SessionActionModal } from './edit-session-modal';
import { SessionButtons } from './session-buttons';
import './time-tile.scss';

type TimeTileProps = {
  sessions: BookedSession[];
  startHour: number;
  selectedDay: Date;
  isSessionActive: boolean;
  startCounter: number;
  isOtherCoachCalendarView?: boolean;
  isAdmin: boolean;
  isAbleToChangeCalendar: boolean;
};

export const TimeTile: React.FC<TimeTileProps> = ({
  startHour,
  selectedDay,
  sessions,
  startCounter,
  isOtherCoachCalendarView,
  isAbleToChangeCalendar,
}) => {
  const { t } = useTranslation();
  const currentTime = useNow();
  const [isOpen, open, close] = useModal();
  const { rejectCoachSession } = useCoachSessions();

  const isTimeIndicatorVisible =
    isToday(selectedDay) && startHour === getHours(currentTime);

  const handleRemoveSession = async (id: number) => {
    await rejectCoachSession(id);
  };

  const [currentSession, setCurrentSession] = useState<BookedSession | null>(
    null
  );
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  return (
    <div className="time-tile">
      {currentSession && currentSession.participants && (
        <EditMeetingModal
          isOpen={isOpen}
          close={close}
          sessionDetails={currentSession}
        />
      )}
      {currentSession && (
        <SessionActionModal
          sessionDetails={currentSession}
          isOpen={sessionModalOpen}
          setOpen={setSessionModalOpen}
        />
      )}
      {isTimeIndicatorVisible && (
        <TimeIndicator
          verticalPosition={`${percentage(
            getMinutes(currentTime),
            MINUTES_IN_HOUR
          )}%`}
        />
      )}
      <div className="time-tile__tile">
        <time
          dateTime={`${startHour}:00`.padStart(5, '0')}
          className="time-tile__time"
        >
          {`${startHour}:00`.padStart(5, '0')}
        </time>
        {sessions.length ? (
          <>
            {sessions.map((sessionDetails, index) => (
              <SessionCard
                className="time-tile__session-card"
                sessionDetails={sessionDetails}
                key={sessionDetails.id}
                sessionsCount={startCounter + index}
                {...(isFuture(new Date(sessionDetails.start_time)) &&
                  !isOtherCoachCalendarView && {
                    buttons: (
                      <SessionButtons
                        sessionId={sessionDetails.id}
                        status={sessionDetails.status}
                        canConfirm={!!sessionDetails.participants.length}
                      />
                    ),
                    menu: sessionDetails.status !==
                      BookedSessionStatus.REJECTED && (
                      <EditAndRemoveMenu
                        dropdownClassName="time-tile__dropdown"
                        buttonColor="white"
                        onEdit={() => {
                          setCurrentSession(sessionDetails);
                          open();
                        }}
                        onRemove={() => handleRemoveSession(sessionDetails.id)}
                        onChangeCoach={() => {
                          setCurrentSession(sessionDetails);
                          setSessionModalOpen(true);
                        }}
                        canEdit={!!sessionDetails.participants.length}
                        canChangeCoach={isAbleToChangeCalendar}
                      />
                    ),
                  })}
              />
            ))}
          </>
        ) : (
          <Card className="time-tile__no-session">
            <div className="time-tile__text">{t('no-session')}</div>
            <div className="time-tile__type">
              {t('empty-individual-session')}
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};
