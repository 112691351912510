import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { Card } from 'components/card';
import { Loader } from 'components/loader';
import { Person } from 'components/person';
import { StyledScrolls } from 'components/styled-scrolls';
import { ViewTransition } from 'components/view-transition';
import { decipherId } from 'helpers/utils';
import { useCourseParticipants } from 'hooks/use-course-participants';
import { Roles } from 'types/enums';
import { CourseErrorHandle } from './course-error-handle';
import './course-participants.scss';

export const CourseParticipants: React.FC = () => {
  const { isParticipant, user } = useContext(AuthContext);
  const { courseId: courseCipheredId } = useParams<{ courseId: string }>();
  const courseId = decipherId(courseCipheredId);
  const { isLoading, participants } = useCourseParticipants(courseId);
  const { t } = useTranslation();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ViewTransition resolved={!isLoading}>
      <div className="course-participants">
        <Card className="course-participants__card">
          <StyledScrolls className="course-participants__scrolls">
            <div className="course-participants__list">
              {participants?.length ? (
                participants.map((participant) => {
                  const shouldChatBeVisible =
                    (participant.role === Roles.HR &&
                      participant.id !== user?.id) ||
                    (participant.role === Roles.PARTICIPANT && !isParticipant);
                  return (
                    <Person
                      type={participant.role}
                      layout="large"
                      key={participant.id}
                      profile={participant}
                      chat={shouldChatBeVisible}
                      details={participant.role === Roles.HR}
                    />
                  );
                })
              ) : (
                <CourseErrorHandle lackOf={t('lack-of-participants')} />
              )}
            </div>
          </StyledScrolls>
        </Card>
      </div>
    </ViewTransition>
  );
};
