import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, addMonths, startOfMonth } from 'date-fns';
import { Tab } from 'components/tab';
import {
  getMonthlySessionPeriod,
  getTodayAndWeekLater,
} from 'helpers/coaching-session-calendar';
import { today } from 'helpers/time';

export enum CalendarView {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export const useCalendarViews = (selectedDate: Date) => {
  const { t } = useTranslation();
  const [viewType, setViewType] = useState(CalendarView.WEEKLY);
  const [month, setMonth] = useState(selectedDate);

  const calendarStartAndEndDates = useMemo(
    () =>
      viewType === CalendarView.MONTHLY
        ? getMonthlySessionPeriod(month)
        : getTodayAndWeekLater(),
    [month, viewType]
  );

  const ISODates = useMemo(
    () =>
      viewType === CalendarView.MONTHLY
        ? [
            startOfMonth(month).toISOString(),
            startOfMonth(addMonths(month, 1)).toISOString(),
          ]
        : [today().toISOString(), addDays(today(), 6).toISOString()],
    [month, viewType]
  );

  const tabs = [
    {
      content: (
        <Tab
          text={t('weekly-view')}
          isActive={viewType === CalendarView.WEEKLY}
        />
      ),
      value: CalendarView.WEEKLY,
    },
    {
      content: (
        <Tab
          text={t('monthly-view')}
          isActive={viewType === CalendarView.MONTHLY}
        />
      ),
      value: CalendarView.MONTHLY,
    },
  ];

  const changeTab = (value: string) => setViewType(value as CalendarView);

  return {
    month,
    setMonth,
    viewType,
    calendarStartAndEndDates,
    tabs,
    changeTab,
    ISODates,
  };
};
