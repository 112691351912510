import { Question } from 'components/quiz';
import { QuizAnswer, QuizQuestion, QuizQuestionV2 } from 'types/api';
import './quiz-questions.scss';

type QuizQuestionsProps = {
  questions: QuizQuestion[] | QuizQuestionV2[];
  answers?: QuizAnswer[];
};

export const QuizQuestions: React.FC<QuizQuestionsProps> = ({
  questions,
  answers,
}) => {
  const selectedAnswerId = (id: number) =>
    answers
      ?.find((answer) => answer.question_id === id)
      ?.answer_option_id.toString();

  const isAnswerCorrect = (id: number) =>
    answers?.find((answer) => answer.question_id === id)?.correct;

  return (
    <ol className="quiz-questions">
      {questions.map((question, index) => (
        <li key={question.id}>
          <Question
            question={{
              id: question.id,
              body: 'question' in question ? question.question : question.body,
              answer_options:
                'answers' in question
                  ? question.answers
                  : question.answer_options,
            }}
            orderNumber={
              'display_order' in question ? question.display_order : index + 1
            }
            {...(answers && {
              value: selectedAnswerId(question.id),
              correct: isAnswerCorrect(question.id),
            })}
            disabled
          />
        </li>
      ))}
    </ol>
  );
};
