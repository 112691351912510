import { useState } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TFunctionResult } from 'i18next';
import { Button } from 'components/button';
import { ReactComponent as SearchLine } from 'assets/icons/search-line.svg';
import './input.scss';

type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange'
> & {
  inputClassName?: string;
  label?: string | boolean | TFunctionResult;
  errorLabel?: string;
  visiblePassword?: boolean;
  register?: any;
  errors?: DeepMap<any, FieldError>;
  onChange?: (value: string) => void;
  togglerClassName?: string;
  errorClassName?: string;
};

export const Input = ({
  type = 'text',
  label = false,
  name = '',
  placeholder = '',
  visiblePassword = true,
  className,
  inputClassName,
  register,
  errors = {},
  errorLabel,
  disabled = false,
  value,
  onChange,
  togglerClassName,
  errorClassName,
  ...props
}: InputProps) => {
  const [changeType, setChangeType] = useState(false);
  const { t } = useTranslation();
  const errorMessage = name && errors[name] ? errors[name].message : null;
  const errorLabelWithFallback =
    errorMessage || errorLabel || `${t('field-required')}`;
  const toggleType = () => {
    setChangeType(!changeType);
  };

  const innerType = () => {
    if (changeType && type === 'password') {
      return 'text';
    }
    return type;
  };

  const computedProps = onChange
    ? {
        value,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value as string);
        },
      }
    : { defaultValue: value };

  return (
    <div className={clsx('form-input', className)}>
      <label className="form-input__label" htmlFor={`form-${name}`}>
        <span className="form-input__label-text">{label}</span>
        {type === 'password' && visiblePassword && (
          <Button
            className={togglerClassName}
            type="button"
            variant="secondary"
            onClick={toggleType}
          >
            {changeType ? t('hide') : t('show')}
          </Button>
        )}
      </label>
      <div className={clsx({ 'form-input__search': type === 'search' })}>
        <input
          className={clsx(
            'form-input__input',
            type === 'search' && 'form-input__input--search',
            name &&
              errors?.[name] &&
              errorLabelWithFallback &&
              'form-input__input--error',
            inputClassName
          )}
          {...computedProps}
          name={name}
          type={innerType()}
          placeholder={placeholder}
          ref={register}
          disabled={disabled}
          id={`form-${name}`}
          {...props}
        />
        {type === 'search' && <SearchLine className="form-input__icon" />}
      </div>
      {name && errors?.[name] && errorLabelWithFallback && (
        <span className={clsx('form-input__error', errorClassName)}>
          {errorLabelWithFallback}
        </span>
      )}
    </div>
  );
};
