import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Select } from 'components/form';
import { Checkbox } from 'components/form/checkbox';
import { createPickerValue, getHours } from 'helpers/coaching-session-calendar';
import { BookedSession, CoachBusinessHours } from 'types/api';
import './singular-day.scss';

type SingularDayProps = {
  day: CoachBusinessHours;
  isOtherCoachCalendarView: boolean;
  handleInput: (dayDetails: CoachBusinessHours) => void;
  futureSessions?: BookedSession[];
};

const START_HOUR = 2;
const END_HOUR = 23;
const WORKING_HOURS = [...Array(22)].map((_, index) => index + START_HOUR);

export const SingularDay: React.FC<SingularDayProps> = ({
  day,
  handleInput,
  isOtherCoachCalendarView,
  futureSessions,
}) => {
  const { t } = useTranslation();
  const [dayActive, setDayActive] = useState(day.is_active);
  const startTime = getHours(new Date(day.start_time));
  const endTime = getHours(new Date(day.end_time));

  const [selectedStart, setSelectedStart] = useState(startTime);
  const [selectedEnd, setSelectedEnd] = useState(endTime);

  const getHoursFromNumber = (hourNumber: number) => {
    return `${String(hourNumber).padStart(2, '0')}:00`;
  };

  const startHours = WORKING_HOURS.map((hour) => getHoursFromNumber(hour));
  const endHours = WORKING_HOURS.map((hour) => getHoursFromNumber(hour));

  const sessionInRange = useCallback(() => {
    return futureSessions?.some((session) => {
      const sessionStart = new Date(session.start_time).getHours();

      if (
        sessionStart > parseInt(endTime, 10) - 1 ||
        sessionStart < parseInt(startTime, 10)
      ) {
        return true;
      }
      return false;
    });
  }, [endTime, futureSessions, startTime]);

  const errorMessage = useMemo(() => {
    if (!isOtherCoachCalendarView && startTime >= endTime) {
      if (parseInt(selectedEnd, 10) < END_HOUR) {
        return t('validate-error-change-end-hour');
      }
      return t('validate-error-change-start-hour');
    }
    if (!isOtherCoachCalendarView && dayActive && sessionInRange()) {
      if (parseInt(selectedEnd, 10) < END_HOUR) {
        return t('validate-error-change-working-hours');
      }
    }
    if (!isOtherCoachCalendarView && sessionInRange()) {
      return t('validate-error-session-in-range');
    }
    return '';
  }, [
    dayActive,
    endTime,
    isOtherCoachCalendarView,
    selectedEnd,
    sessionInRange,
    startTime,
    t,
  ]);

  const getDataForTimeChange = (value: string, startTimeChanged: boolean) => {
    const buisnessHoursStart = startTimeChanged
      ? parseInt(value, 10)
      : parseInt(selectedStart, 10);
    const buisnessHoursEnd = startTimeChanged
      ? parseInt(selectedEnd, 10)
      : parseInt(value, 10);
    return {
      buisnessHoursStart,
      buisnessHoursEnd,
      changeSecoundTime: buisnessHoursStart >= buisnessHoursEnd,
    };
  };

  const updateHours = ({
    start_time,
    end_time,
    is_active,
  }: {
    start_time: string;
    end_time: string;
    is_active: boolean;
  }) => {
    handleInput({
      day_name: day.day_name,
      start_time,
      end_time,
      day_id: day.day_id,
      is_active,
    });
  };

  const handleStartTimeChange = (value: string) => {
    const { buisnessHoursStart, changeSecoundTime } = getDataForTimeChange(
      value,
      true
    );

    setSelectedStart(value);
    if (changeSecoundTime) {
      setSelectedEnd(getHoursFromNumber(buisnessHoursStart + 1));
    }

    const endHour = changeSecoundTime
      ? createPickerValue(
          getHoursFromNumber(
            buisnessHoursStart < END_HOUR
              ? buisnessHoursStart + 1
              : buisnessHoursStart
          ),
          day.end_time
        )
      : day.end_time;

    updateHours({
      start_time: createPickerValue(value, day.start_time),
      end_time: endHour,
      is_active: dayActive,
    });
  };

  const handleEndTimeChange = (value: string) => {
    const { buisnessHoursEnd, changeSecoundTime } = getDataForTimeChange(
      value,
      false
    );

    setSelectedEnd(value);
    if (changeSecoundTime) {
      setSelectedStart(getHoursFromNumber(buisnessHoursEnd - 1));
    }

    const startHour = changeSecoundTime
      ? createPickerValue(
          getHoursFromNumber(
            buisnessHoursEnd > START_HOUR
              ? buisnessHoursEnd - 1
              : buisnessHoursEnd
          ),
          day.start_time
        )
      : day.start_time;

    updateHours({
      start_time: startHour,
      end_time: createPickerValue(value, day.end_time),
      is_active: dayActive,
    });
  };

  return (
    <div
      className={clsx('singular-day', errorMessage && 'singular-day--error')}
    >
      <div className="singular-day__checkbox">
        <Checkbox
          className="singular-day__checkbox-input"
          checked={dayActive}
          disabled={isOtherCoachCalendarView}
          onChange={(checked) => {
            setDayActive(checked);
            updateHours({
              start_time: day.start_time,
              end_time: day.end_time,
              is_active: checked,
            });
          }}
          name="isActive"
          type="checkbox"
        />
      </div>
      <div className="singular-day__day">
        <div className="singular-day__day--name">
          {new Date(day.start_time).toLocaleString('pl', { weekday: 'long' })}
        </div>
        <div className="singular-day__day--error">{errorMessage}</div>
      </div>
      <div className="singular-day__time-pickers">
        <Select
          value={startTime || undefined}
          options={[
            ...startHours.map((hour) => ({
              value: hour,
              label: hour,
            })),
          ]}
          onChange={(value) => {
            handleStartTimeChange(value);
          }}
          disabled={!dayActive || isOtherCoachCalendarView}
        />

        <Select
          value={endTime || undefined}
          options={[
            ...endHours.map((hour) => ({
              value: hour,
              label: hour,
            })),
          ]}
          onChange={(value) => {
            handleEndTimeChange(value);
          }}
          disabled={!dayActive || isOtherCoachCalendarView}
        />
      </div>
    </div>
  );
};
