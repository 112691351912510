import { Area } from 'react-easy-crop/types';
import { CROP_SIZE, IMAGE_QUALITY } from 'constants/image-cropper';

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = CROP_SIZE;
  canvas.height = CROP_SIZE;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise<{ file: Blob; url: string }>((resolve, reject) => {
    canvas.toBlob(
      (file) => {
        if (!file) {
          return reject(new Error('File is null'));
        }
        return resolve({
          file,
          url: URL.createObjectURL(file),
        });
      },
      'image/jpeg',
      IMAGE_QUALITY
    );
  });
}
