import clsx from 'clsx';
import './tab.scss';

type TabProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'role'
> & { active: boolean };

export const Tab: React.FC<TabProps> = ({
  children,
  active,
  ...otherProps
}) => (
  <button
    role="tab"
    {...otherProps}
    className={clsx('tab', active && 'tab--active')}
  >
    {children}
  </button>
);
