import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { encipherId } from 'helpers/utils';
import { ModuleDetails } from 'types/api';
import { ModulesList } from './modules-list';
import './course-progressV2.scss';

type CourseProgressProps = {
  modules: ModuleDetails[];
  activeContentId?: number | string;
  courseId: number;
  activeModule: ModuleDetails | null;
};

export const CourseProgressV2: React.FC<CourseProgressProps> = ({
  activeModule,
  modules,
  activeContentId,
  courseId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToLesson = (id: number) => {
    const isQuizCompleted = activeModule?.lessons.some((lesson) => {
      return lesson.type === 'Quiz' && lesson.id === id && lesson.completed;
    });

    const lessonSlug = modules
      .flatMap(({ lessons }) => lessons)
      .find((lesson) => lesson.id === id)?.slug;

    if (isQuizCompleted) {
      toast.info(t('quiz-solved'));
    } else {
      history.push(`/courses/${encipherId(courseId)}/lesson/${lessonSlug}`);
    }
  };

  if (!modules.length) {
    return null;
  }

  return (
    <div className="course-progressV2">
      <ModulesList
        modules={modules}
        goToLesson={goToLesson}
        activeContentId={activeContentId ?? -1}
      />
    </div>
  );
};
