import { Radio } from 'components/radio';
import { QuizQuestionV2 } from 'types/api';
import './question.scss';

type QuestionProps = {
  question: Omit<QuizQuestionV2, 'answer_options'> & {
    answer_options:
      | { id: number; label: string }[]
      | {
          id: number;
          body: string;
        }[];
  };
  orderNumber?: number;
  value?: string;
  onChange?: (value: string) => void;
  correct?: boolean;
  disabled?: boolean;
};

export const Question: React.FC<QuestionProps> = ({
  question,
  orderNumber,
  value,
  onChange,
  correct = true,
  disabled = false,
}) => (
  <fieldset className="question">
    <legend className="question__legend">
      {orderNumber && <span>{orderNumber}.</span>} {question.body}
    </legend>

    <Radio
      correct={correct}
      name={`quiz-${question.id}`}
      options={question.answer_options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  </fieldset>
);
