import { Redirect, Route, Switch } from 'react-router-dom';
import { StyledScrolls } from 'components/styled-scrolls';
import { useProfileRoutes } from 'hooks/use-profile-routes';
import { ProfileSidebar } from './components';
import './profile.scss';

const Profile: React.FC = () => {
  const { routesPerRole } = useProfileRoutes();

  return (
    <StyledScrolls className="module-profile">
      <ProfileSidebar routes={routesPerRole} keyPrefix="index" withCloseIcon />
      <div className="module-profile__content">
        <Switch>
          {routesPerRole.map((valueRoute) => {
            return (
              <Route
                key={valueRoute.path}
                exact
                path={valueRoute.path}
                render={(props: any) => (
                  <valueRoute.component name={valueRoute.name} {...props} />
                )}
              />
            );
          })}
          <Redirect to="/profile" />
        </Switch>
      </div>
    </StyledScrolls>
  );
};

export default Profile;
