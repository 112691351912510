import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import axios from 'axios';
import clsx from 'clsx';
import { QueryKeys } from 'constants/query-keys';
import { AuthContext } from 'contexts';
import { sendTaskAnswer, updateAnswer } from 'services/tasks';
import { v4 as uuid } from 'uuid';
import { AnswerNote } from 'components/answer-note';
import { AttachmentItem } from 'components/attachment-item';
import { AudioPlayer } from 'components/audio-player';
import { Button } from 'components/button';
import { FileInput, Textarea, TypeView } from 'components/form';
import { Person } from 'components/person';
import { RichTextWrapper } from 'components/rich-text-wrapper';
import { StyledScrolls } from 'components/styled-scrolls';
import { TaskSentModal } from 'components/task-sent-modal';
import { VoiceRecorder } from 'components/voice-recorder';
import { format, today } from 'helpers/time';
import {
  getFileExtension,
  getPathDestination,
  validateFilesExtensions,
} from 'helpers/utils';
import { AnswerAttachment, ModuleDetails, Task } from 'types/api';
import { AnswerStatus, Roles } from 'types/enums';
import { ReactComponent as ExclamationPoint } from 'assets/icons/exclamation-point.svg';
import { ReactComponent as Microphone } from 'assets/icons/microphone.svg';
import './tasks-list-item.scss';

type TaskAnswer = {
  id: number;
  answer: string;
  note: string;
  answer_date: string;
  status: string;
  collateral?: AnswerAttachment;
};

type TasksListItemProps = Omit<
  Task,
  | 'completed'
  | 'course'
  | 'participants'
  | 'resource'
  | 'resource_number'
  | 'owners'
  | 'answers'
  | 'additional'
> & {
  isFinished?: boolean;
  hideModal: () => void;
  onSave?: () => void;
  taskAnswer: TaskAnswer | null;
  onSend?: () => void;
  currentModule?: ModuleDetails | undefined;
};

type AnswerFormType = {
  asset: File[];
  answer: string;
};

export const TASK_FILE_EXTENSIONS =
  '.mp4, .avi, .mpeg, .mov, .mp3, .wav, .m4a, .pdf, .doc, .docx, .xls, .xlsx, .txt, .webm, .jpg, .jpeg, .png';

enum WordFileTypes {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
}

const getMappedFile = (file: File) => {
  const extension = getFileExtension(file.name);

  if (extension === 'docx') {
    return new File([file], file.name, {
      type: WordFileTypes.DOCX,
    });
  }

  if (extension === 'doc') {
    return new File([file], file.name, {
      type: WordFileTypes.DOC,
    });
  }

  return null;
};

export const TasksListItem: React.FC<TasksListItemProps> = ({
  isFinished = false,
  description,
  instruction,
  id,
  name,
  owner,
  module_lesson,
  hideModal,
  onSave = () => {},
  onSend = () => {},
  taskAnswer,
  currentModule,
}) => {
  const { user } = useContext(AuthContext);
  const isAnswerRejected =
    taskAnswer && taskAnswer.status === AnswerStatus.FAILED;
  const isAnswerToVerify =
    taskAnswer && taskAnswer.status === AnswerStatus.TO_VERIFY;
  const [audioUrl, setAudioUrl] = useState<string>();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    watch,
    unregister,
  } = useForm({
    shouldFocusError: false,
  });
  const audioAsset = watch('asset');
  const shouldShowPlayer = audioAsset && audioUrl;

  const isBrowserSupported = !!window.MediaRecorder;

  const initialAnswerContent = taskAnswer?.answer;

  const { isCoach, isHR } = useContext(AuthContext);

  const location = useLocation();
  const history = useHistory();

  const lesson =
    currentModule?.lessons.find(
      (moduleLesson) => moduleLesson.id === module_lesson?.id
    ) ?? null;

  const [taskSent, setTaskSent] = useState(false);

  const queryClient = useQueryClient();

  const save = async (data: AnswerFormType) => {
    if (!!data.asset[0] && data.asset[0].size <= 0) {
      toast.error(t('asset-size-too-small'));
      return;
    }

    const formData = new FormData();

    if (data.asset.length) {
      const file = data.asset[0];
      if (file.type === '') {
        const mappedFile = getMappedFile(file);
        if (mappedFile) {
          formData.append(
            'task_answer[collateral_attributes][attachment]',
            mappedFile
          );
        }
      } else {
        formData.append('task_answer[collateral_attributes][attachment]', file);
      }
      formData.append(
        'task_answer[collateral_attributes][description]',
        data.answer
      );
      formData.append('task_answer[collateral_attributes][name]', file.name);
    } else {
      formData.append('task_answer[answer]', data.answer);
    }

    try {
      if (isAnswerRejected) {
        if (data.asset.length) {
          formData.append('task_answer[answer]', '');
        } else {
          formData.append('task_answer[collateral_attributes]', '');
        }
        await updateAnswer(taskAnswer.id, formData);
      } else {
        await sendTaskAnswer(id, formData);
      }
      queryClient.invalidateQueries(QueryKeys.TASKS);
      onSave();
      setTaskSent(true);
    } catch {
      toast.error(t('answer-error'));
    }
  };

  const isAnswerEmpty = control.getValues('answer') !== '';

  const downloadAttachments = useCallback(async () => {
    const files: File[] = [];

    if (taskAnswer?.collateral?.url) {
      const { data } = await axios.get<Blob>(taskAnswer.collateral?.url, {
        responseType: 'blob',
      });
      const file: File & { url?: string } = new File(
        [data],
        taskAnswer.collateral.name,
        {
          type: data.type,
        }
      );
      file.url = taskAnswer.collateral?.url;
      files.push(file);
    }

    setValue('asset', files);
  }, [taskAnswer, setValue]);

  const handleRecordingStop = (_: string, blob: Blob) => {
    const userInfo =
      user &&
      `${user.first_name}_${user.last_name}_${user.company_name}_${format(
        today(),
        'onlyDate'
      )}_${uuid()}`;
    const file = new File([blob], `${userInfo}.mp3`, {
      type: 'audio/mpeg',
    });
    setValue('asset', [file]);
    setAudioUrl(URL.createObjectURL(file));
  };

  useEffect(() => {
    if (isAnswerRejected && !taskAnswer) {
      downloadAttachments();
    }
  }, [downloadAttachments, isAnswerRejected, taskAnswer]);

  useEffect(() => {
    if (audioAsset && !audioAsset.length) {
      setAudioUrl(undefined);
    }
  }, [audioAsset]);

  if (taskSent) {
    return <TaskSentModal />;
  }

  const sendAnswer = () => {
    const { answer, asset } = getValues();

    if (lesson) {
      lesson.task_status = AnswerStatus.TO_VERIFY;
    }

    onSend();
    unregister('asset');

    if (answer && asset && answer.length === 0 && asset.length === 0) {
      toast.error(t('failed-to-send-answer'));
    }
  };

  return (
    <div className="participant-task-modal">
      <StyledScrolls
        className={clsx(
          'participant-task-left',
          (isCoach || isHR) && 'participant-task-left--short'
        )}
      >
        <div className="participant-task-left__head">
          <div className="participant-task-left__header">
            <p className="participant-task-left__type">{t('task')}</p>

            {taskAnswer?.answer_date && (
              <div
                className={clsx(
                  'participant-task-left__status',
                  isAnswerRejected && 'participant-task-left__status--rejected'
                )}
              >
                {isAnswerRejected ? t('rejected') : t('task-accepted')}
                <time>{format(taskAnswer.answer_date, 'dateWithTime')}</time>
              </div>
            )}
          </div>
          {owner && (
            <Person
              key={owner.id}
              profile={owner}
              layout="small"
              size={40}
              details={owner.role === Roles.COACH}
              type={owner.role}
            />
          )}
        </div>
        <div className="participant-task-left__name">
          {getPathDestination(name)}
        </div>
        <RichTextWrapper>{description || ''}</RichTextWrapper>

        <div className="participant-task-left__instruction-label">
          {t('instruction')}:
        </div>

        <RichTextWrapper>{instruction}</RichTextWrapper>
      </StyledScrolls>
      {!(isCoach || isHR) && (
        <StyledScrolls className="participant-task-right">
          {taskAnswer?.note && (
            <AnswerNote owner={owner} answerNote={taskAnswer.note} />
          )}
          {(taskAnswer && isFinished) || isAnswerToVerify ? (
            <div className="participant-task-right__answer">
              <div className="participant-task-right__answer-heading">
                {t('your-answer')}
              </div>
              {taskAnswer.collateral ? (
                <>
                  <AttachmentItem
                    name={taskAnswer.collateral.name}
                    url={taskAnswer.collateral.url}
                  />
                  <div className="participant-task-right__answer-heading">
                    {t('short-desc')}
                  </div>
                  <div>{taskAnswer.collateral.description}</div>
                </>
              ) : (
                <div>{taskAnswer.answer}</div>
              )}
            </div>
          ) : (
            <>
              {isAnswerRejected && (
                <div className="participant-task-right__rejected">
                  <ExclamationPoint />
                  <div>{t('task-rejected')}</div>
                </div>
              )}
              <form
                className="participant-task-right__form"
                onSubmit={handleSubmit(save)}
              >
                <Textarea
                  className="participant-task-right__answer-input"
                  label={t('your-answer')}
                  register={register({
                    validate: {
                      isRequired: (value) =>
                        !!value || control.getValues('asset').length > 0,
                    },
                  })}
                  name="answer"
                  value={initialAnswerContent}
                />

                {isBrowserSupported ? (
                  <VoiceRecorder
                    className="participant-task-right__recorder"
                    maxLengthInSeconds={120}
                    onStop={handleRecordingStop}
                  />
                ) : (
                  <Tippy
                    className="participant-task-right__recorder-unavailable-tooltip"
                    content={<span>{t('microphone-unavailable')}</span>}
                    theme="light-border"
                  >
                    <span className="participant-task-right__recorder-unavailable">
                      <Microphone />
                    </span>
                  </Tippy>
                )}

                <div className="participant-task-right__button-wrapper">
                  <Controller
                    render={({ onChange, value }) => (
                      <FileInput
                        errors={formState.errors}
                        accept={TASK_FILE_EXTENSIONS}
                        typeView={TypeView.PLUS_ICON}
                        name="asset"
                        maxCount={1}
                        onDrop={onChange}
                        preview={false}
                        value={value || []}
                      />
                    )}
                    control={control}
                    name="asset"
                    defaultValue=""
                    rules={{
                      validate: {
                        isSet: (fileList: FileList) =>
                          fileList.length > 0 || isAnswerEmpty || ' ',
                        areExtensionsAllowed: (fileList: FileList) =>
                          validateFilesExtensions(
                            fileList,
                            TASK_FILE_EXTENSIONS
                          ) || (t('wrong-file-extension') as string),
                      },
                    }}
                  />
                  {shouldShowPlayer && (
                    <AudioPlayer
                      className="participant-task-right__audio-player"
                      src={audioUrl}
                    />
                  )}
                </div>
                {!(isCoach || isHR) && (
                  <div className="participant-task-right__button-group">
                    <Button
                      disabled={formState.isSubmitting}
                      className="submit-button"
                      type="submit"
                      onClick={sendAnswer}
                    >
                      {t('send-task')}
                    </Button>

                    <Button
                      onClick={() => {
                        hideModal();
                        if (location?.search) {
                          history.push(location.pathname);
                        }
                      }}
                      variant="secondary"
                    >
                      {t('discard')}
                    </Button>
                  </div>
                )}
                {formState.errors.answer && (
                  <div className="participant-task-right__error-message">
                    {t('upload-file-or-fill-textarea')}
                  </div>
                )}
              </form>
            </>
          )}
        </StyledScrolls>
      )}
    </div>
  );
};
