import { DayModifiers, DayPicker } from 'react-day-picker';
import clsx from 'clsx';
import { isEqual, isPast, isSameDay } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { t } from 'i18next';
import { Card } from 'components/card';
import {
  ChosenTimeslot,
  SelectedSlot,
} from 'components/coaching-session-modal/select-date-step';
import {
  calculateMeetingsCount,
  getActiveTimeslots,
  getAvailableTimeSlots,
  getCoachMeetingsCount,
  getSelectedMeetingsDay,
  isCoachAvailable,
} from 'helpers/coaching-session-calendar';
import { today } from 'helpers/time';
import { StateSetter } from 'helpers/types';
import { createDaysMatcher } from 'helpers/utils';
import { Availability, SortedTimeslot } from 'types/api';
import { CoachSessions } from 'views/calendar';
import { CalendarCaption } from './calendar-caption';
import { DayCell } from './day-cell';
import { TimePicker } from './time-picker';
import 'react-day-picker/dist/style.css';
import './monthly-calendar.scss';

type MonthlyCalendarProps = {
  availabilities?: Availability[];
  coachSessions?: CoachSessions;
  selectedMonth: Date;
  setSelectedMonth: StateSetter<Date>;
  selectedSlot: SelectedSlot;
  maxDate?: Date;
  minDate?: Date;
  setSelectedSlot: StateSetter<SelectedSlot>;
  setErrorMessage?: (message: string) => void;
  className?: string;
  coachTimeSlots?: SortedTimeslot[];
  setChosenTimeslot?: StateSetter<ChosenTimeslot>;
  chosenTimeSlot?: ChosenTimeslot;
};

export const MonthlyCalendar: React.FC<MonthlyCalendarProps> = ({
  availabilities,
  selectedMonth,
  setSelectedMonth,
  selectedSlot,
  setSelectedSlot,
  coachSessions,
  setErrorMessage,
  className,
  maxDate,
  minDate,
  coachTimeSlots,
  chosenTimeSlot,
  setChosenTimeslot,
}) => {
  const isCoachAvailableInMonth = isCoachAvailable(availabilities);

  const handleDayClick = (
    day: Date,
    { outside, selected, disabled }: DayModifiers
  ) => {
    if (disabled || selected) {
      return;
    }
    if (outside) {
      setSelectedMonth(day);
    }
    if (!setChosenTimeslot) {
      setSelectedSlot({
        date: day,
        id: -1,
      });
    } else {
      setChosenTimeslot({ startTime: day.toISOString(), endTime: '-1' });
    }
    if (setErrorMessage) {
      setErrorMessage('');
    }
  };

  const getCounterValue = (date: Date) => {
    const isValidDate =
      !isPast(date) ||
      isEqual(new Date(today().toDateString()), new Date(date.toDateString()));

    if (availabilities && isValidDate) {
      return calculateMeetingsCount(date, availabilities);
    }
    const currentMonthActive =
      new Date(today().toISOString()).getMonth() === selectedMonth.getMonth();

    if (
      currentMonthActive
        ? date.getDate() >= new Date(today().toISOString()).getDate()
        : date >= new Date(today().toISOString())
    ) {
      return getCoachMeetingsCount(date, coachSessions);
    }
    return 0;
  };

  const getTimeslotsCount = (date: Date) => {
    const isValidDate = !isPast(date) || isSameDay(new Date(), new Date(date));

    if (coachTimeSlots && isValidDate) {
      const meetingsInDay = getSelectedMeetingsDay(
        date,
        coachTimeSlots
      )?.timeSlots;
      const meetingsWithoutPastDates = getActiveTimeslots(
        meetingsInDay || []
      )?.length;

      return meetingsWithoutPastDates;
    }

    return 0;
  };

  const disabledDays = createDaysMatcher(minDate, maxDate);

  return (
    <Card className={clsx('monthly-calendar', className)}>
      {!coachTimeSlots && (
        <DayPicker
          disabled={disabledDays}
          components={{
            Caption: (captionProps) => (
              <CalendarCaption
                {...captionProps}
                maxDate={maxDate}
                minDate={minDate}
                onMonthChange={setSelectedMonth}
              />
            ),
            DayContent: ({ date, ...otherDayContentProps }) => (
              <DayCell
                {...otherDayContentProps}
                date={date}
                meetingsCount={getCounterValue(date)}
              />
            ),
          }}
          locale={pl}
          showOutsideDays
          selected={selectedSlot.date}
          month={selectedMonth}
          onMonthChange={setSelectedMonth}
          onDayClick={handleDayClick}
        />
      )}
      {isCoachAvailableInMonth && availabilities ? (
        <TimePicker
          options={getAvailableTimeSlots(selectedSlot.date, availabilities)}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
        />
      ) : (
        availabilities && (
          <div className="monthly-calendar-unavailable-info">
            <h3>{t('coach-unavailable-in-month')}</h3>
          </div>
        )
      )}
      {coachTimeSlots && chosenTimeSlot && (
        <>
          <DayPicker
            disabled={disabledDays}
            components={{
              Caption: (captionProps) => (
                <CalendarCaption
                  {...captionProps}
                  maxDate={maxDate}
                  minDate={minDate}
                  onMonthChange={setSelectedMonth}
                />
              ),
              DayContent: ({ date, ...otherDayContentProps }) => (
                <DayCell
                  {...otherDayContentProps}
                  date={date}
                  meetingsCount={getTimeslotsCount(date)}
                />
              ),
            }}
            locale={pl}
            showOutsideDays
            selected={new Date(chosenTimeSlot.startTime)}
            month={selectedMonth}
            onMonthChange={setSelectedMonth}
            onDayClick={handleDayClick}
          />
          <TimePicker
            availableTimeslots={
              getSelectedMeetingsDay(
                new Date(chosenTimeSlot.startTime),
                coachTimeSlots
              )?.timeSlots
            }
            chosenTimeSlot={chosenTimeSlot}
            setChosenTimeslot={setChosenTimeslot}
          />
        </>
      )}
    </Card>
  );
};
