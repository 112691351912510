import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Button } from 'components/button';
import { DropdownButton } from 'components/dropdown-button';
import { DropdownMenuItem } from 'components/dropdown-menu-item';
import { Input } from 'components/form';
import { Loader } from 'components/loader';
import { getFullName } from 'helpers/chat';
import { compareStrings } from 'helpers/utils';
import { useMedia } from 'hooks/use-media';
import { useParticipantsProgress } from 'hooks/use-participants-progress';
import { ParticipantProgress } from 'types/api';
import { Roles } from 'types/enums';
import { ReactComponent as IconPlus } from 'assets/icons/icon-finder-plus-line.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { ReactComponent as IconFilter } from 'assets/icons/sort-table.svg';
import { AddParticipants } from './add-participants';
import { ParticipantCollapse } from './participant-collapse';
import { ParticipantTableRow } from './participant-table-row';
import './participants-table.scss';

export const ParticipantsTable: React.FC = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [modal, setModal] = useState(false);
  const [isOrderASC, setIsOrderASC] = useState(-1);
  const { companyId } = useParams<{ companyId?: string }>();
  const { participantsProgress, updateParticipantsProgress, isLoading } =
    useParticipantsProgress(companyId);
  const [sortedParticipants, setSortedParticipants] = useState<
    ParticipantProgress[]
  >([]);
  const { t } = useTranslation();
  const shouldShowCollapse = useMedia({
    mediaQueries: { '(max-width: 768px)': true },
    defaultValue: false,
  });

  const { userRole } = useContext(AuthContext);

  useEffect(() => {
    if (participantsProgress.length) {
      setSortedParticipants(
        participantsProgress.sort((a, b) => {
          const valueA: string = a.first_name?.toUpperCase();
          const valueB: string = b.first_name?.toUpperCase();

          return compareStrings(valueA, valueB);
        })
      );
    }
  }, [participantsProgress]);

  const sortParticipants = (
    getValue: (participant: ParticipantProgress) => string
  ) => {
    setIsOrderASC((prevState) => -prevState);
    setSortedParticipants((prevState) => {
      const sortedData = [...prevState].sort((a, b) => {
        const valueA: string = getValue(a)?.toUpperCase();
        const valueB: string = getValue(b)?.toUpperCase();

        if (!a.confirmed) {
          return 1;
        }
        if (!b.confirmed) {
          return -1;
        }

        return compareStrings(valueA, valueB, isOrderASC);
      });
      return sortedData;
    });
  };

  const sortParticipantByProgress = () => {
    setIsOrderASC((prevState) => -prevState);
    setSortedParticipants((prevState) => {
      const sortedPraticipantProgress = [...prevState].map((participant) => {
        const courses = [...participant.courses].sort((a, b) => {
          const valueA = +a.percentage_progress;
          const valueB = +b.percentage_progress;

          return valueA < valueB ? isOrderASC : -isOrderASC;
        });
        return { ...participant, courses };
      });

      const sortedData = sortedPraticipantProgress.sort((a, b) => {
        if (!a.courses.length) {
          return isOrderASC;
        }
        if (!b.courses.length) {
          return -isOrderASC;
        }
        const valueA = a.courses[0].percentage_progress;
        const valueB = b.courses[0].percentage_progress;

        if (!a.confirmed) {
          return 1;
        }
        if (!b.confirmed) {
          return -1;
        }

        return valueA < valueB ? isOrderASC : -isOrderASC;
      });

      return sortedData;
    });
  };

  const sortCourses = () => {
    setIsOrderASC((prevState) => prevState * -1);
    setSortedParticipants((prevState) => {
      const sortedCourses = [...prevState].map((participant) => {
        const courses = [...participant.courses].sort((a, b) => {
          const valueA = a.name;
          const valueB = b.name;

          return compareStrings(valueA, valueB) * isOrderASC;
        });
        return { ...participant, courses };
      });
      return sortedCourses;
    });
  };

  const filteredParticipants = useMemo(() => {
    return sortedParticipants.filter((participant) => {
      const fullName = getFullName(
        participant.first_name,
        participant.last_name
      );
      return fullName.toUpperCase().includes(searchPhrase.toUpperCase().trim());
    });
  }, [searchPhrase, sortedParticipants]);

  const dropdownMenuItems = [
    {
      id: 0,
      onClick: () => sortParticipants((participant) => participant.position),
      children: t('position'),
    },
    {
      id: 1,
      onClick: () => sortParticipants((participant) => participant.team),
      children: t('team'),
    },
    {
      id: 2,
      onClick: sortCourses,
      children: t('course'),
    },
    {
      id: 3,
      onClick: sortParticipantByProgress,
      children: t('progress'),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="participants">
      <header className="participants__header">
        <Input
          className="participants__search"
          type="search"
          value={searchPhrase}
          placeholder={t('find-participant')}
          onChange={(value) => {
            setSearchPhrase(value);
          }}
        />
        <div className="participants__buttons-container">
          {shouldShowCollapse && (
            <div className="participants__filter-button">
              <DropdownButton
                menuClassName="participants__filter-menu"
                buttonContent={
                  <>
                    <SortIcon /> {t('sort')}
                  </>
                }
              >
                <DropdownMenuItem>{t('participant')}</DropdownMenuItem>
                {dropdownMenuItems.map((item) => {
                  return (
                    <DropdownMenuItem
                      key={item.id}
                      className="participants__filter-menu-item"
                      onClick={item.onClick}
                    >
                      {item.children}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownButton>
            </div>
          )}
          {userRole === Roles.HR && (
            <Button
              className="participants__add-button"
              variant="icon-text"
              icon={<IconPlus />}
              onClick={() => setModal(true)}
            >
              {t('add')}
            </Button>
          )}
        </div>
      </header>
      {shouldShowCollapse ? (
        <p className="participants__collapse">
          {filteredParticipants.map((participant) => (
            <ParticipantCollapse
              key={participant.id}
              participant={participant}
            />
          ))}
        </p>
      ) : (
        <table className="participants__table">
          <thead className="participants__thead">
            <tr>
              <td className="participants__td-first">
                <div className="participants__filter">
                  {t('participant')}
                  <Button
                    variant="icon"
                    icon={<IconFilter />}
                    onClick={() =>
                      sortParticipants((participant) => participant.first_name)
                    }
                  />
                </div>
              </td>
              <th>
                <div className="participants__filter">
                  {t('position')}
                  <Button
                    className="participants__filter-button"
                    variant="icon"
                    icon={<IconFilter />}
                    onClick={() =>
                      sortParticipants(
                        (participant) => participant.position ?? ''
                      )
                    }
                  />
                </div>
              </th>
              <th>
                <div className="participants__filter">
                  {t('team')}
                  <Button
                    className="participants__filter-button"
                    variant="icon"
                    icon={<IconFilter />}
                    onClick={() =>
                      sortParticipants((participant) => participant.team ?? '')
                    }
                  />
                </div>
              </th>
              <th>
                <div className={clsx('participants__filter')}>
                  {t('course')}
                  <Button
                    className="participants__filter-button"
                    variant="icon"
                    icon={<IconFilter />}
                    onClick={sortCourses}
                  />
                </div>
              </th>
              <td className="participants__td-last">
                <div className={clsx('participants__filter')}>
                  {t('progress')}
                  <Button
                    className="participants__filter-button"
                    variant="icon"
                    icon={<IconFilter />}
                    onClick={sortParticipantByProgress}
                  />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredParticipants.map((participant) => (
              <ParticipantTableRow
                key={participant.id}
                participant={participant}
              />
            ))}
          </tbody>
        </table>
      )}

      {!filteredParticipants.length && (
        <p className="participants__no-participants">
          {t('lack-participants')}
        </p>
      )}
      <AddParticipants
        updateParticipantsProgress={updateParticipantsProgress}
        modal={modal}
        setModal={setModal}
      />
    </div>
  );
};
