import { Avatar } from 'components/people-list/avatar';

type LazyAvatarProps = {
  imageClassName?: string;
  src: string | null;
  alt?: string;
  userInitials?: string | null;
};

export const LazyAvatar: React.FC<LazyAvatarProps> = ({
  imageClassName,
  src,
  alt,
  userInitials,
}) => {
  return (
    <Avatar
      userInitials={userInitials}
      avatar={src}
      className={imageClassName}
      alt={alt}
    />
  );
};
