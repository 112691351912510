import { useState } from 'react';
import clsx from 'clsx';
import { t } from 'i18next';
import { ReactComponent as ExclamationPoint } from 'assets/icons/exclamation-point.svg';
import './avatar.scss';

type AvatarProps = {
  avatar: string | null;
  alt?: string;
  className?: string;
  small?: boolean;
  userInitials?: string | null;
};

export const Avatar: React.FC<AvatarProps> = ({
  avatar,
  alt,
  className,
  small = false,
  userInitials,
}) => {
  const [isError, setIsError] = useState(false);
  return avatar && !isError ? (
    <img
      className={clsx('avatar', className, small && 'avatar__small')}
      src={avatar}
      onError={() => setIsError(true)}
      alt={alt ?? t('your-avatar')}
      loading="lazy"
    />
  ) : (
    <div
      className={clsx(
        'avatar',
        'avatar--placeholder',
        className,
        small && 'avatar__small'
      )}
    >
      {userInitials || <ExclamationPoint />}
    </div>
  );
};
