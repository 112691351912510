import { useCallback, useState } from 'react';
import { HrDetailsModal } from 'components/person//hr-details-modal';
import { LargeLayout, SmallLayout } from 'components/person/layouts';
import { ProfileType } from 'types/user';

interface HRType {
  profile: ProfileType;
  layout?: 'small' | 'large';
  position?: string;
  details?: boolean;
  chat?: boolean;
  size?: number;
}

export const HRRole: React.FC<HRType> = ({
  profile,
  layout = 'small',
  details = false,
  chat = false,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const showDetails = async () => {
    if (!details || !profile) {
      return;
    }

    setModalOpen(true);
  };

  const handleClose = useCallback(() => setModalOpen(false), []);

  return (
    <>
      {modalOpen && <HrDetailsModal onClose={handleClose} profile={profile} />}
      {
        {
          small: (
            <SmallLayout
              {...props}
              firstName={profile?.first_name}
              lastName={profile?.last_name}
              details={details}
              showDetails={showDetails}
              avatar={profile?.avatar}
            />
          ),
          large: (
            <LargeLayout
              {...props}
              firstName={profile?.first_name}
              lastName={profile?.last_name}
              {...profile}
              details={details}
              showDetails={showDetails}
              chat={chat}
            />
          ),
        }[layout]
      }
    </>
  );
};
