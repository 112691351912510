import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader';
import { StyledScrolls } from 'components/styled-scrolls';
import { format } from 'helpers/time';
import { Report } from 'types/api';

type ReportsListProps = {
  reports: Report[];
  isLoading: boolean;
};

export const ReportsList: React.FC<ReportsListProps> = ({
  reports,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="report__files-header">
        <div>{t('generated-reports')}</div>
        <div>
          {t('queued', {
            count: reports.filter(({ status }) => status === 'queued').length,
          })}
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <StyledScrolls className="report__scrollable-content">
          <div className="report__files" data-testid="report-files">
            {reports
              .filter(({ status }) => status !== 'queued')
              .map((report) => (
                <a
                  key={report.id}
                  className="report__file"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href={report.url}
                  title={report.csv_filename ?? ''}
                >
                  <div className="report__file-name">{report.csv_filename}</div>
                  <div>
                    <div
                      className={`report__status report__status--${report.status}`}
                    >
                      {t(`${report.status}`)}
                    </div>
                    <div className="report__date">
                      {(report.completed_at && format(report.completed_at)) ||
                        t('now')}
                    </div>
                  </div>
                </a>
              ))}
          </div>
        </StyledScrolls>
      )}
    </div>
  );
};
