import { useCallback, useEffect, useMemo, useRef } from 'react';
import EventEmitter from 'events';
import { areRecordsDifferent } from 'helpers/utils';

enum Events {
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
}

type UseFormPersistArgs<T extends object, K extends keyof T> = {
  name: string;
  defaultValues?: T;
  values: T;
  setValue: (name: K, value: T[K], config?: Object) => void;
  shouldSetValues?: boolean;
  onPreviousSessionIsDirty: () => void;
};

export const useFormPersist = <T extends object, K extends keyof T>({
  name,
  defaultValues,
  values,
  setValue,
  onPreviousSessionIsDirty,
}: UseFormPersistArgs<T, K>) => {
  const persistedValueRef = useRef(localStorage.getItem(name));
  const emitter = useMemo(() => new EventEmitter(), []);

  const restorePersistedForm = useCallback(() => {
    const localStorageValues =
      persistedValueRef.current && JSON.parse(persistedValueRef.current);

    Object.keys(localStorageValues).forEach((key) => {
      setValue(key as K, localStorageValues[key], {
        shouldDirty: false,
      });
    });
  }, [setValue]);

  const isPreviousSessionDirty = useMemo(
    () =>
      !!defaultValues &&
      !!localStorage.getItem(name) &&
      areRecordsDifferent(
        defaultValues,
        JSON.parse(localStorage.getItem(name) || '')
      ),

    [defaultValues, name]
  );

  const persistForm = useCallback(() => {
    localStorage.setItem(name, JSON.stringify(values));
  }, [name, values]);

  useEffect(() => {
    persistForm();
  }, [persistForm]);

  useEffect(() => {
    if (isPreviousSessionDirty) {
      onPreviousSessionIsDirty();
    }
  }, [isPreviousSessionDirty, onPreviousSessionIsDirty]);

  useEffect(() => {
    emitter.once(Events.CONFIRM, () => {
      restorePersistedForm();
    });

    return () => {
      emitter.removeAllListeners();
    };
  }, [emitter, setValue, restorePersistedForm]);

  return {
    confirm: () => emitter.emit(Events.CONFIRM),
  };
};
