import { useContext } from 'react';
import { AuthContext } from 'contexts';
import { ProgressItemStatus } from 'components/progress-item-button';
import { CompletedAttemptsCount, Lesson } from 'types/api';

export const useGetLessonStatus = () => {
  const { isParticipant } = useContext(AuthContext);

  return (
    lessonIndex: number,
    courseLessons: Lesson[],
    completedQuizAttempts?: number
  ) => {
    const shouldDisableLesson =
      completedQuizAttempts === CompletedAttemptsCount.NO_ATTEMPTS &&
      isParticipant;

    if (shouldDisableLesson) {
      return ProgressItemStatus.DISABLED;
    }

    const isFirst = lessonIndex === 0;
    const isCompleted = courseLessons[lessonIndex].completed;
    const isPreviousCompleted = courseLessons[lessonIndex - 1]?.completed;
    const isInProgress =
      !isParticipant || (!isCompleted && (isFirst || isPreviousCompleted));

    if (isInProgress) {
      return ProgressItemStatus.IN_PROGRESS;
    }
    if (isCompleted) {
      return ProgressItemStatus.COMPLETED;
    }
    return ProgressItemStatus.DISABLED;
  };
};
