import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { AuthContext } from 'contexts';
import { fetchSubscriptionCourses } from 'services/courses';
import { createCourseSelectOptions } from 'helpers/utils';
import { ReportSelectsData, SubscriptionSelectOptions } from 'types/api';

export const useSelectOptions = ({
  onSuccess,
}: {
  onSuccess?: (
    options: {
      value: string;
      label: string;
    }[]
  ) => void;
} = {}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const {
    data: subscriptions,
    isSuccess,
    ...rest
  } = useQuery(
    [QueryKeys.SUBSCRIPTION_COURSES],
    () => fetchSubscriptionCourses({ select: true }),
    {
      onError: () => {
        toast.error(t('courses-error'));
      },
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(
            createCourseSelectOptions(
              data as (SubscriptionSelectOptions | ReportSelectsData)[]
            )
          );
        }
      },
      enabled: !!user?.company_id,
    }
  );

  const selectOptions = isSuccess
    ? createCourseSelectOptions(
        subscriptions as (SubscriptionSelectOptions | ReportSelectsData)[]
      )
    : [];

  return { selectOptions, isSuccess, ...rest };
};
