export const loginSelectors = {
  emailInputClass: 'cypress-sign-in-email-input',
  passwordInputClass: 'cypress-sign-in-password-input',
  submitClass: 'cypress-sign-in-submit',
  passwordTogglerClass: 'cypress-sign-in-toggler',
  errorClass: 'cypress-sign-in-error',
  emailErrorClass: 'cypress-sign-in-email-error',
  passwordErrorClass: 'cypress-sign-in-password-error',
  forgetPasswordPinkClass: 'cypress-sign-in-reset-password-link',
};
