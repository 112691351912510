import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import {
  IMediaRecorder,
  MediaRecorder as ExtendableMediaRecorder,
} from 'extendable-media-recorder';

export type MediaRecorderRenderProps = {
  startRecording: () => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => void;
  deleteRecording: () => void;
  status: StatusMessages;
  clearBlobUrl: () => void;
};

export type MediaRecorderHookProps = {
  onStop?: (blobUrl: string, blob: Blob) => void;
};
export type MediaRecorderProps = MediaRecorderHookProps & {
  render: (props: MediaRecorderRenderProps) => ReactElement;
};

export type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped'
  | 'paused';

export enum RecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
}

export function useMediaRecorder({
  onStop = () => null,
}: MediaRecorderHookProps): MediaRecorderRenderProps {
  const mediaRecorder = useRef<IMediaRecorder | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | undefined>(
    undefined
  );
  const mountedRef = useRef(true);

  const stopRecording = async () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping');
        mediaRecorder.current.stop();
        if (mediaStream.current) {
          mediaStream.current.getTracks().forEach((track) => track.stop());
        }
        mediaChunks.current = [];
      }
    }
  };
  const deleteRecording = useCallback(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.onstop = () => {
        setStatus('stopped');
      };
      stopRecording();
    }
  }, []);

  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');
    const requiredMedia: MediaStreamConstraints = {
      audio: true,
    };
    try {
      const stream = await window.navigator.mediaDevices.getUserMedia(
        requiredMedia
      );
      if (mountedRef.current) {
        mediaStream.current = stream;
        setStatus('idle');
      } else {
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (err: any) {
      setStatus('idle');
    }
  }, []);

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new Error('Unsupported Browser');
    }
    return () => {
      if (mediaStream.current) {
        const tracks = mediaStream.current.getTracks();
        tracks.forEach((track) => track.clone().stop());
        deleteRecording();
      }
      mountedRef.current = false;
    };
  }, [deleteRecording, getMediaStream]);

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    const blobProperty: BlobPropertyBag = {
      type: 'audio/wav',
    };
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    setStatus('stopped');
    setMediaBlobUrl(url);
    onStop(url, blob);
  };

  const startRecording = async () => {
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some((track) => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }

      if (!mediaStream.current.active) {
        return;
      }

      mediaRecorder.current = new ExtendableMediaRecorder(mediaStream.current);
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onerror = () => {
        setStatus('idle');
      };
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setStatus('paused');
      mediaRecorder.current.pause();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      setStatus('recording');
      mediaRecorder.current.resume();
    }
  };

  return {
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    deleteRecording,
    status,
    clearBlobUrl: () => {
      if (mediaBlobUrl) {
        URL.revokeObjectURL(mediaBlobUrl);
      }
      setMediaBlobUrl(undefined);
      setStatus('idle');
    },
  };
}

export const MediaRecorder = (props: MediaRecorderProps) =>
  props.render(useMediaRecorder(props));
