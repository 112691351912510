import clsx from 'clsx';
import './tab-switch.scss';

type TabSwitchProps = {
  className?: string;
  buttonClassName?: string;
  activeButtonClassName?: string;
  tabs: {
    content: React.ReactNode;
    value: string;
  }[];
  value: string;
  onChange: (value: string) => void;
};

export const TabSwitch: React.FC<TabSwitchProps> = ({
  className,
  buttonClassName,
  activeButtonClassName,
  tabs,
  value,
  onChange,
}) => (
  <div className={clsx('tab-switch', className)}>
    {tabs.map((item) => (
      <button
        type="button"
        key={item.value}
        className={clsx(
          'tab-switch__button',
          value === item.value && activeButtonClassName,
          buttonClassName
        )}
        onClick={() => onChange(item.value)}
      >
        {item.content}
      </button>
    ))}
  </div>
);
