import { useEffect } from 'react';
import clsx from 'clsx';
import './dropdown-menu.scss';

type DropdownMenuProps = {
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
  listClassName?: string;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  onClose,
  className,
  listClassName,
}) => {
  useEffect(() => {
    document.addEventListener('click', onClose);
    return () => {
      document.removeEventListener('click', onClose);
    };
  }, [onClose]);

  return (
    <div className={clsx('dropdown-menu', className)}>
      <ul className={clsx('dropdown-menu__list', listClassName)}>{children}</ul>
    </div>
  );
};
