import { ReactNode } from 'react';
import clsx from 'clsx';
import { Loader } from 'components/loader';
import './view-wrapper.scss';

type ViewWrapperProps = {
  header?: ReactNode;
  loading?: boolean;
  children?: ReactNode;
  className?: string;
};

export const ViewWrapper: React.FC<ViewWrapperProps> = ({
  children,
  loading,
  header,
  className,
}: ViewWrapperProps) => {
  if (loading) {
    return (
      <div className="loader-wrapper">
        <Loader />
      </div>
    );
  }

  return (
    <div className={clsx('view-wrapper', className)}>
      {header}
      {children}
    </div>
  );
};
