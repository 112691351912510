import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from 'components/button';
import { QuizQuestions } from 'components/quiz';
import { ButtonsWrapper } from 'components/quiz/participant-quiz/buttons-wrapper';
import { InfoText } from 'components/quiz/participant-quiz/info-text';
import { LinearIndicator } from 'components/quiz/participant-quiz/linear-indicator';
import { QuizAttempt } from 'types/api';
import { QuizAttemptType } from 'types/enums';
import './result.scss';

type ResultProps = {
  quizAttempt: QuizAttempt;
  onRetakeClick: React.MouseEventHandler<HTMLButtonElement>;
  onFinish?: () => void;
};

export const Result: React.FC<ResultProps> = ({
  onRetakeClick,
  onFinish,
  quizAttempt,
}) => {
  const { t } = useTranslation();
  const [showAnswers, setShowAnswers] = useState(false);

  const resultPercentageText = t('result-percentage', {
    resultPercentage: quizAttempt.score,
  });

  const scoresText = t('scores', {
    scoredPoints: quizAttempt.correct_answers_count,
    totalPoints: quizAttempt.total_answers_count,
  });

  const improvedScoresText =
    (quizAttempt.percentage_growth &&
      (quizAttempt.percentage_growth > 0
        ? t('scores-improved', {
            count: quizAttempt.percentage_growth,
          })
        : t('scores-worse', {
            count: -quizAttempt.percentage_growth,
          }))) ||
    '';

  const congrats = quizAttempt.score >= 50 ? t('congrats') : '';

  const reverseButtonsOrder =
    quizAttempt.attempt_type === QuizAttemptType.POST_QUIZ && !showAnswers;

  const showRetakeQuizButton =
    quizAttempt.attempt_type === QuizAttemptType.POST_QUIZ;

  const showResultsButton =
    !showAnswers && !(quizAttempt.attempt_type === QuizAttemptType.PRE_QUIZ);

  const isOutlinedButton =
    quizAttempt.attempt_type === QuizAttemptType.POST_QUIZ && !showAnswers;

  const isPrimaryButton =
    showAnswers || quizAttempt.attempt_type === QuizAttemptType.PRE_QUIZ;

  const finishButtonVariant =
    (isOutlinedButton && 'outlined') ||
    (isPrimaryButton && 'primary') ||
    'secondary';

  return (
    <div className="result">
      <InfoText size={showAnswers ? 'small' : 'medium'}>
        {showAnswers ? (
          <>
            {scoresText} {resultPercentageText} {improvedScoresText || congrats}{' '}
            {quizAttempt.attempt_type === QuizAttemptType.POST_QUIZ &&
              t('retake-quiz-possibility')}
          </>
        ) : (
          t('results')
        )}
      </InfoText>

      <LinearIndicator
        value={quizAttempt.correct_answers_count}
        outOf={quizAttempt.total_answers_count}
      />

      <InfoText
        size={showAnswers ? 'medium' : 'large'}
        color={showAnswers ? 'gray' : 'black'}
      >
        {showAnswers ? (
          <>{scoresText}</>
        ) : (
          <>
            {scoresText} {resultPercentageText} {improvedScoresText || congrats}
          </>
        )}
      </InfoText>

      {showAnswers && quizAttempt.questions && (
        <QuizQuestions
          questions={quizAttempt.questions}
          answers={quizAttempt.answers}
        />
      )}

      <ButtonsWrapper
        className={clsx(
          'result__wrapper',
          reverseButtonsOrder && 'result__wrapper--reverse'
        )}
      >
        {showRetakeQuizButton && (
          <Button variant="secondary" onClick={onRetakeClick}>
            {t('retake-quiz')}
          </Button>
        )}

        {!!onFinish && (
          <Button
            className={clsx(
              'result__button',
              isOutlinedButton && 'result__button--outlined',
              isPrimaryButton && 'result__button--primary'
            )}
            variant={finishButtonVariant}
            onClick={onFinish}
          >
            {t('finish')}
          </Button>
        )}

        {showResultsButton && (
          <Button
            className="result__button"
            onClick={() => setShowAnswers(true)}
          >
            {t('show-result')}
          </Button>
        )}
      </ButtonsWrapper>
    </div>
  );
};
