import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoachesList } from 'components/coaches-list';
import { Loader } from 'components/loader';
import { Notepad } from 'components/notepad';
import { TabbedInterface } from 'components/tabbed-interface';
import {
  CourseAside as CourseAsideType,
  ModuleDetails,
  Notepad as NotepadType,
} from 'types/api';
import { CourseProgressV2 } from './course-progressV2';

type CourseAsideProps = {
  courseAside: CourseAsideType | null;
  activeModule: ModuleDetails;
  activeContentId: number | string;
  initialNote: NotepadType | null;
};

export const CourseAsideV2: React.FC<CourseAsideProps> = ({
  courseAside,
  activeModule,
  activeContentId,
  initialNote,
}) => {
  const [note, setNote] = useState<NotepadType | null>(initialNote);

  const { t } = useTranslation();

  if (!courseAside) {
    return <Loader />;
  }

  return (
    <TabbedInterface
      items={[
        {
          id: 1,
          label: t('lessons'),
          component: () => (
            <CourseProgressV2
              modules={courseAside.modules}
              courseId={courseAside.id}
              activeModule={activeModule}
              activeContentId={activeContentId}
            />
          ),
        },
        {
          id: 2,
          label: t('coaches'),
          component: () => <CoachesList />,
        },
        {
          id: 3,
          label: t('notes'),
          component: () => (
            <Notepad
              setNote={setNote}
              note={note}
              courseId={courseAside.course_id}
            />
          ),
        },
      ]}
    />
  );
};
