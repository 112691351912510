import { Roles } from 'types/enums';

export const isCoachRole = (roles: Roles[]) => {
  const rolesAsCoach = [Roles.COACH, Roles.SUPER_COACH, Roles.ADMIN];
  return roles.some((role) => rolesAsCoach.includes(role));
};

export const isHrRole = (roles: Roles[]) => {
  const rolesAsHr = [Roles.HR];
  return roles.some((role) => rolesAsHr.includes(role));
};
