import './status-tile.scss';

type TileType = { color: string; status: string };

export const StatusTile: React.FC<TileType> = ({ color, status }) => {
  return (
    <div className="status-tile">
      <div className={`status-tile__square status-tile__square--${color}`} />
      <div className="status-tile__text">{status}</div>
    </div>
  );
};
