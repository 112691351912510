import clsx from 'clsx';
import './rich-text-wrapper.scss';

type RichTextWrapperProps = {
  children: string;
  className?: string;
};

export const RichTextWrapper: React.FC<RichTextWrapperProps> = ({
  children,
  className,
}) => (
  <div
    className={clsx('ql-editor', 'rich-text-wrapper', className)}
    dangerouslySetInnerHTML={{ __html: children }}
  />
);
