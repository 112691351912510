import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseAside } from 'components/course-aside';
import { LessonDetails } from 'components/lesson-details';
import { Loader } from 'components/loader';
import { Quiz } from 'components/quiz';
import { RenderedComponentProps, TabRouter } from 'components/tab-router';
import { encipherId } from 'helpers/utils';
import { useCourseAside } from 'hooks/use-course-aside';
import { useCourseHeader } from 'hooks/use-course-header';
import { CourseHeader } from 'views/courses/components/course-header';
import { CourseCoaches } from './course-coaches';
import { CourseErrorHandle } from './course-error-handle';
import { CourseItemContent } from './course-item-content';
import { CourseParticipants } from './course-participants';

export const CourseItem: React.FC<RenderedComponentProps> = ({
  setHeaderRenderer,
}) => {
  const { t } = useTranslation();
  const { headerDetails, isSuccess } = useCourseHeader();
  const {
    courseAside,
    activeModule,
    activeContentId,
    moveToNext,
    setActiveModule,
  } = useCourseAside();

  useEffect(() => {
    setHeaderRenderer(() => <CourseHeader headerDetails={headerDetails} />);

    return () => {
      setHeaderRenderer(null);
    };
  }, [headerDetails, setHeaderRenderer]);

  const isCourseNotEmpty = courseAside && activeModule;

  if (!courseAside) {
    return <Loader />;
  }

  return (
    <CourseItemContent
      isResponseSuccess={isSuccess}
      errorMessage={<CourseErrorHandle courseUnavailable />}
    >
      {headerDetails && (
        <TabRouter
          items={[
            {
              url: `/courses/${encipherId(headerDetails.id)}/lesson/`,
              locationUrl: '/courses/:courseId/lesson/:lessonId',
              label: t('lesson'),
              component: () =>
                isCourseNotEmpty && (
                  <LessonDetails
                    courseSubscriptionId={courseAside.id}
                    modules={courseAside.modules}
                    moveToNext={moveToNext}
                  />
                ),
              exact: false,
            },
            {
              url: `/courses/${encipherId(headerDetails.id)}/coaches`,
              locationUrl: '/courses/:courseId/coaches',
              label: t('coaches'),
              component: CourseCoaches,
            },
            {
              url: `/courses/${encipherId(headerDetails.id)}/participants`,
              locationUrl: '/courses/:courseId/participants',
              label: t('participants'),
              component: CourseParticipants,
            },
            {
              url: `/courses/${encipherId(headerDetails.id)}/lesson-not-found`,
              locationUrl: '/courses/:courseId/lesson-not-found',
              label: t('lesson-not-found'),
              hidden: true,
              component: CourseErrorHandle,
              exact: true,
            },
            {
              url: `/courses/${encipherId(headerDetails.id)}/:quizType/:quizId`,
              locationUrl: `/courses/:courseId/:quizType/:quizId`,
              label: t('quiz'),
              hidden: true,
              component: () =>
                isCourseNotEmpty && (
                  <Quiz
                    modules={courseAside.modules}
                    moveToNextQuiz={moveToNext}
                  />
                ),
              exact: true,
            },
          ]}
          aside={
            <CourseAside
              courseAside={courseAside}
              activeModule={activeModule}
              initialNote={courseAside?.notepad ?? null}
              activeContentId={activeContentId}
              setActiveModule={setActiveModule}
            />
          }
        />
      )}
    </CourseItemContent>
  );
};
