import { useLocation } from 'react-router-dom';
import { TabRouter } from 'components/tab-router';
import { ViewWrapper } from 'components/view-wrapper';
import { useCompanies } from 'hooks/use-companies';
import { useCoursesNavItems } from 'hooks/use-courses-nav-items';
import { CompaniesSelect } from './components/companies-select';
import './coach-course.scss';

const LOCATIONS_TO_DISPLAY_COMPANIES_SELECT = [
  '/courses',
  '/courses/completed',
];

const CoachCourses: React.FC = () => {
  const coursesNavItems = useCoursesNavItems();
  const { pathname: currentLocation } = useLocation();
  const displaySelect =
    LOCATIONS_TO_DISPLAY_COMPANIES_SELECT.includes(currentLocation);

  const { companies, isLoading } = useCompanies();

  return (
    <ViewWrapper loading={isLoading}>
      <div className="coach-courses">
        {displaySelect && (
          <div className="coach-courses__select">
            <CompaniesSelect companies={companies} />
          </div>
        )}

        <TabRouter items={coursesNavItems} />
      </div>
    </ViewWrapper>
  );
};

export default CoachCourses;
