import { useContext } from 'react';
import { AuthContext } from 'contexts';
import { Modal } from 'components/modal';
import { useCoachDetails } from 'hooks/use-coach-details';
import { ProfileType } from 'types/user';
import { CoachDetails } from './coach-details';

type CoachDetailsModalProps = {
  profile: ProfileType;
  isCalendar?: boolean;
  onClose: () => void;
};

export const CoachDetailsModal: React.FC<CoachDetailsModalProps> = ({
  onClose,
  profile,
}) => {
  const { isParticipant, user } = useContext(AuthContext);

  const { coachDetails } = useCoachDetails(profile.id, {
    onError: onClose,
  });
  return (
    <Modal
      isOpen={!!coachDetails}
      onRequestClose={onClose}
      noPadding
      maxWidth="503px"
    >
      {!!coachDetails && (
        <CoachDetails
          coachDetails={coachDetails}
          chat={coachDetails?.coach.id !== user?.id}
          isCalendar={isParticipant}
          additionalChatAction={onClose}
        />
      )}
    </Modal>
  );
};
