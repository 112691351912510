import { useCallback, useState } from 'react';
import { CoachDetailsModal } from 'components/person/coach-details-modal';
import { LargeLayout, SmallLayout } from 'components/person/layouts';
import { ProfileType } from 'types/user';

export interface CoachType {
  profile: ProfileType;
  layout?: 'small' | 'large';
  details?: boolean;
  chat?: boolean;
  isCalendar?: boolean;
  isAvatar?: boolean;
  size?: number;
  showName?: boolean;
  position?: string;
}

export type CoachProps = {
  showDetails?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  showCalendar?: () => void;
} & CoachType;

export const CoachRole: React.FC<CoachProps> = ({
  profile,
  layout = 'small',
  details = true,
  isCalendar,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const showDetails = async () => {
    if (!details || !profile) {
      return;
    }

    setModalOpen(true);
  };

  const { first_name: firstName, last_name: lastName, ...rest } = profile;

  const handleClose = useCallback(() => setModalOpen(false), []);

  return (
    <>
      {modalOpen && (
        <CoachDetailsModal
          onClose={handleClose}
          profile={profile}
          isCalendar={isCalendar}
        />
      )}
      {
        {
          small: (
            <SmallLayout
              firstName={firstName}
              lastName={lastName}
              avatar={rest.avatar}
              details={details}
              showDetails={showDetails}
              {...props}
            />
          ),
          large: (
            <LargeLayout
              firstName={firstName}
              lastName={lastName}
              details={details}
              showDetails={showDetails}
              hasCalendar={isCalendar}
              {...props}
              {...rest}
            />
          ),
        }[layout]
      }
    </>
  );
};
