import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMedia } from './use-media';

const ALWAYS_SCROLL_QUERY = '(max-width: 0px)';

type UseScrollToElementParams = {
  noScrollQuery?: string;
  isScrollDestination?: boolean;
  delayInMilliseconds?: number;
  behavior?: ScrollBehavior;
  scrollBlock?: ScrollLogicalPosition;
};

export const useScrollToElement = <T extends HTMLElement>({
  noScrollQuery = ALWAYS_SCROLL_QUERY,
  isScrollDestination = true,
  delayInMilliseconds = 1_000,
  behavior = 'smooth',
  scrollBlock = 'start',
}: UseScrollToElementParams = {}) => {
  const elementRef = useRef<T>(null);

  const mediaQueries = useMemo(
    () => ({
      [noScrollQuery]: false,
    }),
    [noScrollQuery]
  );

  const shouldScroll = useMedia({
    mediaQueries,
    defaultValue: true,
  });

  const scrollToElement = useCallback(() => {
    if (elementRef.current && isScrollDestination && shouldScroll) {
      elementRef.current.scrollIntoView({ behavior, block: scrollBlock });
    }
  }, [isScrollDestination, shouldScroll, behavior, scrollBlock]);

  useEffect(() => {
    const timeoutId = setTimeout(scrollToElement, delayInMilliseconds);

    return () => clearTimeout(timeoutId);
  }, [scrollToElement, delayInMilliseconds]);

  return elementRef;
};
