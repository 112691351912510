import React from 'react';

export type PropsOf<
  C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>
> = JSX.LibraryManagedAttributes<C, React.ComponentPropsWithoutRef<C>>;

type AsProp<C extends React.ElementType> = {
  as?: C;
};

export type ExtendableProps<
  ExtendedProps = {},
  OverrideProps = {}
> = OverrideProps & Omit<ExtendedProps, keyof OverrideProps>;

export type InheritableElementProps<
  C extends React.ElementType,
  Props = {}
> = ExtendableProps<PropsOf<C>, Props>;

export type PolymorphicComponentProps<
  C extends React.ElementType,
  Props = {}
> = InheritableElementProps<C, Props & AsProp<C>>;

export type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>['ref'];

export type PolymorphicComponentPropsWithRef<
  C extends React.ElementType,
  Props = {}
> = PolymorphicComponentProps<C, Props> & { ref?: PolymorphicRef<C> };

interface Props {
  children: React.ReactNode;
}

export type PolymorphicButtonProps<C extends React.ElementType> =
  PolymorphicComponentPropsWithRef<C, Props>;

export const PolymorphicButton = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    { as, children, ...other }: PolymorphicButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'button';

    return (
      <Component {...other} ref={ref}>
        {children}
      </Component>
    );
  }
);

PolymorphicButton.displayName = 'PolymorphicButton';
