import { CaptionLabel, CaptionProps, useNavigation } from 'react-day-picker';
import { StateSetter } from 'helpers/types';
import { ReactComponent as CalendarArrow } from 'assets/icons/calendar-arrow-right.svg';
import './calendar-caption.scss';

type CalendarCaptionProps = CaptionProps & {
  onMonthChange: StateSetter<Date>;
  minDate?: Date;
  maxDate?: Date;
};

export const CalendarCaption: React.FC<CalendarCaptionProps> = ({
  id,
  displayMonth,
  onMonthChange,
  maxDate,
  minDate,
}) => {
  const { previousMonth, nextMonth, currentMonth } = useNavigation();

  const isPreviousDisable =
    previousMonth && minDate ? minDate > currentMonth : false;
  const isNextDisable = nextMonth && maxDate ? maxDate <= nextMonth : false;

  const goToPreviousMonth = () => {
    if (previousMonth) {
      onMonthChange(previousMonth);
    }
  };

  const goToNextMonth = () => {
    if (nextMonth) {
      onMonthChange(nextMonth);
    }
  };

  return (
    <header className="calendar-caption">
      <button
        className="calendar-caption__button calendar-caption__button--left"
        disabled={!previousMonth || isPreviousDisable}
        onClick={goToPreviousMonth}
      >
        <CalendarArrow />
      </button>

      <CaptionLabel id={id} displayMonth={displayMonth} />

      <button
        className="calendar-caption__button"
        disabled={!nextMonth || isNextDisable}
        onClick={goToNextMonth}
      >
        <CalendarArrow />
      </button>
    </header>
  );
};
