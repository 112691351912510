import clsx from 'clsx';
import './dropdown-menu-item.scss';

export type DropdownMenuItemProps = {
  className?: string;
  wrapperClassName?: string;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'className'
>;

export const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  className,
  wrapperClassName,
  ...buttonProps
}) => (
  <li className={clsx('dropdown-menu-item', wrapperClassName)}>
    <button
      className={clsx('dropdown-menu-item__button', className)}
      {...buttonProps}
    />
  </li>
);
