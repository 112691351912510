import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { QueryKeys } from 'constants/query-keys';
import { getCompanyDetails } from 'services/subscriptions';

export const useCompanyDetails = (companyId: number | null) => {
  const { t } = useTranslation();
  const { data, ...rest } = useQuery(
    QueryKeys.COMPANY_DETAILS,
    () => getCompanyDetails(),
    { onError: () => toast.error(t('course-error')) }
  );

  const companyDetailsByCompany = useMemo(() => {
    return companyId
      ? data?.filter((subscription) => subscription.company.id === companyId) ||
          []
      : data;
  }, [companyId, data]);

  return { companyDetails: companyDetailsByCompany || [], ...rest };
};
