import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AuthContext } from 'contexts';
import { Card } from 'components/card';
import { EditAndRemoveMenu } from 'components/edit-and-remove-menu';
import { PolymorphicButton } from 'components/polymorphic-button';
import { TaskAnswerButton } from 'components/task-answer-button';
import { TaskInfo } from 'components/task-info';
import { TaskModal } from 'components/task-modal';
import { TaskFormData } from 'components/task-modal/task-form';
import { getPathDestination } from 'helpers/utils';
import { useModal } from 'hooks/use-modal';
import { Task } from 'types/api';
import { ResourceTypes } from 'types/enums';
import './participant-task-implementation-card.scss';

const ANSWERS_TO_DISPLAY = 4;

type TaskCardProps = {
  taskDetail: Task;
  handleModal?: (id: number) => void;
  handleAnswerModal: (answerId: number, taskId: number) => void;
  handleParticipantsAnswersModal: (taskId: number) => void;
  removeTask: (id: number) => void;
  editTaskSubmit: (data: TaskFormData, taskId: number) => void;
};

export const ParticipantTaskImplementationCard: React.FC<TaskCardProps> = ({
  taskDetail,
  handleModal,
  handleAnswerModal,
  handleParticipantsAnswersModal,
  removeTask,
  editTaskSubmit,
}) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [isTaskModalOpen, handleOpenTaskModalClick, handleTaskModalClose] =
    useModal();

  const isMoreAnswers = taskDetail.answers.length > ANSWERS_TO_DISPLAY;
  const answersToDisplay = isMoreAnswers
    ? taskDetail.answers.slice(0, ANSWERS_TO_DISPLAY)
    : taskDetail.answers;
  const numberOfRemainingAnswers =
    taskDetail.answers.length - ANSWERS_TO_DISPLAY;

  const isModuleLesson =
    taskDetail?.resource_type === ResourceTypes.MODULE_LESSON;

  const isTasksOwner = user?.id === taskDetail.owner?.id;

  const handleRemoveTask = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('sure-to-delete-task'))) {
      removeTask(taskDetail.id);
    }
  };

  const getParticipantsIds = () =>
    taskDetail.participants.map((participant) => participant.id);

  return (
    <div className="task-card-implementation">
      <Card className="task-card-implementation__card">
        <div className="task-card-implementation__participant-task">
          <header className="task-card-implementation__header">
            <PolymorphicButton
              as={handleModal ? 'button' : 'div'}
              {...(handleModal && {
                onClick: () => handleModal(taskDetail.id),
              })}
              className={clsx(
                'task-card-implementation__name',
                handleModal && 'task-card-implementation__name--button'
              )}
            >
              <div className="task-card-implementation__ellipsis-wrapper">
                {taskDetail.course.name}
              </div>
            </PolymorphicButton>

            {isTasksOwner && (
              <>
                <EditAndRemoveMenu
                  onRemove={handleRemoveTask}
                  onEdit={handleOpenTaskModalClick}
                  isModuleLesson={isModuleLesson}
                  dropdownClassName="task-card-implementation__dropdown"
                />

                <TaskModal
                  isEditing
                  isOpen={isTaskModalOpen}
                  onClose={handleTaskModalClose}
                  initialFormValues={{
                    description: taskDetail.description || '',
                    instruction: taskDetail.instruction,
                    name: taskDetail.name || '',
                    course_id: taskDetail.course.id.toString(),
                    resource_id: taskDetail.resource.id.toString(),
                  }}
                  onSubmit={(data: TaskFormData) =>
                    editTaskSubmit(
                      {
                        ...data,
                        participant_ids: getParticipantsIds(),
                        resource_type: taskDetail.resource_type,
                      },
                      taskDetail.id
                    )
                  }
                />
              </>
            )}
          </header>

          <div className="task-card-implementation__hr-line" />

          <TaskInfo
            className="task-card-implementation__task-info"
            isModule={taskDetail.resource_type === ResourceTypes.COURSE_MODULE}
            resourceName={taskDetail.resource.name}
            resourceOrder={taskDetail.resource_number}
            owner={taskDetail.owner}
          />

          <div className="task-card-implementation__task-name">
            {`${taskDetail.resource.name} - ${getPathDestination(
              taskDetail.name
            )}`}
          </div>

          <div
            className="task-card-implementation__task-description"
            dangerouslySetInnerHTML={{
              __html: taskDetail.instruction,
            }}
          />
        </div>

        <div className="task-card-implementation__participant">
          {answersToDisplay.map(({ id, user: answerUser, status }) => (
            <TaskAnswerButton
              key={id}
              user={answerUser}
              status={status}
              onClick={() => handleAnswerModal(id, taskDetail.id)}
            />
          ))}
          {isMoreAnswers && (
            <button
              className="task-card-implementation__clickable task-card-implementation__more-participants"
              onClick={() => handleParticipantsAnswersModal(taskDetail.id)}
            >
              +{numberOfRemainingAnswers}
            </button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ParticipantTaskImplementationCard;
