import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Question } from 'components/quiz';
import { InfoText } from 'components/quiz/participant-quiz/info-text';
import { LinearIndicator } from 'components/quiz/participant-quiz/linear-indicator';
import { QuizQuestionV2 } from 'types/api';
import { ControlButtons } from './buttons';
import './form.scss';

type FormProps = {
  questions: QuizQuestionV2[];
  onSubmit: (answers: { answer_ids: number[] }) => void;
};

const createQuizInitialState = (questions: QuizQuestionV2[]) =>
  questions.map(({ id }) => ({ questionId: id, answerId: '' }));

export const Form: React.FC<FormProps> = ({ questions, onSubmit }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(1);
  const [isError, setIsError] = useState(false);
  const stepsTotalCount = questions.length;
  const isFirstStep = activeStep === 1;
  const isLastStep = activeStep === stepsTotalCount;
  const [answers, setAnswers] = useState(createQuizInitialState(questions));
  const [isSend, setIsSend] = useState<boolean>(false);

  const currentQuestion = questions.find(
    (_, index) => index + 1 === activeStep
  );

  const questionValue = answers.find(
    (answer) => answer.questionId === currentQuestion?.id
  )?.answerId;

  const handleAnswerChange = (answerId: string) => {
    if (currentQuestion) {
      setAnswers((prevAnswers) =>
        prevAnswers.map((answer) =>
          answer.questionId === currentQuestion.id
            ? { ...answer, answerId }
            : answer
        )
      );
      setIsError(false);
    }
  };

  const validateQuestion = (callback: () => void) => {
    if (questionValue) {
      callback();
    } else {
      setIsSend(false);
      setIsError(true);
    }
  };

  const goToPreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
    if (isError) {
      setIsError(false);
    }
  };

  const goToNextStep = () =>
    validateQuestion(() => setActiveStep((prevStep) => prevStep + 1));

  const handleSubmit = () => {
    setIsSend(true);
    validateQuestion(() =>
      onSubmit({ answer_ids: answers.map(({ answerId }) => +answerId) })
    );
  };

  return (
    <form className="form" onSubmit={(event) => event.preventDefault()}>
      <LinearIndicator value={activeStep} outOf={stepsTotalCount} />

      <InfoText>
        {t('question')} {activeStep}/{stepsTotalCount}
      </InfoText>

      {!!currentQuestion && (
        <Question
          question={currentQuestion}
          orderNumber={activeStep}
          value={questionValue}
          onChange={handleAnswerChange}
        />
      )}

      <span className="form__error" role="alert">
        {isError && t('answer-question')}
      </span>

      <ControlButtons
        isFirstQuestion={isFirstStep}
        isLastQuestion={isLastStep}
        previousQuestion={goToPreviousStep}
        nextQuestion={goToNextStep}
        submit={handleSubmit}
        isSend={isSend}
      />
    </form>
  );
};
