import React from 'react';
import './courses-accordion-item.scss';

type CoursesAccordionItemTypes = {
  name: string;
};

export const CoursesAccordionItem: React.FC<CoursesAccordionItemTypes> = ({
  name,
}) => {
  return <div className="courses-accordion-item">{name}</div>;
};
