import { components, GroupBase, OptionProps } from 'react-select';
import clsx from 'clsx';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkbox-checked.svg';
import './custom-option.scss';

export const CustomOption = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  ...props
}: React.PropsWithChildren<OptionProps<Option, IsMulti, Group>>) => {
  const { label, isSelected, isMulti } = props;

  const { Option: NativeOption } = components;

  if (isMulti) {
    return (
      <NativeOption {...props} className="option">
        <div
          className={clsx(
            'option__square',
            isSelected && 'option__square--checked'
          )}
        >
          {isSelected && <CheckboxChecked className="option__icon" />}
        </div>
        <div className="option__label">{label}</div>
      </NativeOption>
    );
  }

  return (
    <NativeOption {...props} className="option">
      <div className="option__label">{label}</div>
    </NativeOption>
  );
};
