import { useTranslation } from 'react-i18next';
import { Modal } from 'components/modal';
import { TaskAnswerButton } from 'components/task-answer-button';
import { Task } from 'types/api';
import './more-answers-modal.scss';

type ModalParticipantsAnswersProps = {
  show: boolean;
  onClose: () => void;
  handleAnswerModal: (answerId: number, taskId: number) => void;
  currentTask: Task | null;
};

export const ModalParticipantsAnswers: React.FC<
  ModalParticipantsAnswersProps
> = ({ show, onClose, currentTask, handleAnswerModal }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={show} onRequestClose={onClose} noPadding maxWidth="1000px">
      <div className="more-answers">
        <p className="more-answers__header">{t('all-answers')}</p>
        <div className="more-answers__answers">
          {(currentTask?.answers || []).map(({ id, user, status }) => (
            <TaskAnswerButton
              key={id}
              onClick={() =>
                currentTask && handleAnswerModal(id, currentTask.id)
              }
              layout="large"
              status={status}
              user={user}
              withDetails
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
