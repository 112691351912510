import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { AuthContext } from 'contexts';
import { isFuture, isPast } from 'date-fns';
import { CourseDetailCard } from 'components/course-detail-card';
import { Loader } from 'components/loader';
import { NoResource } from 'components/no-resource';
import { ViewTransition } from 'components/view-transition';
import { format } from 'helpers/time';
import { useCourses } from 'hooks/use-courses';
import './courses-grid.scss';

export const CoursesGrid: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isFinished = pathname.includes('completed');
  const { isLoading, courses } = useCourses({
    isFinished,
  });

  const { isCoach } = useContext(AuthContext);

  const isCourseDisabled = (startDate: string) =>
    isFinished || isFuture(new Date(startDate));

  const tippyContent = (startDate: string) =>
    isCoach
      ? t('company-has-no-access', {
          startDate: format(startDate),
        })
      : t('no-course-access', {
          startDate: format(startDate),
        });

  if (isLoading) {
    return <Loader />;
  }

  if (!courses?.length) {
    return <NoResource>{t('no-courses')}</NoResource>;
  }

  return (
    <ViewTransition resolved={!isLoading}>
      <div className="courses-grid">
        {courses.map((course) => (
          <Tippy
            disabled={isPast(new Date(course.start_date))}
            content={tippyContent(course.start_date)}
            key={course.id}
          >
            <div>
              <CourseDetailCard
                disabled={isCourseDisabled(course.start_date)}
                key={course.id}
                subscriptionCourse={course}
              />
            </div>
          </Tippy>
        ))}
      </div>
    </ViewTransition>
  );
};
